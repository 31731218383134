<div class="main-mat">
    <mat-card-title>
        <div class="row" style="margin-left: 0px; margin-right: auto; padding-top: 0px;">
            Edit Members
            <button mat-icon-button class="btn-close"  (click)="onCloseClick()"><mat-icon>close</mat-icon></button>
        </div>
    </mat-card-title>
    <app-participant-adding
        [selectedUserAttendeeList]="selectedUserMemberList"
        [selectedReqAttendeeList]="selectedReqMemberList"
        [removedUserAttendeeList]="removedUserMemberList"
        [removedReqAttendeeList]="removedReqMemberList"
        [origin]="origin"
        [participantType]="groupCategory">
    </app-participant-adding>
    <div>
        <button mat-raised-button (click)="updateMembers()" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px; margin-right: 5px;">Save</button>
        <button mat-raised-button (click)="onCloseClick()" color="primary" type="button" class="mat-button-class" style="float: right;">Cancel</button>
    </div>
</div>