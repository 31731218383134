<div class="main-mat">
    <mat-card-title>
        <div class="row" style="margin-left: 0px; margin-right: auto; padding-top: 0px;">
            {{event_occur_id}}
            <button mat-icon-button class="btn-close"  (click)="onCloseClick()"><mat-icon>close</mat-icon></button>
        </div>
    </mat-card-title>

    <!-- Event details -->
    <mat-card-subtitle>Event details</mat-card-subtitle>
    <mat-card class="in-mat">
        <div class="container">
            <form [formGroup]="event_details" >
                <div class="row">
                    <div class="col-md-9 col-xs-12">
                        <div class="row">               
                            <div class="long-text col-2">Event Name</div>
                            <div *ngIf="!editMode" class="col-10">
                                {{event_details.controls.event_name.value}}
                            </div>
                            <div *ngIf="editMode">
                                <mat-form-field class="long-input col-10 ">
                                    <input type="text" formControlName="event_name" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">               
                            <div class="long-text col-2">Description</div>
                            <div *ngIf="!editMode" class="col-10">
                                {{event_details.controls.event_desc.value}}
                            </div>
                            <div *ngIf="editMode">
                                <mat-form-field class="long-input col-10">
                                    <textarea formControlName="event_desc"  type="text" matInput required style="height: 20px"></textarea>
                                </mat-form-field>
                            </div>
                        </div>                     
                        <div class="row">               
                            <div class="long-text col-2">Category</div>
                            <div *ngIf="!editMode" class="col-10">
                                {{event_details.controls.event_category.value}}
                            </div>
                            <div *ngIf="editMode">
                                <mat-form-field class="col-10 inputLabel">
                                    <input type="text" formControlName="event_category" matInput>
                                </mat-form-field>
                            </div>
                        </div>                                     
                        <div class="row">               
                            <div class="long-text col-2">Event Type</div>
                            <div *ngIf="!editMode" class="col-10">
                                {{event_details.controls.event_type.value}}
                            </div>
                            <div *ngIf="editMode">
                                <mat-form-field class="col-10 inputLabel">
                                    <mat-select formControlName="event_type" >
                                        <mat-option value="OPEN">OPEN</mat-option> 
                                        <mat-option value="CLOSE">CLOSE</mat-option> 
                                    </mat-select>  
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">               
                            <div class="long-text col-2">All Day</div>
                            <div class="col-10">
                                <span class="badge" [ngClass]="{'badge-secondary': event_details.controls.all_day.value != '1',
                                'badge-success': event_details.controls.all_day.value == '1'}"> 
                                {{event_details.controls.all_day.value == '1'? 'Yes' : 'No'}} 
                                </span>
                            </div>
                        </div>
                        <div class="row">               
                            <div class="long-text col-2">Occurence</div>
                            <div class="col-10">
                                {{event_details.controls.occurrence.value}}
                            </div>
                        </div>
                        <div class="row" *ngIf="event_details.controls.occurrence.value == 'REPEAT'">               
                            <div class="long-text col-2">Repeat Interval</div>
                            <div class="col-10">
                                {{event_details.controls.repeat_interval.value}}
                            </div>
                        </div>
                        <div class="row" *ngIf="event_details.controls.occurrence.value == 'REPEAT'">               
                            <div class="long-text col-2">Occurence End Date</div>
                            <div class="col-10">
                                {{event_details.controls.occurrence_end_date.value}}
                            </div>
                        </div>                   
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="button-line">
                    <button mat-raised-button *ngIf="editMode" (click)="saveChanges()" [disabled]="occurrence_details.pristine" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px">Save</button>

                    <button mat-raised-button (click)="onEditCancel()" color="primary" type="button" class="mat-button-class" style="float: right;">{{editBtnName}}</button>

                    </div>
                </div> -->
            </form>
        </div>
    </mat-card>  
    <!-- end event details -->
    <!-- occurence details -->
    <mat-card-subtitle>Occurence details</mat-card-subtitle>
    <mat-card class="in-mat">
        <div class="container">
            <form [formGroup]="occurrence_details" >              
                <div class="row">                 
                    <div class="col-md-6 col-xs-12">
                        <div class="row">               
                            <div class="col-2">Organized by</div>
                            <div *ngIf="!editMode" class="col-10">
                                {{occurrence_details.controls.organized_by_name.value}}
                            </div>
                            <div *ngIf="editMode">
                                <mat-form-field class="col-10 inputLabel">
                                    <input type="text" matInput formControlName="organized_by_name" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option (onSelectionChange)="assigneeId = assignee.user_id;" *ngFor="let assignee of filteredAssignees | async" [value]="assignee.first_name + ' ' + assignee.last_name">
                                        <img class="select-option-img" aria-hidden [src]="assignee.profile_url || '../../assets/images/user-default.png'" height="25">
                                        {{'   ' + assignee.first_name + ' ' + assignee.last_name}}
                                    </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">               
                            <div class="col-2">Start Date</div>
                            <div *ngIf="!editMode" class="col-10">
                                {{occurrence_details.controls.start_date.value}}
                            </div>
                            <div *ngIf="editMode">
                                <mat-form-field class="col-10 inputLabel">
                                    <input matInput [matDatepicker]="pickerEndDate"
                                        formControlName="start_date" required>
                                    <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerEndDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">               
                            <div class="col-2">Start Time</div>
                            <div *ngIf="!editMode || event_details.controls.all_day.value == '1'" class="col-10">
                                {{occurrence_details.controls.start_time.value}}
                            </div>
                            <div *ngIf="editMode && event_details.controls.all_day.value != '1'">
                                <mat-form-field class="col-10 inputLabel">
                                    <input type="time" formControlName="start_time" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="row">               
                            <div class="col-2">Location</div>
                            <div *ngIf="!editMode" class="col-10">
                                {{occurrence_details.controls.location.value}}
                            </div>
                            <div *ngIf="editMode">
                                <mat-form-field class="col-10 inputLabel">
                                    <input type="text" formControlName="location" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">               
                            <div class="col-2">End Date</div>
                            <div *ngIf="!editMode" class="col-10">
                                {{occurrence_details.controls.end_date.value}}
                            </div>
                            <div *ngIf="editMode">
                                <mat-form-field class="col-10 inputLabel">
                                    <input matInput [matDatepicker]="pickerEndDate"
                                        formControlName="end_date" required>
                                    <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerEndDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">               
                            <div class="col-2">End Time</div>
                            <div *ngIf="!editMode || event_details.controls.all_day.value == '1'" class="col-10">
                                {{occurrence_details.controls.end_time.value}}
                            </div>
                            <div *ngIf="editMode && event_details.controls.all_day.value != '1'">
                                <mat-form-field class="col-10 inputLabel">
                                    <input type="time" formControlName="end_time" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="button-line">
                        <button mat-raised-button *ngIf="editMode" (click)="saveChanges()" [disabled]="occurrence_details.pristine && event_details.pristine" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px">Save</button>
                        <button mat-raised-button *ngIf="permission.PERM_EDIT_EVENT" (click)="onEditCancel()" color="primary" type="button" class="mat-button-class" style="float: right;">{{editBtnName}}</button>
                    </div>
                </div>

            </form>
        </div>
    </mat-card>
    <!--end occurence details -->
    <!--participants details -->
    <mat-card class="in-mat" style="padding: 16px 1px">
        <mat-expansion-panel (opened)="loadAttendees()" style="width: 100%;">
            <mat-expansion-panel-header>
                Participants Details
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>           
                <div class="row"> 
                    <mat-card class="col card-outline box-card">
                        <mat-card-header style="display: inline;">   
                            <mat-card-title>
                                <div class="row">User Attendees
                                    <i id="count">{{(filteredUserAttendees | async)?.length}}</i>
                                </div>
                                <div class="row">
                                    <div class="col-9" style="padding:0px">
                                        <mat-form-field class="box-card-search">  
                                            <i matPrefix class="fa fa-search" style="width: 25px;" aria-hidden="true"></i>
                                            <input type="text" matInput [formControl]="userAttendeeSearch">
                                        </mat-form-field>
                                    </div>
                                </div>                              
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="box-card-content">   
                            <div class="not-found-option" *ngIf="(filteredUserAttendees | async)?.length == 0">No participant</div>                     
                            <mat-nav-list>
                                <mat-list-item id="box-list-option" *ngFor="let participant of filteredUserAttendees | async">
                                    <img class="list-option-img" [src]="participant.profile_url || '../../assets/images/user-default.png'" alt="avatar" height="30">
                                    <div class="mat-list-text">{{participant.first_name}}</div>
                                </mat-list-item>
                            </mat-nav-list>                                      
                        </mat-card-content>        
                    </mat-card>
                    <mat-card *ngIf="requesterAttendeeList?.length !=0" class="col card-outline box-card">
                        <mat-card-header style="display: inline;">   
                            <mat-card-title>
                                <div class="row">Requester Attendees
                                    <i id="count">{{(filteredReqAttendees | async)?.length}}</i>
                                </div>
                                <div class="row">
                                    <div class="col-9" style="padding:0px">
                                        <mat-form-field class="box-card-search">  
                                            <i matPrefix class="fa fa-search" style="width: 25px;" aria-hidden="true"></i>
                                            <input type="text" matInput [formControl]="reqAttendeeSearch">
                                        </mat-form-field>
                                    </div>
                                </div>                                
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="box-card-content">   
                            <div class="not-found-option" *ngIf="(filteredReqAttendees | async)?.length == 0">No participants</div>                     
                            <mat-nav-list>
                                <mat-list-item id="box-list-option" *ngFor="let participant of filteredReqAttendees | async">
                                    <img class="list-option-img" [src]="participant.profile_url || '../../assets/images/user-default.png'" alt="avatar" height="30">
                                    <div class="mat-list-text">{{participant.name_in_full}}</div>
                                </mat-list-item>
                            </mat-nav-list>                                      
                        </mat-card-content>        
                    </mat-card>
                </div>
                <div class="row">
                    <button *ngIf="permission.PERM_EDIT_EVENT" mat-flat-button (click)="addOrEditParticipants()" color="primary" type="button" style="margin:auto">Add/Edit Participants</button>
                </div>
            </ng-template>    
        </mat-expansion-panel>
    </mat-card> 
    
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Comments">
          <!-- comment section -->
          <mat-card class="comment">
            <mat-form-field class="text-area">
              <mat-label>Add a comment</mat-label>
              <textarea matInput [(ngModel)]="comment" placeholder="Leave your comment here...."></textarea>
            </mat-form-field>
            <button mat-raised-button (click)="setComment()" style="margin-top: 20px;">Post Comment</button>
          </mat-card>
          <mat-card>
            <!-- <mat-list *ngIf="comments[0].comment != null"> -->
            <mat-list *ngIf="comments">
              <mat-list-item *ngFor="let comment of comments">
                <h4 class="comment-head" matLine>
                  <img class="comment-image" alt width="16" height="16"
                    [src]=" comment.profile_url != null ? comment.profile_url : '../../assets/images/user-default.png' "/>
                  {{ comment.commented_by_name || "Requester" }} added comment -
                  {{ comment.commented_date }}
                </h4>
                <!-- <h5 matLine>{{comment.commented_date}}</h5> -->
                <p matLine class="comment-line">
                  <span class="demo-2">{{ comment.comment }}</span>
                </p>
              </mat-list-item>
            </mat-list>
          </mat-card>
          <!-- end comment section -->
        </mat-tab>
        <!-- <mat-tab label="History">
          
        </mat-tab> -->
      </mat-tab-group>

</div>