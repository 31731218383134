import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ViewEventService } from '../event-view.service';

export interface UserAttendee {
  user_id: string;
  user_name: string;
  first_name: string;
  last_name: string;
  profile_url: string;
  selected: boolean;
}

export interface ReqAttendee {
  nic: string;
  name_in_full: string;
  selected: boolean;
}

@Component({
  selector: 'app-edit-participant-dlg',
  templateUrl: './edit-participant-dlg.component.html',
  styleUrls: ['./edit-participant-dlg.component.css']
})
export class EditParticipantDlgComponent implements OnInit {

  selectedUserAttendeeList: UserAttendee[] = [];
  selectedReqAttendeeList: ReqAttendee[] = [];
  event_occur_id: string;
  origin: string = 'event-view'; //To pass participant-adding component
  public selectedUserIds: String[] = []; //Use when submit
  public selectedReqNics: String[] = []; //Use when submit

  constructor(
    public dialogRef: MatDialogRef<EditParticipantDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private viewEventService  : ViewEventService,
    private toastr            : ToastrService
  ) 
  {
    this.event_occur_id = data.event_occur_id;
    this.selectedUserAttendeeList = data.user_attendees;
    this.selectedReqAttendeeList = data.req_attendees;
  }

  ngOnInit(): void {
  }

  // loadAttendees() {
  //   this.viewEventService.getEventAttendees(this.event_occur_id).subscribe(res => {
  //     if(res != null){
  //       res['requester_attendees'].forEach(element => {
  //         if(!this.selectedReqAttendeeList.some(ele => ele.nic == element.nic)){ //Restrict duplicate data
  //           this.selectedReqAttendeeList.push(element);        
  //         }  
  //       });
  //       res['user_attendees'].forEach(element => {
  //         if(!this.selectedUserAttendeeList.some(ele => ele.user_id == element.user_id)){ //Restrict duplicate data
  //           this.selectedUserAttendeeList.push(element);     
  //         }     
  //       });
  //     //  this.setReqAttendeeFilter();
  //      // this.setUserAttendeeFilter();
  //     }   
  //   });
  // }

  onCloseClick(){
    this.dialogRef.close();
  }

  updateParticipants(){
    this.selectedUserAttendeeList.forEach(element => {
      this.selectedUserIds.push(element.user_id);
    });

    this.selectedReqAttendeeList.forEach(element => {
      this.selectedReqNics.push(element.nic);
    });
    this.viewEventService.updateEventOccurParticipants(this.event_occur_id, this.selectedUserIds, this.selectedReqNics).subscribe(
      res => {
        if(res['status'] == 'success'){
          this.toastr.success('Event participants updated successfully', 'Success');
        }
        else{
          this.toastr.error('Something went wrong!', 'Failed');
        }
      },
      err => {
        this.toastr.error('Something went wrong!', 'Failed');
      }
    );
  }

}
