import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { AddItemDlgComponent } from './add-item-dlg/add-item-dlg.component';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.css']
})
export class LabelsComponent implements OnInit {

  labels: string[] = ['Lemon', 'Dhana'];
  removable = true;

  constructor(
    private confDialog  : MatDialog
  ) { }

  ngOnInit(): void {
  }

  remove(label: string): void {
    const title = 'Delete Confirmation';
    const message = 'Are you sure you want to this label?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
      //   this.designationsService.deleteDesignation(rowData)
      // .subscribe((data: any) => {
      //   if(data['status'] == "success"){
      //     this.designationDetails.data.splice(index, 1); // Remove from datasource
      //     this.designationDetails.data = this.designationDetails.data.slice();
      //     this.toastr.success('Record is deleted successfully', 'Success!');
      //   }
      //   else{
      //     //this.cancelEdit(rowData);
      //     this.toastr.error("Delete failed", 'Error!');
      //   }
      // }, err => {
      //     this.toastr.error("Sever error", 'Error!');         
      // });

        const index = this.labels.indexOf(label);

        if (index >= 0) {
          this.labels.splice(index, 1);
        }
      
      }
    });   
  }

  onClickAdd(){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    // dialogconfig.data = {
    //   title : title,
    //   message : message
    // }
    var dialog_ref = this.confDialog.open(AddItemDlgComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res['confirm']){
        console.log("%%%%%%%%%%%%%%%%%%%%%%%");
      //   this.designationsService.deleteDesignation(rowData)
      // .subscribe((data: any) => {
      //   if(data['status'] == "success"){
      //     this.designationDetails.data.splice(index, 1); // Remove from datasource
      //     this.designationDetails.data = this.designationDetails.data.slice();
      //     this.toastr.success('Record is deleted successfully', 'Success!');
      //   }
      //   else{
      //     //this.cancelEdit(rowData);
      //     this.toastr.error("Delete failed", 'Error!');
      //   }
      // }, err => {
      //     this.toastr.error("Sever error", 'Error!');         
      // });

      this.labels.push(res['itemValue']);
      
      }
    });   
  }
}
