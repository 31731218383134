<div class="main-mat">
    <mat-card-title>
        <div class="row" style="margin-left: 0px; margin-right: auto; padding-top: 0px;">
            {{agrNumber}}
            <span id="tag" class="badge badge-danger" [ngClass]="{'badge-secondary': state == 'New', 
            'badge-warning': state == 'In Progress','badge-success': state == 'Completed', 
            'badge-danger': state == 'Canceled'}"> 
            {{state}} 
            </span>
            <button mat-icon-button class="btn-close"  (click)="onCloseClick()"><mat-icon>close</mat-icon></button>
        </div>
    </mat-card-title>

    <!-- user details -->
    <mat-card-subtitle>Agreement details</mat-card-subtitle>
    <mat-card class="in-mat">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-xs-12">
                    <div class="row">               
                        <div class="col-2">Priority</div>
                        <div *ngIf="!editPriority" (click)="editPriority = true" class="col-10" (mouseover)="showEditPriority = true" (mouseleave)="showEditPriority = false">
                            <img alt width="16" height="16" 
                                [src]="priority == '1'? '../../assets/images/critical.svg':
                                    priority == '2'? '../../assets/images/major.svg':
                                    priority == '3'? '../../assets/images/minor.svg':
                                    priority == '4'? '../../assets/images/trivial.svg': null">
                            {{priority == '1' ? 'One':
                            priority == '2' ? 'Two':
                            priority == '3' ? 'Three':
                            priority == '4' ? 'Four': null}}
                            <i *ngIf="showEditPriority" (click)="editPriority = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="editPriority">
                            <mat-form-field class="col-10 inputLabel" style="max-width: 180px;">
                                <span matPrefix style="padding-right:3px; padding-top: 3px; display:inline-block;">
                                    <img alt width="16" height="16" 
                                    [src]="priority == '1'? '../../assets/images/critical.svg':
                                        priority == '2'? '../../assets/images/major.svg':
                                        priority == '3'? '../../assets/images/minor.svg':
                                        priority == '4'? '../../assets/images/trivial.svg': null">
                                </span>
                                <mat-select [(value)]="priority">
                                    <mat-option value="1"><img alt width="16" height="16" src="../../assets/images/critical.svg"> One</mat-option>
                                    <mat-option value="2"><img alt width="16" height="16" src="../../assets/images/major.svg"> Two</mat-option>
                                    <mat-option value="3"><img alt width="16" height="16" src="../../assets/images/minor.svg"> Three</mat-option>
                                    <mat-option value="4"><img alt width="16" height="16" src="../../assets/images/trivial.svg"> Four</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <i (click)="changePriority()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">State</div>
                        <div class="col-10">{{state}}</div>
                    </div>
                    <div class="row">
                        <div class="col-2">Category</div>
                        <div class="col-10" *ngIf="!editCategory" (click)="editCategory = true" (mouseover)="showEditCategory = true" (mouseleave)="showEditCategory = false">
                            {{categoryName}}
                            <i *ngIf="showEditCategory" (click)="loadCategoryList(); editCategory = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="editCategory">
                            <mat-form-field *ngIf="editCategory" class="col-10 inputLabel">
                                <mat-select [(value)]="categoryId">
                                    <mat-option *ngFor="let category of categoryList" [value]="category.agr_category_id">
                                        {{category.agr_category_name}}
                                    </mat-option> 
                                </mat-select>                                     
                            </mat-form-field>
                            <i (click)="changeCategory()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
                        </div>    
                    </div>
                    <!-- <div class="row">
                        <div class="col-2">GS Division</div>
                        <div class="col-10" *ngIf="!editDivision" (click)="editDivision = true" (mouseover)="showEditDivision = true" (mouseleave)="showEditDivision = false">
                            {{division.value || "Unknown"}}
                            <i *ngIf="showEditDivision" (click)="loadDivisionList(); editDivision = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
                        </div>
                        <div class="col-10" *ngIf="editDivision">
                            <mat-form-field class="inputLabel" style="max-width: 230px; width: 230px;">
                                <input type="text" matInput [formControl]="division" [matAutocomplete]="autoDiv"/>
                                <mat-autocomplete #autoDiv="matAutocomplete">
                                  <mat-option 
                                    (onSelectionChange)="divisionId = division.division_id"
                                    *ngFor="let division of filteredDivisionList | async" 
                                    [value]="division.division_id + ' - ' + division.division_name">
                                    {{"   " + division.division_id + " - " + division.division_name}}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                            <i (click)="changeDivision()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
                        </div>    
                    </div> -->
                    <div class="row">
                        <div class="col-2">Agreement Date</div>
                        <div class="col-10">{{agrDate}}</div>
                    </div>
                    <div class="row">
                        <div class="col-2">Due Date</div>
                        <div class="col-10" *ngIf="!editDueDate" (click)="editDueDate = true" (mouseover)="showEditDueDate = true" (mouseleave)="showEditDueDate = false">
                            {{dueDate}}
                            <i *ngIf="showEditDueDate" (click)="editDueDate = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="editDueDate">
                            <mat-form-field class="col-10 inputLabel">                
                                <input matInput [matDatepicker]="pickerDue" [(ngModel)]="dueDate" [min]="agrDate">
                                <mat-datepicker-toggle matSuffix [for]="pickerDue"></mat-datepicker-toggle>
                                <mat-datepicker #pickerDue></mat-datepicker>
                            </mat-form-field>
                            <i (click)="changeDueDate()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
                        </div>
                    </div>           
                    
                    <div class="row">
                        <div class="col-2">Followup By</div>
                        <div class="col-10" *ngIf="!editFollowUpBy" (mouseover)="showEditFollowUpBy = true" (mouseleave)="showEditFollowUpBy = false">
                            <img class="select-option-img" aria-hidden [src]="followerUrl || '../../assets/images/user-default.png'" height="25">
                            {{followerName.value || 'Unassigned'}}
                            <i *ngIf="showEditFollowUpBy" (click)="loadUserList(); editFollowUpBy = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
                        </div>
                        <div class="col-10" *ngIf="editFollowUpBy">
                            <mat-form-field class="inputLabel" style="max-width: 230px; width: 230px;">
                                <input type="text" matInput [formControl]="followerName" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete">
                                  <mat-option (onSelectionChange)="followerId = follower.user_id; followerUrl = follower.profile_url" *ngFor="let follower of filteredFollowers | async" [value]="follower.first_name + ' ' + follower.last_name">
                                    <img class="select-option-img" aria-hidden [src]="follower.profile_url || '../../assets/images/user-default.png'" height="25">
                                    {{"   " + follower.first_name + ' ' + follower.last_name}}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <i (click)="changeFollowUpBy()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xs-12">
                    <button mat-stroked-button [disabled]="state == 'Canceled'" (click)="changeState('Canceled')" style="float: right;">Cancel</button>
                    <button mat-stroked-button (click)="changeState('check')" style="float: right; margin-right: 5px;">
                        {{state == 'New' ? 'Start Progress':
                        state == 'In Progress' ? 'Close':
                        'Reopen'}}
                    </button>

                </div>
            </div>
        </div>
    </mat-card>
    <mat-card style="border-top: none; padding: 0px 30px 20px 30px;">
        <mat-card-subtitle>{{agrTopic}}</mat-card-subtitle>
        <p>{{agrDesc}}</p>
    </mat-card>
    
    <!-- end genaral details -->
    <!-- Attachments -->
    <mat-card-subtitle>Attachments
        <input style="display: none" type="file" multiple (change)="onFileAdd($event.target.files)" #hiddenfileinput maxlength="1e+7" accept=".jpg,.jpeg,.png,.txt,.pdf,.zip,.xlsx,.pptx,.docx">
        <button mat-button (click)="hiddenfileinput.click()" style="float: right;"><mat-icon>control_point</mat-icon>Add New</button>
    </mat-card-subtitle>
    <mat-progress-bar mode="indeterminate" *ngIf="progress_bar" style="margin-bottom:2px"></mat-progress-bar>
    <mat-card>
        <mat-nav-list *ngIf="attachments.length > 0">
            <mat-list-item *ngFor="let attach of attachments">
                <mat-icon>attach_file</mat-icon> <a href="{{attach.link}}" target="_blank">{{attach.name}}</a>
                <button mat-icon-button (click)="onFileRemove(attach)" class="attachment-btn-close">
                    <mat-icon>close</mat-icon>
                </button>              
            </mat-list-item>
        </mat-nav-list>
        <div *ngIf="attachments.length == 0">
            No attachments
        </div>
    </mat-card>
    <!-- end attachment -->

    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Comments">
            
            <mat-card class="comment">
                <mat-form-field class="text-area">
                    <mat-label>Add a comment</mat-label>
                    <textarea matInput [(ngModel)]="comment" placeholder="Leave your comment here...."></textarea>
                </mat-form-field>
                <button mat-raised-button (click)="setComment()" style="margin-top: 20px;">Post Comment</button>
            </mat-card>
            <mat-card>
                <!-- <mat-list *ngIf="comments[0].comment != null"> -->
                <mat-list>
                    <mat-list-item *ngFor="let comment of comments">
                        <h4 class="comment-head" matLine><img class="comment-image" alt width="16" height="16" [src]="comment.profile_url != null? comment.profile_url : '../../assets/images/user-default.png'">  
                            {{comment.user_name}} added comment - {{comment.commented_date}}</h4>
                        <p matLine class="comment-line">                  
                            <span class="demo-2">{{comment.comment}}</span>
                        </p>
                    </mat-list-item>
                </mat-list>
            </mat-card>

        </mat-tab>
        <!-- <mat-tab label="History">
            <mat-list>
                <mat-list-item *ngFor="let comment of comments">
                    <h4 class="comment-head" matLine>  
                        {{comment.commented_date}}</h4>
                    <p matLine class="comment-line">   
                        <img class="history-image" alt width="32" height="32" [src]="comment.profile_url != null? comment.profile_url : '../../assets/images/user-default.png'">               
                        <span class="highlight-part">{{comment.user_name}}</span>
                        Created request
                    </p>
                </mat-list-item>
                <mat-list-item *ngFor="let comment of comments">
                    <h4 class="comment-head" matLine>  
                        {{comment.commented_date}}</h4>
                    <p matLine class="comment-line">   
                        <img class="history-image" alt width="32" height="32" [src]="comment.profile_url != null? comment.profile_url : '../../assets/images/user-default.png'">               
                        <span class="highlight-part">{{comment.user_name}}</span>
                        changed
                         <span class="highlight-part">State</span> New --> 
                        <!--In Progress
                    </p>
                </mat-list-item>
                <mat-list-item *ngFor="let comment of comments">
                    <h4 class="comment-head" matLine>  
                        {{comment.commented_date}}</h4>
                    <p matLine class="comment-line">   
                        <img class="history-image" alt width="32" height="32" [src]="comment.profile_url != null? comment.profile_url : '../../assets/images/user-default.png'">               
                        <span class="highlight-part">{{comment.user_name}}</span>
                        added a comment
                    </p>
                </mat-list-item>
            </mat-list>
        </mat-tab> -->
    </mat-tab-group>
   
    
</div>