import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';


const URL_get_category_list = environment.url_blogics + '/basic-data/agreement-category/get_agr_category_list.php';
const URL_add_new_agr_category = environment.url_blogics + '/basic-data/agreement-category/create_new_agr_category.php';
const URL_update_agr_category = environment.url_blogics + '/basic-data/agreement-category/update_agr_category.php';
const URL_delete_agr_category = environment.url_blogics + '/basic-data/agreement-category/remove_agr_category.php';
const URL_delete_agr_categories = environment.url_blogics + '/basic-data/agreement-category/remove_agr_categories.php';

@Injectable({
  providedIn: 'root'
})
export class AgreementCategoryService {

  constructor(private _http: HttpClient) { }

  getAgrCategoryList(){
    return this._http.get(URL_get_category_list);
  }

  saveNewAgrCategory(formData : FormData){
    return this._http.post(URL_add_new_agr_category, JSON.stringify(formData));
  }

  updateAgrCategory(rowData: any){
    return this._http.post(URL_update_agr_category, JSON.stringify(rowData));
  }

  deleteAgrCategory(rowData: any){
    return this._http.post(URL_delete_agr_category, JSON.stringify(rowData));
  }

  deleteAgrCategories(data: any){
    return this._http.post(URL_delete_agr_categories, JSON.stringify(data));
  }
}
