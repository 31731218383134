
<div class="window-title">
  <h2>Request Progress</h2>
</div>
<div class="search-div">
    <mat-paginator [pageSizeOptions]="[5,10,25,50,100]" [pageSize]="50" showFirstLastButtons>
    </mat-paginator>
    <div style="margin-top:25px">
        <mat-form-field class="search-form-field" floatLabel="never">
            <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off" (keyup)="applyFilter()">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <button mat-button (click)="onClickStateFilter('1')">
            <mat-icon *ngIf="state=='1'">visibility</mat-icon> New
        </button>
        <button mat-button (click)="onClickStateFilter('2')">
            <mat-icon *ngIf="state=='2'">visibility</mat-icon> In Progress
        </button>
        <button mat-button (click)="onClickStateFilter('6')">
            <mat-icon *ngIf="state=='6'">visibility</mat-icon> Pending Approval
        </button>
        <button mat-button (click)="onClickStateFilter('7')">
            <mat-icon *ngIf="state=='7'">visibility</mat-icon> Approved
        </button>
        <div class="mobileFilter">
            <button mat-button (click)="onClickStateFilter('5')">
                <mat-icon *ngIf="state=='5'">visibility</mat-icon> Resolved
            </button>
            <button mat-button (click)="onClickStateFilter('3')">
                <mat-icon *ngIf="state=='3'">visibility</mat-icon> Closed
            </button>
            <button mat-button (click)="onClickStateFilter('0')">
                <mat-icon *ngIf="state=='0'">visibility</mat-icon> All
            </button>
            <button mat-button (click)="onClickStateFilter('4')">
                <mat-icon *ngIf="state=='4'">visibility</mat-icon> Canceled
            </button>
            <button mat-button (click)="onClickStateFilter('8')">
                <mat-icon *ngIf="state=='8'">visibility</mat-icon> Rejected
            </button>
            <button mat-button (click)="onClickStateFilter('9')">
                <mat-icon *ngIf="state=='9'">visibility</mat-icon> Pending
            </button>
        </div>
        <button mat-icon-button class="mobileViewer" [matMenuTriggerFor]="filterMenu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #filterMenu="matMenu">
            <button mat-button (click)="onClickStateFilter('5')">
                <mat-icon *ngIf="state=='5'">visibility</mat-icon> Resolved
            </button>
            <button mat-menu-item (click)="onClickStateFilter('3')">
                <mat-icon *ngIf="state=='3'">visibility</mat-icon> Closed
            </button>
            <button mat-menu-item (click)="onClickStateFilter('0')">
                <mat-icon *ngIf="state=='0'">visibility</mat-icon> All
            </button>
            <button mat-menu-item (click)="onClickStateFilter('4')">
                <mat-icon *ngIf="state=='4'">visibility</mat-icon> Canceled
            </button>
            <button mat-menu-item (click)="onClickStateFilter('8')">
                <mat-icon *ngIf="state=='8'">visibility</mat-icon> Rejected
            </button>
            <button mat-menu-item (click)="onClickStateFilter('9')">
                <mat-icon *ngIf="state=='9'">visibility</mat-icon> Pending
            </button>
          </mat-menu>

        <a mat-button (click)="onClickShowFilters()" class="MoreInfoBtn" style="margin-left: 20px;">
            More
            <mat-icon *ngIf="!showFilters" aria-hidden="false">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="showFilters" aria-hidden="false">keyboard_arrow_up</mat-icon>
        </a>
        
        <button mat-stroked-button color="primary" type="button" (click)="onClickExport()" style="float: right; margin-left: auto; margin-top: 10px;">
            <mat-icon aria-hidden="false">save_alt</mat-icon>&emsp;Export
        </button>
        <button mat-icon-button [matMenuTriggerFor]="columnMenu" color="primary" style="float: right; margin-left: auto; margin-right: 5px; margin-top: 10px;">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #columnMenu="matMenu">
            <!-- <button *ngFor="let item of tableColumns" mat-menu-item>{{item}}</button> -->
            <ul class="menu-item">
            <div *ngFor="let item of additionalColumns">
                <mat-checkbox (change)="getCheckboxValues($event,item)" (click)="$event.stopPropagation()">
                    {{item}}
                </mat-checkbox>
            </div>
        </ul>
        </mat-menu>
    </div>
</div>
<div *ngIf="showFilters" class="row filters-row">
    <mat-form-field class="no-line">
        <mat-label>Requester Group</mat-label>
        <mat-select [formControl]="reqGroupFilterControl" multiple>       
            <!-- <mat-select-trigger>
            <mat-chip-list >
                <mat-chip *ngFor="let group of reqGroupFilterControl.value"
                [removable]="true" (removed)="onReqCategoryRemoved(group)">
                {{ group }}
                <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
            </mat-select-trigger> -->
            <mat-select-filter [array]="reqGroupList" (filteredReturn)="filteredReqGroupList=$event" [displayMember]="'group_id'">
            </mat-select-filter>
            <mat-option *ngFor="let group of filteredReqGroupList" [value]="group.group_id">
                {{group.group_id+' - '+group.group_name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="no-line">
        <mat-label>Section</mat-label>
        <mat-select [formControl]="sectionFilterControl" multiple>       
            <mat-select-filter [array]="sectionList" (filteredReturn)="filteredSectionList=$event" [displayMember]="'section_id'">
            </mat-select-filter>
            <mat-option *ngFor="let section of filteredSectionList" [value]="section.section_id">
                {{section.section_id+' - '+section.section_name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="no-line">
        <mat-label>Priority</mat-label>
        <mat-select [formControl]="priorityFilterControl" multiple>       
            <mat-option value="1"><img alt width="16" height="16"
                src="../../assets/images/critical.svg">
                One</mat-option>
            <mat-option value="2"><img alt width="16" height="16" src="../../assets/images/major.svg">
                Two
            </mat-option>
            <mat-option value="3"><img alt width="16" height="16" src="../../assets/images/minor.svg">
                Three
            </mat-option>
            <mat-option value="4"><img alt width="16" height="16" src="../../assets/images/trivial.svg">
                Four</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="no-line">
        <mat-label>Assigned to</mat-label>
        <mat-select [formControl]="assigneeFilterControl" multiple>       
            <mat-select-filter [array]="assigneeList" (filteredReturn)="filteredAssigneeList=$event" [displayMember]="'user_name'">
            </mat-select-filter>
            <mat-option *ngFor="let assignee of filteredAssigneeList" [value]="assignee.user_id">
                <img class="select-option-img" aria-hidden [src]="assignee.profile_url || '../../assets/images/user-default.png'" height="25">
                {{assignee.user_name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="no-line">
        <mat-label>Labels</mat-label>
        <mat-select [formControl]="sectionFilterControl" multiple>       
            <mat-select-filter [array]="sectionList" (filteredReturn)="filteredSectionList=$event" [displayMember]="'section_id'">
            </mat-select-filter>
            <mat-option *ngFor="let section of filteredSectionList" [value]="section.section_id">
                {{section.section_id+' - '+section.section_name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div>
        <button mat-stroked-button (click)="resetFilters()" color="primary" type="button">
          <mat-icon aria-hidden="false">settings_backup_restore</mat-icon>&emsp;Reset</button>
    </div>
    <div class="col-xl-2">
        <button mat-stroked-button (click)="search()" color="primary" type="button">
          <mat-icon aria-hidden="false">search</mat-icon>&emsp;Search</button>
    </div>
</div>

<div class="table-wrapper">   
    <mat-table matTableExporter [dataSource]="requestData" #exporter="matTableExporter" matSort>
        <ng-container matColumnDef="ref_no">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 120px;">Reference Number</mat-header-cell>
            <mat-cell class="clickOn" *matCellDef="let request" (click)="showRecord(request.ref_no)" style="min-width: 120px;">
                {{request.ref_no}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="topic">
            <mat-header-cell *matHeaderCellDef style="min-width: 250px; padding-left: 10px;">Topic</mat-header-cell>
            <mat-cell *matCellDef="let request"  style="min-width: 250px; padding-left: 10px;">{{request.topic}}</mat-cell>
        </ng-container>
        <!-- Export Attribute -->
        <ng-container matColumnDef="nic">
            <mat-header-cell *matHeaderCellDef>Req NIC</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.nic}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="name_in_full">
            <mat-header-cell *matHeaderCellDef style="min-width: 250px; margin-left: 10px;">Requestor</mat-header-cell>
            <mat-cell *matCellDef="let request" style="min-width: 250px; margin-left: 10px;">{{request.name_in_full}}</mat-cell>
        </ng-container>
        <!-- Export Attribute -->
        <ng-container matColumnDef="mobile_no">
            <mat-header-cell *matHeaderCellDef>Mobile No</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.mobile_no}}</mat-cell>
        </ng-container>
        <!-- Export Attribute -->
        <ng-container matColumnDef="address">
            <!-- <mat-header-cell *matHeaderCellDef style="display: none">Address</mat-header-cell>
            <mat-cell *matCellDef="let request" style="display: none">{{request.address}}</mat-cell> -->
            <mat-header-cell *matHeaderCellDef style="min-width: 220px; margin-right: 10px;">Address</mat-header-cell>
            <mat-cell *matCellDef="let request" style="min-width: 220px; margin-right: 10px;">{{request.address}}</mat-cell>
        </ng-container>
        <!-- Export Attribute -->
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.email}}</mat-cell>
        </ng-container>
        <!-- Export Attribute -->
        <!-- <ng-container matColumnDef="division">
            <mat-header-cell *matHeaderCellDef>Division</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.division}}</mat-cell>
        </ng-container> -->
        <!-- Export Attribute -->
        <!-- <ng-container matColumnDef="electoral_div">
            <mat-header-cell *matHeaderCellDef>Electorol</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.electoral_div}}</mat-cell>
        </ng-container> -->
        <!-- Export Attribute -->
        <!-- <ng-container matColumnDef="div_sec">
            <mat-header-cell *matHeaderCellDef>Divisional Sec</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.div_sec}}</mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="created_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.created_date}}</mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="section">
            <mat-header-cell *matHeaderCellDef>Section</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.section}}</mat-cell>
        </ng-container> -->
        <!-- <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.request_category}}</mat-cell>
        </ng-container> -->
        <!-- <ng-container matColumnDef="service_area">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Division</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.service_area}}</mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="priority">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Priority</mat-header-cell>
            <mat-cell *matCellDef="let request">
                <img alt width="16" height="16" 
                                        [src]="request.priority == '1'? '../../assets/images/critical.svg':
                                        request.priority == '2'? '../../assets/images/major.svg':
                                        request.priority == '3'? '../../assets/images/minor.svg':
                                        request.priority == '4'? '../../assets/images/trivial.svg': null">
                {{request.priority == '1'? 'One':
                request.priority == '2'? 'Two':
                request.priority == '3'? 'Three':
                request.priority == '4'? 'Four': 'Null'}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assigned_user">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.assigned_user}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="assigned_by">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned By</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.assigned_by}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
            <!-- <mat-cell *matCellDef="let request">{{request.status}}</mat-cell> -->
            <mat-cell *matCellDef="let request">           
                <span class="badge" [ngClass]="{
                    'badge-secondary': request.state == 'New',
                    'badge-warning': request.state == 'In Progress',
                    'badge-primary': request.state == 'Resolved',
                    'badge-success': request.state == 'Closed',
                    'badge-danger': request.state == 'Rejected',
                    'badge-dark': request.state == 'Approved',
                    'badge-info': request.state == 'Pending Approval',
                    'badge-light': request.state == 'Pending',
                    'badge-canceled': request.state == 'Canceled'
                  }"> 
                    {{request.state}} 
                </span>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
</div>
