<div class="row" style="margin-top: 15px; margin-right: 0px;">
    <div class="col-md-3 col-xs-6">
        <mat-form-field class="inline-form-field">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
    </div>
    <div class="col-md-9 col-xs-6">
        <button mat-raised-button (click)="addNewRecord()" type="button" style="margin-left:10px; margin-top:10px">
            <mat-icon aria-hidden="false">add</mat-icon>&emsp;
        </button>
        <button mat-raised-button (click)="deleteRows()" type="button" style="margin-left:10px; margin-top:10px">
            <mat-icon aria-hidden="false">delete</mat-icon>
            &emsp;
        </button>
    </div>
</div>
<mat-card [@slideInOut]="animationState" [hidden]="!showAddNew" class="add-new-card">
    <form [formGroup]="filed_details">
        <div class="row">
            <div class="col-md-2 col-xs-12">
                <mat-form-field class="inline-form-field">
                    <mat-label>Lable</mat-label>
                    <input matInput formControlName="field_lable" required>
                </mat-form-field>
            </div>

            <div class="col-md-4 col-xs-16">
                <mat-form-field class="inline-form-field">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="field_description" class="input_fld_long">
                </mat-form-field>
            </div>

            <div class="col-md-2 col-xs-12">
                <div class="row">
                    <mat-form-field class="inline-form-field">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="field_type" [formControl]="fieldType">
                            <mat-option [value]="'text'">Text</mat-option>
                            <mat-option [value]="'cdate'">Date</mat-option>
                            <mat-option [value]="'telephone'">Phone Number</mat-option>
                            <mat-option [value]="'email'">Email Address</mat-option>
                            <mat-option [value]="'cdatetime'">Date Time</mat-option>
                            <mat-option [value]="'counter'">Counter</mat-option>
                            <mat-option [value]="'richtext'">Rich Text</mat-option>
                            <mat-option [value]="'checkbox'">Check Box</mat-option>
                            <mat-option [value]="'radio'">Radio Button</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="inline-form-field" [hidden]="fieldType.value!=='counter'">
                        <mat-label>Max Number</mat-label>
                        <input matInput formControlName="field_counter_max" class="input_fld_long" >
                    </mat-form-field>
                    <mat-form-field class="inline-form-field" [hidden]="fieldType.value!=='radio'">
                        <mat-label>Options ( Seperated By a ',' )</mat-label>
                        <input matInput formControlName="field_radio_options" class="input_fld_long">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4 col-xs-16">
                <mat-form-field class="inline-form-field">
                    <mat-label>Required Field ?</mat-label>
                    <mat-select formControlName="field_required">
                        <mat-option [value]=true>Yes</mat-option>
                        <mat-option [value]=false>No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4 col-xs-16" [hidden]="fieldType.value==='counter' || fieldType.value==='radio' || fieldType.value==='checkbox' || fieldType.value==='cdate' ||fieldType.value==='cdatetime'">
                <mat-form-field class="inline-form-field">
                    <mat-label>Placeholder</mat-label>
                    <input matInput formControlName="filed_placeholder" class="input_fld_long">
                </mat-form-field>
            </div>
            <div class="col-md-4 col-xs-16">
                <mat-form-field class="inline-form-field">
                    <mat-label>Filed Info Message</mat-label>
                    <input matInput formControlName="filed_info" class="input_fld_long">
                </mat-form-field>
            </div>
            <div class="col-md-4 col-xs-16">
                <mat-form-field class="inline-form-field">
                    <mat-label>Filed Error Message</mat-label>
                    <input matInput formControlName="filed_error" class="input_fld_long">
                </mat-form-field>
            </div>
            <br>
            <button mat-flat-button color="primary" (click)="cancelAddNew()"
                style="float: right; margin-top:10px; margin-left:10px">Cancel</button><span>&nbsp; &nbsp;</span>
            <button mat-flat-button color="primary" [disabled]="!filed_details.valid" (click)="saveNewRecord()"
                style="float: right; width: 75px; margin-top:10px">Save</button>
        </div>

    </form>
</mat-card>

<table mat-table [dataSource]="FiledDetails" style="width: 100%;" matSort>
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="10%">
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row.fld_id) : null"
                [checked]="selection.isSelected(row.fld_id)">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="fld_lable">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Field Lable</th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.fld_id!==editRowId">
                {{row.fld_lable || 'N/A'}}
            </ng-container>
            <ng-container *ngIf="row.fld_id===editRowId">
                <mat-form-field class="inline-edit" style="max-width:120px">
                    <input matInput name="Filed Lable" [(ngModel)]="row.fld_lable" maxlength="50" required>
                </mat-form-field>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="fld_description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Filed Description </th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.fld_id!==editRowId">
                {{row.fld_description || 'N/A'}}
            </ng-container>
            <ng-container *ngIf="row.fld_id===editRowId">
                <mat-form-field class="inline-edit" style="max-width:120px">
                    <input matInput name="Filed Description" [(ngModel)]="row.fld_description" maxlength="50" required>
                </mat-form-field>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="fld_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Filed Type </th>
        <td mat-cell *matCellDef="let row"> 
            <ng-container>
                {{row.fld_type || 'N/A'}}
            </ng-container>
            <!-- <ng-container *ngIf="row.fld_id===editRowId">
                <mat-select [(value)]="row.fld_type" class="inline-edit" style="max-width: 250px;">
                    <mat-option [value]="'text'">Text</mat-option>
                    <mat-option [value]="'cdate'">Date</mat-option>
                    <mat-option [value]="'telephone'">Phone Number</mat-option>
                    <mat-option [value]="'email'">Email Address</mat-option>
                    <mat-option [value]="'cdatetime'">Date Time</mat-option>
                    <mat-option [value]="'counter'">Counter</mat-option>
                    <mat-option [value]="'richtext'">Rich Text</mat-option>
                    <mat-option [value]="'checkbox'">Check Box</mat-option>
                    <mat-option [value]="'radio'">Radio Button</mat-option>
                </mat-select>
            </ng-container> -->
        </td>
    </ng-container>

    <ng-container matColumnDef="fld_required">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Filed Required</th>
        <td mat-cell *matCellDef="let row"> 
            <ng-container *ngIf="row.fld_id!==editRowId">
                {{ row.fld_required !== undefined ? (row.fld_required ? 'Yes' : 'No') : 'N/A' }}
            </ng-container>
            <ng-container *ngIf="row.fld_id===editRowId">
                <mat-select [(value)]="row.fld_required" class="inline-edit" style="max-width: 250px;">
                    <mat-option [value]=true>Yes</mat-option>
                    <mat-option [value]=false>No</mat-option>
                </mat-select>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="fld_placeholder">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Filed Placeholder </th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.fld_id!==editRowId">
                {{row.fld_placeholder || 'N/A'}}
            </ng-container>
            <ng-container *ngIf="row.fld_id===editRowId">
                <mat-form-field class="inline-edit" style="max-width:120px">
                    <input matInput name="Filed Placeholder" [(ngModel)]="row.fld_placeholder" maxlength="50" required>
                </mat-form-field>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="fld_info_message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Filed Information </th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.fld_id!==editRowId">
                {{row.fld_info_message || 'N/A'}}
            </ng-container>
            <ng-container *ngIf="row.fld_id===editRowId">
                <mat-form-field class="inline-edit" style="max-width:120px">
                    <input matInput name="Filed Information" [(ngModel)]="row.fld_info_message" maxlength="50" required>
                </mat-form-field>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="fld_error_message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Filed Error </th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.fld_id!==editRowId">
                {{row.fld_error_message || 'N/A'}}
            </ng-container>
            <ng-container *ngIf="row.fld_id===editRowId">
                <mat-form-field class="inline-edit" style="max-width:120px">
                    <input matInput name="Filed Error" [(ngModel)]="row.fld_error_message" maxlength="50" required>
                </mat-form-field>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row ; let i = index" style="justify-content: center; text-align: right;"> 
            <button mat-flat-button *ngIf="row.fld_id!==editRowId" (click)="editRow(row)" [disabled]="inEditMode">
                <i class="fa fa-pencil fa-2x" aria-hidden="true"></i>
            </button> 
            <!-- <button mat-flat-button *ngIf="row.fld_id!==editRowId" (click)="manageFileds()" [disabled]="inEditMode">
                <i class="fa fa-cog fa-2x" aria-hidden="true"></i>
            </button>  -->

            <button mat-flat-button *ngIf="row.fld_id!==editRowId" (click)="deleteRow(row, i)" [disabled]="inEditMode">
                <i class="fa fa-trash-o fa-2x" aria-hidden="true"></i>
            </button> 
            <ng-container *ngIf="row.fld_id===editRowId">
                <button mat-icon-button color="primary" matTooltip="Update" (click)="updateEdit(row)">
                    <i class="fa fa-check fa-2x" aria-hidden="true"></i>
                </button>
                <button mat-icon-button color="warn" matTooltip="Cancel" (click)="cancelEdit(i)" style="margin-left: 25px; margin-right: 10px;">
                    <i class="fa fa-close fa-2x" aria-hidden="true"></i>
                </button>
            </ng-container>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="filedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: filedColumns;"></tr>
</table>