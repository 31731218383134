import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

const URL_request_details = environment.url_blogics + "/progress/get_request_by_ref.php";
const URL_attachments = environment.url_blogics + "/progress/get_attachment_by_ref.php";
const URL_state = environment.url_sfeatures + "/get_state_list.php";
const URL_set_state = environment.url_blogics + "/progress/change_request_state.php";
const URL_assignee_list = environment.url_blogics + "/progress/get_assignee_list.php";
const URL_change_assignee = environment.url_blogics + "/progress/change_assign_user.php";
const URL_change_assignment_group = environment.url_blogics + "/progress/change_assign_group.php";
const URL_get_comment = environment.url_blogics + "/progress/get_comment_details.php";
const URL_put_comment = environment.url_blogics + "/progress/leave_comment.php";
const URL_get_section_list = environment.url_sfeatures + "/basic-data/get_section_list.php";
const URL_get_categories = environment.url_blogics + "/progress/get_ticket_categories.php";
const URL_set_section = environment.url_blogics + "/progress/change_section_id.php";
const URL_set_cat = environment.url_blogics + "/progress/change_category_id.php";
const URL_set_priority = environment.url_blogics + "/progress/change_priority.php";
const URL_get_history_log = environment.url_sfeatures + "/get_activity_log.php";
const URL_get_letter_list = environment.url_blogics + "/letter/get_letter_list_by_ref.php";
const URL_get_service_areas_list = environment.url_sfeatures+'/basic-data/get_division_list.php';
const URL_set_service_area = environment.url_blogics + "/progress/change_service_area.php";
const URL_get_group_list = environment.url_blogics + "/collaboration/group_list/get_active_user_group_list.php";
@Injectable({
  providedIn: "root",
})
export class RequestStatusService {
  constructor(private httpclient: HttpClient) {}
  //get request details
  getRequestDetails(requestID: string) {
    return this.httpclient.post(
      URL_request_details,
      JSON.stringify({ ref_no: requestID })
    );
  }
  //get attachments
  getAttachments(requestID: string) {
    return this.httpclient.post(
      URL_attachments,
      JSON.stringify({ ref_no: requestID })
    );
  }
  //get status list
  getStatusList() {
    return this.httpclient.post(URL_state, JSON.stringify({}));
  }
  //get user list
  getAssigneeList(): Observable<any> {
    return this.httpclient.post(URL_assignee_list, JSON.stringify({}));
  }
  //get all comments
  getComments(requestID: string) {
    return this.httpclient.post(
      URL_get_comment,
      JSON.stringify({ ref_no: requestID })
    );
  }
  //get section list
  getSectionList() : Observable<any> {
    return this.httpclient.post(URL_get_section_list, JSON.stringify({}));
  }

  //get groups list
  getGroupList() : Observable<any>{
    return this.httpclient.post(URL_get_group_list, JSON.stringify({}));
  }

  //get categories
  getCategory() {
    return this.httpclient.post(URL_get_categories, JSON.stringify({}));
  }
  //get service areas list
  getServiceAreasList(): Observable<any> {
    return this.httpclient.get(URL_get_service_areas_list);
  }
  //set comment
  setComment(requestID: string, comment: string, isCustVisible: string) {
    var x = this.httpclient.post(
      URL_put_comment,
      JSON.stringify({
        ref_no: requestID,
        comment: comment,
        is_cust_visible: isCustVisible,
      })
    );
    return x;
  }
  //Set Priority
  setPriority(requestID: string, priority: string) {
    return this.httpclient.post( URL_set_priority, JSON.stringify({ ref_no: requestID, priority: priority }));
  }
  //set Status
  setStatus(requestID: string, state: string) {
    return this.httpclient.post(URL_set_state, JSON.stringify({ ref_no: requestID, state: state }));
  }
  // set Assignee
  setAssignee(requestID: string, assignee: string) {
    return this.httpclient.post(URL_change_assignee, JSON.stringify({ ref_no: requestID, assign_user: assignee }));
  }
  setGroup(requestID: string, group: string){
    return this.httpclient.post(URL_change_assignment_group,JSON.stringify({ ref_no: requestID, assign_group: group }));
  }
  //set Section
  setSection(requestID: string, section: string) {
    return this.httpclient.post(URL_set_section, JSON.stringify({ section_id: section, ref_no: requestID }));
  }
  //set Category
  setCategory(requestID: string, cat: string) {
    return this.httpclient.post(URL_set_cat,JSON.stringify({ category_id: cat, ref_no: requestID }));
  }
  //set Service Area
  setServiceArea( requestID: string, serviceArea: string) {
    return this.httpclient.post(URL_set_service_area, JSON.stringify({ service_area: serviceArea, ref_no: requestID }));
  }
  //get history log
  getHistoryLog(requestID: string) {
    return this.httpclient.post(URL_get_history_log, JSON.stringify({ ref_no: requestID }));
  }

  getLetterList(reqId : string){
    let params = new HttpParams();
    params = params.append('reqId', reqId);
    return(this.httpclient.get(URL_get_letter_list,{params: params}));
  }
}
