import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.css'],
})
export class BasicDataComponent implements OnInit {

  constructor()
  { }

  ngOnInit(): void {
  }

}
