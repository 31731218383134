import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { UserPanelService } from './user-panel.service';
import { ToastrService } from 'ngx-toastr';
import { FileUploadServiceService } from 'src/app/services/file-upload.service';
import { PermissionService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.css']
})
export class UserPanelComponent implements OnInit {
  source: string;
  userId: string;
  username: string;
  editBtnName: string = "Edit";
  activeState: false;
  isEditable: boolean = false;
  isSetTmpPwd: boolean = false;
  informMethod: string = 'none';
  defaultImage: string = '../../assets/images/user-default.png';
  imageUrl: string
  imageError: string;
  isShowProgress: boolean = false;
  isImageExist: boolean;
  
  designationName: string;
  userRoleName: string;
  sectionName: string;

  userRolesList: any;
  designationsList: any;
  sectionsList: any;

  constructor(
    public dialogRef: MatDialogRef<UserPanelComponent>,
    @Inject(MAT_DIALOG_DATA) data, 
    private _formBuilder      : FormBuilder, 
    private userPanelService  : UserPanelService,
    private toastr            : ToastrService,
    private fileUploadService : FileUploadServiceService,
    public  permission        : PermissionService) 
  { 
    this.source = data.source;
    this.imageUrl = this.defaultImage;
    this.getUserRolesList();
    this.getDesignationsList();
    this.getSectionsList();
    if(this.source == "view&edit"){
      this.userId = data.userId;
      this.username = data.username;
      this.getUserDetails();      
    }
    else if(this.source == "addNew"){
      this.isEditable = true;
      this.isSetTmpPwd = true;
      this.pwd_details.disable();
    }
  }

  informMethods: informMethod[] = [
    {value: 'none', viewValue: 'None'},
    {value: 'email', viewValue: 'Email'},
    {value: 'text', viewValue: 'Text Message'},
    {value: 'email&text', viewValue: 'Email & Text'}
  ];

  user_details = this._formBuilder.group({
    user_id : [''],
    user_name : ['', Validators.required],
    nic : ['', Validators.required],
    first_name : ['', Validators.required],
    last_name : ['', Validators.required],
    mobile_no : ['', Validators.required ],
    email : ['', [Validators.email, Validators.required]],
    designation : [''],
    section : [''],
    user_role : ['', Validators.required],
    active : ['0'],
    image_url : [null]
  });

  pwd_details = this._formBuilder.group({
    admin_pwd : ['', Validators.required],
    tmp_pwd : ['', Validators.required],
    inform_method : ['']
  });

  ngOnInit() {
    if(this.imageUrl.includes('http://')){
      this.isImageExist = true;
    }
  }

  getUserDetails(){
    this.userPanelService.getUserDetails(this.userId, this.username)
    .subscribe(data => {
      if(data["user_name"] != null){
        this.user_details.patchValue({
          user_id     : data["user_id"],
          user_name   : data["user_name"],
          nic         : data["nic"],
          first_name  : data["first_name"], 
          last_name   : data["last_name"],
          email       : data["email"],
          mobile_no   : data["mobile_no"],
          designation : data["designation"],
          user_role   : data["user_role"],
          section     : data["section"],
          active      : data["active"],
          image_url   : data["image_url"]
        })
        if(data["image_url"] != null && data["image_url"] != ''){
          this.imageUrl = data["image_url"];
          this.isImageExist = true;
        }
        if(this.designationsList != null){
          this.setDesignationNameFromList(data["designation"]);
        }
        if(this.userRolesList != null){
          this.setUserRoleNameFromList(data["user_role"]);
        }
        if(this.sectionsList != null){
          this.setSectionNameFromList(data["section"]);
        }
        //this.setValuesFromLists(data["designation"], data["user_role"], data["section"]);
      }
      else{
        this.toastr.error('Server Error', 'Error');
        console.log('User default data not fetched...');
      }
    });
  }

  getUserRolesList(){
    this.userPanelService.getUserRolesList()
    .subscribe(data => {
      if(data!= null){
        this.userRolesList = data;
        if(this.user_details.controls.user_role.value != ''){
          this.setUserRoleNameFromList(this.user_details.controls.user_role.value);
        }
      }
      else{
        this.toastr.error('Server Error', 'Error');
      }
    });
  }

  getDesignationsList(){
    this.userPanelService.getDesignationsList()
    .subscribe(data => {
      if(data!= null){
        this.designationsList = data;
        if(this.user_details.controls.designation.value != ''){
          this.setDesignationNameFromList(this.user_details.controls.designation.value);
        }
      }
      else{
        this.toastr.error('Server Error', 'Error');
      }
    });
  }

  getSectionsList(){
    this.userPanelService.getSectionsList()
    .subscribe(data => {
      if(data!= null){
        this.sectionsList = data;
        if(this.user_details.controls.section.value != ''){
          this.setSectionNameFromList(this.user_details.controls.section.value);
        }
      }
      else{
        this.toastr.error('Server Error', 'Error');
      }
    });
  }

  setValuesFromLists(designation: string, userRole: string, section: string){
    this.designationName = (this.designationsList.find(x => x.designation_id === designation)).designation_name;
    this.userRoleName = (this.userRolesList.find(x => x.role_id === userRole)).role_name;
   // this.sectionName = (this.sectionsList.find(x => x.section_id === section)).section_name; ***DON'T REMOVE***
  }
  setDesignationNameFromList(designation: string){
    this.designationName = (this.designationsList.find(x => x.designation_id === designation)).designation_name;
  }
  setUserRoleNameFromList(userRole: string){
    this.userRoleName = (this.userRolesList.find(x => x.role_id === userRole)).role_name;
  }
  setSectionNameFromList(section: string){
   // this.sectionName = (this.sectionsList.find(x => x.section_id === section)).section_name; ***DON'T REMOVE***
  }

  setState(e){
    if(e.checked){
      this.user_details.patchValue({ active : '1'});
    }
    else{
      this.user_details.patchValue({ active : '0'});
    }   
  }

  onEditCancel(){
    if(!this.isEditable){
      this.isEditable = true;
      this.editBtnName = "Cancel";
    }
    else{
      this.isEditable = false;
      this.editBtnName = "Edit";  
    }
  }

  saveDetails(){
    if(this.source == "view&edit"){
      this.userPanelService.updateUserDetails(this.user_details.value)
      .subscribe(res => {
        if(res['status'] == 'success'){
          this.isEditable = false; 
          this.editBtnName = "Edit";
          this.toastr.success('Details updated', 'Success');
          this.setValuesFromLists(this.user_details.controls.designation.value, this.user_details.controls.user_role.value, this.user_details.controls.section.value);        
        }
        else{
          this.toastr.error('Update failed', 'Failed'); 
        }
      });
    }
    else if(this.source == "addNew"){
      this.userPanelService.addNewUser(this.user_details.value)
      .subscribe(res => {
        if(res['status'] == 'success'){
          this.isEditable = false; 
          this.user_details.patchValue({ user_id : res['user_id']});
          this.source = "view&edit";
          this.pwd_details.enable(); //Set tem pwd section enable...
          this.setValuesFromLists(this.user_details.controls.designation.value, this.user_details.controls.user_role.value, this.user_details.controls.section.value);
          this.toastr.success('Details updated', 'Success');
        }
        else if(res['status'] == 'user_exist'){
          this.toastr.error('Username already exist', 'Failed');
        }
        else{
          this.toastr.error('Record saving failed', 'Failed'); 
        }
      });
    }
  }

  activeSetTmpPwd(){
    if(!this.isSetTmpPwd){
      this.isSetTmpPwd = true;
    }
    else{
      this.isSetTmpPwd = false;
    }
  }

  setTemporaryPassword(){
    this.userPanelService.setTmpPassword(this.user_details.controls.user_name.value, this.pwd_details.value)
    .subscribe(res => {
      if(res['status'] == 'success'){
        this.isEditable = false; 
        this.editBtnName = "Edit";
        this.toastr.success('Details updated', 'Success');
      }
      else{
        this.toastr.error('Update failed', 'Failed'); 
      }
    });
  }

  clear(){
    this.user_details.reset();
  }

  onCloseClick(){
    this.dialogRef.close();
  }

  userImageChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.isShowProgress = true;
      const max_size = 10000000;
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
  
      if (fileInput.target.files[0].size > max_size) {
          this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
          this.isShowProgress = false;
          return false;
      }
  
      if (!allowed_types.includes(fileInput.target.files[0].type)) {
          this.imageError = 'Only Images are allowed ( JPG | PNG )';
          this.isShowProgress = false;
          return false;
      }
  
      this.fileUploadService.uploadUserImage(this.username, this.userId, fileInput.target.files[0])
      .subscribe( res => {
        if(res['status'] == 'success'){
          this.user_details.patchValue({
            image_url : res["image_url"]
          })
          this.imageUrl = res["image_url"];
          this.isShowProgress = false;
          this.isImageExist = true;
        }
        else{
          this.toastr.error('File Uplading failed', 'Failed');
          this.isShowProgress = false;
        }    
      });
    }
  }

  removeImage() {
    this.fileUploadService.removeUserImage(this.username, this.userId, this.imageUrl)
    .subscribe( res => {
      if(res['status'] == 'success'){
        this.user_details.patchValue({
          image_url : null
        })
        this.imageUrl = this.defaultImage;
        this.isImageExist = false;
      }
      else{
        this.toastr.error('File Uplading failed', 'Failed');
      }    
    }); 
  }
}

interface informMethod {
  value: string;
  viewValue: string;
}

// interface userRole{
//   id: string;
//   role_name: string;
// }