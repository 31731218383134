import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Chart} from 'chart.js';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from './dashboard.service';

export interface iRequestData {
  user_name: string;
  first_name: string;
  last_name: string;
  mobile_no: string;
  nic: string;
  designation: string;
  active: string;
}

export interface iRequestNumData {
  new: number;
  in_progress: number;
  completed: number;
  canceled: number;
  pending_approval: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DashboardComponent],
})
export class DashboardComponent implements OnInit {

  displayedColumns: string[] = ['ref_no', 'topic', 'name_in_use', 'created_date', 'category'];
  newTopRequests: MatTableDataSource<iRequestData>;
  inProgressTopRequests: MatTableDataSource<iRequestData>;
  completedTopRequests: MatTableDataSource<iRequestData>;
  requestsNumData = new RequestNumData();
  barChartLabels: string[] = [];
 // barChartValues: any = [];
  barChartValues: number[] = [];

  constructor( private _router : Router, 
               private route   : ActivatedRoute,
               private _auth   : AuthService,
               private dashboardService : DashboardService) {
                
               }
    
  ngOnInit() {
    this._auth.setShowHeader(true);
    this.getRequestsNumData();
    this.getRecentNewRequests();
    //this.getTopNewRequests();
    //this.loadSystemAreaChart();
    //this.loadBarChart();
  }

  getRequestsNumData(){
    this.dashboardService.getRequestsNumData().subscribe(res => {
      this.requestsNumData = res as RequestNumData;
      console.log(this.requestsNumData);
      this.loadSystemCountChart();  
    });
  }

  getRecentNewRequests(){
    this.dashboardService.getRecentNewRequestCounts().subscribe(res => {
      res.forEach(element => {
        this.barChartLabels.push(element['date']);           
        this.barChartValues.push(element['new_amount']);  
      });
      this.loadBarChart();
    });
  }

  // getTopNewRequests(){
  //   this.dashboardService.getTopNewRequests().subscribe(res => {
  //     console.log(res);
  //     this.newTopRequests = new MatTableDataSource(res as iRequestData[]);
  //   });
  // }

  loadSystemCountChart()
  {
    var dChartData = {
      labels: ['New', 'In Progress', 'Completed', 'Canceled'],
      datasets: [{
          label: '# of Tickets',
          data: [+this.requestsNumData.new, +this.requestsNumData.in_progress, +this.requestsNumData.completed, +this.requestsNumData.canceled],
          backgroundColor: [
              'rgba(49, 112, 143, 0.7)',
              'rgba(255, 152, 0, 0.7)',
              'rgba(76, 175, 80, 0.7)',
              'rgba(244, 67, 54, 0.7)'
          ],
          borderColor: [
            'rgba(49, 112, 143, 1)',
            'rgb(255, 152, 0)',
            'rgb(76, 175, 80)',
            'rgb(244, 67, 54)' 
          ],
          borderWidth: 1
      }]
    }
    
    Chart.defaults.global.tooltips.custom = function (tooltip) {
      var tooltipEl = document.getElementById('chartjs-tooltip'); 
      // Hide if no tooltip
      if (tooltip.opacity === 0) {
          tooltipEl.style.color = "#6610f2";
          //$("#chartjs-tooltip div p").text("100%");
          tooltipEl.style.opacity = '0';
          return;
      }
    
      function getBody(bodyItem) {
          return bodyItem.lines;
      }
      // Set Text
      if (tooltip.body) {
          var bodyLines = tooltip.body.map(getBody);
          var innerHtml = '<p>';
          bodyLines.forEach(function (body, i) {
              var dataNumber = body[i].split(":");
              var dataValNum = parseInt(dataNumber[1].trim());
              var dataToPercent = (dataValNum / sumOfDataVal(dChartData) * 100).toFixed(0) + '%';
              innerHtml += dataToPercent;
          });
    
          innerHtml += '</p>';
    
          var tableRoot = tooltipEl.querySelector('div');
          tableRoot.innerHTML = innerHtml;
      }
      tooltipEl.style.opacity = '1';
      tooltipEl.style.color = "#929292";
    };

    var chart = new Chart('doughnutChart', {
        type: 'doughnut',
        data: dChartData,
        options: {
          tooltips: {
            enabled: true        
          }
        }     
    });

    function sumOfDataVal(dataArray) {
      return dataArray['datasets'][0]['data'].reduce(function (sum, value) {
          return sum + value;
      }, 0);
    }
  }

//test =  [10, 20, 30, 40, 50, 60, 70];

  loadBarChart()
  {
    var barChart = new Chart('barChart', {
        type: 'bar',
        data: {
            labels: this.barChartLabels,
            datasets: [{
              barPercentage: 0.5,
              //barThickness: 6,
              //maxBarThickness: 8,
              minBarLength: 2,
              data: this.barChartValues,
              backgroundColor: 'rgba(49, 112, 143, 0.7)'
            //   backgroundColor: [
            //     'rgba(49, 112, 143, 0.7)',
            //     'rgba(255, 152, 0, 0.7)',
            //     'rgba(76, 175, 80, 0.7)',
            //     'rgba(244, 67, 54, 0.7)'
            // ]
          }]
        },
        //options: { }
        options: {
          legend: {
            display: false
          },
          scales: {
              xAxes: [{
                  stacked: true
              }],
              yAxes: [{
                  stacked: true
              }]
          }
        }
    });
  }

}

class RequestNumData {
  new: number;
  in_progress: number;
  completed: number;
  canceled: number;
  all: number;
  pending_approval: number;
  approved: number;
  resolved: number;
  pending: number;
}