import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

const URL_get_request_list = environment.url_blogics + '/progress/get_request_list_by_state.php';
const URL_get_group_list = environment.url_blogics + '/collaboration/group_list/get_active_group_list.php';
const URL_get_section_list = environment.url_sfeatures + '/basic-data/get_section_list.php';
const URL_get_assignee_list = environment.url_blogics + '/progress/get_assignee_list.php';
const URL_get_filtered_request_list = environment.url_blogics + '/progress/get_request_list_by_search_set.php';
const URL_set_comment = environment.url_blogics + '/progress/set_comment_for_export_list.php';
const URL_get_label_list = "";

@Injectable({
  providedIn: "root",
})
export class ProgressService {
  constructor(private httpclient: HttpClient) {}

  progressList(state : string) {
    return (this.httpclient.post(URL_get_request_list, JSON.stringify({ "state" : state}))) 
  }

  progressSelectedList(state : string, user : string) {
    return (this.httpclient.post(URL_get_request_list, JSON.stringify({"state" : state, "user_id" : user}))) 
  }

  getGroupList(){
    return (this.httpclient.get(URL_get_group_list));
  }

  getSectionList(){
    return (this.httpclient.get(URL_get_section_list));
  }

  getAssigneeList(){
    return (this.httpclient.get(URL_get_assignee_list));
  }

  getLabelList(){
    return (this.httpclient.get(URL_get_label_list));
  }

  getFilteredRequestsData(state: string, reqGroups, sections, priority, assignee){
    return (this.httpclient.post(URL_get_filtered_request_list, JSON.stringify({
                                                      "state"     : state,
                                                      "reqGroups" : reqGroups, 
                                                      "sections"  : sections,
                                                      "priority"  : priority,
                                                      "assignee"  : assignee}))); 
  }

  setCommentForExportList(filteredReqIds, comment: string){
    return (this.httpclient.post(URL_set_comment, JSON.stringify({
                                            "reqIds"  : filteredReqIds, 
                                            "comment" : comment}))); 
  }
}
