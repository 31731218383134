<h1 mat-dialog-title>
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <p>{{message}}</p>
    <mat-radio-group *ngIf='eventType == "REPEAT"' color="primary" [(ngModel)]="delOption" aria-label="Select an option">
      <mat-radio-button value="one">Current Occurence Only</mat-radio-button>
      <mat-radio-button value="all">All Related Occurences (upcoming)</mat-radio-button>
    </mat-radio-group>
  </div>
  
  <div mat-dialog-actions>
    <button mat-stroked-button (click)="onDismiss()">Cancel</button>
    <button mat-flat-button color="primary" *ngIf='eventType != "repeat"' (click)="onConfirm()">Confirm</button>
    <button mat-flat-button color="primary" *ngIf='eventType == "repeat"' [disabled]="delOption == null" (click)="onConfirm()">Confirm</button>
  </div>