<div class="main-mat">
    <div class="row head-row">
        <mat-card-title *ngIf="source == 'view&edit'">{{username || user_details.controls.user_name.value}}</mat-card-title>
        <mat-card-title *ngIf="source == 'addNew'"><i class="fa fa-user-plus fa-lg" aria-hidden="true"></i>&ensp;New User</mat-card-title>
        <button mat-stroked-button class="btn-close"  (click)="onCloseClick()">Close</button>
    </div>
    <!-- user details -->
    <mat-card-subtitle>User Details</mat-card-subtitle>
    <mat-card class="in-mat">
        <form [formGroup]="user_details" enctype="multipart/form-data">
            <div class="container row"> 
                <div class="col-xs-12 col-md-7">
                    <div class="row">
                        <div class="col-2">Username</div>
                        <div class="col-10" *ngIf="source == 'view&edit'">{{user_details.controls.user_name.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="source == 'addNew'">
                            <input matInput formControlName="user_name">
                        </mat-form-field>               
                    </div>
                    <div class="row" >
                        <div class="col-2">Mobile No</div>
                        <div class="col-10" *ngIf="!isEditable">{{user_details.controls.mobile_no.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <input matInput formControlName="mobile_no" type="number">
                        </mat-form-field> 
                    </div>
                    <div class="row">
                        <div class="col-2">First Name</div>
                        <div class="col-10" *ngIf="!isEditable">{{user_details.controls.first_name.value}}</div> 
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <input matInput formControlName="first_name">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-2">Last Name</div>
                        <div class="col-10" *ngIf="!isEditable">{{user_details.controls.last_name.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <input matInput formControlName="last_name">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-2">NIC</div>
                        <div class="col-10" *ngIf="!isEditable">{{user_details.controls.nic.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <input matInput formControlName="nic">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-2">Email</div>
                        <div class="col-10" *ngIf="!isEditable">{{user_details.controls.email.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <input matInput formControlName="email">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-2">Designation</div>
                        <div class="col-10" *ngIf="!isEditable">{{designationName}}</div>
                        <mat-form-field *ngIf="isEditable" class="inputLabel">
                            <mat-select formControlName="designation" >
                                <mat-option *ngFor="let designation of designationsList" [value]="designation.designation_id">
                                    {{designation.designation_name}}
                                </mat-option> 
                            </mat-select>                                     
                        </mat-form-field>
                    </div> 
                    <div class="row">
                        <div class="col-2">User Role</div>
                        <div class="col-10" *ngIf="!isEditable">{{userRoleName}}</div>
                        <mat-form-field *ngIf="isEditable" class="inputLabel">
                            <mat-select formControlName="user_role">
                                 <mat-option *ngFor="let userRole of userRolesList" [value]="userRole.role_id">
                                    {{userRole.role_name}}
                                  </mat-option> 
                            </mat-select>                                     
                        </mat-form-field>
                    </div>  
                    <!-- <div class="row">
                        <div class="col-2">Section</div>
                        <div class="col-10" *ngIf="!isEditable">{{sectionName}}</div>
                        <mat-form-field *ngIf="isEditable" class="inputLabel">
                            <mat-select formControlName="section">
                                 <mat-option *ngFor="let section of sectionsList" [value]="section.section_id">
                                    {{section.section_name}}
                                  </mat-option> 
                            </mat-select>                                     
                        </mat-form-field>
                    </div>  -->
                    <div class="row">
                        <div class="col-2">State</div>
                        <div class="col-10">
                            <mat-slide-toggle [checked]="user_details.controls.active.value == '1' ? true : false"
                             [disabled]="!isEditable" (change)="setState($event)">
                                <div>{{user_details.controls.active.value == '1' ? 'Active' : 'Inactive'}}</div>
                            </mat-slide-toggle>
                        </div>
                    </div>  
                </div>
                <div class="col-xs-12 col-md-5">
                    <div class="img-space">
                        <img src="{{imageUrl}}" class="img-responsive">
                    </div>
                    <!-- <fieldset class="form-group">
                        <div class="custom-file fileInputProfileWrap">
                            <input type="file" (change)="userImageChangeEvent($event)" class="fileInputProfile">
                            <div class="img-space">
                                <ng-container>
                                 <img src="{{imageUrl}}" class="img-responsive">
                                </ng-container>
                                <mat-progress-bar *ngIf="isShowProgress" mode="indeterminate"></mat-progress-bar>
                            </div>                           
                            <button mat-button color="primary" *ngIf="isImageExist" (click)="removeImage()" style="padding-left: 0px;">Remove</button>
                             {{imageError}}                            
                        </div>
                    </fieldset> -->
                </div>
            </div>
            <div class="button-line">
                <button mat-raised-button *ngIf="isEditable" (click)="saveDetails()" [disabled]="!user_details.valid" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px">Save Details</button>
                <button mat-raised-button *ngIf="source == 'view&edit' && permission.PERM_EDIT_USER" (click)="onEditCancel()" color="primary" type="button" class="mat-button-class" style="float: right;">{{editBtnName}}</button>
                <button mat-raised-button *ngIf="source == 'addNew'" (click)="clear()" color="primary" type="button" class="mat-button-class" style="float: right;">Clear</button>

            </div>
        </form>
        <a mat-button *ngIf="source == 'view&edit' && permission.PERM_EDIT_USER" (click)="activeSetTmpPwd()"><i class="fa fa-key"></i> Set Temporary Password</a>
    </mat-card>
    <!-- end user details -->
    <!-- temp password -->
    <mat-card-subtitle *ngIf="isSetTmpPwd && permission.PERM_EDIT_USER">Set Tempory Password</mat-card-subtitle>
    <mat-card *ngIf="isSetTmpPwd" class="in-mat">
        <form [formGroup]="pwd_details" enctype="multipart/form-data">   
            <div class="container row">
                <div class="col-sm-12 col-md-7">
                    <div class="row">
                        <div class="col-2">Admin password</div>
                        <mat-form-field class="inputLabel">
                            <input matInput formControlName="admin_pwd" type="password">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-2">Temporary password</div>
                        <mat-form-field class="inputLabel">
                            <input matInput formControlName="tmp_pwd" type="password">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-2">Informing method</div>
                        <mat-form-field class="inputLabel">
                            <mat-select formControlName="inform_method">
                                <mat-option *ngFor="let infoMethod of informMethods" [value]="infoMethod.value">
                                    {{infoMethod.viewValue}}
                                </mat-option>
                            </mat-select>                 
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div>
                <button mat-raised-button (click)="setTemporaryPassword()" [disabled]="!pwd_details.valid" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px">Set Password</button>
                <button mat-raised-button (click)="activeSetTmpPwd()" color="primary" type="button" class="mat-button-class" style="float: right;">Cancel</button>
            </div>
        </form>
    </mat-card>
    <!-- end temp password -->
</div>