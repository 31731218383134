import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
// import { UserPanelComponent } from './requester.service';
import { RequesterService } from './requester.service';
import { RequesterPanelComponent } from './requester-panel/requester-panel.component';
import { PermissionService } from '../services/permissions.service';

export interface iRequestorData {
  nic: string;
  title: string;
  name_in_use: string;
  requester_type: string;
  division: string;
  email: string;
  address: string;
  dob: string;
  mobile_no: string;
  active: string;
}

@Component({
  selector: 'app-requester',
  templateUrl: './requester.component.html',
  styleUrls: ['./requester.component.css']
})
export class RequesterComponent implements OnInit {

  designation: string;
  displayedColumns: string[] = ['nic', 'title', 'name_in_use', 'division', 'requester_type','active', 'action'];
  requestersDetails: MatTableDataSource<iRequestorData>;

  showFilters: boolean = false;

  //Temp Filer attributes...
  jobSeeker = false;
  degree = false;
  al = false;
  ol = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private dialog : MatDialog, private requesterService :  RequesterService, public permission: PermissionService) {
    this.loadAllUsersData();
  }

  ngOnInit() {}

  loadAllUsersData() {
    this.requesterService.getAllRequestersData().subscribe(res => {
      this.requestersDetails = new MatTableDataSource(res as iRequestorData[]);
      this.requestersDetails.sort = this.sort;
      this.requestersDetails.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.requestersDetails.filter = filterValue.trim().toLowerCase();
    if (this.requestersDetails.paginator) {
      this.requestersDetails.paginator.firstPage();
    }
  }

  viewRequesterPanel(nic: string){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= false;
    dialogconfig.width = "50%";
    dialogconfig.minWidth = "380px"; /*Set minimum width - Important for mobile widows*/
    dialogconfig.data = {
      nic : nic,
      source : "view&edit"
    }
    var dialog_ref = this.dialog.open(RequesterPanelComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      //this.loadAllUsersData();
      this.loadAllUsersDataBySearch(); //Only for special instance...
    });
  }

  onClickShowFilters(){
    if(this.showFilters){
      this.showFilters = false;
    }
    else{
      this.showFilters = true;
    }
  }

  search(){
    this.loadAllUsersDataBySearch();
  }

  loadAllUsersDataBySearch() {
    this.requesterService.getSearchRequestersData().subscribe(res => {
      this.requestersDetails = null;
      this.requestersDetails = new MatTableDataSource(res as iRequestorData[]);
      this.requestersDetails.sort = this.sort;
      this.requestersDetails.paginator = this.paginator;
    });
  }
  
  addNewRequester(){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= false;
    dialogconfig.width = "50%";
    dialogconfig.minWidth = "380px"; /*Set minimum width - Important for mobile widows*/
    dialogconfig.data = {
      source : "addNew"
    }
    var dialog_ref = this.dialog.open(RequesterPanelComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      this.loadAllUsersData();
    });
  }

}
