<mat-card>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      Profile Settings
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent> 

      <form [formGroup]="user_details" >
        <!-- <a mat-button (click)="activeChangePwd()" style="float: right; margin-left: auto;"><i class="fa fa-key"></i> Change Password</a> -->
        <div class="row">

          <div class="col-md-3 col-xs-12">
            <div class="img-space" (click)="changeImage()">
              <img src="{{imageUrl}}" class="img-responsive">
            </div>
          </div>


          <div class="col-md-6 col-xs-12">
            <div class="row">
              <div class="col-2">Username</div>
              <div class="col-10">{{user_details.controls.user_name.value}}</div>              
            </div>
            <div class="row" >
                <div class="col-2">Mobile No</div>
                <div class="col-10" *ngIf="!isEditable">{{user_details.controls.mobile_no.value}}</div>
                <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                    <input matInput formControlName="mobile_no" type="number">
                </mat-form-field> 
            </div>
            <div class="row">
                <div class="col-2">First Name</div>
                <div class="col-10" *ngIf="!isEditable">{{user_details.controls.first_name.value}}</div> 
                <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                    <input matInput formControlName="first_name">
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-2">Last Name</div>
                <div class="col-10" *ngIf="!isEditable">{{user_details.controls.last_name.value}}</div>
                <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                    <input matInput formControlName="last_name">
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-2">NIC</div>
                <div class="col-10" *ngIf="!isEditable">{{user_details.controls.nic.value}}</div>
                <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                    <input matInput formControlName="nic">
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-2">Email</div>
                <div class="col-10" *ngIf="!isEditable">{{user_details.controls.email.value}}</div>
                <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-2">Designation</div>
                <div class="col-10">{{user_details.controls.designation.value}}</div>
            </div> 
        </div>
        <div class="col-md-3 col-xs-12">
          <a mat-button (click)="activeChangePwd()" class="changePwdBtn" style="float: right; margin-left: auto;"><i class="fa fa-key"></i> Change Password</a>
        </div>
        </div>
        <div class="row">
          <div class="button-line">
            <button mat-raised-button *ngIf="isEditable" (click)="saveChanges()" [disabled]="!user_details.valid" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px">Save</button>
            <button mat-raised-button (click)="onEditCancel()" color="primary" type="button" class="mat-button-class" style="float: right;">{{editBtnName}}</button>
          </div>
        </div>
      </form>
      
      <form [formGroup]="pwd_details" *ngIf="isChangePwd" style="border-top: 1px solid rgb(172, 172, 172); padding-top: 30px;">
        <div class="row">       
          <div class="col-lg-4 col-xs-12">
            <label class="col-3">Old Password :</label>
            <mat-form-field class="col-9">
              <input matInput formControlName="old_pwd" type="password">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-xs-12">
            <label class="col-3">New Password :</label>
            <mat-form-field class="col-9">
              <input matInput formControlName="new_pwd" type="password">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-xs-12">
            <label class="col-3">Verify Password :</label>
            <mat-form-field class="col-9">
              <input matInput formControlName="confirm_pwd" type="password">
            </mat-form-field>
          </div>
        <div class="col" style="padding-top: 20px;">
          <button mat-raised-button (click)="updatePassword()" [disabled]="!pwd_details.valid" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px">Update</button>
          <button mat-raised-button (click)="activeChangePwd()" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px">Close</button>
        </div>   
        </div>
      </form>
    </ng-template>
  </mat-expansion-panel>
  <br>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      Application Settings
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <!-- TO BE ADDED -->

      


    </ng-template>
  </mat-expansion-panel>
  <br>
</mat-card>