import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const URL_create_new_group = environment.url_blogics + '/collaboration/group_list/create_group.php';
const URL_update_group_details = environment.url_blogics + '/collaboration/group_list/update_group.php';
const URL_get_group_details = environment.url_blogics + '/collaboration/group_list/get_group_details_by_id.php';
const URL_get_group_members = environment.url_blogics + '/collaboration/group_list/get_group_members.php';
const URL_add_or_update_group_members = environment.url_blogics + '/collaboration/group_list/add_or_update_group_members.php';

@Injectable({
  providedIn: 'root'
})

export class GroupInfoService {

  constructor(private _http: HttpClient) { }

  createNewGroup(formData: FormData){
    return this._http.post(URL_create_new_group, JSON.stringify(formData));
  }

  updateGroupDetails(formData: FormData){
    return this._http.post(URL_update_group_details, JSON.stringify(formData));
  }

  addOrUpdateGroupMembers(group_id: string, group_type: string, user_list, req_list, removed_user_list, removed_req_list){
    return this._http.post(URL_add_or_update_group_members, JSON.stringify({group_id, group_type, user_list, req_list, removed_user_list, removed_req_list}));
  }

  getGroupMembers(group_id: string) : Observable<any>{
    let params = new HttpParams();
    params = params.append('group_id', group_id.toString());
    return this._http.get(URL_get_group_members, {params: params});
  }

  getGroupDetails(group_id: string) : Observable<any>{
    let params = new HttpParams();
    params = params.append('group_id',group_id.toString());
    return this._http.get(URL_get_group_details, {params: params});
  }
}
