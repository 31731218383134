import { Injectable } from '@angular/core';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket$: WebSocketSubject<any>;

  public connect(url: string): WebSocketSubject<any> {
    console.log('connecting to WS !!!!');
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.create(url);
      console.log("Successfully connected: " + url);
    }
    return this.socket$;
  }

  private create(url: string): WebSocketSubject<any> {
    const socketConfig: WebSocketSubjectConfig<any> = {
      url: url,
      deserializer: (msg: MessageEvent) => JSON.parse(msg.data) // Deserialize the message data
    };
    console.log("test case");
    return new WebSocketSubject(socketConfig);
  }

  public sendMessage(message: Object): void {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.next(JSON.stringify(message));
    }
  }

  public closeConnection(): void {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.complete();
    }
  }
}



// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// //import * as io from 'socket.io-client';
// import * as Rx from 'rxjs/Rx';

// @Injectable({
//   providedIn: 'root'
// })
// export class WebsocketService {

//   constructor() {}

//   private subject: Rx.Subject<MessageEvent>;

//   public connect(url): Rx.Subject<MessageEvent> {

//     this.subject= null;

//     if (!this.subject) {
//       this.subject = this.create(url);
//       console.log("Successfully connected: " + url);
//     }
//     return this.subject;
//   }

//   private create(url): Rx.Subject<MessageEvent> {
//     let ws = new WebSocket(url);

//     let observable = Rx.Observable.create((obs: Rx.Observer<MessageEvent>) => {
//       ws.onmessage = obs.next.bind(obs);
//       ws.onerror = obs.error.bind(obs);
//       ws.onclose = obs.complete.bind(obs);
//       return ws.close.bind(ws);
//     });
//     let observer = {
//       next: (data: Object) => {
//         if (ws.readyState === WebSocket.OPEN) {
//           ws.send(JSON.stringify(data));
//         }
//       }
//     };
//     return Rx.Subject.create(observer, observable);
//   }



//   constructor() {}

//   // Our socket connection
//   private socket;

//   connect(): Rx.Subject<MessageEvent> {
//     // If you aren't familiar with environment variables then
//     // you can hard code `environment.ws_url` as `http://localhost:5000`
//     this.socket = io(environment.WS_Url);

//     // We define our observable which will observe any incoming messages
//     // from our socket.io server.
//     let observable = new Observable(observer => {
//         this.socket.on('message', (data) => {
//           console.log("Received message from Websocket Server")
//           observer.next(data);
//         })
//         return () => {
//           this.socket.disconnect();
//         }
//     });

//     // We define our Observer which will listen to messages
//     // from our other components and send messages back to our
//     // socket server whenever the `next()` method is called.
//     let observer = {
//       next: (data: Object) => {
//           this.socket.emit('message', JSON.stringify(data));
//       },
//   };

//   // we return our Rx.Subject which is a combination
//   // of both an observer and observable.
//   return Rx.Subject.create(observer, observable);
// }

