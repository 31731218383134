<div *ngIf="origin != 'group-info'" class="row" style="margin-top: 20px;">
    <div class="col">
        Participant Type:
        <mat-button-toggle-group [(value)]="participantType" (change)="onPartTypeChange()" style="margin-left: 10px;">
            <mat-button-toggle value="user">Users</mat-button-toggle>
            <mat-button-toggle value="requester">Requesters</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>           
<div class="row filter-panel" style="margin-top: 20px;">
    <mat-chip-list *ngIf="participantType == 'user'" aria-label="Filter-Queries">
        <mat-chip (click)="desigFilter.open()">
            Designations
            <mat-select #desigFilter [formControl]="selectedDesignations" placeholder="Designations" [multiple]="true">
                <mat-option>
                    <ngx-mat-select-search [formControl]="designationFilterKeyword"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let designation of filteredDesignationList | async" [value]="designation.designation_id">
                    {{designation.designation_name}}
                </mat-option>
            </mat-select>
        </mat-chip>

        <!-- <mat-chip (click)="sectionFilter.open()">
            Sections
            <mat-select #sectionFilter [formControl]="selectedSections" placeholder="Sections" [multiple]="true">
                <mat-option>
                    <ngx-mat-select-search [formControl]="sectionFilterKeyword"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let section of filteredSectionList | async" [value]="section.section_id">
                    {{section.section_name}}
                </mat-option>
            </mat-select>
        </mat-chip> -->
        <mat-chip (click)="select.open()">State                                            
            <mat-select #select [formControl]="selectedUserState">
                <mat-option value="active">Active</mat-option>
                <mat-option value="inactive">Inactive</mat-option>
                <mat-option value="both">Both</mat-option>
            </mat-select>                                   
        </mat-chip>
        <mat-chip color="primary" style="cursor: pointer;" (click)="loadAttendees()" [selected]=true>
            <i matPrefix class="fa fa-search" style="width: 25px;" aria-hidden="true"></i>
            Load Members
        </mat-chip>
    </mat-chip-list>

    <mat-chip-list *ngIf="participantType == 'requester'" aria-label="Filter-Queries">
        <mat-chip (click)="reqTypeFilter.open()">
            <span>Requester Type</span>
            <mat-select #reqTypeFilter [formControl]="selectedReqTypes" placeholder="reqTypes" [multiple]="true" style="width: inherit;">
                <mat-option *ngFor="let reqType of reqTypeList" [value]="reqType.type_id">
                    {{reqType.type_name}}
                </mat-option>
            </mat-select>
        </mat-chip>
        <mat-chip (click)="divisionFilter.open()">
            Division
            <mat-select #divisionFilter [formControl]="selectedDivisions" placeholder="Divisions" [multiple]="true">
                <mat-option>
                    <ngx-mat-select-search [formControl]="divisionFilterKeyword"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let division of filteredDivisionList | async" [value]="division.division_id">
                    {{division.division_name}}
                </mat-option>
            </mat-select>
        </mat-chip>
        <mat-chip (click)="select.open()">State                                            
            <mat-select #select [formControl]="selectedReqState">
                <mat-option value="active">Active</mat-option>
                <mat-option value="inactive">Inactive</mat-option>
                <mat-option value="both">Both</mat-option>
            </mat-select>                                   
        </mat-chip>
        <mat-chip color="primary" (click)="loadAttendees()" style="cursor: pointer;" selected>
            <i matPrefix class="fa fa-search" style="width: 25px;" aria-hidden="true"></i>
            Load Members
        </mat-chip>
    </mat-chip-list>
</div>            
<div class="row">
    <!-- Particpants select from this box---begin -->
    <div class="col">
        <mat-card class="col card-outline box-card">
            <mat-card-header style="display: inline;">
                <mat-card-title>
                    <div class="row">Select Members</div>
                    <!-- When participant type user selected -->
                    <div *ngIf="participantType == 'user'" class="row">
                        <div class="col-9" style="padding:0px">
                            <mat-form-field class="box-card-search">  
                                <i matPrefix class="fa fa-search" style="width: 25px;" aria-hidden="true"></i>
                                <input type="text" #userSearch matInput [formControl]="attendeeUserSearch">
                            </mat-form-field>
                        </div>
                        <div class="col-3" style="padding-right:0px">
                            <button mat-stroked-button (click)="userSelectAll()" class="box-card-search-btn">Select All</button> 
                        </div>                                       
                    </div> 
                    <!-- When participant type requester selected -->
                    <div *ngIf="participantType == 'requester'" class="row">
                        <div class="col-9" style="padding:0px">
                            <mat-form-field class="box-card-search">  
                                <i matPrefix class="fa fa-search" style="width: 25px;" aria-hidden="true"></i>
                                <input type="text" matInput [formControl]="attendeeReqSearch">
                            </mat-form-field>
                        </div>
                        <div class="col-3" style="padding-right:0px">
                            <button mat-stroked-button (click)="reqSelectAll()" class="box-card-search-btn">Select All</button> 
                        </div>                                       
                    </div> 
                </mat-card-title>            
            </mat-card-header>
            <!-- When participant type user selected -->
            <mat-card-content *ngIf="participantType == 'user'" class="box-card-content">
                <div class="not-found-option" *ngIf="(filteredUserAttendees | async)?.length == 0">No remaining participant</div>
                <mat-selection-list #userParticipants>   
                    <mat-list-option id="box-list-option" *ngFor="let participant of filteredUserAttendees | async" [value]='participant'
                    (click)="participant.selected = !participant.selected"
                    [selected] = "participant.selected">
                    <img matListAvatar [src]="participant.profile_url || '../../assets/images/user-default.png'" alt="avatar">
                    {{participant.first_name+' '+participant.last_name}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-card-content>
            <!-- When participant type requester selected -->
            <mat-card-content *ngIf="participantType == 'requester'" class="box-card-content">
                <div class="not-found-option" *ngIf="(filteredReqAttendees | async)?.length == 0">No any selected participant</div>
                <mat-selection-list #reqParticipants>   
                    <mat-list-option id="box-list-option" *ngFor="let participant of filteredReqAttendees | async" [value]='participant'>
                    <img matListAvatar [src]="participant.profile_url || '../../assets/images/user-default.png'" alt="avatar">
                    {{participant.name_in_full}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="col-1 text-center">
        <button style="margin-top: 200px" mat-raised-button (click)="addToSelected()"><mat-icon>arrow_forward</mat-icon></button> 
    </div>
    <!-- Particpants select from this box---end -->
    <!-- Selected particpants show in this box---begin -->
    <div class="col">
        <mat-card class="col card-outline box-card">
            <mat-card-header style="display: inline;">   
                <mat-card-title>
                    <div class="row">Selected Members
                        <i id="count" *ngIf="participantType == 'user'">{{(filteredUserSelAttendees | async)?.length}}</i>
                        <i id="count" *ngIf="participantType == 'requester'">{{(filteredReqSelAttendees | async)?.length}}</i>
                    </div>
                     <!-- When participant type user selected -->
                     <div *ngIf="participantType == 'user'" class="row">
                        <div class="col-9" style="padding:0px">
                            <mat-form-field class="box-card-search">  
                                <i matPrefix class="fa fa-search" style="width: 25px;" aria-hidden="true"></i>
                                <input type="text" matInput [formControl]="selectedUserAttendeeSearch">
                            </mat-form-field>
                        </div>
                        <div class="col-3" style="padding-right:0px">
                            <button mat-stroked-button (click)="removeAllUserAttendees()" class="box-card-search-btn">Remove All</button> 
                        </div>                                       
                    </div> 
                    <!-- When participant type requester selected -->
                    <div *ngIf="participantType == 'requester'" class="row">
                        <div class="col-9" style="padding:0px">
                            <mat-form-field class="box-card-search">  
                                <i matPrefix class="fa fa-search" style="width: 25px;" aria-hidden="true"></i>
                                <input type="text" matInput [formControl]="selectedReqAttendeeSearch">
                            </mat-form-field>
                        </div>
                        <div class="col-3" style="padding-right:0px">
                            <button mat-stroked-button (click)="removeAllReqAttendees()" class="box-card-search-btn">Remove All</button> 
                        </div>                                       
                    </div> 
                </mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="participantType == 'user'" class="box-card-content">   
                <div class="not-found-option" *ngIf="(filteredUserSelAttendees | async)?.length == 0">No remaining participant</div>                     
                <mat-nav-list>
                    <mat-list-item id="box-list-option" *ngFor="let participant of filteredUserSelAttendees | async">
                        <!-- <mat-list-item id="box-list-option" *ngFor="let participant of selectedUserAttendeeList"> -->

                        <img class="list-option-img" [src]="participant.profile_url || '../../assets/images/user-default.png'" alt="avatar" height="30">
                        <div class="mat-list-text">{{participant.first_name+' '+participant.last_name}}</div>
                        <button mat-icon-button (click)="removeUserAttendee(participant)" class="list-item-close-btn">
                            <mat-icon>close</mat-icon>
                        </button>              
                    </mat-list-item>
                </mat-nav-list>                                      
            </mat-card-content>
            <mat-card-content *ngIf="participantType == 'requester'" class="box-card-content">   
                <div class="not-found-option" *ngIf="(filteredReqSelAttendees | async)?.length == 0">No any selected participant</div>
                <mat-nav-list>
                    <mat-list-item id="box-list-option" *ngFor="let participant of filteredReqSelAttendees | async">
                        <img class="list-option-img" [src]="participant.profile_url || '../../assets/images/user-default.png'" alt="avatar" height="30">
                        <div class="mat-list-text">{{participant.name_in_full}}</div>
                        <button mat-icon-button (click)="removeReqAttendee(participant)" class="list-item-close-btn">
                            <mat-icon>close</mat-icon>
                        </button>              
                    </mat-list-item>
                </mat-nav-list>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- Selected particpants show in this box---end -->
</div>