import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from './settings.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { FileUploadServiceService } from '../services/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { UserImageUploadPanelComponent } from './user-image-upload-panel/user-image-upload-panel.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AddItemDlgComponent } from '../basic-data/labels/add-item-dlg/add-item-dlg.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],

})
export class SettingsComponent implements OnInit {

  isEditable: boolean = false;
  editBtnName: string = "Edit";
  isChangePwd: boolean = false;
  imageUrl: string;
  //imageError: string;
  //isImageSaved: boolean;
  isImageExist: boolean = false;
  //cardImageBase64: string;
  
  constructor(
    private settingsService   : SettingsService,  
    private _formBuilder      : FormBuilder,
    private _auth             : AuthService,
    private fileUploadService : FileUploadServiceService,
    private toastr            : ToastrService,
    private dialog            : MatDialog) 
    { 
      this.getUserDefaultData();
      this._auth.userDetails.subscribe(value =>{
        this.imageUrl = value['imageUrl'];
      })
  }

  user_details = this._formBuilder.group({
    user_id : [''],
    user_name : ['',{disabled: true}],
    nic : ['', Validators.required],
    first_name : ['', Validators.required],
    last_name : ['', Validators.required],
    mobile_no : ['', Validators.required],
    email : ['', Validators.email],
    designation : ['']
  });

  pwd_details = this._formBuilder.group({
    old_pwd : ['', Validators.required],
    new_pwd : ['', Validators.required],
    confirm_pwd : ['', Validators.required]
  });

  ngOnInit() {
    if(this.imageUrl.includes('http://')){
      this.isImageExist = true;
    }
  }

  getUserDefaultData(){
    this.settingsService.getUserDefaults(this._auth.getUserDetails['userId'], this._auth.getUserDetails['username'])
    .subscribe(data => {
      if(data["user_name"] != null){
        this.user_details.patchValue({
          user_id     : data["user_id"],
          user_name   : data["user_name"],
          nic         : data["nic"],
          first_name  : data["first_name"], 
          last_name   : data["last_name"],
          email       : data["email"],
          mobile_no   : data["mobile_no"],
          designation : data["designation"]
        })
      }
      else{
        this.toastr.error('Server Error', 'Error');
        console.log('User default data not fetched...');
      }
    });
  }

  onEditCancel(){
    if(!this.isEditable){
      this.isEditable = true;
      this.editBtnName = "Cancel";
    }
    else{
      this.isEditable = false;
      this.editBtnName = "Edit";  
    }
  }

  saveChanges(){
    this.settingsService.updateProfileSettings(this.user_details.value)
    .subscribe(res => {
      if(res['status'] == 'success'){
        this.isEditable = false; 
        this.editBtnName = "Edit";
        this.toastr.success('Details updated', 'Success');
      }
      else{
        this.toastr.error('Update failed', 'Failed'); 
      }
    });
  }

  activeChangePwd(){
    if(!this.isChangePwd){
      this.isChangePwd = true;
    }
    else{
      this.isChangePwd = false;
    }
  }

  updatePassword(){
    if(this.pwd_details.controls.new_pwd.value == this.pwd_details.controls.confirm_pwd.value){
      this.settingsService.changePassword(this.user_details.controls.user_id.value, this.pwd_details.value)
      .subscribe( res => {
        if(res['status'] == 'success'){
          this.toastr.success('Password changed', 'Success');
          this.pwd_details.reset();
        }
        else{
          this.toastr.error('Failed to update password', 'Failed');
        }
      },
      err => { 
        this.toastr.error('Server error', 'Failed');
        console.log(err);
      });
    }
    else{
      this.toastr.warning('Confirm Password is not match', 'Ooops..!');
    }
  }

  // userImageChangeEvent(fileInput: any) {
  //   this.imageError = null;
  //   if (fileInput.target.files && fileInput.target.files[0]) {
  //     const max_size = 10000000;
  //     const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];

  //     if (fileInput.target.files[0].size > max_size) {
  //         this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
  //         return false;
  //     }

  //     if (!allowed_types.includes(fileInput.target.files[0].type)) {
  //         this.imageError = 'Only Images are allowed ( JPG | PNG )';
  //         return false;
  //     }

  //     this.fileUploadService.uploadUserImage(this.user_details.controls.user_name.value, this._auth.getUserDetails['userId'], fileInput.target.files[0])
  //     .subscribe( res => {
  //       if(res['status'] == 'success'){
  //         reader.readAsDataURL(fileInput.target.files[0]);
  //         this._auth.setImageUrl(res['image_url']);
  //         this.isImageExist = true;
  //       }
  //       else{
  //         this.toastr.error('File Uplading failed', 'Failed');
  //       }    
  //     });

  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       const image = new Image();
  //       image.src = e.target.result;
  //       const imgBase64Path = e.target.result;
  //       this.cardImageBase64 = imgBase64Path;
  //       // this.isImageSaved = true;
  //     };
  //   }
  // }

  removeImage() {
    this.fileUploadService.removeUserImage(this.user_details.controls.user_name.value, this._auth.getUserDetails['userId'], this.imageUrl)
    .subscribe( res => {
      if(res['status'] == 'success'){
        this._auth.setImageUrl('default');
        //this.cardImageBase64 = null;
        this.isImageExist = false;
      }
      else{
        this.toastr.error('File Uplading failed', 'Failed');
      }    
    }); 
  }

  changeImage(){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.width = "50%";
    dialogconfig.minWidth = "350px";
    dialogconfig.data = {
      userId : this._auth.getUserDetails['userId'],
      username : this.user_details.controls.user_name.value,
      hasImage : this.isImageExist
    }
    var dialog_ref = this.dialog.open(UserImageUploadPanelComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res == 'upload'){
        this.isImageExist = true;
      }
      else if(res == 'remove'){
        this.removeImage();
      }
    });
  }

  

}