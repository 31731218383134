import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { SidenavService } from '../sidenavbar/sidenav.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  LoginError : string;
  token: string|undefined;
  // captcha: string;

  constructor( private _auth :AuthService, private sidenavService: SidenavService){
    this._auth.setShowHeader(false);
    this.LoginError = "";
    this.token = undefined;
  }

  login_form = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });

  ngAfterViewInit(): void {
   // this.sidenavService.close();
  }

  async onSubmit() {
    const resp =await this._auth.loginMethod(this.login_form.value)
    if (!resp){
      this.LoginError = "Wrong Username or Password!"
    }
  }

  // resolved(captchaResponse: string){
  //   this.captcha = captchaResponse
  // }
}
