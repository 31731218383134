import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NewRequestComponent } from './request/new-request/new-request.component';
import { ProgressComponent } from './request/progress/progress.component';
import { SettingsComponent } from './settings/settings.component';
import { RouteGuardService } from './services/route-guard.service';
import { UsersComponent } from './users/users.component';
import { CommunicationComponent } from './collaboration/communication/communication.component';
import { BasicDataComponent } from './basic-data/basic-data.component';
import { NewAgreementComponent } from './agreement/new-agreement/new-agreement.component';
import { AgreementProgressComponent } from './agreement/agreement-progress/agreement-progress.component';
import { RequesterComponent } from './requester/requester.component';
import { NewEventComponent } from './event/new-event/new-event.component';
import { EventCalenderComponent } from './event/event-calender/event-calender.component';
import { EventListComponent } from './event/event-list/event-list.component';
import { GroupListComponent } from './collaboration/group-list/group-list.component';
import { SidenavbarComponent } from './sidenavbar/sidenavbar.component';
import { LetterWritingComponent } from './request/letter-writing/letter-writing.component';

const routes: Routes = [
  // { path: 'login', pathMatch:'full', component: LoginComponent},
  // { path: 'dashboard', component: DashboardComponent, canActivate:[RouteGuardService]},
  // { path: 'new_request', component: NewRequestComponent, canActivate:[RouteGuardService]},
  // { path: 'progress', component: ProgressComponent, canActivate:[RouteGuardService]},
  // { path: 'settings', component: SettingsComponent, canActivate:[RouteGuardService]},
  // // { path: 'request_status/:id', component: RequestStatusComponent, canActivate:[RouteGuardService]},
  // { path: 'users', component: UsersComponent, canActivate:[RouteGuardService]},
  // { path: 'requester', component: RequesterComponent, canActivate:[RouteGuardService]},
  // { path: 'communication', component: CommunicationComponent, canActivate:[RouteGuardService]},
  // { path: 'basicData', component: BasicDataComponent, canActivate:[RouteGuardService]},
  // { path: 'agreement', component: NewAgreementComponent, canActivate: [RouteGuardService]},
  // { path: 'arg_progress', component: AgreementProgressComponent, canActivate: [RouteGuardService]},
  // { path: 'new_event', component: NewEventComponent, canActivate: [RouteGuardService]},
  // { path: 'event_calender', component: EventCalenderComponent, canActivate: [RouteGuardService]},
  // { path: 'event_list', component: EventListComponent, canActivate: [RouteGuardService]},
  // { path: 'group_list', component: GroupListComponent, canActivate: [RouteGuardService]},


  // { path: '', redirectTo: 'login',canActivate:[RouteGuardService],pathMatch:'full'}
  //  { path: '**', redirectTo: 'login',canActivate:[RouteGuardService],pathMatch:'full'}

  { path: 'login', pathMatch:'full', component: LoginComponent},
  { path: '', redirectTo: 'login',canActivate:[RouteGuardService],pathMatch:'full'},
  { path: '', component: SidenavbarComponent,
    children:[
      { path: 'dashboard', component: DashboardComponent, canActivate:[RouteGuardService]},
      { path: 'new_request', component: NewRequestComponent, canActivate:[RouteGuardService]},
      { path: 'progress', component: ProgressComponent, canActivate:[RouteGuardService]},
      { path: 'settings', component: SettingsComponent, canActivate:[RouteGuardService]},
      { path: 'users', component: UsersComponent, canActivate:[RouteGuardService]},
      { path: 'requester', component: RequesterComponent, canActivate:[RouteGuardService]},
      { path: 'communication', component: CommunicationComponent, canActivate:[RouteGuardService]},
      { path: 'basicData', component: BasicDataComponent, canActivate:[RouteGuardService]},
      { path: 'agreement', component: NewAgreementComponent, canActivate: [RouteGuardService]},
      { path: 'arg_progress', component: AgreementProgressComponent, canActivate: [RouteGuardService]},
      { path: 'new_event', component: NewEventComponent, canActivate: [RouteGuardService]},
      { path: 'event_calender', component: EventCalenderComponent, canActivate: [RouteGuardService]},
      { path: 'event_list', component: EventListComponent, canActivate: [RouteGuardService]},
      { path: 'group_list', component: GroupListComponent, canActivate: [RouteGuardService]}]},
  { path: 'letter_pad', component: LetterWritingComponent, canActivate:[RouteGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
