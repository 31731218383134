import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FileUploadServiceService } from '../../services/file-upload.service';
import { AgreementStatusService } from './agreement-status.service';
import { DatePipe } from '@angular/common';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

export interface Follower {
  user_id: string;
  user_name: string;
  first_name: string;
  last_name: string;
  profile_url: string;
}

export interface Division {
  division_id: string;
  division_name: string;
  division_description: string;
  active: string;
}

@Component({
  selector: 'app-agreement-status',
  templateUrl: './agreement-status.component.html',
  styleUrls: ['./agreement-status.component.css']
})
export class AgreementStatusComponent implements OnInit {

  agrNumber: string;
  agrId: string;
  agrTopic: string;
  agrDesc: string;
  agrDate: string;
  dueDate: string;
  categoryId: string;
  categoryName: string;
  priority: string;
  state: string;
  followerId: string;
  followerUrl: string;
  followerName = new FormControl();
  filteredFollowers: Observable<Follower[]>;

  categoryList: any;
  stateList: any;
  userList: Follower[] = [];

  // divisionId: string;
  // division = new FormControl();
  // divisionList: Division[] = [];
  // filteredDivisionList: Observable<Division[]>;

  editPriority : boolean;
  showEditPriority : boolean;
  editCategory : boolean;
  showEditCategory : boolean;
  editDueDate : boolean;
  showEditDueDate : boolean;
  editFollowUpBy : boolean;
  showEditFollowUpBy : boolean;
  editDivision : boolean;
  showEditDivision : boolean;

  attachments: object[] = [];
  comments: any;
  comment: string = "";
  progress_bar: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AgreementStatusComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private agreementStatusService : AgreementStatusService,
    private toastr                 : ToastrService,
    private fileUploadService      : FileUploadServiceService,
    private confDialog             : MatDialog,
    private datePipe               : DatePipe)
  {
    this.agrNumber = data.agr_number;
  }

  ngOnInit() {
    this.loadAttachments();
    this.loadStateList();
    //this.loadUserList();
    this.loadComments();
    this.loadAgreementData();
    this.setFollowerFilter();
  }

  loadAgreementData() {
    this.agreementStatusService.getAgreementDetails(this.agrNumber).subscribe(details => {
      this.agrId = details['agr_id'];
      this.agrTopic = details['agr_topic'];
      this.agrDesc = details['agr_desc'];
      this.agrDate = details['agr_date'];
      this.dueDate = details['due_date'];
      this.categoryId = details['category_id'];
      this.categoryName = details['category_name'];
      this.priority = details['priority'];
      this.state = details['state'];
      this.followerId = details['follower_id'];
      this.followerUrl = details['follower_url'];
      this.followerName.setValue(details['follower_name']);
      // this.division.setValue(details['division_id'] + ' - ' + details['division_name']);
    });
  }

  loadAttachments() {
    this.agreementStatusService.getAttachments(this.agrNumber).subscribe(res => {
      if (res['message'] !== "No attachments") {
        for (var val of res as any[]) {
          var value = val['file_path'];
          var name = val['file_name'];
          this.attachments.push({ "link": value, "name": name });
        }
      }
    });
  }

  loadStateList() {
    this.agreementStatusService.getStateList().subscribe(res => {
      this.stateList = res;
    });
  }

  loadCategoryList() {
    this.agreementStatusService.getCategoryList().subscribe(res => {
      this.categoryList = res;
    });
  }

  loadUserList() {
    this.agreementStatusService.getUserList().subscribe(res => {
      if(res != null){     
        res.forEach(element => {
          this.userList.push(element);
        });
      }
      this.setFollowerFilter();
    });
  }

  // loadDivisionList() {
  //   this.agreementStatusService.getDivisionList().subscribe(res => {
  //     if(res != null){
  //       res.forEach(element => {
  //         this.divisionList.push(element);
  //       });
  //     }
  //     this.setDivionFilter();
  //   });
  // }

  loadComments() {
    this.agreementStatusService.getComments(this.agrNumber).subscribe(res => {
      this.comments = res;
    })
  }

  // setDivionFilter(){
  //   this.filteredDivisionList = this.division.valueChanges
  //   .pipe(
  //     startWith(''),
  //     map(division => division ? this._filter_division(division) : this.divisionList.slice())
  //   );
  // }

  setFollowerFilter(){
    this.filteredFollowers = this.followerName.valueChanges
    .pipe(
      startWith(''),
      map(follower => follower ? this._filter(follower) : this.userList.slice())
    );
  }
  //Use to fiter the follower list
  private _filter(follower: string): Follower[] {
    const filterValue = follower.toLowerCase();
    return this.userList.filter(user => (user.first_name.toLowerCase().indexOf(filterValue) === 0)||
    (user.last_name.toLowerCase().indexOf(filterValue) === 0) ||
    ((user.first_name+' '+user.last_name).toLowerCase().indexOf(filterValue) === 0));
  }

  // private _filter_division(division: string): Division[] {
  //   const filterValue = division.toLowerCase();
  //   return this.divisionList.filter(
  //     (division) =>
  //     division.division_id.toLowerCase().indexOf(filterValue) === 0 ||
  //     division.division_name.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  changePriority() {
    this.agreementStatusService.changePriority(this.agrNumber, this.priority).subscribe(res => { 
      if(res['status']=="success"){
        this.editPriority = false;
        this.toastr.success('Agreement priority changed to : ' + this.priority, 'Success');
      }
    });
  }

  changeState(state: string) {
    if(state == 'check'){
      if(this.state == 'In Progress'){
        state = 'Closed';
      }
      else {
        state = 'In Progress';
      }
    }

    var selected = (this.stateList.find(element => element.state_name === state)).id;
    this.agreementStatusService.changeState(this.agrNumber, selected).subscribe(res => {  
      this.state = state;
      this.toastr.success('Ticket state changed to : ' + state, 'Success');
    });
  }

  changeCategory() {
    var category_name = (this.categoryList.find(element => element.agr_category_id === this.categoryId)).agr_category_name;
    this.agreementStatusService.changeCategory(this.agrNumber, this.categoryId).subscribe(res => {
      if(res['status']=="success"){
        this.categoryName = category_name;
        this.editCategory = false;
        this.toastr.success('Category Changed', 'Success');
      }
    })   
  }
  
  changeDueDate() {
    // Change the date format
    this.dueDate = this.datePipe.transform(this.dueDate , 'yyyy-MM-dd');
    this.agreementStatusService.changeDueDate(this.agrNumber, this.dueDate).subscribe(res => {
      if(res['status']=="success"){
        this.editDueDate = false;
        this.toastr.success('Category Changed', 'Success');
      }
    })
  }

  changeFollowUpBy() {
    this.agreementStatusService.changeFollowupUser(this.agrNumber, this.followerId).subscribe(res => {
      if(res['status']=="success"){
        this.editFollowUpBy = false;
        this.toastr.success('Follower Changed', 'Success');
      }
    })
  }

  // changeDivision(){
  //   this.agreementStatusService.changeDivision(this.agrNumber, this.divisionId).subscribe(res => {
  //     if(res['status']=="success"){
  //       this.editDivision = false;
  //       this.toastr.success('Division Changed', 'Success');
  //     }
  //   })
  // }

  setComment() {
    this.agreementStatusService.setComment(this.agrNumber, this.comment).subscribe(res => {
      this.loadComments();
      this.toastr.success('Comment added', 'Success');
      this.comment = "";
    });
  }

  onFileAdd(files){
    console.log(files);
   // console.log(event.target.files[0]);
    this.progress_bar = true;
    // this.files.push(...files);
    this.fileUploadService.fileUpload(this.agrNumber, files, "agreement", "post_upload").subscribe(
      res => {
        if(res != null){
          res.forEach(element => {
            var value = element["file_path"];
            var name = element["file_name"];
            this.attachments.push({ "link": value, "name": name });        
          });
          this.progress_bar = false;
        }else{
          this.toastr.error('File uploadin failed', 'Error');
          this.progress_bar = false;
        }
      }  
    );
  }

  onFileRemove(event){
    const title = 'Delete Confirmation';
    const message = 'Are you sure you want to delete this file?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        var index_no = this.attachments.indexOf(event);
        var attachment = this.attachments[index_no]; 
        this.fileUploadService.fileRemove(this.agrNumber, attachment['name'], attachment['link'], "agreement", "post_remove").subscribe(
          res => {
            if(res['status']=='success'){
              this.attachments.splice(index_no, 1);
              this.toastr.success('File removed from request', 'Success');
            }
            else{
              this.toastr.error('Failed to remove file', 'Error');
            }
          },
          err => console.log(err)
        );
      }
    });  
  }

  onCloseClick(){
    this.dialogRef.close();
  }
}