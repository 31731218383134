import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { AgreementProgressService } from './agreement-progress.service';
import { MatTableDataSource } from '@angular/material/table';
import { AgreementStatusComponent } from '../agreement-status/agreement-status.component';
import { PermissionService } from 'src/app/services/permissions.service';

export interface iAgreement {
  agr_id: string;
  agr_topic: string;
  agr_date: string;
  due_date: string;
  remaining_days: number;
  priority: string;
  category: string;
  state: string;
}

@Component({
  selector: 'app-agreement-progress',
  templateUrl: './agreement-progress.component.html',
  styleUrls: ['./agreement-progress.component.css']
})

export class AgreementProgressComponent implements OnInit {
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;

  tableColumns: string[] = ['agr_id', 'agr_topic', 'agr_date', 'due_date', 'remaining_days', 'priority', 'category', 'state'];
  agreementData: any;
  searchKey: string;
  state: string = "1";

  constructor(
    private progressService : AgreementProgressService, 
    private dialog          : MatDialog,
    private _auth           : AuthService,
    private permission      : PermissionService) 
  { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    if (this.permission.PERM_LOAD_ALL_AGREEMENTS) {
      this.loadAllData();
    }else{
      this.loadSelectedData(this._auth.getUserId);
    }
  }

  loadAllData() {
    this.progressService.getProgressList(this.state).subscribe(res => {
      this.agreementData = new MatTableDataSource(res as iAgreement[]);
      //var diff = this.calculateDateDiff(res['due_date']);
      this.agreementData.sort = this.sort;
      this.agreementData.paginator = this.paginator;
    })
  }

  loadSelectedData(userId: string){
    this.progressService.getProgressSelectedList(this.state,userId).subscribe(res => {
      this.agreementData = new MatTableDataSource(res as iAgreement[]);
      this.agreementData.sort = this.sort;
      this.agreementData.paginator = this.paginator;
    })
  }

  onClickNew() {
    this.state = "1"
    this.loadData();
  }

  onClickProcess() {
    this.state = "2"
    this.loadData();
  }

  onClickCompleted() {
    this.state = "3"
    this.loadData();
  }
  
  onClickAll() {
    this.state = "0"
    this.loadData();
  }

  onClickCancel() {
    this.state = "4"
    this.loadData();
  }

  applyFilter(){
    this.agreementData.filter = this.searchKey.trim().toLowerCase();
  }
  onSearchClear(){
    this.searchKey = "";
    this.applyFilter();
  }

  showRecord(agr_no : string){
    const dialogconfig = new MatDialogConfig();
    //dialogconfig.disableClose = true;
    dialogconfig.autoFocus= false;
    dialogconfig.width = "85%";
    dialogconfig.minWidth = "380px"; /*Set minimum width - Important for mobile widows*/
    dialogconfig.data = {
      agr_number : agr_no
    }
    var dialog_ref = this.dialog.open(AgreementStatusComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      this.loadData();
    });
  }

  calculateDateDiff(date){
    let currentDate = new Date();
    date = new Date(date);

    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) ) /(1000 * 60 * 60 * 24));
}

}
