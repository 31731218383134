<div class="main-mat">
    <mat-card-title>
        <div class="row" style="margin-left: 0px; margin-right: auto; padding-top: 0px;">
            Edit Participants
            <button mat-icon-button class="btn-close"  (click)="onCloseClick()"><mat-icon>close</mat-icon></button>
        </div>
    </mat-card-title>
    <app-participant-adding
        [selectedUserAttendeeList]="selectedUserAttendeeList"
        [selectedReqAttendeeList]="selectedReqAttendeeList"
        [origin]="origin">
    </app-participant-adding>
    <div>
        <button mat-raised-button (click)="updateParticipants()" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px; margin-right: 5px;">Save</button>
        <button mat-raised-button (click)="onCloseClick()" color="primary" type="button" class="mat-button-class" style="float: right;">Cancel</button>
    </div>
</div>