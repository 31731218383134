import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-item-dlg',
  templateUrl: './add-item-dlg.component.html',
  styleUrls: ['./add-item-dlg.component.css']
})
export class AddItemDlgComponent implements OnInit {

  itemValue = new FormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<AddItemDlgComponent>) 
  { }

  ngOnInit(): void {
  }

  onCancel(){
    this.dialogRef.close({confirm: false});
  }

  onSave(){
    this.dialogRef.close({
      confirm     : true, 
      itemValue   : this.itemValue.value});
  }

}
