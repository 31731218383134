import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, Validators, FormControl, ControlValueAccessor, FormGroupDirective } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { NewEventService } from './new-event.service';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { MatSelectionList } from '@angular/material/list';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

export interface UserAttendee {
  user_id: string;
  user_name: string;
  first_name: string;
  last_name: string;
  profile_url: string;
  selected: boolean;
}

export interface ReqAttendee {
  nic: string;
  name_in_full: string;
  selected: boolean;
}

export interface Designation {
  designation_id: string;
  designation_name: string;
  designation_description: string;
  active: string;
}

export interface Section {
  section_id: string;
  section_name: string;
  section_description: string;
  active: string;
}

export interface ReqType {
  type_id: string;
  type_name: string;
}

export interface Division {
  division_id: string;
  division_name: string;
  division_description: string;
  active: string;
}

// export interface Assignee {
//   user_id: string;
//   user_name: string;
//   first_name: string;
//   last_name: string;
//   profile_url: string;
// }

@Component({
  selector: 'app-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.css']
})
export class NewEventComponent implements OnInit {
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild('userParticipants', {static: false}) private userParticipants: MatSelectionList;
  @ViewChild('reqParticipants', {static: false}) private reqParticipants: MatSelectionList;
  
  participantType: string = 'user';
  assigneeId: string;
  assigneeList: UserAttendee[] = [];
  filteredAssignees: Observable<UserAttendee[]>;

  userAttendeeList: UserAttendee[] = [];
  //filteredUserAttendees: Observable<UserAttendee[]>;
  filteredUserAttendees: ReplaySubject<UserAttendee[]> = new ReplaySubject<UserAttendee[]>(1);
  attendeeUserSearch = new FormControl();

  requesterAttendeeList: ReqAttendee[] = [];
  filteredReqAttendees: ReplaySubject<ReqAttendee[]> = new ReplaySubject<ReqAttendee[]>(1);
  attendeeReqSearch = new FormControl();

  selectedUserAttendeeList: UserAttendee[] = [];
  filteredUserSelAttendees: ReplaySubject<UserAttendee[]> = new ReplaySubject<UserAttendee[]>(1);
  selectedUserAttendeeSearch = new FormControl();

  selectedReqAttendeeList: ReqAttendee[] = [];
  filteredReqSelAttendees: ReplaySubject<ReqAttendee[]> = new ReplaySubject<ReqAttendee[]>(1);
  selectedReqAttendeeSearch = new FormControl();

  isUserAllSelected: boolean  = false;
  isReqAllSelected: boolean  = false;

  designationsList: Designation[] = [];
  public filteredDesignationList: ReplaySubject<Designation[]> = new ReplaySubject<Designation[]>(1);
  public selectedDesignations: FormControl = new FormControl();
  public designationFilterKeyword: FormControl = new FormControl();

  sectionsList: Section[] = [];
  public filteredSectionList: ReplaySubject<Section[]> = new ReplaySubject<Section[]>(1);
  public selectedSections: FormControl = new FormControl();
  public sectionFilterKeyword: FormControl = new FormControl();

  reqTypeList: ReqType[] = [];
  //public filteredReqTypeList: ReplaySubject<Section[]> = new ReplaySubject<Section[]>(1);
  public selectedReqTypes: FormControl = new FormControl();
  //public sectionFilterKeyword: FormControl = new FormControl();

  divisionList: Division[] = [];
  public filteredDivisionList: ReplaySubject<Division[]> = new ReplaySubject<Division[]>(1);
  public selectedDivisions: FormControl = new FormControl();
  public divisionFilterKeyword: FormControl = new FormControl();

  selectedUserState: FormControl = new FormControl();
  selectedReqState: FormControl = new FormControl();
  public selectedUserIds: String[] = []; //Use when submit
  public selectedReqNics: String[] = []; //Use when submit
  private _onDestroy = new Subject<void>();

  minDate;
  maxDate;

  constructor(
    private _formBuilder    : FormBuilder,
    private newEventService : NewEventService,
    private toastr          : ToastrService,
    private datePipe        : DatePipe)
  {
    this.loadAssigneeList();
  }

  new_event_form = this._formBuilder.group({
    event_no: [{value: '' ,disabled: true}],
    event_name: ['', Validators.required],
    event_desc: ['', Validators.required],
    event_category: ['', Validators.required],
    assignee: [''],
    location: [''],
    event_start_date: ['', Validators.required],
    event_start_time: [''],
    event_end_date: ['', Validators.required],
    event_end_time: [''],
    all_day: [''],
    event_type: ['', Validators.required],
    occurrence: ['', Validators.required],
    repeat_interval: ['',[Validators.max(7), Validators.maxLength(1)]],
    occurrence_end_date: ['']
  });

  ngOnInit() {
    //this.minDate = new Date(this.new_event_form.controls.event_start_date.value);
   // this.setAssigneeFilter();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onPartTypeChange(){
    this.loadFiltersData();
  }

  onStartDateSelected(start_date){
    this.minDate = new Date(start_date);
  }

  onEndDateSelected(end_date){
    this.maxDate = new Date(end_date);
  }
  
  onSubmit() {
    this.selectedUserAttendeeList.forEach(element => {
      this.selectedUserIds.push(element.user_id);
    });

    this.selectedReqAttendeeList.forEach(element => {
      this.selectedReqNics.push(element.nic);
    });

    this.new_event_form.patchValue({event_start_date : this.datePipe.transform(this.new_event_form.controls.event_start_date.value , 'yyyy-MM-dd')});
    this.new_event_form.patchValue({event_end_date : this.datePipe.transform(this.new_event_form.controls.event_end_date.value , 'yyyy-MM-dd')});
    this.new_event_form.patchValue({occurrence_end_date : this.datePipe.transform(this.new_event_form.controls.occurrence_end_date.value , 'yyyy-MM-dd')});
    this.new_event_form.patchValue({assignee : this.assigneeId}); //Set followerId as follower...

    this.newEventService.createEvent(this.new_event_form.value, this.selectedUserIds, this.selectedReqNics).subscribe(
      res => {
        if(res['status'] == 'success'){
          this.toastr.success('Event created successfully', 'Success');
          this.clear();
        }
        else{
          this.toastr.error('Something went wrong!', 'Failed');
        }
      },
      err => {
        this.toastr.error('Something went wrong!', 'Failed');
      }
    );
  }

  OnAllDayCheckClick(event){
    if (event.source.checked) {
      this.new_event_form.controls['event_start_time'].disable();
      this.new_event_form.controls['event_end_time'].disable();
    }
    else{
      this.new_event_form.controls['event_start_time'].enable();
      this.new_event_form.controls['event_end_time'].enable();
    }
  }

  clear() {
    this.formGroupDirective.resetForm();
    this.new_event_form.reset();
    this.selectedUserAttendeeList = [];
    this.selectedReqAttendeeList = [];
    this.minDate = null;
    this.maxDate = null;
  }

  initializeAttendeeData(){
    //Load filter lov's data
    this.loadFiltersData();
    //Init search filters
    this.setDesignationFilter();
    this.setSectionFilter();
    this.setDivisionFilter();
    //Init box search filters
    this.setUserAttendeeFilter();
    this.filterUserAttendee();
    this.setReqAttendeeFilter();
    this.filterReqAttendee();
    this.setSelectedUserAttendeeFilter();
    this.filterSelectedUserAttendee();
    this.setSelectedReqAttendeeFilter();
    this.filterSelectedReqAttendee();
  }

  addToSelected(){
    if(this.participantType == 'user'){
      if(this.userParticipants.selectedOptions.selected.length > 0){
        this.selectedUserAttendeeList.push(...this.userParticipants.selectedOptions.selected.map(o => o.value) as UserAttendee[]);
        this.removeUserAttendeesFromList(); 
        this.filterSelectedUserAttendee();
      }
    }
    else if(this.participantType == 'requester'){
      if(this.reqParticipants.selectedOptions.selected.length > 0){
        this.selectedReqAttendeeList.push(...this.reqParticipants.selectedOptions.selected.map(o => o.value) as ReqAttendee[]);
        this.removeReqAttendeesFromList();
        this.filterSelectedReqAttendee();
      }    
    } 
  }

  //Remove selected items from user list
  removeUserAttendeesFromList(){
    this.selectedUserAttendeeList.forEach(element => {
      element.selected = false;
      var index_no = this.userAttendeeList.indexOf(element);
      if(index_no !== -1){
        this.userAttendeeList.splice(index_no, 1);
      }
    });
    this.filterUserAttendee(); //Initialize filter
  }

  //Remove selected items from requester list
  removeReqAttendeesFromList(){
    this.selectedReqAttendeeList.forEach(element => {
      element.selected = false;
      var index_no = this.requesterAttendeeList.indexOf(element);
      if(index_no !== -1){
        this.requesterAttendeeList.splice(index_no, 1);
      }
    });
    this.filterReqAttendee(); //Initialize filter
  }

  //Remove item from selected user list
  removeUserAttendee(attendee){
    var index_no = this.selectedUserAttendeeList.indexOf(attendee);
    this.selectedUserAttendeeList.splice(index_no, 1);
    this.userAttendeeList.push(attendee); //Put back to select list
    this.filterUserAttendee();
    this.filterSelectedUserAttendee();
  }

  //Remove item from selected requester list
  removeReqAttendee(attendee){
    var index_no = this.selectedReqAttendeeList.indexOf(attendee);
    this.selectedReqAttendeeList.splice(index_no, 1);
    this.requesterAttendeeList.push(attendee); //Put back to select list
    this.filterReqAttendee();
    this.filterSelectedReqAttendee();
  }

  //Remove all items from selected user list
  removeAllUserAttendees(){
    this.userAttendeeList.push(...this.selectedUserAttendeeList);
    this.selectedUserAttendeeList = [];
  }

  //Remove all items from selected requester list
  removeAllReqAttendees(){
    this.requesterAttendeeList.push(...this.selectedReqAttendeeList);
    this.selectedReqAttendeeList = [];
  }

  //Load filter lov's data
  loadFiltersData(){
    if(this.participantType == 'user'){
      if(this.designationsList.length == 0){
        this.loadDesignationsList(); 
      }
      if(this.sectionsList.length == 0){
        this.loadSectionsList(); 
      }
    }
    else if(this.participantType == 'requester'){
      if(this.reqTypeList.length == 0){
        this.loadReqTypeList();
      }
      if(this.divisionList.length == 0){
        this.loadDivisionList();
      }
    }
  }

  loadDesignationsList(){
    this.newEventService.getDesignationList()
    .subscribe(data => {
      if(data!= null){
        data.forEach(element => {
          this.designationsList.push(element);
        });
        this.filteredDesignationList.next(this.designationsList.slice()); //Filtered list MUST initialize after list load
      }
    });
  }

  loadSectionsList(){
    this.newEventService.getSectionsList()
    .subscribe(data => {
      if(data!= null){
        data.forEach(element => {
          this.sectionsList.push(element);
        });
        this.filteredSectionList.next(this.sectionsList.slice()); //Filtered list MUST initialize after list load
      }
    });
  }

  loadReqTypeList(){
    this.newEventService.getReqTypeList()
    .subscribe(data => {
      if(data!= null){
        data.forEach(element => {
          this.reqTypeList.push(element);
        });
      }
    });
  }

  loadDivisionList(){
    this.newEventService.getDivisionList()
    .subscribe(data => {
      if(data!= null){
        data.forEach(element => {
          this.divisionList.push(element);
        });
        this.filteredDivisionList.next(this.divisionList.slice()); //Filtered list MUST initialize after list load
      }
    });
  }

  loadAttendees() {
    if(this.participantType == 'user'){
      this.userAttendeeList = [];
      this.newEventService.loadUserAttendees(this.participantType, this.selectedDesignations.value, 
        this.selectedSections.value, this.selectedUserState.value).subscribe(res => {
        if(res != null){
          if(res['response'] != false){
            res.forEach(element => {
              if(!this.selectedUserAttendeeList.some(ele => ele.user_id == element.user_id)){ //Do not get elements which are already selected
                this.userAttendeeList.push(element);
              }             
            });
          }        
        }
        this.setUserAttendeeFilter();       
      });
    }
    else if(this.participantType == 'requester'){
      this.requesterAttendeeList = [];
      this.newEventService.loadRequesterAttendees(this.participantType, this.selectedReqTypes.value, 
        this.selectedDivisions.value, this.selectedReqState.value).subscribe(res => {
        if(res != null){
          if(res['response'] != false){
            res.forEach(element => {
              if(!this.selectedReqAttendeeList.some(ele => ele.nic == element.nic)){ //Do not get elements which are already selected
                this.requesterAttendeeList.push(element);
              }
            });
          }
          this.setReqAttendeeFilter();
        }
      });
    }
  }

  loadAssigneeList() {
    this.newEventService.getAssigneeList().subscribe(res => {
      if(res != null){
        res.forEach(element => {
          this.assigneeList.push(element);
        });
      }
      this.setAssigneeFilter();
    });
  }

  setAssigneeFilter(){
    this.filteredAssignees = this.new_event_form.controls.assignee.valueChanges
    .pipe(
      startWith(''),
      map(assignee => assignee ? this._filter(assignee) : this.assigneeList.slice())
    );
  }
  //Use to fiter the assignee list
  private _filter(assignee: string): UserAttendee[] {
    const filterValue = assignee.toLowerCase();
    return this.assigneeList.filter(assignee => (assignee.first_name.toLowerCase().indexOf(filterValue) === 0)||
    (assignee.last_name.toLowerCase().indexOf(filterValue) === 0)||
    ((assignee.first_name+' '+assignee.last_name).toLowerCase().indexOf(filterValue) === 0));
  }

  /*================Searching filters section - Begin =============*/
  setUserAttendeeFilter(){
    this.attendeeUserSearch.valueChanges
      .pipe(takeUntil(this._onDestroy), startWith(''))
      .subscribe(() => {
        this.filterUserAttendee();
    });
  }

  setReqAttendeeFilter(){
    this.attendeeReqSearch.valueChanges
      .pipe(takeUntil(this._onDestroy), startWith(''))
      .subscribe(() => {
        this.filterReqAttendee();
    });
  }

  setSelectedUserAttendeeFilter(){
    this.selectedUserAttendeeSearch.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSelectedUserAttendee();
    });
  }

  setSelectedReqAttendeeFilter(){
    this.selectedReqAttendeeSearch.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSelectedReqAttendee();
    });
  }

  private filterUserAttendee(){
    if (!this.userAttendeeList) {
      return;
    }
    // get the search keyword
    let search = this.attendeeUserSearch.value;
    if (!search) {
      this.filteredUserAttendees.next(this.userAttendeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the user attendees
    this.filteredUserAttendees.next(
      this.userAttendeeList.filter(attendee => ((attendee.first_name.toLowerCase().indexOf(search) > -1) || 
      (attendee.last_name.toLowerCase().indexOf(search) > -1)))
    );
  }

  private filterReqAttendee(){
    if (!this.requesterAttendeeList) {
      return;
    }
    // get the search keyword
    let search = this.attendeeReqSearch.value;
    if (!search) {
      this.filteredReqAttendees.next(this.requesterAttendeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the req attendees
    this.filteredReqAttendees.next(
      this.requesterAttendeeList.filter(attendee => attendee.name_in_full.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterSelectedUserAttendee(){
    if (!this.selectedUserAttendeeList) {
      return;
    }
    // get the search keyword
    let search = this.selectedUserAttendeeSearch.value;
    if (!search) {
      this.filteredUserSelAttendees.next(this.selectedUserAttendeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the selected user attendees
    this.filteredUserSelAttendees.next(
      this.selectedUserAttendeeList.filter(attendee => attendee.first_name.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterSelectedReqAttendee(){
    if (!this.selectedReqAttendeeList) {
      return;
    }
    // get the search keyword
    let search = this.selectedReqAttendeeSearch.value;
    if (!search) {
      this.filteredReqSelAttendees.next(this.selectedReqAttendeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the selected req attendees
    this.filteredReqSelAttendees.next(
      this.selectedReqAttendeeList.filter(attendee => attendee.name_in_full.toLowerCase().indexOf(search) > -1)
    );
  }

  //Use to fiter the user attendee list
  private _userFilter(attendee: string): UserAttendee[] {
    const filterValue = attendee.toLowerCase();
    return this.userAttendeeList.filter(attendee => (attendee.first_name.toLowerCase().indexOf(filterValue) === 0)||
    (attendee.last_name.toLowerCase().indexOf(filterValue) === 0)||
    ((attendee.first_name+' '+attendee.last_name).toLowerCase().indexOf(filterValue) === 0));
  }

  //Use to fiter the requester attendee list
  private _reqFilter(attendee: string): ReqAttendee[] {
    const filterValue = attendee.toLowerCase();
    // return this.requesterAttendeeList.filter(attendee => (attendee.first_name.toLowerCase().indexOf(filterValue) === 0)||
    // (attendee.last_name.toLowerCase().indexOf(filterValue) === 0)||
    // ((attendee.first_name+' '+attendee.last_name).toLowerCase().indexOf(filterValue) === 0));
    return this.requesterAttendeeList.filter(attendee => (attendee.name_in_full.toLowerCase().indexOf(filterValue) === 0));
  }

  //Use to fiter the selected user participant list
  private _selectedUserFilter(attendee: string): UserAttendee[] {
    const filterValue = attendee.toLowerCase();
    return this.selectedUserAttendeeList.filter(attendee => (attendee.first_name.toLowerCase().indexOf(filterValue) === 0)||
    (attendee.last_name.toLowerCase().indexOf(filterValue) === 0)||
    ((attendee.first_name+' '+attendee.last_name).toLowerCase().indexOf(filterValue) === 0));
  }

  //Use to fiter the selected requester participant list
  private _selectedReqFilter(attendee: string): ReqAttendee[] {
    const filterValue = attendee.toLowerCase();
    // return this.requesterAttendeeList.filter(attendee => (attendee.first_name.toLowerCase().indexOf(filterValue) === 0)||
    // (attendee.last_name.toLowerCase().indexOf(filterValue) === 0)||
    // ((attendee.first_name+' '+attendee.last_name).toLowerCase().indexOf(filterValue) === 0));
    return this.selectedReqAttendeeList.filter(attendee => (attendee.name_in_full.toLowerCase().indexOf(filterValue) === 0));
  }
  /*================Searching filters section - End =============*/

  /*================Filter panel filters section - Begin =============*/
  setDesignationFilter(){
    this.designationFilterKeyword.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterDesignations();
    });
  }

  setSectionFilter(){
    this.sectionFilterKeyword.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterSections();
    });
  }

  setDivisionFilter(){
    this.divisionFilterKeyword.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterDivisions();
    });
  }

  userSelectAll(){
    if(!this.isUserAllSelected){
      this.isUserAllSelected = true;
      this.userParticipants.selectAll();
    }
    else{
      this.isUserAllSelected = false;
      this.userParticipants.deselectAll();
    }   
  }

  reqSelectAll(){
    if(!this.isReqAllSelected){
      this.isReqAllSelected = true;
      this.reqParticipants.selectAll();
    }
    else{
      this.isReqAllSelected = false;
      this.reqParticipants.deselectAll();
    }   
  }

  private filterDesignations() {
    if (!this.designationsList) {
      return;
    }
    // get the search keyword
    let search = this.designationFilterKeyword.value;
    if (!search) {
      this.filteredDesignationList.next(this.designationsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the designations
    this.filteredDesignationList.next(
      this.designationsList.filter(desig => desig.designation_name.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterSections() {
    if (!this.sectionsList) {
      return;
    }
    // get the search keyword
    let search = this.sectionFilterKeyword.value;
    if (!search) {
      this.filteredSectionList.next(this.sectionsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the sections
    this.filteredSectionList.next(
      this.sectionsList.filter(section => section.section_name.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterDivisions() {
    if (!this.divisionList) {
      return;
    }
    // get the search keyword
    let search = this.divisionFilterKeyword.value;
    if (!search) {
      this.filteredDivisionList.next(this.divisionList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the designations
    this.filteredDivisionList.next(
      this.divisionList.filter(division => division.division_name.toLowerCase().indexOf(search) > -1)
    );
  }
  /*================Filter panel filters section - End =============*/
}