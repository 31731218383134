import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { EditMembersDlgComponent } from './edit-members-dlg/edit-members-dlg.component';
import { GroupInfoService } from './group-info-panel.service';

export interface User {
  user_id: string;
  user_name: string;
  first_name: string;
  last_name: string;
  profile_url: string;
}

export interface Requester {
  nic: string;
  name_in_full: string;
}

@Component({
  selector: 'app-group-info-panel',
  templateUrl: './group-info-panel.component.html',
  styleUrls: ['./group-info-panel.component.css']
})
export class GroupInfoPanelComponent implements OnInit {

  groupId: string;
  groupCategory: string;
  source: string;
  editBtnName: string = "Edit";
  isEditable: boolean = false;

  userList: User[] = [];
  filteredUserList: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  userSearch = new FormControl();

  requesterList: Requester[] = [];
  filteredReqList: ReplaySubject<Requester[]> = new ReplaySubject<Requester[]>(1);
  reqSearch = new FormControl();

  private _onDestroy = new Subject<void>();

  group_details = this.createGroupDataFormBuilder();
  temp_group_details = this.createGroupDataFormBuilder(); // For backup data when edit

  constructor(
    public dialogRef: MatDialogRef<GroupInfoPanelComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog            : MatDialog,
    private groupInfoService  : GroupInfoService,
    private _formBuilder      : FormBuilder,
    private toastr            : ToastrService) 
  { 
    this.source = data.source;
    this.groupCategory = data.category;
  
    if(this.source == "view&edit"){
      this.groupId = data.group_id;
      this.loadEventDetails();    
      this.loadMembers();  
    }
    else if(this.source == "addNew"){
      this.onEditCancel();
    }
  }

  ngOnInit(): void {
  }

  createGroupDataFormBuilder(){
    return this._formBuilder.group({
      group_id : ['', Validators.required],
      group_name : ['', Validators.required],
      group_desc: [''],
      group_category: ['', Validators.required],
      active: ['']
    });
  }

  loadEventDetails(){
    this.groupInfoService.getGroupDetails(this.groupId).subscribe(group_data => {
      if(group_data != null){
        this.group_details.patchValue({
          group_id       : group_data["group_id"],
          group_name     : group_data["group_name"],     
          group_desc     : group_data["description"],     
          group_category : group_data["category"],    
          active         : group_data["is_active"]
        });

        this.temp_group_details.patchValue(this.group_details.value); // Backup data    
      }
      else{
        this.toastr.error('Server Error', 'Error');
        console.log('Group data are not fetched...');
      }    
    });
  }

  loadMembers(){
    this.groupInfoService.getGroupMembers(this.groupId).subscribe(res => {
      if(res != null){
        if(this.groupCategory.toLocaleLowerCase() == "requester"){
          res.forEach(element => {
            if(!this.requesterList.some(ele => ele.nic == element.nic)){ //Restrict duplicate data
              this.requesterList.push(element);        
            }  
          });
          this.setReqMemberFilter();
        }
        else if(this.groupCategory.toLocaleLowerCase() == "user"){
          res.forEach(element => {
            if(!this.userList.some(ele => ele.user_id == element.user_id)){ //Restrict duplicate data
              this.userList.push(element);     
            }     
          });
          this.setUserMemberFilter();     
        }
      }   
    });
   }

  onEditCancel(){
    if(!this.isEditable){
      this.isEditable = true;
      this.editBtnName = "Cancel";
    }
    else{
      this.isEditable = false;
      this.editBtnName = "Edit";  
      if(this.source == "view&edit"){
        this.group_details.patchValue(this.temp_group_details.value); // Backup data
      }
      else{
        this.temp_group_details.patchValue(this.group_details.value); // Backup data
      }
    }
  }

  setState(e){
    if(e.checked){
      this.group_details.patchValue({active : '1'});
    }
    else{
      this.group_details.patchValue({active : '0'});
    }   
  }

  onCloseClick(){
    this.dialogRef.close();
  }

  clear(){
    this.group_details.reset();
  }

  saveDetails(){
    if(this.source == "view&edit"){ 
      this.groupInfoService.updateGroupDetails(this.group_details.value)
      .subscribe(res => {
        if(res['status'] == 'success'){
          this.onEditCancel();
          this.toastr.success('Details updated', 'Success');
          this.dialogRef.beforeClosed().subscribe(() => this.dialogRef.close({needRefresh : true})); //Set indication to parent component to refresh when close this dialog
        }
        else{
          this.toastr.error('Update failed', 'Failed'); 
        }
      });
    }
    else if(this.source == "addNew"){
      this.groupInfoService.createNewGroup(this.group_details.value)
      .subscribe(res => {
        if(res['status'] == 'success'){
          this.groupId = this.group_details.controls.group_id.value;
          this.groupCategory = this.group_details.controls.group_category.value;
          this.onEditCancel();
          this.source = "view&edit";                 
          this.toastr.success('New group saved', 'Success');
          this.dialogRef.beforeClosed().subscribe(() => this.dialogRef.close({needRefresh : true})); //Set indication to parent component to refresh when close this dialog
        }
        else if (res['status'] == 'duplicate'){
          this.toastr.error('Group ID is already exist', 'Failed');
        }
        else{
          this.toastr.error('Record saving failed', 'Failed'); 
        }
      });
    }
  }

  addOrEditMembers(){
    const dialogconfig = new MatDialogConfig();
    //dialogconfig.disableClose = true;
    dialogconfig.autoFocus= false;
    dialogconfig.width = "85%";
    dialogconfig.minWidth = "380px"; /*Set minimum width - Important for mobile windows*/
    dialogconfig.data = {
      user_members : this.userList,
      req_members  : this.requesterList,
      group_id : this.groupId,
      group_category: this.group_details.controls.group_category.value.toLowerCase()
    }

    var dialog_ref = this.dialog.open(EditMembersDlgComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      this.loadMembers();
    });
  }

  setUserMemberFilter(){
    this.userSearch.valueChanges
      .pipe(takeUntil(this._onDestroy), startWith(''))
      .subscribe(() => {
        this.filterUserMember();
    });
  }

  setReqMemberFilter(){
    this.reqSearch.valueChanges
      .pipe(takeUntil(this._onDestroy), startWith(''))
      .subscribe(() => {
        this.filterReqMember();
    });
  }

  private filterUserMember(){
    if (!this.userList) {
      return;
    }
    let search = this.userSearch.value;
    if (!search) {
      this.filteredUserList.next(this.userList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUserList.next(
      this.userList.filter(attendee => ((attendee.first_name.toLowerCase().indexOf(search) > -1) || 
      (attendee.last_name.toLowerCase().indexOf(search) > -1)))
    );
  }

  private filterReqMember(){
    if (!this.userList) {
      return;
    }
    let search = this.reqSearch.value;
    if (!search) {
      this.filteredReqList.next(this.requesterList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredReqList.next(
      this.requesterList.filter(attendee => ((attendee.nic.toLowerCase().indexOf(search) > -1) || 
      (attendee.name_in_full.toLowerCase().indexOf(search) > -1)))
    );
  }

}
