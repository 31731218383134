import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormGroupDirective } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MoreInOutAnimation } from 'src/app/basic-data/animations';
import { PermissionService } from 'src/app/services/permissions.service';
import { ManageFiledsService } from './manage-fileds.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SlideInOutAnimation } from 'src/app/basic-data/animations';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { error } from 'console';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export interface iFieldDetails{
  fld_id : string;
  fld_lable : string;
  fld_description: string;
  fld_type:string;
  fld_required : any;
  fld_placeholder : string;
  fld_info_message : string;
  fld_error_message : string;
  fld_additional_info: string; // counter max & radio options
  fld_state : boolean;
}
@Component({
  selector: 'app-manage-fileds',
  templateUrl: './manage-fileds.component.html',
  styleUrls: ['./manage-fileds.component.css'],
  animations: [SlideInOutAnimation]
})
export class ManageFiledsComponent implements OnInit {
  filedColumns: string[] = ['select', 'fld_lable', 'fld_description','fld_type', 'fld_required','fld_placeholder', 'fld_info_message', 'fld_error_message','action'];
  source: string;
  animationState = 'out';
  showAddNew : boolean = false;
  fieldType = new FormControl();
  FiledDetails : MatTableDataSource<iFieldDetails>;
  selection = new SelectionModel<string>(true, []);
  editRowId = null;
  inEditMode : boolean = false;
  beforeEditRow : any;
  fieldList: any;
  TicketType : any;

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<ManageFiledsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _formBuilder          : FormBuilder,
    private toastr                : ToastrService,
    private manageFiledsService : ManageFiledsService,
    private datePipe              : DatePipe,
    public permission             : PermissionService,
    private confDialog       : MatDialog) 
    { 
      this.source = data.source;
      this.TicketType = data.Ticket_type;
    }

    filed_details = this._formBuilder.group({
      field_lable: ['', [Validators.required, , Validators.maxLength(50)]],
      field_description: ['', [Validators.required,Validators.maxLength(200)]],
      field_type: [''],
      field_counter_max: [0],
      field_radio_options:['option 1, option 2 ...'],
      field_required: [false],
      filed_placeholder: ['', [Validators.required]],
      filed_info: [''],
      filed_error: ['']
    });

  ngOnInit(): void {
    console.log(this.TicketType);
    this.getFieldDetails();
    // this.filed_details.valueChanges.subscribe((newValues)=>{
    //   console.log("Form Value Changed : ", newValues);
    //   this.OnFomValueChanged();

    // });
  }
  OnFomValueChanged(){
    const invalidFields = [];
    Object.keys(this.filed_details.controls).forEach(fieldName => {
      const fieldControl = this.filed_details.get(fieldName);
      if (fieldControl && fieldControl.invalid) {
        invalidFields.push(fieldName);
      }
    });
    console.log('Invalid fields:', invalidFields);
  }

  getFieldDetails(){
    this.manageFiledsService.getFieldList(this.TicketType).subscribe(res => {
      if(res != null){
        this.FiledDetails = new MatTableDataSource(res as iFieldDetails[]);
        this.FiledDetails.sort = this.sort;
        this.FiledDetails.paginator = this.paginator;
      }
      else{
        this.toastr.error('Server Error', 'Error');
      }
    }, err => {
      this.toastr.error("Sever error", 'Error!');         
  });
  }

  addNewRecord(){
    this.showAddNew = true;
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }
  editRow(row: any){
    this.beforeEditRow = JSON.parse(JSON.stringify(row));
    this.editRowId = row.fld_id;
    this.inEditMode = true;
  }
  updateEdit(rowData: iFieldDetails){
    rowData.fld_required = String(rowData.fld_required);
    this.manageFiledsService.updateField(rowData)
    .subscribe((data: any) => {
      if(data['status'] == "success"){
        this.editRowId = null;
        this.beforeEditRow = null;
        this.inEditMode = false;
        this.toastr.success("Record successfully updated", 'Success!');
      }else{
        //this.cancelEdit(rowData);
        this.toastr.error("Update failed", 'Error!');
      }
    }, err => {
        this.toastr.error("Sever error", 'Error!');         
    });
  }
  cancelEdit(index: any){
    if(this.beforeEditRow != null){
      this.FiledDetails.data[index] = this.beforeEditRow;
      this.FiledDetails.data = this.FiledDetails.data.slice(0);
    }
    this.editRowId = null;
    this.inEditMode = false;
  }
  deleteRow(rowData: any, index: any){
    const title = 'Delete Confirmation';
    const message = 'Are you sure you want to delete this record?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        this.manageFiledsService.deleteField(rowData)
      .subscribe((data: any) => {
        if(data['status'] == "success"){
          this.FiledDetails.data.splice(index, 1); // Remove from datasource
          this.FiledDetails.data = this.FiledDetails.data.slice();
          this.toastr.success('Record is deleted successfully', 'Success!');
        }else{
          //this.cancelEdit(rowData);
          this.toastr.error("Delete failed", 'Error!');
        }
      }, err => {
          this.toastr.error("Sever error", 'Error!');         
      });
      }
    });   
  }
  deleteRows(){
    const title = 'Delete Confirmation';
    const message = 'Are you sure you want to delete these records?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        this.manageFiledsService.deleteFields(this.selection.selected)
        .subscribe((response: any) => {
          response.forEach(data => {
            if(data['status'] == "success"){
              let index: number = this.FiledDetails.data.findIndex(d => d.fld_id == data['record_id']);
              this.FiledDetails.data.splice(index,1) // Remove from records datasource
              this.FiledDetails.data = this.FiledDetails.data.slice();
              this.toastr.success('Records are deleted successfully', 'Success!');
            }
            else if(data['status'] == "dependency"){
              this.toastr.warning("Designation "+data['record_id']+" has dependency with other records", 'Failed!');
            }
            else{
              this.toastr.error("Delete failed", 'Error!');
            }
          });
            this.selection = new SelectionModel<string>(true, []);
        }, err => {
            this.toastr.error("Sever error", 'Error!');         
        });
      }
    });
  }
  cancelAddNew(){
    this.animationState = 'out';
  }
  saveNewRecord(){
    this.filed_details.patchValue({
      field_type: this.fieldType.value
    });


    const formData = this.filed_details.value;
    formData['fld_ticket_type'] = this.TicketType;

    if(formData['field_required']){
      formData['field_required'] = 'true';
    }else{
      formData['field_required'] = 'false';
    }
    
    this.manageFiledsService.saveNewField(formData)
    .subscribe((data: any) => {
      if(data['status'] == "success"){ 
        this.filed_details.patchValue({'cat_id': data['record_id']});   
        this.FiledDetails.data.push(this.filed_details.value);
        this.FiledDetails.data = this.FiledDetails.data.slice();
        this.toastr.success('Record save succesfully', 'Success!');
        this.clearForm();
      }else{
        this.toastr.error("Update failed", 'Error!');
      }
    }, err => {
        this.toastr.error("Sever error", 'Error!');         
    });
  }

  manageFileds(){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= false;
    dialogconfig.width = "80%";
    dialogconfig.minWidth = "380px"; /*Set minimum width - Important for mobile widows*/
    dialogconfig.data = {
      source : "addField"
    }
    var dialog_ref = this.confDialog.open(ManageFiledsComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      this.getFieldDetails();
    });
  }

  clearForm(){
    this.formGroupDirective.resetForm();
    this.filed_details.reset();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.FiledDetails.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.FiledDetails.data.forEach(row => this.selection.select(row.fld_id));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.FiledDetails.filter = filterValue.trim().toLowerCase();
    if (this.FiledDetails.paginator) {
      this.FiledDetails.paginator.firstPage();
    }
  }

}
