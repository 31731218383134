import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const URL_create_agreement = environment.url_blogics + "/agreement/create_agreement.php";
const URL_get_new_arg_id = environment.url_blogics + "/agreement/generate_agr_id.php";
const URL_assignee_list = environment.url_blogics + '/progress/get_assignee_list.php';
const URL_get_category_list = environment.url_sfeatures + '/basic-data/agreement-category/get_agr_category_list.php';
//const URL_get_division_list = environment.url_sfeatures+'/basic-data/get_division_list.php';

@Injectable({
  providedIn: 'root'
})
export class NewAgreementService {

  constructor(private httpclient: HttpClient) { }

  getNextAgreementID() {
    return (this.httpclient.post(URL_get_new_arg_id, JSON.stringify({})));
  }

  createAgreement(agreement: FormData, attachments){
    return (this.httpclient.post(URL_create_agreement,JSON.stringify({agreement, attachments})));
  }

  getAssigneeList() : Observable<any> {
    return (this.httpclient.get(URL_assignee_list));
  }

  getCategoryList(){
    return (this.httpclient.get(URL_get_category_list));
  }

  // getDivisionList(): Observable<any> {
  //   return this.httpclient.get(URL_get_division_list);
  // }
}
