import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileUploadModule } from 'ng2-file-upload';
import { MaterialModule } from './material.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms'
import { MatSlideToggleModule  } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthInterceptor } from './auth.interceptor';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ToastrModule } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTreeModule } from '@angular/material/tree';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserPanelComponent } from './users/user-panel/user-panel.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule  } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgxPrintModule } from 'ngx-print';

import { DatePipe } from "@angular/common";
import { LoaderService } from "./services/loader.service";
import { LoaderInterceptor } from "./services/loader.interceptor";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { LoaderComponent } from "./loader/loader.component";
import { SidenavbarComponent } from "./sidenavbar/sidenavbar.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NewRequestComponent } from "./request/new-request/new-request.component";
import { ProgressComponent } from "./request/progress/progress.component";
import { RequestStatusComponent } from "./request/request-status/request-status.component";
import { SettingsComponent } from "./settings/settings.component";
import { UsersComponent } from "./users/users.component";
import { CommunicationComponent } from "./collaboration/communication/communication.component";
import { BasicDataComponent } from "./basic-data/basic-data.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { DesignationsComponent } from "./basic-data/designations/designations.component";
import { TitlesComponent } from "./basic-data/titles/titles.component";
// import { DivisionsComponent } from "./basic-data/divisions/divisions.component";
import { SectionsComponent } from "./basic-data/sections/sections.component";
import { RequestCategoriesComponent } from "./basic-data/request-categories/request-categories.component";
import { NewAgreementComponent } from "./agreement/new-agreement/new-agreement.component";
import { AgreementProgressComponent } from "./agreement/agreement-progress/agreement-progress.component";
import { RequesterComponent } from "./requester/requester.component";
import { RequesterPanelComponent } from "./requester/requester-panel/requester-panel.component";
import { UserImageUploadPanelComponent } from "./settings/user-image-upload-panel/user-image-upload-panel.component";
import { AgreementStatusComponent } from "./agreement/agreement-status/agreement-status.component";
import { AgreementCategoryComponent } from "./basic-data/agreement-category/agreement-category.component";
import { NewEventComponent } from "./event/new-event/new-event.component";
import { EventCalenderComponent } from "./event/event-calender/event-calender.component";
import { DeleteConfirmationDlgComponent } from "./event/event-calender/delete-confirmation-dlg/delete-confirmation-dlg.component";
import { EventViewComponent } from "./event/event-view/event-view.component";
import { ParticipantAddingComponent } from "./event/participant-adding/participant-adding.component";
import { EditParticipantDlgComponent } from "./event/event-view/edit-participant-dlg/edit-participant-dlg.component";
import { EventListComponent } from "./event/event-list/event-list.component";
import { GroupListComponent } from "./collaboration/group-list/group-list.component";
import { GroupInfoPanelComponent } from "./collaboration/group-info-panel/group-info-panel.component";
import { EditMembersDlgComponent } from "./collaboration/group-info-panel/edit-members-dlg/edit-members-dlg.component";
import { ExportConfirmationDlgComponent } from './request/progress/export-confirmation-dlg/export-confirmation-dlg.component';
import { LetterWritingComponent } from './request/letter-writing/letter-writing.component';
import { AddItemDlgComponent } from './basic-data/labels/add-item-dlg/add-item-dlg.component';
import { LabelsComponent } from './basic-data/labels/labels.component';
import { MatFormFieldModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { environment } from 'src/environments/environment';
import { ManageFiledsComponent } from './basic-data/request-categories/manage-fileds/manage-fileds.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NewRequestComponent,
    ProgressComponent,
    SettingsComponent,
    RequestStatusComponent,
    SidenavbarComponent,
    LoaderComponent,
    UsersComponent,
    UserPanelComponent,
    DashboardComponent,
    CommunicationComponent,
    ConfirmDialogComponent,
    BasicDataComponent,
    DesignationsComponent,
    TitlesComponent,
    SectionsComponent,
    RequestCategoriesComponent,
    NewAgreementComponent,
    AgreementProgressComponent,
    RequesterComponent,
    RequesterPanelComponent,
    UserImageUploadPanelComponent,
    AgreementStatusComponent,
    AgreementCategoryComponent,
    NewEventComponent,
    EventCalenderComponent,
    DeleteConfirmationDlgComponent,
    EventViewComponent,
    ParticipantAddingComponent,
    EditParticipantDlgComponent,
    EventListComponent,
    GroupListComponent,
    GroupInfoPanelComponent,
    EditMembersDlgComponent,
    ExportConfirmationDlgComponent,
    LetterWritingComponent,
    AddItemDlgComponent,
    LabelsComponent,
    ManageFiledsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    FileUploadModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatDialogModule,
    NgxDropzoneModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    MatExpansionModule,
    MatSlideToggleModule,
    FontAwesomeModule,
    MatCheckboxModule,
    RichTextEditorAllModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTreeModule,
    ImageCropperModule,
    MatAutocompleteModule,
    MatRadioModule,
    NgxMatSelectSearchModule,
    MatButtonToggleModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    MatSelectFilterModule,
    MatTableExporterModule,
    NgxPrintModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  exports: [MatDialogModule],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [RequestStatusComponent, UserPanelComponent],
})
export class AppModule {}
