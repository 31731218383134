// // development server urls
// export const environment = {
//   production: true,
//   url_blogics: "http://13.64.91.36:80/dev/public-request-manager/01_business-logics",
//   url_sfeatures: "http://13.64.91.36:80/dev/public-request-manager/02_shared-features",
//   // WS_Url: 'ws://13.64.91.36:4207/ws'
//   WS_Url: "ws://178.128.54.43:8191/ws",
// };

// // uat server urls
// // export const environment = {
// //   production: true,
// //   url_blogics  : 'http://13.64.91.36:80/uat/public-request-manager/01_business-logics',
// //   url_api      : 'http://13.64.91.36:80/uat/public-request-manager/api', // NEW APIs UNDER THIS...
// //   url_sfeatures: 'http://13.64.91.36:80/uat/public-request-manager/02_shared-features'
// // };

// WorkitTest server urls
// export const environment = {
//   production: true,
//   url_blogics  : 'http://178.128.54.43:8080/prm/01_business-logics',
//   url_sfeatures: 'http://178.128.54.43:8080/prm/02_shared-features',
//   WS_Url: 'ws://178.128.54.43:8192/ws'
// };

// Cud_Roshan server urls
// export const environment = {
//   production: true,
//   url_blogics: "http://localhost/Back/01_business-logics/",
//   url_api: "http://localhost/Back/api", // NEW APIs UNDER THIS...
//   url_sfeatures: "http://localhost/Back/02_shared-features",
//   WS_Url: "ws://24.144.80.120:5000/ws/",
//   recaptcha: {
//   siteKey: '6Lck2lkpAAAAACidbhuOM-FDOXoXY4_rqpOaNIJ-',
//   }
// };
// export const environment = {
//   production: false,
//    url_blogics: 'http://localhost:8822/workitbe/01_business-logics',
//   // url_blogics: 'http://api.workit.techifuze.com:88/',
//   url_sfeatures: 'http://localhost:8822/workitbe/01_business-logics',
//   WS_Url: "ws://24.144.80.120:5000/ws/",
// };
export const environment = {
  production: true,
  url_blogics: 'https://api.workit.techifuze.com/01_business-logics',
  url_api: "https://api.workit.techifuze.com/01_business-logics", // NEW APIs UNDER THIS...
  url_sfeatures: 'https://api.workit.techifuze.com/01_business-logics',
  WS_Url: "ws://24.144.80.120:5000/ws",
  siteKey: '6Lck2lkpAAAAACidbhuOM-FDOXoXY4_rqpOaNIJ-',
};
