import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Md5 } from 'ts-md5/dist/md5';
import { Router } from '@angular/router';
import { SidenavService } from '../sidenavbar/sidenav.service';
import { PermissionService } from './permissions.service';
import { NotificationService } from './notification.service';

/**
 * takes environment varialble from environment.ts file
 * and use it throughout this class with URL constant
 */
const URL = environment.url_blogics;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // behaviorsubject instance returns last emitted value
  //authenticationState = new BehaviorSubject(false);
  private logInStatus = false;
  private userId = null;
  private showHeader = new BehaviorSubject(false);
  public userRole = new BehaviorSubject('');
  public defaultImage: string = '../../assets/images/user-default.png';
  public userDetails: BehaviorSubject<any> = new BehaviorSubject<any>({
    userId : null,
    username: null,
    imageUrl: this.defaultImage
  });

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private _router: Router,
    private sidenavService: SidenavService,
    private permissionService: PermissionService,
    private notificationService: NotificationService)
  {
      this.setLoginState(false);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 2000,
    });
  }

  setShowHeader(value : boolean){
    this.showHeader.next(value);
  }

  setLoginState(value : boolean){
    this.logInStatus = value;
  }

  setUserRole(value : string){
    this.userRole.next(value);
    this.permissionService.setPermissions(value);
  }

  setUserDetails(userId: string, username: string, imgPath: string){
    this.userId = userId;
    if(imgPath != null){
      this.userDetails.next(Object.assign({userId: userId, username: username, imageUrl: imgPath }));
    }
    else{
      this.userDetails.next(Object.assign({userId: userId, username: username, imageUrl: this.defaultImage}));
    }
  }

  setImageUrl(imageUrl : string){
    if(imageUrl == 'default'){
      this.setUserDetails(this.getUserDetails['userId'], this.getUserDetails['username'], this.defaultImage);
    }
    else{
      this.setUserDetails(this.getUserDetails['userId'], this.getUserDetails['username'], imageUrl);
    }
  }

  get isShowHeader() {
    return this.showHeader.asObservable();
  }

  get isLoggedIn() {
    //return this.authenticationState.asObservable();
    return this.logInStatus;
  }

  get getUserId() {
    return this.userId;
  }

  get getUserRole() {
    return this.userRole.getValue();
  }

  get getUserDetails(){
    return this.userDetails.getValue();
  }

  async loginMethod(data: FormData): Promise<boolean> {
    var return_state = false;
    const md5 = new Md5();
    var authString = btoa(data['username'] +':'+ md5.appendStr(data['password']).end())
    return new Promise((resolve,reject)=>{
      this.http.post(URL + '/login/login.php', JSON.stringify({"authstring": authString})).subscribe((data) => {
        if (data[`access_token`] != null) {
          localStorage.setItem('token', data[`access_token`]);
         // localStorage.setItem('user_id', data[`user_id`]);
          localStorage.setItem('username', data[`user_name`]);
          this.setUserDetails(data[`user_id`], data[`user_name`], data[`image_url`]);
          this.setUserRole(data[`user_role`]);
          this.setLoginState(true); // emit authenticationState value as true
          this.setShowHeader(true);
          //this.openSnackBar('Login Success !');
          this._router.navigate(['dashboard']);
         // this.sidenavService.open(); // Open sidenav
          this.notificationService.connect(data[`user_id`]); //Connect to WebSocket server to get notifications
          resolve(true);
        } else {
          reject(false);
          this.setLoginState(false);
          this.openSnackBar(data[`message`] + '!')
        }
      })


    });
  }

  checkAuthentication(){
    return this.http.get(URL+'/login/validate_token.php');
  }

  logoutMethod() {
    localStorage.clear();
    this.setLoginState(false);
    this._router.navigate(['login']);
  }
}
