import { Component, ComponentFactoryResolver, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
import html2canvas from 'html2canvas';
import { LetterWritingService } from './letter-writing.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { createElement, addClass, removeClass, Browser } from '@syncfusion/ej2-base';
import { DatePipe } from '@angular/common';
//import * as CodeMirror from 'codemirror';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-letter-writing',
  templateUrl: './letter-writing.component.html',
  styleUrls: ['./letter-writing.component.css']
})
export class LetterWritingComponent implements OnInit {
  
  @ViewChild('letterContainer')
  private letterContainer: RichTextEditorComponent;

  // public letterContent: string = '<table style="border: none; width: 98.2879%; min-width: 0px; height: 22px;"><tbody><tr><td class="" style="border: none; width: 25%;"><br></td><td style="border: none !important; width: 25%;">{{this.requestId}}00</td><td style="width: 25%;"><br></td><td style="width: 25%;" class="">00</td></tr></tbody></table><br>';
  public letterContent: string;
  public letterId: string;
  public letterState: string;
  public requestId: string;
  public sectionId: string;
  public categoryId: string;
  public yourNo = new FormControl();
  public date = this.datepipe.transform(new Date(), 'yyyy.MM.dd');

  constructor(
    private letterWritingService : LetterWritingService,
    private toastr               : ToastrService,
    public datepipe              : DatePipe
  ){
    this.letterId = localStorage.getItem('letter_id');
    this.requestId = localStorage.getItem('req_id');
    this.letterState = localStorage.getItem('letter_state');
    this.sectionId = localStorage.getItem('section_id');
    this.categoryId = localStorage.getItem('category_id');
    this.getLetterInfo();
  }


  ngOnInit(){ }

  public textArea: HTMLElement;
    public myCodeMirror: any;

  ngAfterViewInit(): void {
    const rteObj: RichTextEditorComponent = this.letterContainer;
    setTimeout(() => { this.textArea = rteObj.contentModule.getEditPanel() as HTMLElement; }, 600);
}

   //letter writing box Configurations.....
  public tools: object = {
    items: [
          'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
          'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
          'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
          'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
          'Indent', 'Outdent', '|', 'CreateTable',
          'Image', '|', 'ClearFormat', 'SourceCode', '|', 'FullScreen']
  };

  public placeholder: String = '<p>Type your letter content here... </p>';

  public fontSizes = {
    width: '40px',
    items: [{ text: '6pt', value: '8pt' },
    { text: '7pt', value: '8pt' },
    { text: '8pt', value: '8pt' },
    { text: '9pt', value: '9pt' },
    { text: '10pt', value: '10pt' },
    { text: '11pt', value: '11pt' },
    { text: '12pt', value: '12pt' },
    { text: '13pt', value: '13pt' },
    { text: '14pt', value: '14pt' },
    { text: '20pt', value: '20pt' },
    { text: '42pt', value: '42pt' }]
  };

  public pasteCleanupSettings: object = {
    prompt: false,
    plainText: false,
    keepFormat: false,
    deniedTags: ['a'],
    deniedAttrs: ['class', 'title', 'id'],
    allowedStyleProps: ['color', 'margin', 'font-size']
    };



  public actionCompleteHandler(e: any): void {
    if (e.targetItem && (e.targetItem === 'SourceCode')) {
       // console.log('***************DP********************');
        this.letterWritingService.setComment(this.requestId, this.letterId).subscribe();
    } else {
     // console.log('######################DP#########################');
    }
  }

  getLetterInfo(){
    if(this.letterState === 'exist'){
      this.letterWritingService.getLetterInfo(this.letterId).subscribe(res => {
        console.log(res);
        if(res != null){
          console.log(res["content"]);
          this.letterContent = res["content"];
        }
        else{
          this.toastr.success('Server Error', 'Error');
        }
      });
    }
  }


  makeDraftPdf(){
    var data;
    var dataFooter;
    var docDefinition;

    html2canvas(document.querySelector("#letter-head"), {
      scale:3
      }).then(canvas => {

        html2canvas(document.querySelector("#letter-footer"), {
          scale:3,
          height: 100
        }).then(canvas2 => { 
          {
            data = canvas.toDataURL();
            dataFooter = canvas2.toDataURL();
            docDefinition = {
              pageSize: 'LETTER',
              watermark: { text: 'Draft Letter', fontSize: 70, color: 'Grey', opacity: 0.2, bold: true, italics: false },
              content: [{
                  image: data,
                  width: 550,
                  margin: [-8, -15]
              },
              {
              columns:[
                {
                  image: dataFooter,
                  width: 510,
                  margin: [-5, 8, 0, 0]
                }
              ]
            }]
            };
            pdfMake.createPdf(docDefinition).open();
          }
        });
      }
    );
  }

  generatePdf(){
    var data;
    var dataFooter;
    var docDefinition;

    this.letterWritingService.setComment(this.requestId, this.letterId).subscribe(res => {
      if(res['status']=="success"){
        html2canvas(document.querySelector("#letter-head"), {
          scale:3
        }).then(canvas => {
          html2canvas(document.querySelector("#letter-footer"), {
            scale:3,
            height: 100
          }).then(canvas2 => { 
            {
              data = canvas.toDataURL();
              dataFooter = canvas2.toDataURL();
              docDefinition = {
                pageSize: 'LETTER',
                content: [{
                    image: data,
                    width: 550,
                    margin: [-8, -15]
                },
                {
                columns:[
                  {
                    image: dataFooter,
                    width: 510,
                    margin: [-5, 8, 0, 0]
                  },
                  {
                    qr: this.letterId, foreground: '#26266b', style: 'qr_pos', fit : 50
                  }
                ]
              }],
                styles: {
                  qr_pos: {
                    margin: [0, 10, 30, 0],
                    alignment: 'right',
                    italics: true,
                    width: 50,
                    height:50
                  },
                }
              };
              pdfMake.createPdf(docDefinition).download(this.letterId);
            }
          });
        });
      }
    });

  }

  SaveLetter(){
    this.letterWritingService.saveLetter(this.requestId, this.letterId, this.letterContent).subscribe(res => {
      if(res['status']=="success"){
        this.toastr.success('Letter Saved', 'Success');
      }
    });
  }


  Print(){
    //this.letterContent = this.letterContainer.getHtml();
    this.letterWritingService.setComment(this.requestId, this.letterId).subscribe();
  }
}
