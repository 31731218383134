import { trigger, transition, style, animate, state, group } from '@angular/animations';

export const SlideInOutAnimation = [trigger('slideInOut', [
    state('in', style({
        'max-height': '500px', 'opacity': '1', 'padding': '16px 16px 0px'
    })),
    state('out', style({
        'max-height': '0px', 'opacity': '0', 'padding': '0px 16px', 'margin': '0px 0px'
    })),
    transition('in => out', [group([
        animate('350ms ease-in-out', style({
            'opacity': '0'
        })),
        animate('400ms ease-in-out', style({
            'max-height': '0px'
        })),
        // animate('600ms ease-in-out', style({
        //     'visibility': 'hidden'
        // })),
         animate('550ms ease-in-out', style({
           'padding': '0px 16px', 'margin': '0px 0px'
         }))
    ]
    )]),
    transition('out => in', [group([
        // animate('1ms ease-in-out', style({
        //     'visibility': 'visible'
        // })),
        animate('600ms ease-in-out', style({
            'max-height': '500px'
        })),
        animate('800ms ease-in-out', style({
            'opacity': '1'
        })),
        animate('200ms ease-in-out', style({
            'padding': '16px 16px 0px', 'margin': '15px 0px'
        }))
    ]
    )])
])]

export const MoreInOutAnimation = [trigger('moreSlideInOut', [
    state('in', style({
        'max-height': '500px', 'opacity': '1', 'padding-top': '16px'
    })),
    state('out', style({
        'max-height': '0px', 'opacity': '0', 'padding': '0px', 'margin': '0px 0px'
    })),
    transition('in => out', [group([
        animate('350ms ease-in-out', style({
            'opacity': '0'
        })),
        animate('400ms ease-in-out', style({
            'max-height': '0px'
        })),
        // animate('600ms ease-in-out', style({
        //     'visibility': 'hidden'
        // })),
         animate('550ms ease-in-out', style({
           'padding': '0px', 'margin': '0px 0px'
         }))
    ]
    )]),
    transition('out => in', [group([
        // animate('1ms ease-in-out', style({
        //     'visibility': 'visible'
        // })),
        animate('600ms ease-in-out', style({
            'max-height': '500px'
        })),
        animate('800ms ease-in-out', style({
            'opacity': '1'
        })),
        animate('200ms ease-in-out', style({
            'padding-top': '16px'
        }))
    ]
    )])
])]