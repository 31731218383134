<div class="main-mat">
    <div class="row head-row">
        <mat-card-title>Change your image</mat-card-title>
    </div>
    <mat-divider></mat-divider>
    <div class="container row"> 
        <div style="width: 100%">
            <div *ngIf="!isImageLoaded" class="row" style="margin: 20px;">
                <div class="text-center col-12">
                    <input type="file" (change)="fileChangeEvent($event)" />
                </div>
            </div>
            <div *ngIf="isImageLoaded" class="row" style="margin-top: 10px;">
                <div class="col-3">
                    <img class="preview-img" [src]="croppedImage" />
                </div>
                <div class="col-9" >
                    <span style="vertical-align: middle; display: inline-block;">
                        Select which part of the uploaded image you wish to use as the avatar.
                    </span>                           
                </div>
            </div>
            <mat-divider style="margin-right: -30px;"></mat-divider>
            <div class="row" style="margin-top: 5%;">
                <div class="col-12">
                    <image-cropper 
                    [imageChangedEvent]="imageChangedEvent" 
                    [maintainAspectRatio]="true" 
                    [aspectRatio]="4 / 4"
                    [resizeToWidth]="256" 
                    format="png" 
                    (imageCropped)="imageCropped($event)" 
                    (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady()" 
                    (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>
            </div>
        </div>

    </div>
    <div mat-dialog-actions>
        <button mat-flat-button *ngIf="isPreImageExist && !isImageLoaded" color="primary" (click)="onRemoveImage()">Remove Image</button>
        <button mat-flat-button *ngIf="isImageLoaded" color="primary" (click)="saveImage()">Confirm</button>
        <button mat-stroked-button (click)="onCancel()">Cancel</button>
    </div>
</div>