import {Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit} from '@angular/core';
import {startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import { Subject, Subscription, timer, ObservableInput } from 'rxjs';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView} from 'angular-calendar';
import { EventCalenderService } from './event-calender.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDlgComponent } from './delete-confirmation-dlg/delete-confirmation-dlg.component';
import { EventViewComponent } from '../event-view/event-view.component';
import { PermissionService } from 'src/app/services/permissions.service';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-event-calender',
  templateUrl: './event-calender.component.html',
  styleUrls: ['./event-calender.component.css']
})
export class EventCalenderComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actionsEditDel: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-pencil fa-lg"> </i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.viewEvent(event.id, true);
      },
    },
    {
      label: '<i class="fa fa-trash fa-lg"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.deleteEvent(event);
        // this.events = this.events.filter((iEvent) => iEvent !== event);
        // this.handleEvent('Deleted', event);
      }
    },
  ];

  actionsEdit: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-pencil fa-lg"> </i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.viewEvent(event.id, true);
      },
    }
  ];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  constructor(
    private eventCalenderService : EventCalenderService,
    private toastr               : ToastrService,
    private dialog               : MatDialog,
    public  permission           : PermissionService) 
  {
    this.loadEventData();
  }

  ngOnInit(): void {}

  loadEventData() {
    this.eventCalenderService.getEventList().subscribe(res => {
      if(res != null){
        res.forEach(element => {   
          var color = colors.red; // To define the color of calender node according to occurence type & all day
          var allDay = false; // To define all day TRUE or FALSE;
          if(element.all_day == '1'){
            allDay = true;
          }

          if(element.occurrence == 'REPEAT'){
            color = colors.blue;
          }
          else{
            color = colors.red;
          }

          this.events = [
            ...this.events,
            {
              id: element.event_occur_id,
              title: element.event_name,
              start: new Date(element.start),
              end: new Date(element.end),
              actions: ((this.permission.PERM_EDIT_EVENT && this.permission.PERM_DELETE_EVENT)? this.actionsEditDel: (this.permission.PERM_EDIT_EVENT)? this.actionsEdit : undefined),
              color: color,
              allDay: allDay,
              draggable: true,
              resizable: {
                beforeStart: true,
                afterEnd: true,
              },
              meta: element.occurrence
            },
          ];
        });
      }
    })
  }

  editEventDateTime(){
    
  }

  deleteEvent(eventToDelete: CalendarEvent){  
    var title = 'Delete Confirmation';
    var message = '';
    if(eventToDelete.meta == 'repeat'){
      message = 'What do you want to delete?';
    }
    else{
      message = 'Are you sure you want to delete this record?';
    }
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "420px";
    dialogconfig.data = {
      title     : title,
      message   : message,
      eventType : eventToDelete.meta
    }
    var dialog_ref = this.dialog.open(DeleteConfirmationDlgComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res['confirm'] == true){
        this.eventCalenderService.deleteEvent(eventToDelete.id, res['option']).subscribe(res => {
            if(res != null){
              if(res['status']=='success'){
                this.toastr.success('Event deleted successfully', 'Success');
                this.events = this.events.filter((iEvent) => iEvent !== eventToDelete);
              }
              else{
                this.toastr.error('Event delete failed', 'Error');
              }
            }
          });     
      }
    });   
  }

  viewEvent(event_no : string | number, editMode: boolean){
    const dialogconfig = new MatDialogConfig();
    //dialogconfig.disableClose = true;
    dialogconfig.autoFocus= false;
    dialogconfig.width = "70%";
    dialogconfig.minWidth = "390px"; /*Set minimum width - Important for mobile widows*/
    dialogconfig.data = {
      event_no  : event_no,
      edit_mode : editMode
    }
    var dialog_ref = this.dialog.open(EventViewComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res != null && res['needRefresh'] == true){
        this.events = [];
        this.loadEventData();
      }
    });
  }


  /*=========================Methods related to Calender=======================*/

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length == 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });

    //Convert date into local time zone
    newStart = new Date(newStart + ' UTC');
    newEnd   = new Date(newEnd + ' UTC');
    this.eventCalenderService.changeEventTime(event.id, newStart, newEnd).subscribe(res => {
      if(res != null){
        if(res['status'] != 'success'){
          this.toastr.error('Something went wrong!', 'Update Failed');
        }
      }
    })
    
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.viewEvent(event.id, false);
  }

  // addEvent(): void {
  //   this.events = [
  //     ...this.events,
  //     {
  //       title: 'New event',
  //       start: startOfDay(new Date()),
  //       end: endOfDay(new Date()),
  //       color: colors.red,
  //       draggable: true,
  //       resizable: {
  //         beforeStart: true,
  //         afterEnd: true,
  //       },
  //     },
  //   ];
  // }

  // deleteEvent(eventToDelete: CalendarEvent) {
  //   this.events = this.events.filter((event) => event !== eventToDelete);
  // }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

}
