<div class="window-title">
    <h2>New Event</h2>
</div>
<div class="row main-div">
    <form [formGroup]="new_event_form">
        <div class="row">
            <div class="col">
                <h1 class="sub-heading">Event Details</h1>
            </div>
        </div>
        <div class="row" style="margin-top: 15px; margin-right: 0px;">
            <div class="col">
                <div class="row">
                    <mat-form-field>
                        <mat-label>Event Name</mat-label>
                        <input formControlName="event_name" type="text" matInput>
                    </mat-form-field>
                    <!-- <mat-label>Event Name</mat-label><br>
                    <mat-label>Musical show Mega Blaster</mat-label> -->
                </div>
            </div>
            <div class="row">
                <mat-form-field style="margin: 0 15px;">
                    <mat-label>Description</mat-label>
                    <textarea formControlName="event_desc"  type="text" matInput required></textarea>
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-md-4 col-lg-4">
                    <mat-form-field>
                        <mat-label>Assignee</mat-label>
                        <input type="text" matInput formControlName="assignee" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option (onSelectionChange)="assigneeId = assignee.user_id;" *ngFor="let assignee of filteredAssignees | async" [value]="assignee.first_name + ' ' + assignee.last_name">
                            <img class="select-option-img" aria-hidden [src]="assignee.profile_url || '../../assets/images/user-default.png'" height="25">
                            {{'   ' + assignee.first_name + ' ' + assignee.last_name}}
                          </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-lg-4">
                    <mat-form-field>
                        <mat-label>Location</mat-label>
                        <input formControlName="location" type="text" matInput>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-lg-3">
                    <mat-form-field>
                        <mat-label>Category</mat-label>
                        <input formControlName="event_category" type="text" matInput>
                    </mat-form-field>
                </div> 
                <div class="col-md-2 col-lg-1" style="padding-top: 12px">
                    <mat-checkbox class="example-margin" formControlName="all_day" (change)="OnAllDayCheckClick($event)" color="primary">All Day</mat-checkbox>
                    <!-- <mat-form-field>
                        <mat-label>Category</mat-label>
                        <input formControlName="event_category" type="text" matInput>
                    </mat-form-field> -->
                </div>              
            </div>
            <div class="row">
                <div class="col-md-3">   
                    <mat-form-field>
                        <mat-label>Start Date</mat-label>
                        <input matInput #startD [matDatepicker]="pickerStartDate" placeholder="01/01/2020" [max]="maxDate" (dateChange)="onStartDateSelected(startD.value)"
                            formControlName="event_start_date" required>
                        <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerStartDate></mat-datepicker>
                    </mat-form-field>
                    <!-- <mat-form-field>
                        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" formControlName="start_date"
                            [disabled]="false">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"                         
                           [touchUi]="false" color="primary" [enableMeridian]="true" 
                           [disableMinute]="false" [hideTime]="false">
                        </ngx-mat-datetime-picker>
                     </mat-form-field> -->
                </div>               
                <div class="col-md-3">
                    <mat-form-field>
                        <mat-label>End Date</mat-label>
                        <input matInput #endD [matDatepicker]="pickerEndDate" [min]="minDate" placeholder="01/01/2020" (dateChange)="onEndDateSelected(endD.value)"
                            formControlName="event_end_date" required>
                        <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEndDate></mat-datepicker>
                    </mat-form-field>
                </div>    
                <div class="col-md-3">
                    <mat-form-field>
                        <mat-label>Start Time</mat-label>
                        <input type="time" formControlName="event_start_time" matInput>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <mat-label>End Time</mat-label>
                        <input type="time" formControlName="event_end_time" min="07:00:00" matInput>
                    </mat-form-field>
                </div>            
            </div>
            <div class="row">
                <mat-card class="col-md card-outline">
                    <mat-card-content>
                      <h2 class="mat-heading">Event Type</h2>                  
                      <section class="mat-section">
                        <mat-radio-group class="radio-group" formControlName="event_type" color = "primary">
                            <mat-radio-button class="radio-button" value="close">Close event</mat-radio-button>
                            <mat-radio-button class="radio-button" value="open">Open event</mat-radio-button>
                        </mat-radio-group>
                      </section>
                    </mat-card-content>
                </mat-card>
                <mat-card class="col-md card-outline">
                    <mat-card-content>
                    <h2 class="mat-heading">Occurrence</h2>             
                    <section class="mat-section">
                        <mat-radio-group color="primary" class="radio-group" formControlName="occurrence">
                            <mat-radio-button class="radio-button" value="once" style="margin-bottom: -10px; margin-top: 15px">Once</mat-radio-button>
                            <mat-radio-button class="radio-button" value="repeat">Repeat after 
                                <!-- <input type="number" formControlName="repeat_interval" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==1) return false;" style="width: 35px;"> -->
                                <input type="number" formControlName="repeat_interval" onKeyPress="if(this.value.length==1) return false;" style="width: 35px;">
                                days till
                                <mat-form-field class="occur-end-date">
                                    <input matInput [matDatepicker]="pickerUntilDate" formControlName="occurrence_end_date" [disabled]="new_event_form.controls.occurrence.value != 'repeat'? 'true': 'false'">
                                    <mat-datepicker-toggle matSuffix [for]="pickerUntilDate"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerUntilDate></mat-datepicker>
                                </mat-form-field>
                                                     
                            </mat-radio-button>
                        </mat-radio-group>
                    </section>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="row">
                <mat-expansion-panel style="width: inherit;">
                    <mat-expansion-panel-header>
                        Participants Details
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>  
                        <app-participant-adding
                            [selectedUserAttendeeList]="selectedUserAttendeeList"
                            [selectedReqAttendeeList]="selectedReqAttendeeList">
                        </app-participant-adding>
                    </ng-template>
                </mat-expansion-panel>
            </div>            
            <div class="button-panel">
                <button mat-raised-button color="primary" type="button" (click)="clear()" class="mat-button-class"
                    style="float: right; margin-right:10px">Clear</button>
                <button mat-raised-button type="submit" (click)="onSubmit()" color="primary" class="mat-button-class"
                [disabled]="!new_event_form.valid" style="float: right; margin-right:10px">Submit</button>
            </div>     
        </div>
    </form>
</div>