<div class="row" style="margin-top: 15px; margin-right: 0px;">
    <div class="col-md-3 col-xs-6">
    <mat-form-field class="inline-form-field">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
    </div>
    <div class="col-md-9 col-xs-6">
        <button mat-raised-button (click)="addNewRecord()" type="button" style="margin-left:10px; margin-top:10px">
        <mat-icon aria-hidden="false">add</mat-icon>&emsp;
        </button>
        <button mat-raised-button (click)="deleteRows()" [disabled]="!selection.hasValue()" type="button" style="margin-left:10px; margin-top:10px">
        <mat-icon aria-hidden="false">delete</mat-icon>
        &emsp;
        </button>
    </div>
</div>
<mat-card [@slideInOut]="animationState" [hidden]="!showAddNew" class="add-new-card">
    <form [formGroup]="designation_details">
        <div class="row">
            <div class="col-md-3 col-xs-12">
            <mat-form-field class="inline-form-field">
                <mat-label>Designation ID</mat-label>
                <input matInput formControlName="designation_id" required>
            </mat-form-field>
            </div>
            <div class="col-md-3 col-xs-12">
            <mat-form-field class="inline-form-field">
                <mat-label>Designation Name</mat-label>
                <input matInput formControlName="designation_name" required>
            </mat-form-field>
            </div>
            <div class="col-md-3 col-xs-12">
            <mat-form-field class="inline-form-field">
                <mat-label>Description</mat-label>
                <input matInput formControlName="designation_description">
            </mat-form-field>
            </div>

            <div class="col-md-3 col-xs-12">
            <button mat-flat-button color="primary" (click)="cancelAddNew()" style="float: right; margin-top:10px; margin-left:10px">Cancel</button>
            <button mat-flat-button color="primary" [disabled]="!designation_details.valid" (click)="saveNewRecord()" style="float: right; width: 75px; margin-top:10px">Save</button>
            </div>
        </div>
    </form>
</mat-card>
<div class="table-wrapper">
    <table mat-table [dataSource]="designationDetails" matSort>
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef width="10%">
            <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row.designation_id) : null"
                            [checked]="selection.isSelected(row.designation_id)">
            </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="designation_id" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> ID </th>
            <td mat-cell *matCellDef="let row"> 
                <ng-container>
                    {{row.designation_id || 'N/A'}}
                </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="designation_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Designation Name </th>
            <td mat-cell *matCellDef="let row">        
                <ng-container *ngIf="row.designation_id!==editRowId">
                    {{row.designation_name || 'N/A'}}
                </ng-container>
                <ng-container *ngIf="row.designation_id===editRowId">
                    <mat-form-field class="inline-edit">
                        <input matInput name="designation name" [(ngModel)]="row.designation_name"  maxlength="50" required>
                    </mat-form-field>
                </ng-container>     
            </td>
        </ng-container>
        <ng-container matColumnDef="designation_description"  width="40%">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let row"> 
                <ng-container *ngIf="row.designation_id!==editRowId">
                    {{row.designation_description || 'N/A'}}
                </ng-container>
                <ng-container *ngIf="row.designation_id===editRowId">
                    <mat-form-field style="width: 100%; padding-right: 30px;">
                        <input matInput name="designation description" [(ngModel)]="row.designation_description"  maxlength="100">
                    </mat-form-field>
                </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="action" >
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row ; let i = index" style="justify-content: center; text-align: right;"> 
                <button mat-flat-button *ngIf="row.designation_id!==editRowId" (click)="editRow(row)" [disabled]="inEditMode">
                    <i class="fa fa-pencil fa-2x" aria-hidden="true"></i>
                </button> 
                <button mat-flat-button *ngIf="row.designation_id!==editRowId" (click)="deleteRow(row, i)" [disabled]="inEditMode">
                    <i class="fa fa-trash-o fa-2x" aria-hidden="true"></i>
                </button> 
                <ng-container *ngIf="row.designation_id===editRowId">
                    <button mat-icon-button color="primary" matTooltip="Update" (click)="updateEdit(row)">
                        <i class="fa fa-check fa-2x" aria-hidden="true"></i>
                    </button>
                    <button mat-icon-button color="warn" matTooltip="Cancel" (click)="cancelEdit(i)" style="margin-left: 25px; margin-right: 10px;">
                        <i class="fa fa-close fa-2x" aria-hidden="true"></i>
                    </button>
                </ng-container>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="designationColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: designationColumns;"></tr>
    </table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="25"></mat-paginator>