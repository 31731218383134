import { AuthService } from './auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { NotificationService } from './notification.service';

const SUPER_USER_ACCESS_MODULE = ["dashboard", "new_request", "progress", "request_status", "users", "requester", 
"communication", "basicData", "agreement", "arg_progress", "new_event", "event_calender", "event_list", "group_list", "settings"];
const ADMIN_MODULE = ["dashboard", "new_request", "progress", "request_status", "users", "requester",
 "basicData", "agreement", "arg_progress", "new_event", "event_calender", "event_list", "settings"];
const APP_FULL_FILLER_MODULE = ["dashboard", "new_request", "progress", "request_status",
"agreement", "arg_progress", "new_event", "event_calender", "event_list", "settings"];
const APP_USER_ACCESS_MODULE = ["dashboard", "new_request", "agreement", "event_calender", "settings"];

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {

  private accessModules: string[];

  constructor(private _auth: AuthService, private _router: Router, private notificationService: NotificationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    if(this.accessModules == null){
      if(this._auth.getUserRole == '1'){
        this.accessModules = SUPER_USER_ACCESS_MODULE;
      }
      else if (this._auth.getUserRole == '2'){
        this.accessModules = ADMIN_MODULE;
      }
      else if (this._auth.getUserRole == '3'){
        this.accessModules = APP_FULL_FILLER_MODULE;
      }
      else if (this._auth.getUserRole == '4'){
        this.accessModules = APP_USER_ACCESS_MODULE;
      } 
    }
    
    if(this._auth.isLoggedIn){
      if(this.accessModules.indexOf(next.url.toString()) > -1){
        return true;
      }
    }
    else{
      try{
        return this._auth.checkAuthentication().pipe(
          map(
          res => {
            if(res["authorization"] != null){
              if (res["authorization"]){ 
                console.log('Authorized');
                this._auth.setLoginState(true);
                this._auth.setUserRole(res["user_role"]); 
                this._auth.setUserDetails(res["user_id"], res["user_name"], res["image_url"]);
                this._auth.setShowHeader(true);                  
                this.notificationService.connect(res[`user_id`]); //Connect to WebSocket server to get notifications

                return true;                  
              }
              else{                 
                console.log('Unauthorized');
                this._router.navigate(['login']);
                return false;
              } 
            }
            else{
              console.log('Server authentication failed...');
              this._router.navigate(['login']);
              return false;
            }
          }
        ))
      }catch{
        console.log('Server connection failed');
        this._router.navigate(['login']);
        return false;
      } 
    }
  }
}