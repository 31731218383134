import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5/dist/md5';

const URL_get_user_defaults       = environment.url_blogics + '/users/get_user_through_id.php';
const URL_update_profile_settings = environment.url_blogics + '/settings/update_profile_details.php';
const URL_change_password         = environment.url_blogics + '/settings/change_password.php';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

constructor(private _http : HttpClient) { }

getUserDefaults(userId: string, username: string){
  return this._http.post(URL_get_user_defaults,JSON.stringify({"user_id": userId, "user_name":username}));
}

updateProfileSettings(formData : FormData){
  return this._http.post(URL_update_profile_settings, JSON.stringify(formData));
}

changePassword(userId : string, formData : FormData){
  const md51 = new Md5();
  const md52 = new Md5();
  var pwdString = btoa(md51.appendStr(formData['old_pwd']).end() +':'+ md52.appendStr(formData['new_pwd']).end())
  return this._http.post(URL_change_password, JSON.stringify({"user_id": userId, "pwd_string": pwdString}));
}

  // getUserRoles() {
  //   return this._http.post(URL + '/login/return_roles.php',JSON.stringify({"token": localStorage.getItem('token')}))
  // }

}
