<div class="window-title">
    <h2>New Agreement</h2>
</div>
<div class="row main-div">
    <form [formGroup]="new_agreement_form" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col">
                <h1 class="sub-heading">Agreement Details</h1>
            </div>
        </div>
        <!-- <mat-card-title class="sub-heading">Agreement Details</mat-card-title> -->

        <div class="row">
            <div class="col-md-5">
                <mat-form-field>
                    <mat-label>Agreement No</mat-label>
                    <input type="text" matInput placeholder="Agreement No" formControlName="agr_no" [readonly]="true">
                </mat-form-field>
            </div>
            <!-- <div class="col-md-5">
                <mat-form-field>
                    <mat-label>GS Division</mat-label>
                    <input type="text" matInput formControlName="division" [matAutocomplete]="autoDiv"/>
                    <mat-autocomplete #autoDiv="matAutocomplete">
                      <mat-option
                        (onSelectionChange)="divisionId = division.division_id"
                        *ngFor="let division of filteredDivisions | async"
                        [value]="division.division_id + ' - ' + division.division_name">
                        {{"   " + division.division_id + " - " + division.division_name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </div> -->
            <div class="col-md-2">
                <mat-form-field>
                    <mat-label>Priority</mat-label>
                    <span matPrefix style="padding-right:3px; padding-top: 3px; display:inline-block;">
                        <img alt width="16" height="16" [src]="new_agreement_form.controls.priority.value == '1'? '../../assets/images/critical.svg':
                            new_agreement_form.controls.priority.value == '2'? '../../assets/images/major.svg':
                            new_agreement_form.controls.priority.value == '3'? '../../assets/images/minor.svg':
                            new_agreement_form.controls.priority.value == '4'? '../../assets/images/trivial.svg': null">
                    </span>
                    <mat-select formControlName="priority">
                        <mat-option value="1"><img alt width="16" height="16" src="../../assets/images/critical.svg">
                            One
                        </mat-option>
                        <mat-option value="2"><img alt width="16" height="16" src="../../assets/images/major.svg">
                            Two
                        </mat-option>
                        <mat-option value="3"><img alt width="16" height="16" src="../../assets/images/minor.svg">
                            Three
                        </mat-option>
                        <mat-option value="4"><img alt width="16" height="16" src="../../assets/images/trivial.svg">
                            Four
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Agreement Date</mat-label>
                    <input matInput [matDatepicker]="pickerDate" placeholder="01/01/2020"
                        formControlName="agr_date" required>
                    <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category" required>
                         <mat-option *ngFor="let category of categoryList" [value]="category.agr_category_id">
                            {{category.agr_category_name}}
                          </mat-option> 
                    </mat-select>                                     
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Agreement Due</mat-label>
                    <input matInput [matDatepicker]="pickerDue" placeholder="01/01/2020" formControlName="due_date"
                    [min]="new_agreement_form.controls.agr_date.value" required>
                    <mat-datepicker-toggle matSuffix [for]="pickerDue"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDue></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <mat-label>Followup By</mat-label>
                    <input type="text" matInput formControlName="followup" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option multiple (onSelectionChange)="followerId = assignee.user_id;" *ngFor="let assignee of filteredAssignees | async" [value]="assignee.first_name + ' ' + assignee.last_name">
                        <img class="select-option-img" aria-hidden [src]="assignee.profile_url || '../../assets/images/user-default.png'" height="25">
                        {{'   ' + assignee.first_name + ' ' + assignee.last_name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </div>
            
        </div>

       
        <div class="row" style="margin-right: 0px;">
            <div class="col">
                <div class="row">
                    <mat-form-field>
                        <mat-label>Topic</mat-label>
                        <input type="text" matInput placeholder="Topic" formControlName="agr_topic" required>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field>
                        <mat-label>Description</mat-label>
                        <textarea type="text" matInput placeholder="Description"
                            formControlName="agr_description" required></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- <mat-progress-bar mode="indeterminate" *ngIf="progress_bar" style="margin-bottom:2px"></mat-progress-bar> -->
        <div class="drop-zone-area">
            <mat-progress-bar mode="indeterminate" *ngIf="progress_bar" style="margin-bottom:2px"></mat-progress-bar>
            <ngx-dropzone (change)="onFilesAdded($event)" [maxFileSize]="1e+7" [accept]="'.jpg,.jpeg,.png,.txt,.pdf,.zip,.xlsx,.pptx,.docx'">
                <ngx-dropzone-label><i class="fa fa-file-text fa-2x"></i> Drop your files!</ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onFileRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
        </div>
        <div style=" float: right; margin-top: 15px;">
            <button mat-raised-button (click)="clear()" color="primary" type="button" class="mat-button-class"
                style="float: right; margin-right:10px">Clear</button>
            <button mat-raised-button type="submit" color="primary" class="mat-button-class"
                [disabled]="!new_agreement_form.valid" style="float: right; margin-right:23px">Submit</button>
        </div>
    </form>
</div>