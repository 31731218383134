import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const URL_update_field = environment.url_blogics + "/basic-data/request-category/update_custom_field.php";
const URL_delete_field = environment.url_blogics + "/basic-data/request-category/remove_custom_field.php";
const URL_delete_fields = environment.url_blogics + "/basic-data/request-category/remove_custom_fields.php";
const URL_add_new_fields = environment.url_blogics + "/basic-data/request-category/create_new_custom_field.php";
const URL_get_field_list = environment.url_blogics + "/basic-data/request-category/get_custom_filed_list.php";

@Injectable({
  providedIn: 'root'
})
export class ManageFiledsService {

  constructor(private _http: HttpClient) { }
    //get request details
  getFieldList(ticketType: string) {
      return this._http.post(
        URL_get_field_list,
        JSON.stringify({ ticket_type: ticketType })
      );
  }
  // getFieldList(){
  //   return this._http.get(URL_get_field_list);
  // }
  updateField(rowData: any){
    return this._http.post(URL_update_field, JSON.stringify(rowData));
  }
  deleteField(rowData: any){
    return this._http.post(URL_delete_field, JSON.stringify(rowData));
  }
  deleteFields(data: any){
    return this._http.post(URL_delete_fields, JSON.stringify(data));
  }
  saveNewField(formData : FormData){
    return this._http.post(URL_add_new_fields, JSON.stringify(formData));
  }
  
}
