<mat-card>
    <div class="col-md-12 col-xs-12">
        <div class="row">
          <label class="col-2" style="padding-top: 5px;">Request Labels :</label>
          <mat-chip-list #chipList class="col-9" style="float:left;">
            <mat-chip class="label-chip"
              *ngFor="let label of labels"
              [selectable]="true"
              [removable]= "true"
              (removed)="remove(label)">
              {{label}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>

          <button mat-icon-button class="corner-button" (click)="onClickAdd()">
            <mat-icon>add</mat-icon>
          </button>

        </div>
      </div>
</mat-card>