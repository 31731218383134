<div class="window-title">
  <h2>Users</h2>
</div>
<div class="search-div">
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
  <div class="row search-row">
    <div class="col-xl-2 col-xs-6">
      <mat-form-field> 
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Enter any key word">
      </mat-form-field>
    </div>
    <div *ngIf="permission.PERM_ADD_USER" class="col-md-10 col-xs-6">
      <button mat-flat-button (click)="addNewUser()" color="primary" type="button" style="margin-left:10px">
        <mat-icon aria-hidden="false">person_add</mat-icon>&emsp;New User</button>
    </div>
  </div>
</div> 
<div class="table-wrapper">
  <table mat-table [dataSource]="usersDetails" matSort>
    <ng-container matColumnDef="user_image" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
      <td mat-cell *matCellDef="let row"> 
        <img src="{{row.image_url}}" *ngIf="row.image_url!=null && row.image_url!=''" alt="avatar" class="img-fluid rounded-circle z-depth-0" style="height: 40px; width: 40px;">   
        <img src="{{defaultImage}}" *ngIf="row.image_url==null || row.image_url==''" alt="avatar" class="img-fluid rounded-circle z-depth-0" style="height: 40px; width: 40px;">   
      </td>
    </ng-container>
    <ng-container matColumnDef="user_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
      <td mat-cell *matCellDef="let row"> {{row.user_name}} </td>
    </ng-container>
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
      <td mat-cell *matCellDef="let row"> {{row.first_name}} </td>
    </ng-container>
    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
      <td mat-cell *matCellDef="let row">{{row.last_name}}  </td>
    </ng-container>
    <ng-container matColumnDef="nic">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIC </th>
      <td mat-cell *matCellDef="let row"> {{row.nic}} </td>
    </ng-container>
    <ng-container matColumnDef="user_role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User Role </th>
      <td mat-cell *matCellDef="let row"> {{row.user_role}} </td>
    </ng-container>
    <ng-container matColumnDef="active">
      <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header width="50%"> State </th> -->
      <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>     
      <td mat-cell *matCellDef="let row">
        <span class="badge" [ngClass]="{'badge-danger': row.active == '0', 'badge-success': row.active == '1'}"> 
          {{row.active == '1' ? ' Active ' : 'Inactive'}} 
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="button" stickyEnd>
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row" style="justify-content: center; text-align: right;"> 
        <button mat-flat-button (click)="viewUserPanel(row.user_id, row.user_name)">
          <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
        </button> 
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
</div>