import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

const URL_get_group_list = environment.url_blogics+'/collaboration/group_list/get_group_list.php';
const URL_delete_group = environment.url_blogics+'/collaboration/group_list/delete_group.php';

@Injectable({
  providedIn: 'root'
})
export class GroupListService {

  constructor(private httpclient: HttpClient) { }

  getGroupList(){
    return (this.httpclient.get(URL_get_group_list)); 
  }

  deleteGroup(groupId: string){
    return this.httpclient.post(URL_delete_group, JSON.stringify({"group_id" : groupId}));
  }


}
