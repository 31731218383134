import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WebsocketService } from './websocket.service';
import { WebSocketSubject } from 'rxjs/webSocket';

const URL_set_notify_seen = environment.url_blogics + '/alerts/alert_seen.php';
const URL_close_notification = environment.url_blogics + '/alerts/alert_closed.php';

export interface INotification {
  notification_id: string;
  closed : number;
  header: string;
  message: string;
  seen: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationsSubject: BehaviorSubject<INotification[]> = new BehaviorSubject<INotification[]>([]);
  public notifications$: Observable<INotification[]> = this.notificationsSubject.asObservable();

  private socket$: WebSocketSubject<any>;

  constructor(
    private wsService: WebsocketService,
    private httpclient: HttpClient
  ) { }

  connect(userId: string): void {
    //console.log("Inside Connect");
    this.socket$ = this.wsService.connect(environment.WS_Url + '?user_id=' + userId);

    this.socket$.pipe(
      tap((response: MessageEvent): void => {
        //console.log ("Response Data : ", response['alerts']);
        //let data = JSON.parse(response);
        //let alerts = data['alerts'];
        let alerts =  response['alerts'];
        this.notificationsSubject.next(alerts);
      })
    ).subscribe();
  }

  setNotifySeen(NotifyIds): Observable<any> {
    return this.httpclient.post(URL_set_notify_seen, JSON.stringify({ 'alert_ids': NotifyIds }));
  }

  closeNotification(NotifyIds): Observable<any> {
    return this.httpclient.post(URL_close_notification, JSON.stringify({ 'alert_ids': NotifyIds }));
  }

  // Additional methods or properties can be added as needed

  sendMessage(message: Object): void {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.next(JSON.stringify(message));
    }
  }

  closeConnection(): void {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.complete();
    }
  }
}



// import { HttpClient } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { BehaviorSubject, Subject } from 'rxjs';
// import { environment } from 'src/environments/environment';
// import { WebsocketService } from './websocket.service';

// const URL_set_notify_seen = environment.url_blogics+'/alerts/alert_seen.php';
// const URL_close_notification = environment.url_blogics+'/alerts/alert_closed.php';

// export interface INotification {
//   notification_id: string;
//   header: string;
//   message: string;
//   module: string;
// }

// @Injectable({
//   providedIn: 'root'
// })
// export class NotificationService {

//   public notifications: Subject<any>;

//   constructor(
//     private wsService: WebsocketService,
//     private httpclient: HttpClient) 
//   { }

//   connect(userId: string){
//     this.notifications = <Subject<INotification[]>>this.wsService.connect(environment.WS_Url + '?user_id=' + userId).map(
//       (response: MessageEvent): any => {
//         let data = JSON.parse(response.data);
//         let alerts = data['alerts'];
//         return {
//           alerts
//           // author: data.author,
//           // message: data.message
//         };
//       }
//     );
//   }

//   setNotifySeen(NotifyIds){
//     return (this.httpclient.post(URL_set_notify_seen, JSON.stringify({'alert_ids':NotifyIds})));
//   }

//   closeNotification(NotifyIds){
//     return (this.httpclient.post(URL_close_notification, JSON.stringify({'alert_ids':NotifyIds})));
//   }

//   // Our simplified interface for sending
//   // messages back to our socket.io server
//   // sendMsg(msg) {
//   //   this.messages.next(msg);
//   // }
// }
