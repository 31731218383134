import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

const URL_get_user_roles_list = environment.url_blogics + '/settings/get_user_roles.php';
const URL_get_designations_list = environment.url_sfeatures + '/basic-data/get_designation_list.php';
const URL_get_sections_list = environment.url_blogics + '/process/get_section_list.php';
const URL_add_new_designation = environment.url_blogics + '/basic-data/designation/create_new_designation.php';
const URL_update_designation = environment.url_blogics + '/basic-data/designation/update_designation.php';
const URL_delete_designation = environment.url_blogics + '/basic-data/designation/remove_designation.php';
const URL_delete_designations = environment.url_blogics + '/basic-data/designation/remove_designations.php';

@Injectable({
  providedIn: 'root'
})
export class DesignationsService {

  constructor(private _http: HttpClient) { }

  getUserRolesList(){
    return this._http.get(URL_get_user_roles_list);
  }

  getDesignationsList(){
    return this._http.get(URL_get_designations_list);
  }

  getSectionsList(){
    return this._http.get(URL_get_sections_list);
  }

  saveNewDesignation(formData : FormData){
    return this._http.post(URL_add_new_designation, JSON.stringify(formData));
  }

  updateDesignation(rowData: any){
    return this._http.post(URL_update_designation, JSON.stringify(rowData));
  }

  deleteDesignation(rowData: any){
    return this._http.post(URL_delete_designation, JSON.stringify(rowData));
  }

  deleteDesignations(data: any){
    return this._http.post(URL_delete_designations, JSON.stringify(data));
  }

}
