//#region imports
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators, FormGroupDirective } from "@angular/forms";
import { NewRequestService } from "./new-request.service";
import { FileUploadServiceService } from "../../services/file-upload.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { RequestStatusService } from "../request-status/request-status.service";
//#endregion

//#region interfaces

export interface AssignmentGroup{
  group_id : string;
  group_name: string;
}

export interface AssignmentGroupMember{
  user_id : string;
  first_name : string;
  last_name : string;
}
// export interface Division {
//   division_id: string;
//   division_name: string;
//   division_description: string;
//   active: string;
// }

// export interface Section {
//   section_id: string;
//   section_name: string;
// }

// export interface Category {
//   cat_id: string
//   cat_name: string;
//   cat_description: string;
// }
//#endregion

@Component({
  selector: "app-new-request",
  templateUrl: "./new-request.component.html",
  styleUrls: ["./new-request.component.css"],
})
export class NewRequestComponent implements OnInit {
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  //#region class variables
  reference_no: string;
  attachments: any = []; // files url path array
  files: File[] = [];
  titlesList: any;

  asGroupList: AssignmentGroup[] = [];
  assignmentGroupID : string;
  filteredAsGroups : Observable<AssignmentGroup[]>;

  asMemberList: AssignmentGroupMember[]=[];
  assignedMemberID : string;
  filteredMembers: Observable<AssignmentGroupMember[]>;

  // divisionsList: Division[] = [];
  // serviceAreasList: Division[] = [];
  // sectionsList: Section[] = [];
  // categoryList: Category[] = [];
  // filteredDivisions: Observable<Division[]>;
  // filteredServiceAreas: Observable<Division[]>;
  // filteredSections: Observable<Section[]>;
  // filteredCategories: Observable<Category[]>;
  // divisionId: string;
  // serviceAreaId: string;
  progress_bar: boolean = false;
  generate_Id: boolean = false;
  requestId: string;
  // sections: any;
  // sectionId: string = null;
  // section_name: string;
  // categoryId: string;
  //#endregion


  constructor(
    private newRequestService: NewRequestService,
    private requestStatus: RequestStatusService,
    private _formBuilder: FormBuilder,
    private fileUploadService: FileUploadServiceService,
    private toastr: ToastrService,
    private datePipe: DatePipe
  ) { }

  //#region FormGroup
  new_request = this._formBuilder.group({
    nic: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(12)]],
    mobile_no: ["null", Validators.required],
    email: ["", Validators.email],
    title: ["", Validators.required],
    name_in_full: ["", Validators.required],
    name_in_use: ["", Validators.required],
    dob: [""],
    as_group:["",Validators.required],
    as_member:[""],
    // division: ["", Validators.required],
    address: ["", Validators.required],
    background: [""],
    ref_no: ["", { disabled: true }],
    topic: ["", [Validators.required, Validators.maxLength(200)]],
    // section: [""],
    // category: [""],
    priority: ["", Validators.required],
    // service_area: [""],
    request: [""]
  });
  //#endregion FormGroup

  ngOnInit() {
    this.getRefNumber();
    this.getTitleList();
    this.getAsGroupList();
    //this.getAsGroupMemberList();
    // this.getDivisionList();
    // this.setDivisionFilter();
    // this.setServiceAreasFilter();
    // this.loadSectionList();
    // this.setSectionFilter();
    this.setGroupFilter();
  }

  //#region onSubmit function
  onSubmit() {
    // Change the date format
    this.new_request.patchValue({
      dob: this.datePipe.transform(
        this.new_request.controls.dob.value,
        "yyyy-MM-dd"
      ),
    });
    //#endregion


    //#region backup values of division,section, category and ServiceArea for future uses
    // var bkDivion = this.new_request.controls.division.value;
    // var bkSection = this.new_request.controls.section.value;
    // var bkCategory = this.new_request.controls.category.value;
    // var bkServiceArea = this.new_request.controls.service_area.value;
    var bk_as_group = this.new_request.controls.as_group.value;
    var bk_as_member = this.new_request.controls.as_member.value;
    // //patch values
    //this.new_request.patchValue({as_group : this.assignmentGroupID});
    //this.new_request.patchValue({as_member : this.assignedMemberID});
    // this.new_request.patchValue({ division: this.divisionId }); //Set divisionId as division...
    // this.new_request.patchValue({ section: this.sectionId }); //Set sectionId as section...
    // this.new_request.patchValue({ category: this.categoryId }); //Set categoryId as category...
    // this.new_request.patchValue({ service_area: this.serviceAreaId }); //Set serviceAreaId as service_area...
    this.new_request.patchValue({as_group : this.assignmentGroupID});
    this.new_request.patchValue({as_member : this.assignedMemberID});
    //#endregion

    //#region Create Request
    this.newRequestService
      .createRequest(this.new_request.value, this.attachments)
      .subscribe(
        (res) => {
          if (res["status"] == "success") {
            localStorage.removeItem("ref_no");
            this.clear();
            this.toastr.success("Record saved successfully", "Success");
          } else {
            localStorage.removeItem("ref_no");
            this.toastr.error("Something went wrong!", "Failed");

            //Backup set when get errors....
            // this.new_request.patchValue({ division: bkDivion });
            // this.new_request.patchValue({ section: bkSection });
            // this.new_request.patchValue({ category: bkCategory });
            // this.new_request.patchValue({ service_area: bkServiceArea });
            this.new_request.patchValue({as_group: bk_as_group});
            this.new_request.patchValue({ as_member: bk_as_member });
          }
        },
        (err) => {
          this.toastr.error("Something went wrong!", "Failed");
          //Backup set when get errors....
          // this.new_request.patchValue({ division: bkDivion });
          // this.new_request.patchValue({ section: bkSection });
          // this.new_request.patchValue({ category: bkCategory });
          // this.new_request.patchValue({ service_area: bkServiceArea });
          this.new_request.patchValue({ as_member: bk_as_member });
          this.new_request.patchValue({as_group: bk_as_group});
        }
      );
  }
  //#endregion

  getRefNumber() {
    if (localStorage.getItem("ref_no") == null) {
      this.newRequestService.getGeneratedId().subscribe((res) => {
        this.new_request.patchValue({
          ref_no: res,
        });
        localStorage.setItem("ref_no", res.toString()); // Store ref_no to avoid regenerate when refresh
      });
    } else {
      this.new_request.patchValue({
        ref_no: localStorage.getItem("ref_no"),
      });
    }
  }

  //#region To fetch default requester data after adding NIC
  getDefaultData() {
    this.newRequestService
      .getDefaults(this.new_request.controls.nic.value)
      .subscribe((data) => {
        if (data["nic"] != null) {
          this.new_request.patchValue({
            name_in_full: data["name_in_full"],
            name_in_use: data["name_in_use"],
            email: data["email"],
            mobile_no: data["mobile_no"],
            address: data["address"],
            dob: data["dob"],
            background: data["background"],
            title: data["title"],
            // division: data["division_id"] + ' - ' + data["division_name"],
            // service_area: data["division_id"] + ' - ' + data["division_name"]
          });
          // this.divisionId = data["division_id"];
          // this.serviceAreaId = data["division_id"];
        } else {
          this.generate_Id = true;
        }
      });
  }
  //#endregion

  //#region populate form related data
  getTitleList() {
    this.newRequestService.getTitleList().subscribe((data) => {
      if (data != null) {
        this.titlesList = data;
      } else {
        this.toastr.error("Server Error", "Error");
      }
    });
  }

  getAsGroupList(){
    this.newRequestService.getAssignmentGroupList().subscribe((res)=>{
      if(res != null){
        res.forEach((element) => {
          this.asGroupList.push(element);
        });
        this.setGroupFilter();
      }
      else{
        this.toastr.error("Server Error", "Error");
      }
      //console.log(this.asGroupList[0]);
    });
  }

  getAsGroupMemberList(){
    this.newRequestService.getAssignmentGroupMemberList(this.assignmentGroupID).subscribe((res)=>{
      if(res != null){
        res.forEach((element) => {
          this.asMemberList.push(element);
        });
        this.setGroupMemberFilter();
      }
      else{
        this.toastr.error("Server Error", "Error");
      }
      console.log(this.asMemberList[0]);
    });
  }

  // getDivisionList() {
  //   this.newRequestService.getDivisionList().subscribe((res) => {
  //     if (res != null) {
  //       //  this.divisionsList = data;
  //       res.forEach((element) => {
  //         this.divisionsList.push(element);
  //         this.serviceAreasList.push(element);
  //       });
  //       this.setDivisionFilter();
  //       this.setServiceAreasFilter();
  //     } else {
  //       this.toastr.error("Server Error", "Error");
  //     }
  //   });
  // }

  // loadSectionList() {
  //   this.requestStatus.getSectionList().subscribe((res) => {
  //     if (res != null) {
  //       res.forEach((element) => {
  //         this.sectionsList.push(element)
  //       })
  //       this.setSectionFilter();
  //     } else {
  //       this.toastr.error("Server Error", "Error");
  //     }
  //   });
  // }

  // loadCategoryList() {
  //   if (this.sectionId != null) {
  //     this.categoryList = [];
  //     this.newRequestService.getCategoryList(this.sectionId).subscribe((res) => {
  //       if (res != null) {
  //         res.forEach((element) => {
  //           this.categoryList.push(element)
  //         })
  //         this.setCategoryFilter();
  //       } else {
  //         this.toastr.error("Server Error", "Error");
  //       }
  //     });
  //   }
  // }
  //#endregion

  //#region reset form
  clear() {
    this.formGroupDirective.resetForm();
    this.new_request.reset();
    this.getRefNumber();
    this.attachments = [];
    this.files = [];
  }
  //#endregion


  //#region Attachment Managers
  //When select a file attachment...
  onFilesAdded(event) {
    this.progress_bar = true;
    this.files.push(...event.addedFiles);

    this.fileUploadService
      .fileUpload(
        this.new_request.controls.ref_no.value,
        event.addedFiles,
        "request",
        "upload"
      )
      .subscribe((res) => {
        if (res != null) {
          res.forEach((element) => {
            var obj = {
              file_path: element["file_path"],
              file_name: element["file_name"],
            };
            this.attachments.push(obj);
          });
          this.progress_bar = false;
        } else {
          this.toastr.error("File uploadin failed", "Error");
          this.progress_bar = false;
        }
      });
  }

  onFileRemove(event) {
    console.log(this.attachments);
    var index_no = this.files.indexOf(event);
    var attachment = this.attachments[index_no];
    this.fileUploadService
      .fileRemove(this.new_request.controls.ref_no.value,
        attachment["file_name"],
        attachment["file_path"],
        "request", "remove"
      )
      .subscribe(
        (res) => {
          if (res["status"] == "success") {
            this.files.splice(index_no, 1);
            this.attachments.splice(index_no, 1);
          } else {
            this.toastr.error("File remove failed", "Error");
          }
        },
        (err) => {
          console.log(err);
          this.toastr.error("File remove failed", "Error");
        }
      );
  }
  //#endregion

  //#region filters
  // setDivisionFilter() {
  //   this.filteredDivisions = this.new_request.controls.division.valueChanges.pipe(
  //     startWith(""),
  //     map((division) =>
  //       division ? this._filter_division(division) : this.divisionsList.slice()
  //     )
  //   );
  // }

  // setServiceAreasFilter() {
  //   this.filteredServiceAreas = this.new_request.controls.service_area.valueChanges.pipe(
  //     startWith(""),
  //     map((serviceArea) =>
  //       serviceArea ? this._filter_service_area(serviceArea) : this.serviceAreasList.slice()
  //     )
  //   );
  // }

  // setSectionFilter() {
  //   this.filteredSections = this.new_request.controls.section.valueChanges.pipe(
  //     startWith(''),
  //     map((section) => section ? this._filter_section(section) : this.sectionsList.slice()
  //     )
  //   );
  // }

  setGroupFilter(){
    console.log("Group ID :"+this.assignmentGroupID);
    console.log(this.asGroupList[0]);
    this.filteredAsGroups = this.new_request.controls.as_group.valueChanges.pipe(
      startWith(''),
      map((as_group) => as_group ? this._filter_as_group(as_group): this.asGroupList.slice())
    );
  }

  setGroupMemberFilter(){
    this.filteredMembers = this.new_request.controls.as_member.valueChanges.pipe(
      startWith(''),
      map((as_member)=> as_member ? this._filter_as_group_members(as_member): this.asMemberList.slice())
    )
  }

  // setCategoryFilter() {
  //   this.filteredCategories = this.new_request.controls.category.valueChanges.pipe(
  //     startWith(''),
  //     map((category) => category ? this._filter_category(category) : this.categoryList.slice()
  //     )
  //   );
  // }

  private _filter_as_group(as_group:string): AssignmentGroup[]{
    const filterValue = as_group.toLocaleLowerCase();
    return this.asGroupList.filter(
      (as_group) => 
      as_group.group_id.toLowerCase().indexOf(filterValue)===0||
      as_group.group_name.toLowerCase().indexOf(filterValue)===0
    );
  }

  private _filter_as_group_members(as_group_member:string): AssignmentGroupMember[]{
    const filterValue = as_group_member.toLocaleLowerCase();
    return this.asMemberList.filter(
      (as_group_member)=> 
      as_group_member.first_name.toLowerCase().indexOf(filterValue)===0||
      as_group_member.last_name.toLowerCase().indexOf(filterValue)===0
    );
  }
  // private _filter_division(division: string): Division[] {
  //   const filterValue = division.toLowerCase();
  //   return this.divisionsList.filter(
  //     (division) =>
  //       division.division_id.toLowerCase().indexOf(filterValue) === 0 ||
  //       division.division_name.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  // private _filter_service_area(serviceArea: string): Division[] {
  //   const filterValue = serviceArea.toLowerCase();
  //   return this.serviceAreasList.filter(
  //     (serviceArea) =>
  //       serviceArea.division_id.toLowerCase().indexOf(filterValue) === 0 ||
  //       serviceArea.division_name.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  // private _filter_section(section: string): Section[] {
  //   const filterValue = section.toLowerCase();
  //   return this.sectionsList.filter(
  //     (section) =>
  //       section.section_id.toLowerCase().indexOf(filterValue) === 0 ||
  //       section.section_name.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  // private _filter_category(category: string): Category[] {
  //   const filterValue = category.toLowerCase();
  //   return this.categoryList.filter(
  //     (category) =>
  //       category.cat_name.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }
  //#endregion
}
