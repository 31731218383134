<div class="top-bar">
    <h2 class="topic">Letter Writter</h2>
    <span class="req-label">{{letterId}}</span>
</div>

<div class="button-line row">
    <div class="col">
        <div class="row">
            <!-- <div class="col-2" style="margin-top: 7px">My Number : </div>
            <div class="col" style="margin-top: 7px">
                {{"RR/"+sectionId+"/"+categoryId+"/"+requestId}}
            </div> -->
            <div class="col-2" style="margin-top: 7px">Your Number : </div>
            <div class="col" style="margin-top: -15px">
                <mat-form-field class="inputLabel">
                    <input matInput [formControl]="yourNo">
                </mat-form-field>     
            </div>
        </div>
    </div>              
    <div class="col">
        <!-- <button mat-stroked-button color="primary" style="float: right;" (click)="generatePdf()"><mat-icon>download</mat-icon>Generate PDF</button>
        <button mat-stroked-button color="primary" style="float: right;  margin-right: 5px" (click)="makeDraftPdf()"><mat-icon>visibility</mat-icon>Make Draft</button> -->
        <button mat-stroked-button color="primary" style="float: right;  margin-right: 5px" 
        [useExistingCss]="false" 
        [printStyle]="{p : {'font-size': '16px','white-space': 'normal', 'margin': '0px !important'}, br : {'border-top': '100px', 'line-height': '0px'}}"
        printSectionId="print-section" ngxPrint (click)="Print()"><mat-icon>print</mat-icon>Print</button>
        <button mat-stroked-button color="primary" style="float: right;  margin-right: 5px" (click)="SaveLetter()"><mat-icon>save</mat-icon>Save</button>
    </div>
</div>

<ejs-richtexteditor #letterContainer id='letterContainer' 
    [toolbarSettings]='tools' 
    [fontSize]='fontSizes'
    [placeholder]='placeholder'
    [(value)]='letterContent'
    (actionComplete)='actionCompleteHandler($event)'
    [pasteCleanupSettings]="pasteCleanupSettings">
</ejs-richtexteditor>

<div style="width: 20cm;" style="align-content: center;">
<div>
    <div class="container-fluid">
        <div id="letter-head" class="container" style="width: 20cm;">
            <div class="row">
                <div class="col-2">
                    <img src="../../assets/images/sri-lanka-state-logo.png" alt="" height="100" style="margin-top: 10px;">
                </div>
                <div class="col-10 center">
                    <div class="row">
                        <div class='col compact-head'>
                            <span class="tp-sn" style="font-size:18px;">රොෂාන් රණසිංහ </span> <span class="sp-sn-sm">පා.ම.</span><br>
                            <span class="tp-tm" style="font-size:11px;">ரொஷான் றனசிங்க </span> <span class="sp-tm-sm">பா.உ.</span><br>
                            <span class="tp-es" style="font-size:12px;">ROSHAN RANASINGHE </span> <span class="sp-es-sm">M.P.</span>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 7px;">
                        <div class='col compact-head'>
                            <span class="tp-sn bold">පළාත් සභා හා පළාත් පාලන කටයුතු රාජ්‍ය අමාත්‍යංශය</span><br>
                            <span class="tp-tm bold">மகாண சபைகல் மற்றும் உள்ளூராட்சி விவகாரங்கள் இராஐரங்க
                                அமைச்சர்</span><br>
                            <span class="tp-es bold">State Ministry of Provincial Councils and Local Government
                                Affairs</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row low compact" style="font-weight: normal;">
                <div class="col tp-sn-md" style="padding-right: 0px;"> අංක 330<br>ආචාර්ය කොල්වින් ආර්.ද සිල්වා මාවත<br>(යුනියන් පෙදෙස)<br>කොළඹ 02</div>
                <div class="col tp-tm-md" style="padding-left: 0px; padding-right: 40px; margin-left: -20px;"> இல. 330<br>டாக்டர். கொல்வின் ஆர்.டி. சில்வா மாவத்தை<br>(யூனியன்
                    பிளேஸ்)<br>கொழும்பு 02 </div>
                <div class="col tp-en-md"  style="padding-left: 0px; margin-right: -90px;">No. 330<br>Dr. Colvin R. de Silva Mawatha<br>(Union Place)<br>Colombo 02</div>    
                <!-- <div class="col tp-en-md"  style="padding-left: 0px; padding-right: 0px;"><span class="d-flex flex-row-reverse"> No. 330<br>Dr. Colvin R. de Silva
                        Mawatha<br>(Union Place)<br>Colombo 02</span></div> -->
            </div>
            <div class="row low" style="margin-top:0px;">
                <div class="col" style="margin-top:-12px">
                    <hr class="divider">
                </div>
            </div>
            <div class="row low justify-content-evenly compact" style="font-weight: 500;margin-top:-12px;">
                <div class="col-5">
                    <div class="row">
                        <div class="col-4">
                            <!-- <span class="tp-sn-md">මගේ අංකය</span><br>
                            <span class="tp-tm-md">எனது இல</span><br>
                            <span class="tp-en-md">My No</span> -->
                            <p class="tp-sn-sm">මගේ අංකය</p><br>
                            <p class="tp-tm-sm">எனது இல</p><br>
                            <p class="tp-en-sm">My No</p>
                        </div>
                        <div class="col-8 align-self-center tp-en-lbl">
                            <!-- <span>{{"RR/"+sectionId+"/"+categoryId+"/"+requestId}}</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-4" style="padding-left: 0px;">
                            <p class="tp-sn-sm">ඔබේ අංකය</p><br>
                            <p class="tp-tm-sm">உமது இல</p><br>
                            <p class="tp-en-sm">Your No</p>
                        </div>
                        <!-- <div class="col-8 align-self-center tp-en-lbl">
                            <span>{{yourNo.value}}</span>
                        </div> -->
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-3">
                            <p class="tp-sn-sm">දිනය</p><br>
                            <p class="tp-tm-sm">திகதி</p><br>
                            <p class="tp-en-sm">Date</p>
                        </div>
                        <div class="col-9 align-self-center tp-en-lbl" style="padding-left: 20px;">
                            <!-- <span>2021/04/12</span> -->
                        </div>
                    </div>
                </div>
            </div>
        
            <!-- add the content in following container-->
            <div id="print-section" class="container" style="height: 850px; margin-top: -20px; text-align: justify;">
                <div class="row number-line">
                    <div style="margin: 0 0 50px 60px; float: left; font-size: 14px;">
                        {{"RR/"+letterId}}
                    </div>
                    <div class="your-num" style="margin: 0 0 50px 100px; float: left; width: 228px;; font-size: 14px;">
                        <span>{{yourNo.value}}</span>
                    </div>
                    <div style="margin: 0 0 50px 180px; ; font-size: 14px;">
                        <span>{{date}}</span>
                    </div>
                </div>
                <div style="float: none; text-align: justify;" [innerHTML]="letterContent"></div>
            </div>
            
            <div class="row low">
                <div class="col">
                    <hr class="divider">
                </div>
            </div>
        </div>

        <footer style="font-weight: 600; font-size: 11px; margin-left: 75px;">   
            <div id="letter-footer" class="container" style="width: 22cm; margin-top: -10px;">       
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-5">
                                <!-- <span class="tp-sn-sm">ගරු ඇමතිතුමා</span><br>
                                <span class="tp-tm-sm">கெளரவ. அமைச்சர்</span><br>
                                <span class="tp-en-sm">Hon.Minister</span> -->
                                <p class="tp-sn-sm">ගරු ඇමතිතුමා</p><br>
                                <p class="tp-tm-sm">கெளரவ. அமைச்சர்</p><br>
                                <p class="tp-en-sm">Hon.Minister</p>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col-1 footer-sm-unit footer-num">Tel: </div>
                                    <div class="col footer-num">011 2328098<br>011 2326732</div>
                                </div>
                                <div class="row">
                                    <div class="col-1 footer-sm-unit footer-num">Mob: </div>
                                    <div class="col footer-num">070 7266666</div>
                                </div>
                                <div class="row">
                                    <div class="col-1 footer-sm-unit footer-num">Fax: </div>
                                    <div class="col footer-num">011 2240664</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6" style="padding-left: 0px">
                        <div class="row">
                            <div class="col-7" style="padding-left: 0px;">
                                <span class="tp-sn-sm">කාර්යාලය (කොළඹ)</span><br>
                                <span class="tp-tm-sm">அலுவலகம் (கொழும்பு)</span><br>
                                <span class="tp-en-sm">office (Colombo)</span>
                            </div>
                            <div class="col" style="margin-left: -10px; padding-left: 0px;">
                                <div class="row">
                                    <div class="col-1 footer-sm-unit footer-num">Tel: </div>
                                    <div class="col footer-num">011 2347530<br>011 2305326/27<br>011 2303280</div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-7" style="padding-left: 0px;">
                                <p class="tp-sn-sm">කාර්යාලය (පොළොන්නරුව)</p><br>
                                <p class="tp-tm-sm">அலுவலகம் (பொலொன்னருவை)</p><br>
                                <p class="tp-en-sm">Office (Polonnaruwa)</p>
                                <!-- <span>කාර්යාලය (පොළොන්නරුව)</span><br>
                                <span>அலுவலகம் (பொலொன்னருவை)</span><br>
                                <span>Office (Polonnaruwa)</span> -->
                            </div>
                            <div class="col" style="margin-left: -10px; padding-left: 0px;">
                                <div class="row">
                                    <div class="col-1 footer-sm-unit footer-num">Tel: </div>
                                    <div class="col footer-num">027 2227052/53</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Add QR here-->
                    <!-- <div class="col">
                        QR
                    </div> -->
                    <!--end QR-->
                </div>
            </div>
        </footer>  
    </div>
</div>