import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

const URL_get_req_list = environment.url_blogics+'/requester/get_requester_list.php';
const URL_get_req_list_by_search = environment.url_blogics+'/requester/get_requester_list_by_search.php';

@Injectable({
  providedIn: 'root'
})
export class RequesterService {

  constructor(private httpclient: HttpClient) { }

  getAllRequestersData(){
    return (this.httpclient.get(URL_get_req_list)); 
  }

  getSearchRequestersData(){
    let params = new HttpParams();
    return (this.httpclient.get(URL_get_req_list_by_search, {params: params})); 
  }
  
//   progressSelectedList(state : string, user : string) {
//     return (this.httpclient.post(URL, JSON.stringify({"state" : state, "user_id" : user}))) 
//   }
}
