import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { NewAgreementService } from './new-agreement.service'
import { FileUploadServiceService } from '../../services/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

export interface Assignee {
  user_id: string;
  user_name: string;
  first_name: string;
  last_name: string;
  profile_url: string;
}

export interface Division {
  division_id: string;
  division_name: string;
  division_description: string;
  active: string;
}

@Component({
  selector: 'app-new-agreement',
  templateUrl: './new-agreement.component.html',
  styleUrls: ['./new-agreement.component.css']
})
export class NewAgreementComponent implements OnInit {

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  assigneeList: Assignee[] = [];
  categoryList: any;
  attachments: any = []; // files url path array
  files: File[] = [];
  filteredAssignees: Observable<Assignee[]>;
  // divisionsList: Division[] = [];
  // filteredDivisions: Observable<Division[]>;
  followerId: string = null;
  // divisionId: string;
  progress_bar: boolean = false;

  constructor(
    private _formBuilder        : FormBuilder,
    private newAgreementService : NewAgreementService,
    private fileUploadService   : FileUploadServiceService,
    private toastr              : ToastrService,
    private datePipe            : DatePipe) { }

  new_agreement_form = this._formBuilder.group({
    agr_no: ['', {disabled: true}],
    agr_date: ['', Validators.required],
    due_date: ['', Validators.required],
    category: ['', Validators.required],
    priority: ['', Validators.required],
    // division: [''],
    followup: [''],
    agr_topic: ['', Validators.required],
    agr_description: ['', Validators.required],
  });

  ngOnInit(): void {
    this.getNewAgrID();
    this.loadAssigneeList();
    this.loadCategoryList();
    //this.setAssigneeFilter();
    //this.getDivisionList();
  }

  getNewAgrID() {
    console.log("Arg_NO : "+localStorage.getItem('agr_no'));
    if (localStorage.getItem('agr_no') == null) {
      this.newAgreementService.getNextAgreementID().subscribe(res => {
        this.new_agreement_form.patchValue({
          agr_no: res['agr_id']
        });      
        localStorage.setItem('agr_no', res['agr_id'].toString());
      });
    } else {
      this.new_agreement_form.patchValue({
        agr_no: localStorage.getItem('agr_no')
      });
    }
  }

  loadAssigneeList() {
    this.newAgreementService.getAssigneeList().subscribe(res => {
      if(res != null){
        res.forEach(element => {
          this.assigneeList.push(element);
        });
      }
      this.setAssigneeFilter();
    });
  }

  loadCategoryList() {
    this.newAgreementService.getCategoryList().subscribe(res => {
      this.categoryList = res;
    });
  }

  // getDivisionList() {
  //   this.newAgreementService.getDivisionList().subscribe((res) => {
  //     if (res != null) {
  //       res.forEach((element) => {
  //         this.divisionsList.push(element);
  //       });
  //       this.setDivisionFilter();
  //     } else {
  //       this.toastr.error("Server Error", "Error");
  //     }
  //   });
  // }

  // setDivisionFilter() {
  //   this.filteredDivisions = this.new_agreement_form.controls.division.valueChanges.pipe(
  //     startWith(""),
  //     map((division) =>
  //       division ? this._filter_division(division) : this.divisionsList.slice()
  //     )
  //   );
  // }

  setAssigneeFilter(){
    this.filteredAssignees = this.new_agreement_form.controls.followup.valueChanges
    .pipe(
      startWith(''),
      map(assignee => assignee ? this._filter_assignee(assignee) : this.assigneeList.slice())
    );
  }
  //Use to fiter the assignee list
  private _filter_assignee(assignee: string): Assignee[] {
    const filterValue = assignee.toLowerCase();
    return this.assigneeList.filter(assignee => (assignee.first_name.toLowerCase().indexOf(filterValue) === 0)||
    (assignee.last_name.toLowerCase().indexOf(filterValue) === 0)||
    ((assignee.first_name+' '+assignee.last_name).toLowerCase().indexOf(filterValue) === 0));
  }

  // private _filter_division(division: string): Division[] {
  //   const filterValue = division.toLowerCase();
  //   return this.divisionsList.filter(
  //     (division) =>
  //       division.division_id.toLowerCase().indexOf(filterValue) === 0 ||
  //       division.division_name.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  onSubmit() {
    // Change the date format
    this.new_agreement_form.patchValue({agr_date : this.datePipe.transform(this.new_agreement_form.controls.agr_date.value , 'yyyy-MM-dd')});
    this.new_agreement_form.patchValue({due_date : this.datePipe.transform(this.new_agreement_form.controls.due_date.value , 'yyyy-MM-dd')});
    this.new_agreement_form.patchValue({followup : this.followerId}); //Set followerId as follower
    //this.new_agreement_form.patchValue({division : this.divisionId}); //Set divisionId as division
    this.newAgreementService.createAgreement(this.new_agreement_form.value, this.attachments).subscribe(
        res => {
          if(res['status'] == 'success'){
            localStorage.removeItem('agr_no');
            this.clear();
            //this.getNewAgrID();
            this.toastr.success('Record saved successfully', 'Success');
          }
          else{
          //  localStorage.removeItem('ref_id');
            this.toastr.error('Something went wrong!', 'Failed');
          }
        },
        err => {
          this.toastr.error('Something went wrong!', 'Failed');
        }
      );
  }

  clear() {
    this.formGroupDirective.resetForm();
    this.new_agreement_form.reset();
    this.getNewAgrID();
    this.attachments = [];
    this.files = [];
  }

  //When select a file attachment...
  onFilesAdded(event) {
    this.progress_bar = true;
    this.files.push(...event.addedFiles);

    this.fileUploadService.fileUpload(this.new_agreement_form.controls.agr_no.value, event.addedFiles, "agreement", "upload").subscribe(
      res => {
        if(res != null){
          res.forEach(element => {
            var obj = {'file_path':element["file_path"],'file_name':element["file_name"]};
            this.attachments.push(obj);           
          });
          this.progress_bar = false;
        }else{
          this.toastr.error('File uploadin failed', 'Error');
          this.progress_bar = false;
        }
      }  
    );
  }
   
  onFileRemove(event) {
    console.log(this.attachments);
    var index_no = this.files.indexOf(event);
    var attachment = this.attachments[index_no];
    this.fileUploadService.fileRemove(this.new_agreement_form.controls.agr_no.value, attachment['file_name'], attachment['file_path'], "agreement", "remove").subscribe(
      res => {
        if(res['status'] == 'success'){
          this.files.splice(index_no, 1);
          this.attachments.splice(index_no, 1);
        }
        else{
          this.toastr.error('File remove failed', 'Error');
        }
      },
      err => {
        console.log(err);
        this.toastr.error('File remove failed', 'Error');
      }
    );
  }

}
