import { Component, OnInit, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FileUploadServiceService } from 'src/app/services/file-upload.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StickyDirection } from '@angular/cdk/table';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-image-upload-panel',
  templateUrl: './user-image-upload-panel.component.html',
  styleUrls: ['./user-image-upload-panel.component.css']
})
export class UserImageUploadPanelComponent implements OnInit {

  userId: string;
  username: string;
  isPreImageExist: boolean;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isImageLoaded: boolean = false;
  defaultImageUrl: string = '../../assets/images/user-default.png';
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef          : MatDialogRef<UserImageUploadPanelComponent>,
    private fileUploadService : FileUploadServiceService,
    private toastr            : ToastrService,
    private _auth             : AuthService)
    {
      this.userId = data.userId;
      this.username = data.username;
      this.isPreImageExist = data.hasImage;
   }

  ngOnInit(): void {
  }
  onImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.defaultImageUrl;
  }
  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
      this.isImageLoaded = true;
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  saveImage(){

    this.fileUploadService.uploadUserImage(this.username, this.userId, this.croppedImage)
      .subscribe( res => {
        if(res['status'] == 'success'){
          this._auth.setImageUrl(res['image_url']);
          // this.isImageExist = true;
          this.dialogRef.close("upload");
        }
        else{
           this.toastr.error('File Uplading failed', 'Failed');
        }
      });
  }

  onRemoveImage(){
    this.dialogRef.close("remove");
  }

  onCancel(){
    this.dialogRef.close();
  }
}
