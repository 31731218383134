import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const URL_get_designations_list = environment.url_sfeatures + '/basic-data/get_designation_list.php';
//const URL_assignee_list = environment.url_blogics + '/progress/get_assignee_list.php';
const URL_get_sections_list = environment.url_sfeatures + '/basic-data/get_section_list.php';
const URL_get_req_type_list = environment.url_sfeatures + '/basic-data/get_req_type_list.php'; //TO CHECK...
const URL_get_division_list = environment.url_sfeatures + '/basic-data/get_division_list.php'; //TO CHECK...
const URL_get_user_attendee_list = environment.url_blogics + '/event/load_user_attendees.php';
const URL_get_req_attendee_list = environment.url_blogics + '/event/load_requester_attendees.php';
const URL_create_event = environment.url_blogics + '/event/create_new_event.php';
const URL_get_assignee_list = environment.url_blogics + '/progress/get_assignee_list.php';

@Injectable({
  providedIn: 'root'
})

export class NewEventService {

  constructor(private _http: HttpClient) { }

  getAssigneeList() : Observable<any> {
    return (this._http.get(URL_get_assignee_list));
  }

  getDesignationList() : Observable<any> {
    return this._http.get(URL_get_designations_list);
  }

  getSectionsList() : Observable<any>{
    return this._http.get(URL_get_sections_list);
  }

  getReqTypeList() : Observable<any>{
    return this._http.get(URL_get_req_type_list);
  }

  getDivisionList() : Observable<any>{
    return this._http.get(URL_get_division_list);
  }

  loadUserAttendees(type: string, selectedDesigs, selectedSections, selectedState) : Observable<any>{
    return this._http.post(URL_get_user_attendee_list, JSON.stringify({ "type": type, "designation": selectedDesigs,
     "section":selectedSections, "state": selectedState }));
  }

  loadRequesterAttendees(type: string, reqTypes, divisions, selectedState) : Observable<any>{
    return this._http.post(URL_get_req_attendee_list, JSON.stringify({ "type": type, "req_type": reqTypes,
     "division":divisions, "state": selectedState }));
  }

  createEvent(event_details: FormData, user_attendees, req_attendees){
    return this._http.post(URL_create_event, JSON.stringify({event_details, user_attendees, req_attendees}));
  }
}
