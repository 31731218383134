import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { EventListService } from './event-list.service';
import { EventViewComponent } from '../event-view/event-view.component';

export interface iEvent {
  event_occur_id: string;
  event_name: string;
  event_category: string;
  event_type: string;
  remaining_days: number;
  start_date: string;
  end_date: string;
  occurrence: string;
}
@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;

  tableColumns: string[] = ['event_occur_id', 'event_name', 'event_category', 'event_type', 'start_date', 'end_date'];
  eventData: any;
  searchKey: string;
  state: string = "upcoming";

  constructor(
    private eventListService: EventListService, 
    private dialog : MatDialog,
    private _auth: AuthService
  ) 
  { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    if (this._auth.getUserRole == "1") {
      this.loadAllData();
    }else{
      this.loadSelectedData(localStorage.getItem('user_id'));
    }
  }

  loadAllData() {
    this.eventListService.getEventList(this.state).subscribe(res => {
      this.eventData = new MatTableDataSource(res as iEvent[]);
      this.eventData.sort = this.sort;
      this.eventData.paginator = this.paginator;
    })
  }

  loadSelectedData(userId: string){
    this.eventListService.getSelectedEventList(this.state, userId).subscribe(res => {
      this.eventData = new MatTableDataSource(res as iEvent[]);
      this.eventData.sort = this.sort;
      this.eventData.paginator = this.paginator;
    })
  }

  onClickUpcoming() {
    this.state = "upcoming"
    this.loadData();
  }

  onClickOngoing() {
    this.state = "ongoing"
    this.loadData();
  }

  onClickPast() {
    this.state = "past"
    this.loadData();
  }
  
  onClickAll() {
    this.state = "all"
    this.loadData();
  }

  applyFilter(){
    this.eventData.filter = this.searchKey.trim().toLowerCase();
  }
  onSearchClear(){
    this.searchKey = "";
    this.applyFilter();
  }

  showRecord(event_no : string | number){
    const dialogconfig = new MatDialogConfig();
    //dialogconfig.disableClose = true;
    dialogconfig.autoFocus= false;
    dialogconfig.width = "70%";
    dialogconfig.minWidth = "390px"; /*Set minimum width - Important for mobile widows*/
    dialogconfig.data = {
      event_no : event_no,
      edit_mode : false
    }
    var dialog_ref = this.dialog.open(EventViewComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res != null && res['needRefresh'] == true){
        this.loadAllData();
      }
    });
  }

  calculateDateDiff(date){
    let currentDate = new Date();
    date = new Date(date);

    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) ) /(1000 * 60 * 60 * 24));
}

}
