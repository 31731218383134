<div class="row" style="margin: 0 auto -15px -5px">
  <h2 class="window-title">Basic Data</h2>
</div>
<mat-card>
    <mat-tab-group animationDuration="0ms" mat-align-tabs="center">
        <mat-tab label="Designations">
          <ng-template matTabContent>
            <app-designations></app-designations>
          </ng-template>
        </mat-tab>
        <mat-tab label="Titles">
          <ng-template matTabContent>
            <app-titles></app-titles> 
          </ng-template>
        </mat-tab>
        <mat-tab label="Departments">
          <ng-template matTabContent>
            <app-sections></app-sections> 
          </ng-template>
        </mat-tab>
        <mat-tab label="Request Categories">
          <ng-template matTabContent>
            <app-request-categories></app-request-categories> 
          </ng-template>
        </mat-tab>
        <mat-tab label="Agreement Categories">
          <ng-template matTabContent>
            <app-agreement-category></app-agreement-category> 
          </ng-template>
        </mat-tab>
        <!-- <mat-tab label="Labels">
          <ng-template matTabContent>
            <app-labels></app-labels> 
          </ng-template>
        </mat-tab> -->
    </mat-tab-group>
</mat-card>
