<div class="window-title">
    <h2>Event List</h2>
</div>
<div class="search-div">
    <mat-paginator [pageSizeOptions]="[5,10,25,50,100]" [pageSize]="50" showFirstLastButtons>
    </mat-paginator>
    <div style="margin-top:25px">
        <mat-form-field class="search-form-field" floatLabel="never">
            <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off" (keyup)="applyFilter()">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <button mat-button (click)="onClickUpcoming()">
            <mat-icon *ngIf="state=='upcoming'">visibility</mat-icon> Upcomming
        </button>
        <button mat-button (click)="onClickOngoing()">
            <mat-icon *ngIf="state=='ongoing'">visibility</mat-icon> Ongoing
        </button>
        <button mat-button (click)="onClickPast()">
            <mat-icon *ngIf="state=='past'">visibility</mat-icon> Past
        </button>
        <button mat-button (click)="onClickAll()">
            <mat-icon *ngIf="state=='all'">visibility</mat-icon> All
        </button>
        <!-- <button mat-button (click)="onClickCancel()">
            <mat-icon *ngIf="state=='4'">visibility</mat-icon> Canceled
        </button> -->
    </div>
</div>
<div class="table-wrapper">
    <mat-table [dataSource]="eventData" matSort>
        <ng-container matColumnDef="event_occur_id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Event ID</mat-header-cell>
            <mat-cell class="clickOn" *matCellDef="let row" (click)="showRecord(row.occurrence_id)">
                {{row.occurrence_id}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="event_name">
            <mat-header-cell *matHeaderCellDef>Event Name</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.event_name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="event_type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Event Category</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.event_category}}</mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="remaining_days">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Remaining Days</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.state == 'Completed'? 'N/A' : row.state == 'onClickCancel'? 'N/A' : row.remaining_days}}</mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="event_category">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.event_type}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="start_date">
            <mat-header-cell *matHeaderCellDef>Start Date</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.start_date}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="end_date">
            <mat-header-cell *matHeaderCellDef>End Date</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.end_date}}</mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="occurrence">
            <mat-header-cell *matHeaderCellDef>Occurrence</mat-header-cell>
            <mat-cell *matCellDef="let row">                        
                {{row.occurrence}}
            </mat-cell>
        </ng-container> -->
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
</div>