//#region imports
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { RequestStatusService } from './request-status.service';
import { ToastrService } from 'ngx-toastr';
import { FileUploadServiceService } from '../../services/file-upload.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent } from '@angular/material';
import { COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { AssignmentGroup } from '../new-request/new-request.component';
import { element } from 'protractor';
//#endregion

//#region interfaces
export interface Assignee {
  user_id: string;
  user_name: string;
  first_name: string;
  last_name: string;
  profile_url: string;
}

export interface Division {
  division_id: string;
  division_name: string;
  division_description: string;
  active: string;
}

export interface AassignmentGroup{
  group_id : string;
  group_name: string;
}
//#endregion

@Component({
  selector: 'app-request-status',
  templateUrl: './request-status.component.html',
  styleUrls: ['./request-status.component.css']
})

export class RequestStatusComponent implements OnInit {

  @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  //#region class variables
  requestId: string;
  created: string;
  requester: string;
  requesterNick: string;
  requesterMobile: string;
  requesterAddress: string;
  // requesterDivision: string;
  // electoralDivision: string;
  // divisionalSecretary: string;
  requesterBackground: string;
  topic: string;
  description: string;
  attachments: object[] = [];
  files: File[] = [];
  comments: any;
  historyRecords: any = null;
  priority: string;
  state: string;
  stateSet: any;
  assignedDate: string;
  comment: string = "";
  isCustVisible : boolean = false;
  // sections: any;
  // section_id: string;
  // section_name: string;
  categories: any;
  category_id: string;
  category_name: string;
  progress_bar: boolean = false;

  assignee_id: string;
  assignee_url: string;
  assignee = new FormControl();
  assigneeList: Assignee[] = [];
  filteredAssignees: Observable<Assignee[]>;

  assignment_group_id:string;
  assignment_group_name:string;
  assignment_group = new FormControl();
  assignment_group_list :AassignmentGroup[] = [];
  filteredAssignmentGroups: Observable<AassignmentGroup[]>;

  // serviceAreaId: string;
  // serviceArea = new FormControl();
  // serviceAreasList: Division[] = [];
  // filteredServiceAreaList: Observable<Division[]>;

  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, SPACE, COMMA];
  labelCtrl = new FormControl();
  filteredLabels: Observable<string[]>;
  labels: string[] = ['Lemon', 'Lime'];
  allLabels: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];

  editPriority: boolean = false;
  showEditPriority: boolean = false;
  editSection: boolean = false;
  showEditSection: boolean = false;
  editCategory: boolean = false;
  showEditCategory: boolean = false;
  editServiceArea: boolean = false;
  showEditServiceArea: boolean = false;
  editAssignee: boolean = false;
  showEditAssignee: boolean = false;
  editLabels: boolean = false;
  showEditLabels: boolean = false;

  editAssignmentGroup: boolean = false;
  showEditAssignmentGroup: boolean = false;

  letterList: any;
  showLetterBtn: boolean = false;
  showNewLetterBtn: boolean = false;
//#endregion

//#region constructor
  constructor(
    public dialogRef: MatDialogRef<RequestStatusComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private requestStatusService : RequestStatusService,
    private toastr               : ToastrService,
    private fileUploadService    : FileUploadServiceService,
    private confDialog           : MatDialog,
    private router               : Router)
  {
    this.requestId = data.ref_no;
    //this.setAssigneeFilter();
  }
  //#endregion

  ngOnInit() {
    this.loadAttachments();
    this.loadStateList();
    this.loadComments();
    this.loadRequestData();
    this.getLetterIdList();
    this.setAssigneeFilter();
    this.setLabelFilter();
    //this.loadHistoryRecords();
  }

  //#region Load Request Data
  loadRequestData() {
    this.requestStatusService.getRequestDetails(this.requestId).subscribe(res => {
      var details = res[0];
      this.topic = details['topic'];
      this.description = details['request'];
      this.requester = details['name_in_full'];
      this.requesterNick = details['name_in_use'];
      this.requesterMobile = details['mobile_no'];
      this.requesterAddress = details['address'];
      // this.requesterDivision = details['division'];
      this.requesterBackground = details['background'];
      this.created = details['created_date'];
      this.state = details['state'];
      this.priority = details['priority'];;
      this.assignedDate = details['assigned_date'];
      this.assignment_group_id = details['as_group_id'];
      this.assignment_group_name = details['as_group_name'];
      // this.section_id = details['section_id'];
      // this.section_name = details['section_name'];
      this.category_id = details['category_id'];
      this.category_name = details['category_name'];
      this.assignee_id = details['assignee_id'];
      this.assignee_url = details['assignee_url'];
      this.assignee.setValue(details['assignee']);
      // this.divisionalSecretary = details['div_sec_name'];
      // this.electoralDivision = details['electoral_division'];
      // this.serviceAreaId = details['service_area'];
      // if(details['service_area_id']){
      //   this.serviceArea.setValue(details['service_area_id'] + ' - ' + details['service_area_name']);
      // }
      //load categories based on current section
      this.loadCategories();
    });
  }
//#endregion

//#region Load Data
  loadAttachments() {
    this.requestStatusService.getAttachments(this.requestId).subscribe(res => {
      if (res[0] !== "no_attachments") {
        for (var val of res as any[]) {
          var value = val['file_path'];
          var name = val['file_name'];
          this.attachments.push({ "link": value, "name": name });
        }
      }
    });
  }

  loadStateList() {
    this.requestStatusService.getStatusList().subscribe(res => {
      this.stateSet = res;
    });
  }

  loadAssigneeList() {
    this.requestStatusService.getAssigneeList().subscribe(res => {
      if(res != null){
        res.forEach(element => {
          this.assigneeList.push(element);
        });
      }
      this.setAssigneeFilter();
    });
  }

  loadGroupList(){
    this.requestStatusService.getGroupList().subscribe(res => {
      if(res != null){
        res.forEach(element => {
          this.assignment_group_list.push(element);
        });
      }
      this.setAssignmentGroupFilter();
    })
  }

  // loadServiceAreaList() {
  //   this.requestStatusService.getServiceAreasList().subscribe(res => {
  //     if(res != null){
  //       res.forEach(element => {
  //         this.serviceAreasList.push(element);
  //       });
  //     }
  //     this.setServiceAreaFilter();
  //   });
  // }

  loadLabels(){

  }
  //#endregion

  //#region Set Filters
  setAssigneeFilter(){
    this.filteredAssignees = this.assignee.valueChanges
    .pipe(
      startWith(''),
      map(assignee => assignee ? this._filter_assignee(assignee) : this.assigneeList.slice())
    );
  }

  setAssignmentGroupFilter(){
    this.filteredAssignmentGroups = this.assignment_group.valueChanges.pipe(
      map(assignment_group => assignment_group ? this._filtered_assignment_group(assignment_group): this.assignment_group_list.slice())
    )
  }
  // setServiceAreaFilter(){
  //   this.filteredServiceAreaList = this.serviceArea.valueChanges
  //   .pipe(
  //     startWith(''),
  //     map(serviceArea => serviceArea ? this._filter_service_area(serviceArea) : this.serviceAreasList.slice())
  //   );
  // }

  setLabelFilter(){
    this.filteredLabels = this.labelCtrl.valueChanges.pipe(
      startWith(null),
      map((label: string | null) => label ? this._filter_label(label) : this.allLabels.slice()));
  }

  //Use to fiter the assignee list
  private _filter_assignee(assignee: string): Assignee[] {
    const filterValue = assignee.toLowerCase();
    return this.assigneeList.filter(assignee => (assignee.first_name.toLowerCase().indexOf(filterValue) === 0)||
    (assignee.last_name.toLowerCase().indexOf(filterValue) === 0)||
    ((assignee.first_name+' '+assignee.last_name).toLowerCase().indexOf(filterValue) === 0));
  }

  private _filtered_assignment_group(assignment_group:string): AassignmentGroup[]{
    const filterValue = assignment_group.toLowerCase();
    return this.assignment_group_list.filter(assignment_group => assignment_group.group_name.toLocaleLowerCase().indexOf(filterValue)===0)
  }

  // private _filter_service_area(serviceArea: string): Division[] {
  //   const filterValue = serviceArea.toLowerCase();
  //   return this.serviceAreasList.filter(
  //     (serviceArea) =>
  //     serviceArea.division_id.toLowerCase().indexOf(filterValue) === 0 ||
  //     serviceArea.division_name.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  // loadSectionList() : any {
  //   this.requestStatusService.getSectionList().subscribe(res => {
  //     this.sections = res;
  //     return true;
  //   });
  // }

  loadCategories() {

      this.requestStatusService.getCategory().subscribe(res => {
        this.categories = res;
      });
  }

  loadComments() {
    this.requestStatusService.getComments(this.requestId).subscribe(res => {
      this.comments = res;
    })
  }

  loadHistoryRecords() {
    this.requestStatusService.getHistoryLog(this.requestId).subscribe(res => {
      this.historyRecords = res;
    })
  }
//#endregion


  getLetterIdList() {
    this.requestStatusService.getLetterList(this.requestId).subscribe(res => {
      this.letterList = res;
      if(this.letterList.length > 0){
        this.showLetterBtn = true;
      }
      else{
        this.showNewLetterBtn = true;
      }
    })
  }

  //#region On Button Click
  onStateBtn1Click(){
    var state;
    if (this.state == 'New') { state = 'In Progress' }
    else if (this.state == 'In Progress') { state = 'Resolved' }
    else if (this.state == 'Pending') { state = 'In Progress' }   
    else if (this.state == 'Pending Approval') { state = 'Approved' }
    else if (this.state == 'Approved') { state = 'Resolved' }
    else if (this.state == 'Resolved') { 
      const title = 'Close Confirmation';
      const message = 'Are you sure you want to close this request?';
      const dialogconfig = new MatDialogConfig();
      dialogconfig.disableClose = false;
      dialogconfig.autoFocus= true;
      dialogconfig.maxWidth = "400px";
      dialogconfig.data = {
        title : title,
        message : message
      }
      var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
      dialog_ref.afterClosed().subscribe(res=>{
        if(res){
          state = 'Closed';
          this.setState(state);
        }
      });   
    }

    this.setState(state);
  }

  onStateBtn2Click(){
    var state;
    if (this.state == 'In Progress') { state = 'Pending Approval' }
    else if (this.state == 'Pending') { state = 'Canceled' }
    else if (this.state == 'Pending Approval') { state = 'Rejected' }
    else if (this.state == 'Resolved') { state = 'In Progress' }
    else if (this.state == 'Rejected') { state = 'Canceled' }
    this.setState(state);
  }
//#endregion

//#region Set Data
  setPriority() {
    this.requestStatusService.setPriority(this.requestId, this.priority).subscribe(res => {
      if(res['status']=="success"){
        this.editPriority = false;
        this.toastr.success('Ticket priority changed','Success');
      }
    });
  }

  setState(state: string) {
    var selected = (this.stateSet.find(element => element.state_name === state)).id;
    this.requestStatusService.setStatus(this.requestId, selected).subscribe(res => {
      this.state = state;
      this.toastr.success('Ticket state changed to : ' + state, 'Success');
    });
  }

  setAssignee() {
    this.requestStatusService.setAssignee(this.requestId, this.assignee_id).subscribe(res => {
      if(res['status']=="success"){
        //this.loadRequestData();
        this.editAssignee = false;
        this.toastr.success('Assignee changed', 'Success');
      }
    });
  }

  setGroup(){
    this.requestStatusService.setGroup(this.requestId, this.assignment_group_id).subscribe(res => {
      if(res['status']=="success"){
        //this.loadRequestData();
        this.editAssignmentGroup = false;
        this.assignee.setValue("");
        this.toastr.success('Assignment Group changed', 'Success');
      }
    });
  }

  // setServiceArea() {
  //   this.requestStatusService.setServiceArea(this.requestId, this.serviceAreaId).subscribe(res => {
  //     if(res['status']=="success"){
  //       this.editServiceArea = false;
  //       this.toastr.success('Service area changed', 'Success');
  //     }
  //   });
  // }

  setComment() {
    this.requestStatusService.setComment(this.requestId, this.comment,String(this.isCustVisible)).subscribe(res => {
      this.loadComments();
      this.toastr.success('Comment added', 'Success');
      this.comment = "";
    });
  }

  // setSection() {
  //   var section_name = (this.sections.find(element => element.section_id === this.section_id)).section_name;
  //   this.requestStatusService.setSection(this.requestId, this.section_id).subscribe(res => {
  //     if(res['status']=='success'){
  //       this.section_name = section_name;
  //      // this.loadCategories(); //load categories based on current section
  //       this.editSection = false;
  //       this.toastr.success('Section Changed', 'Success');
  //     }
  //     else{
  //       this.toastr.error('Failed to change section', 'Error');
  //     }
  //   })
  // }

  setCategory() {
    var category_name = (this.categories.find(element => element.cat_id === this.category_id)).cat_name;
    this.requestStatusService.setCategory(this.requestId, this.category_id).subscribe(res => {
      console.log("Status : "+ (res['status']));
      if(res['status']=='success'){
        this.category_name = category_name;
        this.editCategory = false;
        this.toastr.success('Category Changed', 'Success');
        this.loadRequestData();
      }
      else{
        this.toastr.error('Failed to change category', 'Error');
      }
    })
  }

  setLabels(){

    //NEED TO IMPLEMENT...
    this.editLabels = false;
  }
  //#endregion

  //#region Manage File Upleads
  onFileAdd(files){
    this.progress_bar = true;
    // this.files.push(...files);
    this.fileUploadService.fileUpload(this.requestId, files, "request", "post_upload").subscribe(
      res => {
        if(res != null){
          res.forEach(element => {
            var value = element["file_path"];
            var name = element["file_name"];
            this.attachments.push({ "link": value, "name": name });
          });
          this.progress_bar = false;
        }else{
          this.toastr.error('File uploadin failed', 'Error');
          this.progress_bar = false;
        }
      }
    );
  }

  onFileRemove(event){
    const title = 'Delete Confirmation';
    const message = 'Are you sure you want to delete this file?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        var index_no = this.attachments.indexOf(event);
        var attachment = this.attachments[index_no];
        this.fileUploadService.fileRemove(this.requestId, attachment['name'], attachment['link'], "request", "post_remove").subscribe(
          res => {
            if(res['status']=='success'){
              this.attachments.splice(index_no, 1);
              this.toastr.success('File removed from request', 'Success');
            }
            else{
              this.toastr.error('Failed to remove file', 'Error');
            }
          },
          err => console.log(err)
        );
      }
    });
  }
//#endregion


  onTabChange(event){
    if(event.index == 1){
      //Load activity log when click history tab only.
      this.loadHistoryRecords();
    }
  }

  openLetterWriter(value) {
    localStorage.removeItem("req_id");
    localStorage.removeItem("letter_id");
    localStorage.removeItem("letter_state");
    localStorage.removeItem("section_id");
    localStorage.removeItem("category_id");
    localStorage.setItem("req_id", this.requestId);
    // localStorage.setItem("section_id", this.section_id);
    // localStorage.setItem("category_id", this.category_id);
    if(value=='new'){
      localStorage.setItem("letter_id", this.requestId + '-L' + (this.letterList.length+1));
      localStorage.setItem("letter_state", "new");
    }
    else{
      localStorage.setItem("letter_id", value);
      localStorage.setItem("letter_state", "exist");
    }
    window.open('/#/letter_pad', '_blank');
  }

  //#region Methods related for label selections
  /*---------------Methods related for label selections--------------*/
  add(event: MatChipInputEvent): void {
    // Add label only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.labels.push(value.trim());
      }
      // Reset the input value
      if (input) {
        input.value = '';
      }
      this.labelCtrl.setValue(null);
    }
  }

  remove(label: string): void {
    const index = this.labels.indexOf(label);

    if (index >= 0) {
      this.labels.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.labels.push(event.option.viewValue);
    this.labelInput.nativeElement.value = '';
    this.labelCtrl.setValue(null);
  }

  private _filter_label(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allLabels.filter(label => label.toLowerCase().indexOf(filterValue) === 0);
  }

  onLabelClick(){
    console.log("&&&&&&&&&&&&&&");
   // this.router.navigate(['/progress',{id: 'HelloDhana'}]); 
   this.dialogRef.close()
    this.router.navigate(['/progress'],{queryParams: {label:'HelloDhana'}});
  }


  /*---------------Methods related for label selections---END---------------*/
//#endregion

}