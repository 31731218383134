import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './sidenav.service';
import { INotification, NotificationService } from '../services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { RequestStatusComponent } from '../request/request-status/request-status.component';
import { AgreementStatusComponent } from '../agreement/agreement-status/agreement-status.component';
interface NavItem {
  displayName: string;
  disabled?: boolean;
  iconName: string;
  route?: string;
  children?: NavItem[];
}

const super_user_menu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'home',
    route: '/dashboard',
  },
  {
    displayName: 'Request',
    iconName: 'how_to_vote',
    children: [
      {
        displayName: 'New Request',
        iconName: '',
        route: '/new_request'
      },
      {
        displayName: 'Progress',
        iconName: '',
        route: '/progress'
      }
    ]
  },
  {
    displayName: 'Agreement',
    iconName: 'business_center',
    children: [
      {
        displayName: 'New Agreement',
        iconName: '',
        route: '/agreement'
      },
      {
        displayName: 'Progress',
        iconName: '',
        route: '/arg_progress'
      }
    ]
  },
  {
    displayName: 'Appoinment',
    iconName: 'event_available',
    children: [
      {
        displayName: 'New Appoinment',
        iconName: '',
        route: '/new_event'
      },
      {
        displayName: 'Appoinment Calender',
        iconName: '',
        route: '/event_calender'
      },
      {
        displayName: 'Appoinment List',
        iconName: '',
        route: '/event_list'
      }
    ]
  },
  {
    displayName: 'Collaboration',
    iconName: 'contact_mail',
    children: [
      {
        displayName: 'Communication',
        iconName: '',
        route: '/communication'
      },
      {
        displayName: 'Group List',
        iconName: '',
        route: '/group_list'
      }
    ]
  },
  {
    displayName: 'Requesters',
    iconName: 'perm_contact_calender',
    route: '/requester',
  },
  {
    displayName: 'Users',
    iconName: 'group',
    route: '/users',
  },
  {
    displayName: 'Basic Data',
    iconName: 'layers',
    route: '/basicData',
  }
];

const admin_menu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'home',
    route: '/dashboard',
  },
  {
    displayName: 'Request',
    iconName: 'how_to_vote',
    children: [
      {
        displayName: 'New Request',
        iconName: '',
        route: '/new_request'
      },
      {
        displayName: 'Progress',
        iconName: '',
        route: '/progress'
      }
    ]
  },
  {
    displayName: 'Agreement',
    iconName: 'business_center',
    children: [
      {
        displayName: 'New Agreement',
        iconName: '',
        route: '/agreement'
      },
      {
        displayName: 'Progress',
        iconName: '',
        route: '/arg_progress'
      }
    ]
  },
  {
    displayName: 'Appoinment',
    iconName: 'business_center',
    children: [
      {
        displayName: 'New Appoinment',
        iconName: '',
        route: '/new_event'
      },
      {
        displayName: 'Appoinment Calender',
        iconName: '',
        route: '/event_calender'
      },
      {
        displayName: 'Appoinment List',
        iconName: '',
        route: '/event_list'
      }
    ]
  },
  {
    displayName: 'Requesters',
    iconName: 'perm_contact_calender',
    route: '/requester',
  },
  {
    displayName: 'Users',
    iconName: 'group',
    route: '/users',
  },
  {
    displayName: 'Basic Data',
    iconName: 'layers',
    route: '/basicData',
  }
];

const full_filler_menu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'home',
    route: '/dashboard',
  },
  {
    displayName: 'Request',
    iconName: 'how_to_vote',
    children: [
      {
        displayName: 'New Request',
        iconName: '',
        route: '/new_request'
      },
      {
        displayName: 'Progress',
        iconName: '',
        route: '/progress'
      }
    ]
  },
  {
    displayName: 'Agreement',
    iconName: 'business_center',
    children: [
      {
        displayName: 'New Agreement',
        iconName: '',
        route: '/agreement'
      },
      {
        displayName: 'Progress',
        iconName: '',
        route: '/arg_progress'
      }
    ]
  },
  {
    displayName: 'Appoinment',
    iconName: 'business_center',
    children: [
      {
        displayName: 'New Appoinment',
        iconName: '',
        route: '/new_event'
      },
      {
        displayName: 'Appoinment Calender',
        iconName: '',
        route: '/event_calender'
      },
      {
        displayName: 'Appoinment List',
        iconName: '',
        route: '/event_list'
      }
    ]
  }
];

const app_user_menu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'home',
    route: '/dashboard',
  },
  {
    displayName: 'Request',
    iconName: 'how_to_vote',
    children: [
      {
        displayName: 'New Request',
        iconName: '',
        route: '/new_request'
      }
    ]
  },
  {
    displayName: 'Agreement',
    iconName: 'business_center',
    children: [
      {
        displayName: 'New Agreement',
        iconName: '',
        route: '/agreement'
      }
    ]
  },
  {
    displayName: 'Appoinment',
    iconName: 'business_center',
    children: [
      {
        displayName: 'Event Calender',
        iconName: '',
        route: '/event_calender'
      }
    ]
  }
];

@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css']
})
export class SidenavbarComponent implements OnInit {

  @ViewChild('sidenav') public sidenav: MatSidenav;
  @ViewChild('notifyPanel') public notifyPanel: MatSidenav;
  menu: NavItem[]
  isLoggedIn$: Observable<boolean>;
  isShowHeader$: Observable<boolean>;
  isAuthorized: boolean;
  userRole: string;
  username: string;
  userId: string;
  userImageUrl: string;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  notifications: INotification[] = [];
  notificationCount: number = 0;
  audio: any = new Audio();
  notifyAlertAudio: string = "../../assets/sounds/ios_notification.mp3";
  isFirstLoading: boolean = true;
  defaultImageUrl: string = '../../assets/images/user-default.png';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _auth: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private sidenavService: SidenavService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private toastr: ToastrService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this._auth.userRole.subscribe(value => {
      this.userRole = value;
      if (this.userRole == '1') {
        this.menu = super_user_menu;
      }
      else if (this.userRole == '2') {
        this.menu = admin_menu;
      }
      else if (this.userRole == '3') {
        this.menu = full_filler_menu;
      }
      else if (this.userRole == '4') {
        this.menu = app_user_menu;
      }
    });
    this._auth.userDetails.subscribe(value => {
      this.username = value['username'];
      this.userImageUrl = value['imageUrl'];
      this.userId = value['userId'];
    });
  }

  ngOnInit() {
    this.isShowHeader$ = this._auth.isShowHeader;
    // this.menu = admin_menu;

    //  this.notificationService.notifications$.subscribe(msg => {
    //     console.log('Received message:', msg['alerts']); // Log the received message
    //     let data = msg['alerts'];
    //     if(data.length >0){
    //       data.forEach(element => {
    //         if(!element.closed){
    //           if(!this.notifications.some(ele => ele.notification_id == element.notification_id)){ //Do not get elements which are already selected
    //             this.notifications.unshift(element);
    //             if(!element.seen){
    //               this.notificationCount ++;
    //               if(!this.isFirstLoading){ //Avoid tost and tone when load application first time
    //                 this.playAudio();
    //                 this.openToast(element.header, element.message);
    //               }
    //             }
    //           }
    //         }
    //       });
    //       this.isFirstLoading = false;
    //     }
    //  });

    this.notificationService.notifications$.subscribe(
      (msg) => {
        //console.log('Received message:', msg);
        // let data = msg['alerts'];
        let data = msg;
        if (data && data.length > 0) {
          //console.log("Data Length : ", data.length);
          data.forEach((element) => {
            //console.log("Data Element", element);
            if (element.closed === 0) {
              //console.log("Data Element Processing :  ");
              if (!this.notifications.some((ele) => ele.notification_id == element.notification_id)) {
              this.notifications.unshift(element);
                    if (element.seen === 0) {
                      this.notificationCount++;
                      if (!this.isFirstLoading) {
                        this.playAudio();
                        this.openToast(element.header, element.message);
                      }
                    }
              }
            }
            else{
              console.log("element.closed: ",typeof element.closed);
            }
          });
          this.isFirstLoading = false;
        }
      },
      (error) => {
        console.error('WebSocket Error:', error);
      },
      () => {
        console.log('WebSocket connection closed');
        // You can handle WebSocket closure here if needed
      }
    );

  }
  onImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.defaultImageUrl;
  }
  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
    //this.sidenavService.setSidenav(this.notifyPanel);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logout(): void {
    this._auth.logoutMethod();
  }

  public seenNotifyIds: String[] = [];
  public closeNotifyIds: String[] = [];

  setNotifySeen() {
    if (this.notifyPanel.opened) {
      this.notificationCount = 0;
      this.notifications.forEach(element => {
        this.seenNotifyIds.push(element.notification_id);
      });

      this.notificationService.setNotifySeen(this.seenNotifyIds).subscribe(
        res => { },
        err => {
          this.toastr.error('Something went wrong!', 'Failed');
        }
      );
      this.seenNotifyIds = [];
    }
  }

  playAudio() {
    this.audio.src = this.notifyAlertAudio;
    this.audio.load();
    this.audio.play();
  }

  openToast(header: string, message: string) {
    this.toastr.info(message, header, {
      timeOut: 4000,
      positionClass: 'toast-top-right',
      closeButton: true,
    });
  }

  closeNotification(notification_id: string) {
    this.closeNotifyIds.push(notification_id);
    this.notificationService.closeNotification(this.closeNotifyIds).subscribe(
      res => {
        if (res['status'] == 'success') {
          var index_no = this.notifications.findIndex(ele => ele.notification_id == notification_id);
          this.notifications.splice(index_no, 1);
        }
      },
      err => {
        this.toastr.error('Something went wrong!', 'Failed');
      }
    );
  }

  showRecord(ref_number: string, module: string) {
    const dialogconfig = new MatDialogConfig();
    //dialogconfig.disableClose = true;
    dialogconfig.autoFocus = false;
    dialogconfig.width = "85%";
    dialogconfig.minWidth =
      "390px"; /*Set minimum width - Important for mobile widows*/

    if (module === "REQUEST") {
      dialogconfig.data = {
        ref_no: ref_number,
      };
      var dialog_req = this.dialog.open(RequestStatusComponent, dialogconfig);
      dialog_req.afterClosed().subscribe((res) => {
        //this.loadData();
      });
    }
    else if (module === "AGREEMENT") {
      dialogconfig.data = {
        agr_number: ref_number
      }
      var dialog_agr = this.dialog.open(AgreementStatusComponent, dialogconfig);
      dialog_agr.afterClosed().subscribe(res => {
        // this.loadData();
      });
    }





  }
}
