<div class="window-title">
    <h2>Agreement Progress</h2>
</div>
<div class="search-div">
    <mat-paginator [pageSizeOptions]="[5,10,25,50,100]" [pageSize]="50" showFirstLastButtons>
    </mat-paginator>
    <div style="margin-top:25px">
        <mat-form-field class="search-form-field" floatLabel="never">
            <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off" (keyup)="applyFilter()">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <button mat-button (click)="onClickNew()">
            <mat-icon *ngIf="state=='1'">visibility</mat-icon> New
        </button>
        <button mat-button (click)="onClickProcess()">
            <mat-icon *ngIf="state=='2'">visibility</mat-icon> In Progress
        </button>
        <button mat-button (click)="onClickCompleted()">
            <mat-icon *ngIf="state=='3'">visibility</mat-icon> Closed
        </button>
        <button mat-button (click)="onClickAll()">
            <mat-icon *ngIf="state=='0'">visibility</mat-icon> All
        </button>
        <button mat-button (click)="onClickCancel()">
            <mat-icon *ngIf="state=='4'">visibility</mat-icon> Canceled
        </button>
    </div>
</div>
<div class="table-wrapper">
    <mat-table [dataSource]="agreementData" matSort>
        <ng-container matColumnDef="agr_id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Agreement ID</mat-header-cell>
            <mat-cell class="clickOn" *matCellDef="let row" (click)="showRecord(row.agr_id)">
                {{row.agr_id}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="agr_topic">
            <mat-header-cell *matHeaderCellDef >Topic</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.agr_topic}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="agr_date">
            <mat-header-cell *matHeaderCellDef>Agreement Date</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.agr_date}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="due_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.due_date}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="remaining_days">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Remaining Days</mat-header-cell>
            <!-- <mat-cell *matCellDef="let row">{{row.remaining_days}}</mat-cell> -->
            <mat-cell *matCellDef="let row">{{row.state == 'Closed'? 'N/A' : row.state == 'onClickCancel'? 'N/A' : row.remaining_days}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="priority">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Priority</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <img alt width="16" height="16" 
                                        [src]="row.priority == '1'? '../../assets/images/critical.svg':
                                        row.priority == '2'? '../../assets/images/major.svg':
                                        row.priority == '3'? '../../assets/images/minor.svg':
                                        row.priority == '4'? '../../assets/images/trivial.svg': null">
                {{row.priority == '1'? 'One':
                row.priority == '2'? 'Two':
                row.priority == '3'? 'Three':
                row.priority == '4'? 'Four': 'Null'}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.category}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
            <!-- <mat-cell *matCellDef="let request">{{request.status}}</mat-cell> -->
            <mat-cell *matCellDef="let row">           
                <span class="badge badge-danger" 
                [ngClass]="{'badge-secondary': row.state == 'New', 
                'badge-warning': row.state == 'In Progress',
                'badge-success': row.state == 'Completed', 
                'badge-danger': row.state == 'Canceled'}"> 
                    {{row.state}} 
                </span>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
</div>