import { Component, OnInit, ViewChild } from '@angular/core';
import { ProgressService } from './progress.service';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { RequestStatusComponent } from '../request-status/request-status.component';
import { AuthService } from "../../services/auth.service";
import { PermissionService } from '../../services/permissions.service';
import { FormControl } from '@angular/forms';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { ExportConfirmationDlgComponent } from './export-confirmation-dlg/export-confirmation-dlg.component';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

export interface iRequest {
  ref_no: string;
  topic: string;
  name_in_use: string;
  created_date: string;
  assigned_user: string;
  assigned_by: string;
  state: string;
}

@Component({
  selector: "app-progress",
  templateUrl: "./progress.component.html",
  styleUrls: ["./progress.component.css"],
})
export class ProgressComponent implements OnInit {
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatTableExporterDirective,{static:false}) exporter: MatTableExporterDirective;

  // tableColumns: string[] = ['ref_no',  'name_in_full', 'created_date', 'section', 'category', 'service_area', 'priority', 'assigned_user', 'assigned_by', 'state',
  // 'nic', 'mobile_no', 'address', 'email', 'division', 'electoral_div', 'div_sec']; // These attributes only for export purpose
  
  //tableColumns: string[] = ['ref_no', 'topic', 'name_in_full', 'created_date', 'section', 'category', 'service_area', 'priority', 'assigned_user', 'assigned_by', 'state'];
  tableColumns: string[] = ['ref_no', 'topic', 'name_in_full', 'priority', 'state'];
  additionalColumns: string[] = ['nic', 'mobile_no', 'address', 'email','assigned_user', 'assigned_by', 'created_date'];
  
  requestData: any;
  searchKey: string;
  state: string = "1";
  showFilters: boolean = false;
  mobileView:boolean = false; //To identify mobile view to hide filters

  public reqGroupList: any;
  public filteredReqGroupList: any;
  public sectionList: any;
  public filteredSectionList: any;
  public assigneeList: any;
  public filteredAssigneeList: any;
  public labelList: any;
  public filteredLabelList: any;

  //Forms Controls related for search filters
  reqGroupFilterControl = new FormControl([]);
  sectionFilterControl = new FormControl([]);
  priorityFilterControl = new FormControl([]);
  assigneeFilterControl = new FormControl([]);
  labelFilterControl = new FormControl([]);

  constructor(
    private progressService : ProgressService, 
    private dialog          : MatDialog,
    private _auth           : AuthService,
    private permission      : PermissionService,
    private toastr          : ToastrService,
    private route           : ActivatedRoute) 
  {  
    console.log(this.route.snapshot.queryParamMap.get('label'));
   }

  ngOnInit() {
    this.loadData();

    if (window.screen.width < 360) { // 768px portrait
      this.mobileView = true;
    }
  }

  loadData() {
    if (this.permission.PERM_LOAD_ALL_REQUESTS) {
      this.loadAllData();
    } else {
      this.loadSelectedData(this._auth.getUserId);
    }
  }

  loadAllData() {
    this.progressService.progressList(this.state).subscribe(res => {
      this.requestData = new MatTableDataSource(res as iRequest[]);
      this.requestData.sort = this.sort;
      this.requestData.paginator = this.paginator;
    });
  }

  loadSelectedData(userId: string){
    this.progressService.progressSelectedList(this.state,userId).subscribe(res => {
      this.requestData = new MatTableDataSource(res as iRequest[]);
      this.requestData.sort = this.sort;
      this.requestData.paginator = this.paginator;
    })
  }

  //-----------Load filters related data-------------//
  loadReqGroupList() {
    this.progressService.getGroupList().subscribe(res => {
      if(res != null){
        this.reqGroupList = res;
        this.filteredReqGroupList = this.reqGroupList.slice();
      }     
    });
  }

  loadSectionList() {
    this.progressService.getSectionList().subscribe(res => {
      if(res != null){
        this.sectionList = res;
        this.filteredSectionList = this.sectionList.slice();
      }     
    });
  }

  loadAssigneeList() {
    this.progressService.getAssigneeList().subscribe(res => {
      if(res != null){
        this.assigneeList = res;
        this.filteredAssigneeList = this.assigneeList.slice();
      }     
    });
  }

  loadLabelList() {
    this.progressService.getLabelList().subscribe(res => {
      if(res != null){
        this.labelList = res;
        this.filteredLabelList = this.labelList.slice();
      }     
    });
  }

  loadRequestsBySearch() {
    this.progressService.getFilteredRequestsData(
                    this.state,
                    this.reqGroupFilterControl.value, 
                    this.sectionFilterControl.value,
                    this.priorityFilterControl.value,
                    this.assigneeFilterControl.value).subscribe(res => {
      this.requestData = null;
      this.requestData = new MatTableDataSource(res as iRequest[]);
      this.requestData.sort = this.sort;
      this.requestData.paginator = this.paginator;
    });
  }
  //-------------------------------------------------------------//

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter() {
    this.requestData.filter = this.searchKey.trim().toLowerCase();
  }

  onClickStateFilter(state: string) {
    this.state = state;
    this.loadData();
    this.resetFilters();
  }

  showRecord(ref_number: string) {
    const dialogconfig = new MatDialogConfig();
    //dialogconfig.disableClose = true;
    dialogconfig.autoFocus = false;
    dialogconfig.width = "85%";
    dialogconfig.minWidth =
      "390px"; /*Set minimum width - Important for mobile widows*/
    dialogconfig.data = {
      ref_no: ref_number,
    };
    var dialog_ref = this.dialog.open(RequestStatusComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe((res) => {
      //this.loadData();
      this.loadRequestsBySearch();
    });
  }

  onClickShowFilters(){
    if(this.showFilters){
      this.showFilters = false;  
    }
    else{
      this.showFilters = true;
      this.loadReqGroupList();
      this.loadSectionList();
      this.loadAssigneeList();
    }
  }

  search(){
    this.loadRequestsBySearch();
  }

  resetFilters(){
    this.reqGroupFilterControl = new FormControl([]);
    this.sectionFilterControl = new FormControl([]);
    this.priorityFilterControl = new FormControl([]);
    this.assigneeFilterControl = new FormControl([]);
  }

  onReqCategoryRemoved(topping: string) {
    const groups = this.reqGroupFilterControl.value as string[];
    this.removeFirst(groups, topping);
    this.reqGroupFilterControl.setValue(groups); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  onClickExport(){
    var filteredReqIds: String[] = [];
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "420px";
    dialogconfig.data = {}
    var dialog_ref = this.dialog.open(ExportConfirmationDlgComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res['setComment'] == "true"){
        this.requestData.filteredData.forEach(element => {
          filteredReqIds.push(element.ref_no);

        });

        this.progressService.setCommentForExportList(filteredReqIds, res['comment']).subscribe(res => {
          if(res != null){
            if(res['status']=='success'){
              this.toastr.success('Comment added for exported list', 'Success');
            }
            else{
              this.toastr.error('Comment adding failed', 'Error');
            }
          }
        });  
      }

      if(res['confirm'] == true){
        if(res['exportType'] == 'excel'){
          this.exporter.exportTable('xlsx', {fileName:'test', sheet: 'sheet_name', Props: {Author: 'Weeari'}});
        }
        else if(res['exportType'] == 'svc'){
          this.exporter.exportTable('csv');
        }

      }
    });  
  }

  getCheckboxValues(ev, columnItem) {
    if(ev.checked){
      this.tableColumns.push(columnItem);

    }else {
      let element = this.tableColumns.find(item => item===columnItem);
      
      if(element)
        this.tableColumns.splice(this.tableColumns.indexOf(element),1);
    }
  }
}
