import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const URL_get_events_list = environment.url_blogics + '/event/get_event_list.php';
const URL_change_event_time = environment.url_blogics + '/event/change_event_time.php';
const URL_delete_event = environment.url_blogics + '/event/delete_event.php';

@Injectable({
  providedIn: 'root'
})

export class EventCalenderService {

  constructor(private _http: HttpClient) { }

  getEventList() : Observable<any> {
    return this._http.get(URL_get_events_list);
  }

  changeEventTime(event_occur_id, new_start, new_end){
    return this._http.post(URL_change_event_time, JSON.stringify({event_occur_id, new_start, new_end}));
  }

  deleteEvent(event_occur_id: string | number, option: string){
    let params = new HttpParams();
    params = params.append('event_occur_id',event_occur_id.toString());
    if(option != null){
      params = params.append('option',option);
    }
    return this._http.get(URL_delete_event, {params: params});
  }

}
