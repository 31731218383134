import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const URL_agreement_details = environment.url_blogics + '/agreement/get_agreement_by_id.php';
const URL_get_category_list = environment.url_sfeatures + '/basic-data/agreement-category/get_agr_category_list.php';
const URL_get_state_list = environment.url_sfeatures + '/get_state_list.php';
const URL_get_user_list = environment.url_blogics + '/progress/get_assignee_list.php';
const URL_get_attachments = environment.url_blogics + '/agreement/get_agr_attachment_by_id.php';
const URL_get_comments = environment.url_blogics + '/agreement/get_agr_comments.php';
const URL_change_priority = environment.url_blogics + '/agreement/change_priority.php';
const URL_change_state = environment.url_blogics + '/agreement/change_state.php';
const URL_change_category = environment.url_blogics + '/agreement/change_category.php';
const URL_change_due_date = environment.url_blogics + '/agreement/change_due_date.php';
const URL_change_followup_by = environment.url_blogics + '/agreement/change_followup_user.php';
// const URL_change_division = environment.url_blogics + '/agreement/change_division.php';
const URL_put_comment = environment.url_blogics + '/agreement/leave_agr_comment.php';
const URL_get_division_list = environment.url_sfeatures+'/basic-data/get_division_list.php';

@Injectable({
  providedIn: 'root'
})
export class AgreementStatusService {

  constructor(private httpclient: HttpClient) { }

  getAgreementDetails(agr_no: string) {
    return (this.httpclient.post(URL_agreement_details, JSON.stringify({ "agr_no": agr_no })))
  }

  getStateList() {
    return (this.httpclient.post(URL_get_state_list, JSON.stringify({})));
  }

  getCategoryList() {
    return (this.httpclient.post(URL_get_category_list, JSON.stringify({})))
  }

  getUserList(): Observable<any> {
    return (this.httpclient.post(URL_get_user_list, JSON.stringify({})))
  }

  getDivisionList(): Observable<any>{
    return this.httpclient.get(URL_get_division_list);
  }

  changePriority(agr_no: string, priority: string) {
    return (this.httpclient.post(URL_change_priority, JSON.stringify({"agr_no": agr_no, "priority": priority })));
  }

  changeState(agr_no: string, state: string) {
    return (this.httpclient.post(URL_change_state, JSON.stringify({"agr_no": agr_no, "state": state })));
  }

  changeCategory(agr_no: string, category: string) {
    return (this.httpclient.post(URL_change_category, JSON.stringify({"agr_no": agr_no,  "agr_category_id": category, })));
  }

  changeDueDate(agr_no: string, due_date: string) {
    return (this.httpclient.post(URL_change_due_date, JSON.stringify({"agr_no": agr_no, "due_date": due_date })));
  }

  changeFollowupUser(agr_no: string, user: string) {
    return (this.httpclient.post(URL_change_followup_by, JSON.stringify({"agr_no": agr_no, "followup_by": user })));
  }

  // changeDivision(agr_no: string, division: string) {
  //   return (this.httpclient.post(URL_change_division, JSON.stringify({"agr_no": agr_no, "division_id": division })));
  // }

  setComment(agr_no: string, comment: string) {
    return this.httpclient.post(URL_put_comment, JSON.stringify({"agr_no": agr_no, "comment": comment}));
  }

  getAttachments(agr_no: string) {
    return this.httpclient.post(URL_get_attachments, JSON.stringify({"agr_no": agr_no }));
  }

  getComments(agr_no: string) {
    let params = new HttpParams();
    params = params.append('agr_no',agr_no);
    return this.httpclient.get(URL_get_comments, {params: params});
  }
}
