import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const URL_get_requests_num_data = environment.url_blogics+'/dashboard/get_request_counts.php';
const URL_get_recent_new_requests = environment.url_blogics+'/dashboard/get_recent_new_requests.php';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpclient: HttpClient) { }

  getRequestsNumData(){
    return (this.httpclient.get(URL_get_requests_num_data)); 
  }

  getRecentNewRequestCounts(): Observable<any>{
    return (this.httpclient.get(URL_get_recent_new_requests)); 
  }

  // getTopNewRequests(){
  //   return (this.httpclient.get(URL_get_top_new_requests)); 
  // }
  // getTopInProgressRequests(){
  //   return (this.httpclient.get(URL)); 
  // }
  // getTopCompletedRequests(){
  //   return (this.httpclient.get(URL)); 
  // }

}
