import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

const URL_Email_API = environment.url_blogics + '/collaboration/communication/email_all.php';
//const URL_SMS_API = environment.url_blogics + '/communication/sms_gateway.php';
const URL_SMS_API = 'http://178.128.54.43:8100/prm/01_business-logics/communication/sms_gateway.php';
const URL_get_group_list = environment.url_blogics + '/collaboration/group_list/get_active_group_list.php';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(private httpclient: HttpClient) { }

  getGroupList(){
    return (this.httpclient.get(URL_get_group_list));
  }

  sendEmail(emailGroup, subject: string, content: string){
    return (this.httpclient.post(URL_Email_API, JSON.stringify({ "email_group": emailGroup,"email_sub": subject, "email_content": content })))
  }

  sendSMS(smsGroup: string, message: string){
    return (this.httpclient.post(URL_SMS_API, JSON.stringify({ "sms_group": smsGroup, "message": message, "mask": "Chamara U" , "campaign_name":"shanaka" })))
  }
  
}