<!-- <div>
  <span class="login-form-title">
    <img src="assets/images/app-logo.jpg" style="height: 130px; width: 350px;">
  </span>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="login_form" (ngSubmit)="onSubmit()">
        <p>
          <mat-form-field>
            <input type="text" matInput placeholder="Username" formControlName="username">
          </mat-form-field>
        </p>
        <p>
          <mat-form-field>
            <input type="password" matInput placeholder="Password" formControlName="password">
          </mat-form-field>
        </p>
        <div class="container-login-form-btn">
          <button class="login-form-btn" type="submit" mat-flat-button color="warn">Login</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<p class="background-text">Powered By Weeari Labs (Pvt) Ltd.</p> -->

<!-- =================================Following template related for Cus Roshan================================ -->

<div class="wrapper">
  <div class="image-holder">

  </div>
  <div class="form-inner">

    <mat-card-content>
      <h3>WORKIT REQUEST MANAGER</h3>
      <form [formGroup]="login_form" (ngSubmit)="onSubmit()">
        <mat-form-field>
          <input type="text" matInput placeholder="Username" formControlName="username">
        </mat-form-field>
        <br>
        <mat-form-field>
          <input type="password" matInput placeholder="Password" formControlName="password">
        </mat-form-field>
        <!-- <mat-form-field [hidden]="captcha">
          <re-captcha resolved($event) siteKey="6LeowFIpAAAAAOYVJb5ZtmMUjBUY2eXzB65nrUpx"></re-captcha>
        </mat-form-field> -->
        <div class="container-login-form-btn">
          <button class="login-form-btn" type="submit" mat-flat-button color="warn">Login</button>
        </div>
        <mat-form-field>
          <span style="text-align: left;color: #cb5e5d;">
            <p>{{LoginError}}</p>
          </span>
        </mat-form-field>
      </form>
    </mat-card-content>
  </div>
  <p class="background-text">Powered By Zilicobit Labs (Pvt) Ltd.</p>
</div>