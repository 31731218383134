import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ViewEventService } from './event-view.service';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { EditParticipantDlgComponent } from './edit-participant-dlg/edit-participant-dlg.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { PermissionService } from 'src/app/services/permissions.service';

export interface UserAttendee {
  user_id: string;
  user_name: string;
  first_name: string;
  last_name: string;
  profile_url: string;
}

export interface ReqAttendee {
  nic: string;
  name_in_full: string;
}

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.css']
})
export class EventViewComponent implements OnInit {

  event_occur_id: string;
  editMode: boolean = false;
  editBtnName: string = "Edit";

  assigneeId: string;
  assigneeList: UserAttendee[] = [];
  filteredAssignees: Observable<UserAttendee[]>;

  userAttendeeList: UserAttendee[] = [];
  filteredUserAttendees: ReplaySubject<UserAttendee[]> = new ReplaySubject<UserAttendee[]>(1);
  userAttendeeSearch = new FormControl();

  requesterAttendeeList: ReqAttendee[] = [];
  filteredReqAttendees: ReplaySubject<ReqAttendee[]> = new ReplaySubject<ReqAttendee[]>(1);
  reqAttendeeSearch = new FormControl();

  comments: any;
  comment: string = "";
  private _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<EventViewComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog            : MatDialog,
    private confDialog        : MatDialog,
    private _formBuilder      : FormBuilder,
    private viewEventService  : ViewEventService,
    private toastr            : ToastrService,
    private datePipe          : DatePipe,
    public  permission        : PermissionService) 
  {
    this.event_occur_id = data.event_no;
    //this.editMode = data.edit_mode;
    if(data.edit_mode){
      this.onEditCancel();
    }
    this.loadEventDetails();
     //this.loadAttendees();
    this.loadAssigneeList();
   }

  event_details = this.createEventDataFormBuilder();
  temp_event_details = this.createEventDataFormBuilder(); // For backup data when edit

  occurrence_details = this.createOccurenceDataFormBuilder();
  temp_occurrence_details = this.createOccurenceDataFormBuilder(); // For backup data when edit

  ngOnInit(): void 
  {  
    this.loadComments();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  createEventDataFormBuilder(){
    return this._formBuilder.group({
      event_no : [''],
      event_name : ['test', Validators.required],
      event_desc: ['', Validators.required],
      event_category: ['', Validators.required],
      all_day: [''],
      event_type: ['', Validators.required],
      occurrence: ['', Validators.required],
      repeat_interval: ['',[Validators.max(7), Validators.maxLength(1)]],
      occurrence_end_date: ['']
    });
  }

  createOccurenceDataFormBuilder(){
    return this._formBuilder.group({
      occurrence_id: [''],
      event_category: [''],
      organized_by_name: [''],
      organized_by_id: [''],
      location: [''],
      start_date: ['', Validators.required],
      start_time: [''],
      end_date: ['', Validators.required],
      end_time: ['']
    });
  }

  loadEventDetails(){
    this.viewEventService.getEventDetails(this.event_occur_id).subscribe(res => {
      if(res != null){
        var event_data = res["event_data"];
        var occurence_data = res["occurrence_data"];
        this.event_details.patchValue({
          event_no       : event_data["event_no"],
          event_name     : event_data["event_name"],     
          event_desc     : event_data["event_desc"],     
          event_category : event_data["event_category"],    
          event_type     : event_data["event_type"],
          all_day        : event_data["all_day"],
          occurrence     : event_data["occurrence"],
          repeat_interval: event_data["repeat_interval"],
          occurrence_end_date: event_data["occurrence_end_date"], 
        });

        this.occurrence_details.patchValue({
           occurrence_id      : occurence_data["occurrence_id"],
           event_category     : occurence_data["event_category"],   
           organized_by_name  : occurence_data["organized_by_name"],
           organized_by_id    : occurence_data["organized_by_id"],
           location           : occurence_data["location"],
           start_time         : occurence_data["start_time"],
           end_time           : occurence_data["end_time"], 
           start_date         : occurence_data["start_date"],
           end_date           : occurence_data["end_date"]
         });
        this.assigneeId = occurence_data["organized_by_id"];
        this.temp_event_details.patchValue(this.event_details.value); // Backup data
        this.temp_occurrence_details.patchValue(this.occurrence_details.value); // Backup data       
      }
      else{
        this.toastr.error('Server Error', 'Error');
        console.log('Event data are not fetched...');
      }    
    });
  }

  loadAttendees() {
    this.viewEventService.getEventAttendees(this.event_occur_id).subscribe(res => {
      if(res != null){
        res['requester_attendees'].forEach(element => {
          if(!this.requesterAttendeeList.some(ele => ele.nic == element.nic)){ //Restrict duplicate data
            this.requesterAttendeeList.push(element);        
          }  
        });
        res['user_attendees'].forEach(element => {
          if(!this.userAttendeeList.some(ele => ele.user_id == element.user_id)){ //Restrict duplicate data
            this.userAttendeeList.push(element);     
          }     
        });
        this.setReqAttendeeFilter();
        this.setUserAttendeeFilter();
      }   
    });
  }

  loadComments() {
    this.viewEventService.getComments(this.event_occur_id).subscribe(res => {
      this.comments = res;
    })
  }

  saveChanges(){
    if(!this.event_details.pristine && this.event_details.controls.occurrence.value == 'REPEAT'){
        const title = 'Update Confirmation';
        const message = 'Changed event details will effect for all occurences.';
        const dialogconfig = new MatDialogConfig();
        dialogconfig.disableClose = false;
        dialogconfig.autoFocus= true;
        dialogconfig.maxWidth = "450px";
        dialogconfig.data = {
          title : title,
          message : message
        }
        var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
        dialog_ref.afterClosed().subscribe(res=>{
          if(res){
            this.updateEventDetails();          
          }
        });  
    }
    else{
      this.updateEventDetails();
    }
  }

  updateEventDetails(){
    this.occurrence_details.patchValue({start_date : this.datePipe.transform(this.occurrence_details.controls.start_date.value , 'yyyy-MM-dd')});
    this.occurrence_details.patchValue({end_date : this.datePipe.transform(this.occurrence_details.controls.end_date.value , 'yyyy-MM-dd')});
    this.occurrence_details.patchValue({organized_by_id : this.assigneeId}); //Set followerId as follower...

    this.viewEventService.updateEventDetails(this.event_details.value, this.occurrence_details.value).subscribe(
      res => {
        if(res['status'] == 'success'){
          this.toastr.success('Event updated successfully', 'Success');
          this.editMode = false;
          this.editBtnName = "Edit";
          this.temp_event_details.patchValue(this.event_details.value);
          this.temp_occurrence_details.patchValue(this.occurrence_details.value);
          this.dialogRef.beforeClosed().subscribe(() => this.dialogRef.close({needRefresh : true})); //Set indication to parent component to refresh when close this dialog
        }
        else{
          this.toastr.error('Update failed!', 'Failed');
        }
      },
      err => {
        this.toastr.error('Something went wrong!', 'Failed');
      }
    );
  }

  onEditCancel(){
    if(!this.editMode){
      this.editMode = true;
      this.editBtnName = "Cancel";
      // this.temp_event_details.patchValue(this.event_details.value); // Backup data
      // this.temp_occurrence_details.patchValue(this.occurrence_details.value); // Backup data
    }
    else{
      this.editMode = false;
      this.editBtnName = "Edit";  
      this.event_details.patchValue(this.temp_event_details.value); // Backup data
      this.occurrence_details.patchValue(this.temp_occurrence_details.value); // Backup data
    }
  }
  
  addOrEditParticipants(){
    const dialogconfig = new MatDialogConfig();
    //dialogconfig.disableClose = true;
    dialogconfig.autoFocus= false;
    dialogconfig.width = "85%";
    dialogconfig.minWidth = "380px"; /*Set minimum width - Important for mobile windows*/
    dialogconfig.data = {
      user_attendees : this.userAttendeeList,
      req_attendees  : this.requesterAttendeeList,
      event_occur_id : this.event_occur_id
    }
    var dialog_ref = this.dialog.open(EditParticipantDlgComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      this.loadAttendees();
    });
  }

  onCloseClick(){
    this.dialogRef.close();
  }

  loadAssigneeList() {
    this.viewEventService.getAssigneeList().subscribe(res => {
      if(res != null){
        res.forEach(element => {
          this.assigneeList.push(element);
        });
      }
      this.setAssigneeFilter();
    });
  }

  setAssigneeFilter(){
    this.filteredAssignees = this.occurrence_details.controls.organized_by_name.valueChanges
    .pipe(
      startWith(''),
      map(assignee => assignee ? this._filter(assignee) : this.assigneeList.slice())
    );
  }
  //Use to fiter the assignee list
  private _filter(assignee: string): UserAttendee[] {
    const filterValue = assignee.toLowerCase();
    return this.assigneeList.filter(assignee => (assignee.first_name.toLowerCase().indexOf(filterValue) === 0)||
    (assignee.last_name.toLowerCase().indexOf(filterValue) === 0)||
    ((assignee.first_name+' '+assignee.last_name).toLowerCase().indexOf(filterValue) === 0));
  }

  setUserAttendeeFilter(){
    this.userAttendeeSearch.valueChanges
      .pipe(takeUntil(this._onDestroy), startWith(''))
      .subscribe(() => {
        this.filterUserAttendee();
    });
  }

  setReqAttendeeFilter(){
    this.reqAttendeeSearch.valueChanges
      .pipe(takeUntil(this._onDestroy), startWith(''))
      .subscribe(() => {
        this.filterReqAttendee();
    });
  }

  private filterUserAttendee(){
    if (!this.userAttendeeList) {
      return;
    }
    let search = this.userAttendeeSearch.value;
    if (!search) {
      this.filteredUserAttendees.next(this.userAttendeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUserAttendees.next(
      this.userAttendeeList.filter(attendee => ((attendee.first_name.toLowerCase().indexOf(search) > -1) || 
      (attendee.last_name.toLowerCase().indexOf(search) > -1)))
    );
  }

  private filterReqAttendee(){
    if (!this.userAttendeeList) {
      return;
    }
    let search = this.reqAttendeeSearch.value;
    if (!search) {
      this.filteredReqAttendees.next(this.requesterAttendeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredReqAttendees.next(
      this.requesterAttendeeList.filter(attendee => ((attendee.nic.toLowerCase().indexOf(search) > -1) || 
      (attendee.name_in_full.toLowerCase().indexOf(search) > -1)))
    );
  }

  setComment() {
    this.viewEventService.setComment(this.event_occur_id, this.comment).subscribe(res => {
      this.loadComments();
      this.toastr.success('Comment added', 'Success');
      this.comment = "";
    });
  }
}
