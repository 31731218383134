import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { FormControl, Validators } from '@angular/forms';
import { CommunicationService } from './communication.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {

  @ViewChild('mailBox')
  private mailBox: RichTextEditorComponent;
  public value: string = `<p>Type your mail content here... </p>`;
  public defaultMessage: string = `<p>Type your mail content here... </p>`;
  public groupList: any; 
  public filteredGroupList: any; //For mail group list search
  smsGroupList: string[] = ['All']; //Add your sms group here...
  mailGroups = new FormControl();
  smsGroup = new FormControl();
  emailSubject = new FormControl('', [Validators.required]);
  textMessage = new FormControl('', [Validators.required]);
  panelOpenState = false;
  imagePath: string = 'http://13.64.91.36/dev/public-request-manager/04_uploads/mail/' + this._auth.getUserId + '/';

  //Mail Box Configurations.....
  public tools: object = {
    items: [
          'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
          'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
          'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
          'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
          'Indent', 'Outdent', '|', 'CreateLink','CreateTable',
          'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };

  public image: object = {
    saveUrl: environment.url_blogics +'/collaboration/communication/mail_image_upload.php',
    path: this.imagePath
    //path: 'http://13.64.91.36/dev/public-request-manager/04_uploads/mail/5/'
    
  };
  //----------------------------------


  constructor( 
    private dialog     : MatDialog,
    private comService : CommunicationService,
    private toastr     : ToastrService,
    private _auth      : AuthService) 
  { 
    this.loadEmailGroupList();
  }


  // subscription: Subscription;
  // statusText: string;
  // tstBool: boolean = true;

  ngOnInit(): void {
    // if(this.tstBool){
    //   this.subscription = timer(0, 5000).pipe(
    //     switchMap(() => this.test())
    //   ).subscribe(result => this.statusText = result);
    // }
  }

  // test(): ObservableInput<any>{
  //   console.log('DP------Hello Dhana');
  //   this.tstBool = false;
  //   return 'hello';
  // }

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

  loadEmailGroupList() {
    this.comService.getGroupList().subscribe(res => {
      if(res != null){
        this.groupList = res;
        this.filteredGroupList = this.groupList.slice();
      }     
    });
  }
  
  onMailPost(){
    const title = 'Send Confirmation';
    const message = 'Are you sure you want to send this mail?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.dialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        let mailContent: string = this.mailBox.value;
        //alert(mailContent);
        this.comService.sendEmail(this.mailGroups.value, this.emailSubject.value, mailContent).subscribe(res => {

          console.log(res);
        })
      }
    });
  }

  onSmsSend(){
    const title = 'Send Confirmation';
    const message = 'Are you sure you want to send this SMS?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.dialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        let mailContent: string = this.mailBox.value;
        this.comService.sendSMS(this.smsGroup.value, this.textMessage.value).subscribe(res => {
          console.log(res);
          //this.toastr.success(data.Message, 'Success!');
        })
      }
    });
  }

  //For mail group selection field
  public isFiltered(group) {
    return this.filteredGroupList.find(item => item.group_id === group.group_id);
  }

  
  //Method to set Authorization header to image upload API for mail box...
  public onToolbarClick(e: any): void {
     console.log('********************');
    if (e.item != null && e.item.id == "mailBox_toolbar_Image") { // Checked if image toolbar is clicked 
      console.log('###################');
      let element: any = document.getElementById('mailBox_upload') // Image uploader element 
      element.ej2_instances[0].uploading = function upload(args) { // Added updating event on image uploader 
        args.currentRequest.setRequestHeader('Authorization', localStorage.getItem('token')); // Setting additional headers
      }
    }
  }

}