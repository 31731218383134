<div class="window-title">
  <h2>New Request</h2>
</div>
<div class="row main-div">
  <form [formGroup]="new_request" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
    <mat-card-title class="sub-heading">Requester Details</mat-card-title>
    <div class="row">
      <div class="col-md-5">
        <mat-form-field>
          <mat-label>RID</mat-label>
          <input type="text" matInput placeholder="Requester ID" formControlName="nic"(focusout)="getDefaultData()" maxlength="12" required/>
        </mat-form-field>
      </div>
      <div class="col-md-5">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input type="text" matInput placeholder="Email" formControlName="email"/>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Title</mat-label>
          <mat-select placeholder="Title" formControlName="title" required>
            <mat-option *ngFor="let title of titlesList" [value]="title.title_id">
              {{ title.title_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <mat-form-field>
          <mat-label>Full Name</mat-label>
          <input type="text" matInput formControlName="name_in_full" required />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Name in Use</mat-label>
          <input type="text" matInput formControlName="name_in_use" required />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input type="text" matInput formControlName="address" required />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Mobile No</mat-label>
          <input type="number" matInput formControlName="mobile_no" required />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Date of Birthday</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="27/04/1990"
            formControlName="dob" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Personal Background</mat-label>
          <textarea matInput placeholder="Max 500 characters" formControlName="background" maxlength="500"></textarea>
        </mat-form-field>
      </div>
    </div>
    <br />
    <h1 class="sub-heading">Request Details</h1>
    <mat-card>
      <div class="row">
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Reference No</mat-label>
            <input type="text" matInput formControlName="ref_no" class="ref_no" [readonly]="true" />
          </mat-form-field>
        </div>
        <div class="col-md-8">
          <mat-form-field>
            <mat-label>Topic</mat-label>
            <input type="text" matInput placeholder="Max 200 characters" formControlName="topic" maxlength="200" required />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Priority</mat-label>
            <span matPrefix style=" padding-right: 3px; padding-top: 3px; display: inline-block;" >
              <img alt width="16" height="16" [src]="
                  new_request.controls.priority.value == '1'
                    ? '../../assets/images/critical.svg'
                    : new_request.controls.priority.value == '2'
                    ? '../../assets/images/major.svg'
                    : new_request.controls.priority.value == '3'
                    ? '../../assets/images/minor.svg'
                    : new_request.controls.priority.value == '4'
                    ? '../../assets/images/trivial.svg'
                    : null 
                    "/>
            </span>
            <mat-select formControlName="priority" required>
              <mat-option value="1"><img alt width="16" height="16" src="../../assets/images/critical.svg"/>
                One</mat-option>
              <mat-option value="2"><img alt width="16" height="16" src="../../assets/images/major.svg"/>
                Two
              </mat-option>
              <mat-option value="3"><img alt width="16" height="16" src="../../assets/images/minor.svg"/>
                Three
              </mat-option>
              <mat-option value="4"><img alt width="16" height="16" src="../../assets/images/trivial.svg"/>
                Four
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <!-- <div class="col-md-4">
          <mat-form-field>
            <mat-label>Section</mat-label>
            <input type="text" matInput formControlName="section" [matAutocomplete]="section_auto" />
            <mat-autocomplete #section_auto="matAutocomplete">
              <mat-option (onSelectionChange)="sectionId = section.section_id; loadCategoryList()"
                *ngFor="let section of filteredSections | async" [value]="section.section_id + ' - ' + section.section_name">
                {{section.section_id + " - " + section.section_name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>   
        </div></div> -->
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Assignment Group</mat-label>
            <input type="text" matInput formControlName="as_group" 
            [matAutocomplete]="as_group_auto" required/>
            <mat-autocomplete #as_group_auto="matAutocomplete">
              <mat-option (onSelectionChange)="assignmentGroupID = as_group.group_id; getAsGroupMemberList()"
              *ngFor = "let as_group of filteredAsGroups | async" [value]="as_group.group_id+'-'+as_group.group_name">
              {{as_group.group_id+"-"+as_group.group_name}}
            </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <!-- 
        <div class="col-md-4">
          <mat-form-field class="inputLabel">
            <mat-label>Category</mat-label>
            <input type="text" matInput formControlName="category" [matAutocomplete]="category_auto" [readonly]="sectionId == null" />
            <mat-autocomplete #category_auto="matAutocomplete">
              <mat-option (onSelectionChange)="categoryId = category.cat_id"
                *ngFor="let category of filteredCategories | async" [value]="category.cat_name + ' - ' + category.cat_description">
                {{category.cat_name + " - " + category.cat_description}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div> -->
        <div class="col-md-4">
          <mat-form-field class="inputLabel">
            <mat-label>Assigned To</mat-label>
            <input type="text" matInput formControlName="as_member" [matAutocomplete]="as_member_auto" [readonly]="assignmentGroupID == null"/>
            <mat-autocomplete #as_member_auto="matAutocomplete">
              <mat-option (onSelectionChange)="assignedMemberID = as_member.user_id" 
              *ngFor ="let as_member of filteredMembers | async" [value]="as_member.first_name+' '+as_member.last_name">
              {{as_member.first_name+' '+as_member.last_name}}
            </mat-option>
            <!-- @for(as_member of filteredMembers; track as_member){
              <mat-option [value]="as_member.user_id">{{as_member.first_name+' '+as_member.last_name}}</mat-option>
            } -->
            </mat-autocomplete>
          </mat-form-field>
        </div>

      </div>

      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Request in Detail</mat-label>
            <textarea matInput placeholder="Max 5000 characters" formControlName="request" maxlength="5000"></textarea>
          </mat-form-field>
        </div>
      </div>

      <mat-progress-bar
        mode="indeterminate"
        *ngIf="progress_bar"
        style="margin-bottom: 2px"></mat-progress-bar>
      <div>
        <ngx-dropzone
          (change)="onFilesAdded($event)"
          [maxFileSize]="1e7"
          [accept]="'.jpg,.jpeg,.png,.txt,.pdf,.zip,.xlsx,.pptx,.docx'">
          <ngx-dropzone-label><i class="fa fa-file-text fa-2x"></i> Drop your
            files!</ngx-dropzone-label>
          <ngx-dropzone-preview
            *ngFor="let f of files"
            [removable]="true"
            (removed)="onFileRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
      <mat-card-actions>
        <div class="row" style="float: right; margin-top: 15px">
          <!-- <div> -->
          <!-- <button mat-raised-button (click)="test()" color="primary" type="button" class="mat-button-class" style="float: right; margin-right:10px">Test</button> -->
          <button mat-raised-button (click)="clear()" color="primary" type="button" class="mat-button-class" style="float: right; margin-right: 10px">
            Clear
          </button>
          <button mat-raised-button type="submit" color="primary" class="mat-button-class" [disabled]="!new_request.valid" style="float: right; margin-right: 23px">
            Submit
          </button>
        </div>
      </mat-card-actions>
      <div style="float: none; margin-bottom: 55px"></div>
    </mat-card>
  </form>
</div>
