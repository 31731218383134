import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';


const URL = environment.url_blogics+'/users/get_users.php';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpclient: HttpClient) { }

  getAllUsersData(){
    return (this.httpclient.get(URL)); 
  }

  progressList(state : string) {
    return (this.httpclient.post(URL, JSON.stringify({ "state" : state}))) 
  }
  
  progressSelectedList(state : string, user : string) {
    return (this.httpclient.post(URL, JSON.stringify({"state" : state, "user_id" : user}))) 
  }
}
