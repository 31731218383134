<div class="main-mat">
  <mat-card-title>
    <div class="row" style="margin-left: 0px; margin-right: auto; padding-top: 0px">
      {{ requestId }}
      <span id="tag" class="badge badge-danger"
        [ngClass]="{
          'badge-secondary': state == 'New',
          'badge-warning': state == 'In Progress',
          'badge-primary': state == 'Resolved',
          'badge-success': state == 'Closed',
          'badge-danger': state == 'Rejected',
          'badge-dark': state == 'Approved',
          'badge-info': state == 'Pending Approval',
          'badge-light': state == 'Pending',
          'badge-canceled': state == 'Canceled'
        }">
        {{ state }}
      </span>
    </div>
  </mat-card-title>

  <!-- user details -->
  <mat-card-subtitle>Requestor details</mat-card-subtitle>
  <mat-card class="in-mat">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="row">
            <div class="col-2">Name</div>
            <div class="col-10">{{ requester }}</div>
          </div>
          <div class="row" *ngIf="requesterNick != ''">
            <div class="col-2">Name in Use</div>
            <div class="col-10">{{ requesterNick }}</div>
          </div>
          <div class="row">
            <div class="col-2">Contact No</div>
            <div class="col-10">{{ requesterMobile }}</div>
          </div>
          <div class="row">
            <div class="col-2">Postal Address</div>
            <div class="col-10">{{ requesterAddress }}</div>
          </div>
          <!-- <div class="row">
            <div class="col-2">GS Division</div>
            <div class="col-10">{{ requesterDivision }}</div>
          </div>
          <div class="row">
            <div class="col-2">Electoral Division</div>
            <div class="col-10">{{ electoralDivision }}</div>
          </div>
          <div class="row">
            <div class="col-2">Divisional Secretariats</div>
            <div class="col-10">{{ divisionalSecretary }}</div>
          </div> -->
          <div class="row">
            <div class="col-2">About Requester</div>
            <div class="col-10">{{ requesterBackground }}</div>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <button mat-icon-button *ngIf="state == 'In Progress' || state == 'Pending Approval'" [matMenuTriggerFor]="stateMenu" style="float: right; margin-right: 5px" aria-label="Extra state button set">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #stateMenu="matMenu">        
            <button mat-menu-item *ngIf="state == 'In Progress'" (click)="setState('Pending')">Pending</button>
            <button mat-menu-item *ngIf="state == 'In Progress'" (click)="setState('Canceled')">Cancel</button>
            <button mat-menu-item *ngIf="state == 'Pending Approval'" (click)="setState('In Progress')">Revise</button>
          </mat-menu>
          <button mat-stroked-button *ngIf="!(state === 'New' || state === 'Approved' || state === 'Closed' || state === 'Canceled')" 
            [disabled]="state == 'Canceled'" (click)="onStateBtn2Click()" style="float: right">
            {{state == "In Progress" ? "Request Approval" 
              : state == "Pending" ? "Cancel" 
              : state == "Pending Approval" ? "Reject"
              : state == "Resolved" ? "Reopen"
              : state == "Rejected" ? "Cancel"
              : "" }}
          </button>
          <button mat-stroked-button *ngIf="!(state === 'Closed' || state === 'Canceled' || state === 'Rejected')" 
            (click)="onStateBtn1Click()" style="float: right; margin-right: 5px">
            {{state == "New" ? "Start Progress" 
              : state == "In Progress" ? "Resolve" 
              : state == "Pending" ? "Start Progress" 
              : state == "Pending Approval" ? "Approve"
              : state == "Resolved" ? "Close"
              : state == "Approved" ? "Resolve"
              : "" }}
          </button>
          <button mat-stroked-button *ngIf="showLetterBtn && !(state === 'New' || state === 'Canceled' || state === 'Rejected' || state === 'Pending')" type="button" [matMenuTriggerFor]="menu" style="float: right; margin-right: 5px">
            <mat-icon>description</mat-icon></button>
          <mat-menu #menu="matMenu" style="width: 285px;">
            <button mat-menu-item *ngIf="!(state === 'Completed')" (click)="openLetterWriter('new')" style="margin-top: -10px;"><mat-icon>add_box</mat-icon>New</button>
            <button mat-menu-item *ngFor="let letter of letterList" [value]="letter.id" (click)="openLetterWriter(letter.letter_id)" style="border-top: 1px solid rgb(221, 221, 221);">{{letter.letter_id + " " + letter.last_updated_on}}</button>        
          </mat-menu>
          <button mat-stroked-button *ngIf="showNewLetterBtn && !(state === 'New' || state === 'Canceled' || state === 'Rejected' || state === 'Pending' || state === 'Completed')" type="button" (click)="openLetterWriter('new')" style="float: right; margin-right: 5px">
            <mat-icon>description</mat-icon></button>

        </div>
      </div>
    </div>
  </mat-card>
  <!-- end user detail -->
  <mat-card-subtitle>Genaral details</mat-card-subtitle>
  <!-- general details -->
  <mat-card class="in-mat" style="margin-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xs-12">
          <div class="row">
            <div class="col-3">Created on</div>
            <div class="col-9" style="height: 22px">{{ created }}</div>
          </div>
          <div class="row">
            <div class="col-3">Priority</div>
            <div class="col-9" *ngIf="!editPriority" (mouseover)="showEditPriority = true" (mouseleave)="showEditPriority = false" >
              <img alt width="16" height="16"
                [src]="
                  priority == '1'
                    ? '../../assets/images/critical.svg'
                    : priority == '2'
                    ? '../../assets/images/major.svg'
                    : priority == '3'
                    ? '../../assets/images/minor.svg'
                    : priority == '4'
                    ? '../../assets/images/trivial.svg'
                    : null
                "
              />
              {{ priority == "1" ? "One" : priority == "2" ? "Two" : priority == "3" ? "Three" : priority == "4" ? "Four" : "N/A"}}
              <i *ngIf="showEditPriority" (click)="editPriority = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>

            <div class="col-9" *ngIf="editPriority">
              <mat-form-field class="inputLabel">
                <span matPrefix style="padding-right: 3px; padding-top: 3px; display: inline-block;">
                  <img alt width="16" height="16" [src]="
                      priority == '1'
                        ? '../../assets/images/critical.svg'
                        : priority == '2'
                        ? '../../assets/images/major.svg'
                        : priority == '3'
                        ? '../../assets/images/minor.svg'
                        : priority == '4'
                        ? '../../assets/images/trivial.svg'
                        : null"/>
                </span>
                <mat-select [(value)]="priority">
                  <mat-option value="1">
                    <img alt width="16" height="16" src="../../assets/images/critical.svg"/>One</mat-option>
                  <mat-option value="2">
                    <img alt width="16" height="16" src="../../assets/images/major.svg"/>Two</mat-option>
                  <mat-option value="3">
                    <img alt width="16" height="16" src="../../assets/images/minor.svg"/>Three</mat-option>
                  <mat-option value="4">
                    <img alt width="16" height="16" src="../../assets/images/trivial.svg"/>Four</mat-option>
                </mat-select>
              </mat-form-field>
              <i (click)="setPriority()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-3">Division</div> 
            <div class="col-9" *ngIf="!editServiceArea" (mouseover)="showEditServiceArea = true" (mouseleave)="showEditServiceArea = false">
              {{ serviceArea.value || "None" }}
              <i *ngIf="showEditServiceArea" (click)="loadServiceAreaList(); editServiceArea = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
            <div class="col-9" *ngIf="editServiceArea">
              <mat-form-field class="inputLabel">
                <input type="text" matInput [formControl]="serviceArea" [matAutocomplete]="auto2"/>
                <mat-autocomplete #auto2="matAutocomplete">
                  <mat-option 
                    (onSelectionChange)="serviceAreaId = serviceArea.division_id"
                    *ngFor="let serviceArea of filteredServiceAreaList | async" 
                    [value]="serviceArea.division_id + ' - ' + serviceArea.division_name">
                    {{"   " + serviceArea.division_id + " - " + serviceArea.division_name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <i (click)="setServiceArea()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
          </div> -->
          <div class="row">
            <div class="col-3">Assiged on</div>
            <div class="col-9">{{ assignedDate }}</div>
          </div>
        </div>

        <div class="col-lg-6 col-xs-12">
          <!-- <div class="row">
            <div class="col-3">Section</div>
            <div class="col-9" *ngIf="!editSection" (mouseover)="showEditSection = true" (mouseleave)="showEditSection = false">
              {{ section_name || "N/A" }}
              <i *ngIf="showEditSection" (click)="loadSectionList(); editSection = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
            <div class="col-9" *ngIf="editSection">
              <mat-form-field class="inputLabel">
                <mat-select [(value)]="section_id" (selectionChange)="editSection = true">
                  <mat-option *ngFor="let section of sections" [value]="section.section_id">
                    {{ section.section_name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <i (click)="setSection()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
          </div> -->
          <div class="row">
            <div class="col-3">Category</div>
            <div class="col-9" *ngIf="!editCategory" (mouseover)="showEditCategory = true" (mouseleave)="showEditCategory = false" >
              {{ category_name || "N/A" }}
              <i *ngIf="showEditCategory" (click)="loadCategories(); editCategory = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
            <div class="col-9" *ngIf="editCategory">
              <mat-form-field class="inputLabel">
                <mat-select [(value)]="category_id" (selectionChange)="editCategory = true">
                  <mat-option *ngFor="let cat of categories" [value]="cat.cat_id"> {{ cat.cat_description }} </mat-option>
                </mat-select>
              </mat-form-field>
              <i (click)="setCategory()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-3">Assignment Group</div>
            <div class="col-9" *ngIf="!editAssignmentGroup" (mouseover)="showEditAssignmentGroup = true" (mouseleave)="showEditAssignmentGroup = false">
              {{ assignment_group_name || "N/A" }}
              <i *ngIf="showEditAssignmentGroup" (click)="loadGroupList(); editAssignmentGroup = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
            <div class="col-9" *ngIf="editAssignmentGroup">
              <mat-form-field class="inputLabel">
                <mat-select [(value)]="assignment_group_id" (selectionChange)="editAssignmentGroup = true" [disabled]="assignment_group_id == null">
                  <mat-option *ngFor="let as_group of assignment_group_list" [value]="as_group.group_id"> {{ as_group.group_name }} </mat-option>
                </mat-select>
              </mat-form-field>
              <i (click)="setGroup()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-3">Assignee</div>
            <div class="col-9" *ngIf="!editAssignee" (mouseover)="showEditAssignee = true" (mouseleave)="showEditAssignee = false">
              <img class="select-option-img" aria-hidden [src]="assignee_url || '../../assets/images/user-default.png'" height="25"/>
              {{ assignee.value || "Unassigned" }}
              <i *ngIf="showEditAssignee" (click)="loadAssigneeList(); editAssignee = true" class="fa fa-pencil-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
            <div class="col-9" *ngIf="editAssignee">
              <mat-form-field class="inputLabel">
                <input type="text" matInput [formControl]="assignee" [matAutocomplete]="auto"/>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option (onSelectionChange)=" assignee_id = assignee.user_id; assignee_url = assignee.profile_url"
                    *ngFor="let assignee of filteredAssignees | async" [value]="assignee.first_name + ' ' + assignee.last_name" [disabled]="assignment_group_id == null">
                    <img class="select-option-img" aria-hidden [src]="assignee.profile_url || '../../assets/images/user-default.png'" height="25"/>
                    {{ "   " + assignee.first_name + " " + assignee.last_name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <i (click)="setAssignee()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
          </div>

          <!-- TODO : NEED To Implement -->
          <!-- <div class="row">
            <div class="col-3">Labels</div>
            <div class="col-9" *ngIf="!editLabels" (mouseover)="showEditLabels = true" (mouseleave)="showEditLabels = false" >
              {{labels.length > 0 ? '' : "None"}}
              <mat-chip-list #chipList *ngIf="labels.length > 0" style="float:left;">
                <mat-chip class="label-chip"
                  *ngFor="let label of labels"
                  [selectable]="selectable"
                  [removable]= "false"
                  (click)="onLabelClick()">
                  {{label}}
                </mat-chip>
              </mat-chip-list>

              <i *ngIf="showEditLabels" (click)="loadLabels(); editLabels = true" class="fa fa-pencil-square-o fa-lg edit-icon" 
              style="padding: 0px 0 0 5px;" aria-hidden="true"></i>
            </div>
            <div class="col-9" *ngIf="editLabels">       
              <mat-form-field class="inputLabel" style="width: 92%;">
                <mat-chip-list #chipList>
                  <mat-chip class="label-chip"
                    *ngFor="let label of labels"
                    [selectable]="selectable"
                    [removable]= "removable"
                    (removed)="remove(label)">
                    {{label}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input
                    #labelInput
                    [formControl]="labelCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let label of filteredLabels | async" [value]="label">
                    {{label}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <i (click)="setLabels()" class="fa fa-check-square-o fa-lg edit-icon" aria-hidden="true"></i>
            </div>
          </div> -->

        </div>
      </div>

      <mat-card-subtitle>{{ topic }}</mat-card-subtitle>
      <p>{{ description }}</p>
    </div>
  </mat-card>
  <!-- Attachments -->
  <mat-card-subtitle>
    Attachments
    <input style="display: none" type="file" multiple (change)="onFileAdd($event.target.files)" #hiddenfileinput maxlength="1e+7" accept=".jpg,.jpeg,.png,.txt,.pdf,.zip,.xlsx,.pptx,.docx"/>
    <button mat-button (click)="hiddenfileinput.click()" style="float: right">
      <mat-icon>control_point</mat-icon>Add New
    </button>
  </mat-card-subtitle>
  <mat-progress-bar mode="indeterminate" *ngIf="progress_bar" style="margin-bottom: 2px"></mat-progress-bar>
  <mat-card *ngIf="attachments.length != 0">
    <mat-nav-list>
      <mat-list-item *ngFor="let attach of attachments">
        <mat-icon>attach_file</mat-icon>
        <a href="{{ attach.link }}" target="_blank">{{ attach.name }}</a>
        <button mat-icon-button (click)="onFileRemove(attach)" class="closeBtn">
          <mat-icon>close</mat-icon>
        </button>
      </mat-list-item>
    </mat-nav-list>
  </mat-card>
  <!-- end attachment -->

  <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)" >
    <mat-tab label="Comments">
      <!-- comment section -->
      <mat-card class="comment">
        <mat-form-field class="text-area">
          <mat-label>Add a comment</mat-label>
          <textarea matInput [(ngModel)]="comment" placeholder="Leave your comment here...."></textarea>
        </mat-form-field>
        <mat-slide-toggle [(ngModel)]="isCustVisible">Requestor Visible</mat-slide-toggle>
        <button mat-raised-button (click)="setComment()">Post Comment</button>
      </mat-card>
      <mat-card *ngIf="comments">
        <!-- <mat-list *ngIf="comments[0].comment != null"> -->
        <mat-list *ngIf="comments">
          <mat-list-item *ngFor="let comment of comments">
            <h4 class="comment-head" matLine>
              <img class="comment-image" alt width="16" height="16"
                [src]=" comment.profile_url != null ? comment.profile_url : '../../assets/images/user-default.png' "/>
              {{ comment.commented_by_name || "Requester" }} added comment -
              {{ comment.commented_date }}
            </h4>
            <!-- <h5 matLine>{{comment.commented_date}}</h5> -->
            <p matLine class="comment-line">
              <span class="demo-2">{{ comment.comment }}</span>
            </p>
          </mat-list-item>
        </mat-list>
      </mat-card>
      <!-- end comment section -->
    </mat-tab>
    <mat-tab label="History">
      <mat-list *ngIf="historyRecords">
        <mat-list-item *ngFor="let record of historyRecords">
          <h4 class="comment-head" matLine>
            {{ record.date }}
          </h4>
          <p matLine class="comment-line">
            <img class="history-image" alt width="32" height="32"
              [src]=" record.profile_url == null || record.profile_url == '' ? '../../assets/images/user-default.png'
                  : record.profile_url " />
            <span class="highlight-part">{{ record.user_name + " " }}</span>
            <span>{{ record.action + " " }}</span>
            <span *ngIf="record.action != 'Changed'">{{record.property + " "}}</span>
            <span *ngIf="record.action == 'Changed'" class="highlight-part">{{ record.property + " "}}</span>
            <span *ngIf="record.action == 'Changed' && record.property != 'State'">
              {{ record.pre_value + " --> " + record.new_value }}
            </span>

            <span *ngIf="record.action == 'Changed' && record.property == 'State'" id="tag" class="badge badge-danger"
              [ngClass]="{
                'badge-secondary': record.pre_value == 'New',
                'badge-warning': record.pre_value == 'In Progress',
                'badge-success': record.pre_value == 'Completed',
                'badge-danger': record.pre_value == 'Canceled'
              }" >
              {{ record.pre_value + "  " }}
            </span>
            <span *ngIf="record.action == 'Changed' && record.property == 'State'"> --> </span>
            <span *ngIf="record.action == 'Changed' && record.property == 'State'" class="badge badge-danger"
              [ngClass]="{
                'badge-secondary': record.new_value == 'New',
                'badge-warning': record.new_value == 'In Progress',
                'badge-success': record.new_value == 'Completed',
                'badge-danger': record.new_value == 'Canceled'
              }">
              {{ record.new_value }}
            </span>

            <span *ngIf="record.property == 'Attachment'">{{record.new_value}}</span>
          </p>
        </mat-list-item>
      </mat-list>
    </mat-tab>
  </mat-tab-group>
</div>
