import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequesterPanelService } from './requester-panel.service';
import { DatePipe } from '@angular/common';
import { MoreInOutAnimation } from 'src/app/basic-data/animations';
import { PermissionService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-requester-panel',
  templateUrl: './requester-panel.component.html',
  styleUrls: ['./requester-panel.component.css'],
  animations: [MoreInOutAnimation]
})
export class RequesterPanelComponent implements OnInit {

  nic: string;
  source: string;
  editBtnName: string = "Edit";
  isEditable: boolean = false;
  titleName: string;
  requesterTypeName: string;
  divisionName: string;
  //temp_requester_details;

  titleList: any;
  requesterTypeList: any;
  divisionList: any;

  showMoreInfo : boolean = false;
  animationState = 'out';

  constructor(
    public dialogRef: MatDialogRef<RequesterPanelComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _formBuilder          : FormBuilder,
    private toastr                : ToastrService,
    private requesterPanelService : RequesterPanelService,
    private datePipe              : DatePipe,
    public permission             : PermissionService) 
    { 
      this.source = data.source;
      this.getTitleList();
      this.getRequesterTypeList();
      this.getDivisionList();

      if(this.source == "view&edit"){
        this.nic = data.nic;
        this.getRequesterDetails();      
      }
      else if(this.source == "addNew"){
        this.isEditable = true;
      }
    }

  requester_details = this.createFormBuilder();
  temp_requester_details = this.createFormBuilder(); // For backup data when edit
  // more_info = this.createMoreInfoBuilder();
  // temp_more_info = this.createMoreInfoBuilder(); // For backup data when edit

  ngOnInit(): void {
  }

  createFormBuilder(){
    return this._formBuilder.group({
      nic : [''],
      title: ['', Validators.required],
      name_in_use: ['', Validators.required],
      name_in_full: ['', Validators.required],
      requester_type: ['', Validators.required],
      division: ['', Validators.required],
      email: ['', Validators.required],
      address: ['', Validators.required],
      dob: [''],
      mobile_no: ['', Validators.required],
      background: [''],
      active : ['0'],
      job_seeker : ['0'], //temp_attribute...
      edu_level: [''], //temp_attribute...
      edu_desc: [' '] //temp_attribute...
    });
  }

  getRequesterDetails(){
    this.requesterPanelService.getRequesterDetails(this.nic)
    .subscribe(data => {
      if(data["nic"] != null){
        this.requester_details.patchValue({
          nic            : data["nic"],
          title          : data["title"],
          name_in_use    : data["name_in_use"],
          name_in_full   : data["name_in_full"], 
          requester_type : data["requester_type"],
          division       : data["division"],
          email          : data["email"],
          address        : data["address"],
          dob            : data["dob"],
          mobile_no      : data["mobile_no"],
          background     : data["background"],
          active         : data["active"],
          job_seeker     : data["job_seeker"],
          edu_level      : data["edu_level"],
          edu_desc       : data["edu_description"]
        })

        if(this.titleList != null){
          this.setTitleFromList(data["title"]);
        }
        if(this.requesterTypeList != null){
          this.setRequesterTypeFromList(data["requester_type"]);
        }
        if(this.divisionList != null){
          this.setDivisionFromList(data["division"]);
        }
      }
      else{
        this.toastr.error('Server Error', 'Error');
        console.log('User default data not fetched...');
      }
    });
  }

  getTitleList(){
    this.requesterPanelService.getTitleList()
    .subscribe(data => {
      if(data!= null){
        this.titleList = data;
        if(this.requester_details.controls.title.value != ''){
          this.setTitleFromList(this.requester_details.controls.title.value);
        }
      }
      else{
        this.toastr.error('Server Error', 'Error');
      }
    });
  }

  getRequesterTypeList(){
    this.requesterPanelService.getRequesterTypeList()
    .subscribe(data => {
      if(data!= null){
        this.requesterTypeList = data;
        if(this.requester_details.controls.requester_type.value != ''){
        this.setRequesterTypeFromList(this.requester_details.controls.requester_type.value);
        }
      }
      else{
        this.toastr.error('Server Error', 'Error');
      }
    });
  }

  getDivisionList(){
    this.requesterPanelService.getDivisionList()
    .subscribe(data => {
      if(data!= null){
        this.divisionList = data;
        if(this.requester_details.controls.division.value != ''){
         this.setDivisionFromList(this.requester_details.controls.division.value);
        }
      }
      else{
        this.toastr.error('Server Error', 'Error');
      }
    });
  }

  saveDetails(){
    // Change the date format
    this.requester_details.patchValue({dob : this.datePipe.transform(this.requester_details.controls.dob.value , 'yyyy-MM-dd')});
    
    if(this.source == "view&edit"){ 
      this.requesterPanelService.updateRequesterDetails(this.requester_details.value)
      .subscribe(res => {
        if(res['status'] == 'success'){
          this.isEditable = false; 
          this.editBtnName = "Edit";
          this.toastr.success('Details updated', 'Success');
          this.setValuesFromLists(this.requester_details.controls.title.value, this.requester_details.controls.requester_type.value, this.requester_details.controls.division.value);        
        }
        else{
          this.toastr.error('Update failed', 'Failed'); 
        }
      });
    }
    else if(this.source == "addNew"){
      this.requesterPanelService.addNewRequesterDetails(this.requester_details.value)
      .subscribe(res => {
        if(res['status'] == 'success'){
          this.isEditable = false; 
          this.source = "view&edit";        
          this.setValuesFromLists(this.requester_details.controls.title.value, this.requester_details.controls.requester_type.value, this.requester_details.controls.division.value);        
          this.toastr.success('New record saved', 'Success');
        }
        else if(res['status'] == 'user_exist'){
          this.toastr.error('NIC already exist', 'Failed');
        }
        else{
          this.toastr.error('Record saving failed', 'Failed'); 
        }
      });
    }
  }

  setValuesFromLists(title: string, type: string, division: string){
    this.setTitleFromList(title);
    this.setRequesterTypeFromList(type);
    this.setDivisionFromList(division);
  }
  setTitleFromList(title: string){
    this.titleName = (this.titleList.find(x => x.title_id === title)).title_name;
  }

  setRequesterTypeFromList(type: string){
    this.requesterTypeName = (this.requesterTypeList.find(x => x.type_id === type)).type_name;
  }

  setDivisionFromList(division: string){
    this.divisionName = (this.divisionList.find(x => x.division_id === division)).division_name;
  }

  setState(e){
    if(e.checked){
      this.requester_details.patchValue({active : '1'});
    }
    else{
      this.requester_details.patchValue({active : '0'});
    }   
  }

  onEditCancel(){
    if(!this.isEditable){
      this.isEditable = true;
      this.editBtnName = "Cancel";
      this.temp_requester_details.patchValue(this.requester_details.value); // Backup data
      //console.log('##################---'+this.temp_requester_details.controls.nic.value.toString());
    }
    else{
      this.isEditable = false;
      this.editBtnName = "Edit";
      this.requester_details.patchValue(this.temp_requester_details.value);
    }
  }

  onClickShowMoreInfo(){
    this.animationState = 'in';
    this.showMoreInfo = true;
  }

  clear(){
    this.requester_details.reset();
  }

  onCloseClick(){
    this.dialogRef.close();
  }

  /*----------------Temp methods related for specific installation only------------*/
  setJobSState(e){
    if(e.checked){
      this.requester_details.patchValue({job_seeker : '1'});
    }
    else{
      this.requester_details.patchValue({job_seeker : '0'});
    }   
  }
}
