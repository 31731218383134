<h1 mat-dialog-title>
  Export Confirmation
</h1>

<div mat-dialog-content>
  <div class="row">
    <div class="col-3" style="padding-top: 20px;">Type :</div>
    <mat-form-field class="col-9" style="padding-right: 100px;">
        <mat-select [(ngModel)]="exportType">
            <mat-option value="excel">To Excel</mat-option> 
            <mat-option value="svc">To CSV</mat-option> 
        </mat-select>  
    </mat-form-field>
</div>


  <mat-radio-group color="primary" [(ngModel)]="setComment" aria-label="Select an option">
    <mat-radio-button value="false">Export without comment</mat-radio-button>
    <mat-radio-button value="true">Export by adding a comment</mat-radio-button>
  </mat-radio-group>
</div>

<mat-form-field appearance="outline" *ngIf="setComment == 'true'" style="width: 100%;">
  <mat-label>Add a comment</mat-label>
  <input matInput [formControl]="comment" placeholder="Comment">
</mat-form-field>

<div mat-dialog-actions>
  <button mat-stroked-button (click)="onDismiss()">Cancel</button>
  <button mat-flat-button color="primary" [disabled]="!comment.valid && setComment==='true'" (click)="onConfirm()">Export</button>
</div>