import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const URL_get_event_details = environment.url_blogics + '/event/get_event_details.php';
const URL_get_assignee_list = environment.url_blogics + '/progress/get_assignee_list.php';
const URL_get_event_participants = environment.url_blogics + '/event/get_event_participants.php';
const URL_get_user_attendee_list = environment.url_blogics + '/event/load_user_attendees.php';
const URL_get_req_attendee_list = environment.url_blogics + '/event/load_requester_attendees.php';
const URL_update_event_details = environment.url_blogics + '/event/update_event_occur_details.php';
const URL_update_event_occur_participants = environment.url_blogics + '/event/update_event_occur_participants.php';
const URL_get_event_comments = environment.url_blogics + "/event/get_event_comments.php";
const URL_set_event_comment = environment.url_blogics + "/event/leave_evnt_comment.php";

@Injectable({
  providedIn: 'root'
})

export class ViewEventService {

  constructor(private _http: HttpClient) { }

  getAssigneeList() : Observable<any> {
    return (this._http.get(URL_get_assignee_list));
  }

  getEventDetails(event_occur_id: string) : Observable<any>{
    let params = new HttpParams();
    params = params.append('event_occur_id',event_occur_id.toString());
    return this._http.get(URL_get_event_details, {params: params});
  }

  getEventAttendees(event_occur_id: string) : Observable<any>{
    let params = new HttpParams();
    params = params.append('event_occur_id',event_occur_id.toString());
    return this._http.get(URL_get_event_participants, {params: params});
  }

  loadUserAttendees(type: string, selectedDesigs, selectedSections, selectedState) : Observable<any>{
    return this._http.post(URL_get_user_attendee_list, JSON.stringify({ "type": type, "designation": selectedDesigs,
     "section":selectedSections, "state": selectedState }));
  }

  loadRequesterAttendees(type: string, reqTypes, divisions, selectedState) : Observable<any>{
    return this._http.post(URL_get_req_attendee_list, JSON.stringify({ "type": type, "req_type": reqTypes,
     "division":divisions, "state": selectedState }));
  }

  updateEventDetails(event_details: FormData, occurrence_details: FormData){
    return this._http.post(URL_update_event_details, JSON.stringify({event_details, occurrence_details}));
  }

  updateEventOccurParticipants(occurrence_id: string, user_attendees, req_attendees){
    return this._http.post(URL_update_event_occur_participants, JSON.stringify({occurrence_id, user_attendees, req_attendees}));
  }

  getComments(eventOccurID: string) {
    let params = new HttpParams();
    params = params.append('occurId', eventOccurID);
    return this._http.get(URL_get_event_comments, {params: params});
  }

  setComment(eventOccurID: string, comment: string) {
    var x = this._http.post(
      URL_set_event_comment,
      JSON.stringify({
        occurId: eventOccurID,
        comment: comment
      })
    );
    return x;
  }
}
