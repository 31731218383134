import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

const URL_get_sections_list = environment.url_blogics + "/basic-data/section/get_section_list.php";
const URL_add_new_section = environment.url_blogics + '/basic-data/section/add_new_section.php';
const URL_update_section = environment.url_blogics + '/basic-data/section/update_section.php';
const URL_delete_section = environment.url_blogics + '/basic-data/section/remove_section.php';
const URL_delete_sections = environment.url_blogics + '/basic-data/section/remove_sections.php';


@Injectable({
  providedIn: 'root'
})
export class SectionsService {

  constructor(private _http: HttpClient) { }

  getSectionsList(){
    return this._http.get(URL_get_sections_list);
  }

  saveNewSection(formData : FormData){
    return this._http.post(URL_add_new_section, JSON.stringify(formData));
  }

  updateSection(rowData: any){
    return this._http.post(URL_update_section, JSON.stringify(rowData));
  }

  deleteSection(rowData: any){
    return this._http.post(URL_delete_section, JSON.stringify(rowData));
  }

  deleteSections(data: any){
    return this._http.post(URL_delete_sections, JSON.stringify(data));
  }

}
