import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation-dlg',
  templateUrl: './delete-confirmation-dlg.component.html',
  styleUrls: ['./delete-confirmation-dlg.component.css']
})
export class DeleteConfirmationDlgComponent implements OnInit {

  title: string;
  message: string;
  eventType: string;
  delOption: string = null;

  constructor(public dialogRef: MatDialogRef<DeleteConfirmationDlgComponent>, @Inject(MAT_DIALOG_DATA) data) { 
    this.title = data.title;
    this.message = data.message;
    this.eventType = data.eventType;
  }

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close({confirm: true, option: this.delOption});
  }

  onDismiss(): void {
    this.dialogRef.close({confirm: false});
  }
}