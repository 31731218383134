import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

const URL_save_letter = environment.url_blogics + '/letter/create_update_letter.php';
const URL_set_letter_gen_comment = environment.url_blogics + '/letter/set_letter_gen_comment.php';
const URL_get_letter_info = environment.url_blogics + '/letter/get_content_by_id.php';

@Injectable({
  providedIn: "root",
})
export class LetterWritingService {
  constructor(private httpclient: HttpClient) {}

  getLetterInfo(letterId : string){
    let params = new HttpParams();
    params = params.append('letterId', letterId);
    return(this.httpclient.get(URL_get_letter_info,{params: params}));
  }

  saveLetter(reqId : string, letterId : string, content : string) {
    return (this.httpclient.post(URL_save_letter, JSON.stringify({"requestId" : reqId, "letterId" : letterId, "content" : content}))) 
  }

  setComment(requestId : string, letterId : string) {
    return (this.httpclient.post(URL_set_letter_gen_comment, JSON.stringify({"requestId" : requestId, "letterId" : letterId}))) 
  }

}
