import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { GroupInfoPanelComponent } from '../group-info-panel/group-info-panel.component';
import { GroupListService } from './group-list.service';

export interface iGroupData {
  group_id: string;
  group_name: string;
  description: string;
  category: string;
  is_active:string;
}

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {

  displayedColumns: string[] = ['group_id', 'group_name', 'desctiption', 'group_type', 'active', 'action'];
  groupDetails: MatTableDataSource<iGroupData>

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private dialog           : MatDialog, 
    private confDialog       : MatDialog,
    private groupListService : GroupListService,
    private toastr           : ToastrService) { 
    this.loadAllGroupsData();
  }

  ngOnInit(): void {
  }

  loadAllGroupsData() {
    this.groupListService.getGroupList().subscribe(res => {
      this.groupDetails = new MatTableDataSource(res as iGroupData[]);
      this.groupDetails.sort = this.sort;
      this.groupDetails.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.groupDetails.filter = filterValue.trim().toLowerCase();
    if (this.groupDetails.paginator) {
      this.groupDetails.paginator.firstPage();
    }
  }

  viewGroupInfo(groupId: string, category: string){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= false;
    dialogconfig.width = "50%";
    dialogconfig.minWidth = "380px"; /*Set minimum width - Important for mobile widows*/
    dialogconfig.data = {
      group_id : groupId,
      source : "view&edit",
      category : category
    }
    var dialog_ref = this.dialog.open(GroupInfoPanelComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res != null && res['needRefresh']){
        this.loadAllGroupsData();
      }     
    });
  }

  addNewGroup(){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= false;
    dialogconfig.width = "50%";
    dialogconfig.minWidth = "380px"; /*Set minimum width - Important for mobile widows*/
    dialogconfig.data = {
      source : "addNew"
    }
    var dialog_ref = this.dialog.open(GroupInfoPanelComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res != null && res['needRefresh']){
        this.loadAllGroupsData();
      }   
    });
  }

  deleteGroup(groupId: string, index: any){
    const title = 'Delete Confirmation';
    const message = 'Are you sure you want to delete this group?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        this.groupListService.deleteGroup(groupId)
      .subscribe((data: any) => {
        if(data['status'] == "success"){
          this.groupDetails.data.splice(index, 1); // Remove from datasource
          this.groupDetails.data = this.groupDetails.data.slice();
          this.toastr.success('Record is deleted successfully', 'Success!');
        }
        else{
          this.toastr.error("Delete failed", 'Error!');
        }
      }, err => {
          this.toastr.error("Sever error", 'Error!');         
      });
      }
    });
  }

}
