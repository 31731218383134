import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { SlideInOutAnimation } from '../animations';
import { DesignationsService } from './designations.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';

export interface iDesignationData {
  designation_id: string;
  designation_name: string;
  designation_description: string;
}

@Component({
  selector: 'app-designations',
  templateUrl: './designations.component.html',
  styleUrls: ['./designations.component.css', '../basic-data.component.css'],
  animations: [SlideInOutAnimation]
})

export class DesignationsComponent implements OnInit {
  
  designationColumns: string[] = ['select', 'designation_id', 'designation_name', 'designation_description', 'action'];
  designationDetails: MatTableDataSource<iDesignationData>;
  selection = new SelectionModel<string>(true, []);
  editRowId = null;
  beforeEditRow : any;
  inEditMode : boolean = false;
  showAddNew : boolean = false;
  animationState = 'out';

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('table', { static: true }) table;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(
    private designationsService : DesignationsService,
    private _formBuilder        : FormBuilder,
    private toastr              : ToastrService,
    private confDialog          : MatDialog)
  {}

  designation_details = this._formBuilder.group({
    designation_id: ['', [Validators.required, Validators.maxLength(5)]],
    designation_name: ['', [Validators.required, , Validators.maxLength(50)]],
    designation_description: ['', Validators.maxLength(100)]
  });

  ngOnInit(): void {
    this.getDesignationDetails();
  }

  getDesignationDetails(){
    this.designationsService.getDesignationsList().subscribe(res => {
      this.designationDetails = new MatTableDataSource(res as iDesignationData[]);
      this.designationDetails.sort = this.sort;
      this.designationDetails.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.designationDetails.filter = filterValue.trim().toLowerCase();
    if (this.designationDetails.paginator) {
      this.designationDetails.paginator.firstPage();
    }
  }

  addNewRecord(){
    this.showAddNew = true;
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }

  saveNewRecord(){  
    this.designationsService.saveNewDesignation(this.designation_details.value)
    .subscribe((data: any) => {
      if(data['status'] == "success"){
        this.designationDetails.data.push(this.designation_details.value);
        this.designationDetails.data = this.designationDetails.data.slice();
        this.toastr.success('Record save succesfully', 'Success!');
        this.clearForm();
      }else{
        if(data['respond'] == "duplicate id"){
          this.toastr.error("ID is already exist", 'Error !');
        }
        else{
          this.toastr.error("Failed to create record", 'Error !');
        }
      }
    }, err => {
        this.toastr.error("Sever error", 'Error!');         
    });
  }

  cancelAddNew(){
    this.animationState = 'out';
  }

  editRow(row: any){
    this.beforeEditRow = JSON.parse(JSON.stringify(row));
    this.editRowId = row.designation_id;
    this.inEditMode = true;
  }

  updateEdit(rowData: iDesignationData){
    this.designationsService.updateDesignation(rowData)
      .subscribe((data: any) => {
        if(data['status'] == "success"){
          this.editRowId = null;
          this.beforeEditRow = null;
          this.inEditMode = false;
          this.toastr.success("Record successfully updated", 'Success!');
        }else{
          //this.cancelEdit(rowData);
          this.toastr.error("Update failed", 'Error!');
        }
      }, err => {
          this.toastr.error("Sever error", 'Error!');         
      });
  }

  cancelEdit(index: any){
    if(this.beforeEditRow != null){
      this.designationDetails.data[index] = this.beforeEditRow;
      this.designationDetails.data = this.designationDetails.data.slice(0);
    }
    this.editRowId = null;
    this.inEditMode = false;
  }

  deleteRow(rowData: any, index: any){
    const title = 'Delete Confirmation';
    const message = 'Are you sure you want to delete this record?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        this.designationsService.deleteDesignation(rowData)
      .subscribe((data: any) => {
        if(data['status'] == "success"){
          this.designationDetails.data.splice(index, 1); // Remove from datasource
          this.designationDetails.data = this.designationDetails.data.slice();
          this.toastr.success('Record is deleted successfully', 'Success!');
        }
        else if(data['status'] == "dependency"){
          this.toastr.warning('Record is dependent with other records', 'Failed!');
        }
        else{
          //this.cancelEdit(rowData);
          this.toastr.error("Delete failed", 'Error!');
        }
      }, err => {
          this.toastr.error("Sever error", 'Error!');         
      });
      }
    });   
  }

  deleteRows(){
    const title = 'Delete Confirmation';
    const message = 'Are you sure you want to delete these records?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        this.designationsService.deleteDesignations(this.selection.selected)
        .subscribe((response: any) => {
          response.forEach(data => {
            if(data['status'] == "success"){
              let index: number = this.designationDetails.data.findIndex(d => d.designation_id == data['record_id']);
              this.designationDetails.data.splice(index,1) // Remove from records datasource
              this.designationDetails.data = this.designationDetails.data.slice();
              this.toastr.success('Records are deleted successfully', 'Success!');
            }
            else if(data['status'] == "dependency"){
              this.toastr.warning("Designation "+data['record_id']+" has dependency with other records", 'Failed!');
            }
            else{
              this.toastr.error("Delete failed", 'Error!');
            }
          });
            this.selection = new SelectionModel<string>(true, []);
        }, err => {
            this.toastr.error("Sever error", 'Error!');         
        });
      }
    });   
  }

  clearForm(){
    this.formGroupDirective.resetForm();
    this.designation_details.reset();  
  }

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.designationDetails.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.designationDetails.data.forEach(row => this.selection.select(row.designation_id));
  }
}