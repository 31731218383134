import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-export-confirmation-dlg',
  templateUrl: './export-confirmation-dlg.component.html',
  styleUrls: ['./export-confirmation-dlg.component.css']
})
export class ExportConfirmationDlgComponent implements OnInit {

  title: string;
  setComment: string = "false";
  exportType: string = "excel";
  comment = new FormControl('', [Validators.required]);
  //comment: string = null;

  constructor(public dialogRef: MatDialogRef<ExportConfirmationDlgComponent>, 
    @Inject(MAT_DIALOG_DATA) data) { 
    this.title = data.title;
  }

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close({
      confirm    : true, 
      setComment : this.setComment,
      exportType : this.exportType,
      comment    : this.comment.value});
  }

  onDismiss(): void {
    this.dialogRef.close({confirm: false});
  }
}
