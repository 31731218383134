import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

/**
 * url_blogics from environment.ts concat with /new-request/ will returns full url fot resource
 */
const URL_create_request = environment.url_blogics+'/new-request/create_request.php'
const URL_generate_id = environment.url_blogics+'/new-request/generate_request_id.php'
const URL_get_requester_data = environment.url_blogics+'/new-request/get_requester_data.php'
const URL_get_title_list = environment.url_sfeatures+'/basic-data/get_title_list.php';
// const URL_get_division_list = environment.url_sfeatures+'/basic-data/get_division_list.php';
// const URL_get_categories = environment.url_blogics + "/progress/get_ticket_categories_by_section.php";
const URL_get_group_list = environment.url_blogics+"/collaboration/group_list/get_active_group_list.php";
const URL_get_group_member_list = environment.url_blogics+"/collaboration/group_list/get_group_members.php";
@Injectable({
  providedIn: 'root'
})

export class NewRequestService {

  constructor(private _http: HttpClient) { }

  /**
   * this methods takes following parameters
   * @param nid
   * @param mobile
   * @param email
   * @param reference
   * @param name_in_full
   * @param name_in_use
   * @param dob
   * @param address
   * @param background
   * @param topic
   * @param as_member
   * @param as_group
   * @param request_detail
   * @param attachments
   */
  createRequest(form_data,attachments){

    let requester: {[key: string]: string} = {
      nid: form_data['nic'],
      mobile: form_data['mobile_no'],
      email: form_data['email'],
      title: form_data['title'],
      name_in_full: form_data['name_in_full'],
      name_in_use: form_data['name_in_use'],
      dob: form_data['dob'],
      address: form_data['address'],
      background: form_data['background']
    }

    let request: {[key: string]: string} = {
      reference_no: form_data['ref_no'],
      priority: form_data['priority'],
      topic:form_data['topic'],
      assignment_group: form_data['as_group'],
      assigned_to:form_data['as_member'],
      request_detail:form_data['request']
      // service_area:form_data['service_area']
    }

    return this._http.post<any>(URL_create_request, JSON.stringify({requester, request,attachments}));
  }

  getGeneratedId(){
    return this._http.post(URL_generate_id, JSON.stringify({}));
  }

  getDefaults(nic: string){
    return this._http.post(URL_get_requester_data, JSON.stringify({nic}));
  }

  getTitleList(){
    return this._http.get(URL_get_title_list);
  }

  getAssignmentGroupList(): Observable<any>{
    return this._http.post(URL_get_group_list,JSON.stringify({}));
  }
  getAssignmentGroupMemberList(group_id:string):Observable<any>{
    console.log("group_id : "+group_id);
    let params = new HttpParams();
    params = params.append('group_id', group_id.toString());
    return this._http.get(URL_get_group_member_list, {params: params});
  }

  // getDivisionList(): Observable<any> {
  //   return this._http.get(URL_get_division_list);
  // }

  // getCategoryList(sectionID: string): Observable<any> {
  //   return this._http.post(
  //     URL_get_categories,
  //     JSON.stringify({ section_id: sectionID })
  //   );
  // }
}

