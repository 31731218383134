<div class="row" style="margin-right: 0px;">
    <h2 class="window-title" style="margin-left: 30px;">Communication</h2>
</div>
<mat-card>
    <mat-accordion>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
            Email
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div class="row">
                    <div class="col-md-3 col-xs-12">
                        <!-- <mat-form-field class="mailGroupInput">
                            <mat-label>To</mat-label>
                            <mat-select [formControl]="mailGroup" multiple>
                            <mat-option *ngFor="let mailGroup of mailGroupList" [value]="mailGroup">{{mailGroup}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <!-- <mat-form-field class="mailGroupInput">
                            <mat-select [formControl]="mailGroup" placeholder="Permissions" multiple>
                                <mat-select-filter [array]="permissions" (filteredReturn)="filteredList=$event" [displayMember]="'value'">
                                </mat-select-filter>
                                <mat-option *ngFor="let permission of permissions" [value]="permission"
                                    [class.hide]="!isFiltered(permission)">
                                    {{permission.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <mat-form-field class="mailGroupInput">
                            <mat-select [formControl]="mailGroups" placeholder="To" multiple>
                                <mat-select-filter style="height: 20px;" [array]="groupList" (filteredReturn)="filteredGroupList=$event" [displayMember]="'group_id'">
                                </mat-select-filter>
                                <mat-option *ngFor="let group of groupList" [value]="group.group_id"
                                    [class.hide]="!isFiltered(group)">
                                    {{group.group_id+' - '+group.group_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>



                    </div>
                    <div class="col-md-7 col-xs-12">
                        <mat-form-field class="mailSubjectInput">
                            <mat-label>Subject</mat-label>
                            <input matInput [formControl]="emailSubject" placeholder="Enter Emal subject here">
                            <mat-error *ngIf="textMessage.hasError('required')">
                                Entering message is <strong>mandatory</strong> action before send SMS
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 col-xs-12">
                        <button mat-raised-button color="primary" (click)="onMailPost()" style="float: right; margin-top:10px">Send</button>
                    </div>
                </div>           
                <ejs-richtexteditor #mailBox id='mailBox' (toolbarClick)='onToolbarClick($event)' [toolbarSettings]='tools' [(value)]='value' [insertImageSettings]='image' style="z-index: 0;"> </ejs-richtexteditor>
            </ng-template>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
            SMS
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div class="row">
                    <div class="col-10">
                        <mat-form-field class="mailGroupInput">
                            <mat-label>To</mat-label>
                            <mat-select [formControl]="smsGroup" multiple>
                            <mat-option *ngFor="let smsGroup of smsGroupList" [value]="smsGroup">{{smsGroup}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <button mat-raised-button color="primary" (click)="onSmsSend()" style="float: right; margin-top:10px">Send</button>
                    </div>
                </div>           
                <mat-form-field class="messageField">
                    <mat-label>Message</mat-label>
                    <textarea #Input matInput [formControl]="textMessage" maxlength="100" placeholder="Enter message your message here..."></textarea>
                    <mat-error *ngIf="textMessage.hasError('required')">
                    Entering message is <strong>mandatory</strong> action before send SMS
                    </mat-error>
                    <mat-hint align="end">{{Input.value?.length || 0}}/100</mat-hint>
                </mat-form-field>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card>