import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Md5 } from 'ts-md5';
import { AuthService } from 'src/app/services/auth.service';

const URL_get_user_defaults = environment.url_blogics + '/users/get_user_through_id.php';
const URL_get_user_roles_list = environment.url_sfeatures + '/basic-data/get_user_roles.php';
const URL_get_designations_list = environment.url_sfeatures + '/basic-data/get_designation_list.php';
const URL_get_sections_list = environment.url_sfeatures + '/basic-data/get_section_list.php';
const URL_update_user_details = environment.url_blogics + '/users/update_user_details.php';
const URL_add_new_user = environment.url_blogics + '/users/create_new_user.php';
const URL_set_tmp_pwd = environment.url_blogics + '/users/set_temp_password.php';

@Injectable({
  providedIn: 'root'
})
export class UserPanelService {

  constructor(private _http: HttpClient, private _auth: AuthService,) { }
  
  getUserDetails(userId: string, username: string){
    return this._http.post(URL_get_user_defaults,JSON.stringify({"user_id": userId, "user_name": username}));
  }

  updateUserDetails(formData: FormData){
    return this._http.post(URL_update_user_details, JSON.stringify(formData));
  }

  getUserRolesList(){
    return this._http.get(URL_get_user_roles_list);
  }

  getDesignationsList(){
    return this._http.get(URL_get_designations_list);
  }

  getSectionsList(){
    return this._http.get(URL_get_sections_list);
  }

  addNewUser(formData: FormData){
    return this._http.post(URL_add_new_user, JSON.stringify(formData));
  }

  setTmpPassword(userName: string, formData: FormData){
    const md51 = new Md5();
    const md52 = new Md5();
    var pwd_string = btoa(md51.appendStr(formData['admin_pwd']).end() +':'+ md52.appendStr(formData['tmp_pwd']).end());
    return this._http.post(URL_set_tmp_pwd, JSON.stringify({"user_name": userName, "admin_user_id": this._auth.getUserDetails['userId'], "pwd_string":pwd_string}));
  }
}
