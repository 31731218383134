import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from  'rxjs/operators';

const URL_attachement = environment.url_sfeatures + '/upload-file/file_upload.php';
const URL_user_image  = environment.url_blogics + '/settings/upload_profile_img.php';

@Injectable({
  providedIn: 'root'
})
export class FileUploadServiceService {
  constructor(private http: HttpClient) { }

  fileRemove(ref_no: string, fileName: string, filePath: string, source: string, action: string) {
    const formData = new FormData();
    formData.append('ref_no', ref_no);
    formData.append('file_name', fileName);
    formData.append('file_path', filePath);
    formData.append('source', source);
    formData.append('action', action);
    formData.append('token', localStorage.getItem('token'));
    return this.http.post(URL_attachement, formData);
  }
  
  // postFileRemove(filepath: string) {
  //   const formData = new FormData();
  //   formData.append('file_path', filepath);
  //   formData.append('action', 'post_remove');
  //   formData.append('token', localStorage.getItem('token'));
  //   return this.http.post(URL_attachement, formData);
  // }

  fileUpload(ref_no: string, files: File[], source: string, action: string): Observable<any> {  
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {  
      formData.append("attachments_"+[i], files[i]);  
    }
    formData.append('ref_no', ref_no);
    formData.append('source', source);
    formData.append('action', action);
    return this.http.post(URL_attachement, formData);
  }

  // postFileUpload(ref_no: string, files: File[]): Observable<any> {  
  //   const formData = new FormData();
  //   for (var i = 0; i < files.length; i++) {  
  //     formData.append("attachments_"+[i], files[i]);  
  //   }
  //   formData.append('ref_no', ref_no);
  //   formData.append('action', 'post_upload');
  //   return this.http.post(URL_attachement, formData);
  // }

  uploadUserImage(username: string, userId: string, image: File): Observable<any> {  
    const formData = new FormData();
    if(username != null){
      formData.append('user_name', username); 
    }
    if(userId != null){ 
      formData.append('user_id', userId); 
    }
    formData.append('action', 'upload');
    formData.append('file', image);
    return this.http.post(URL_user_image, formData);
  }

  removeUserImage(username: string, userId: string, imageUrl: string): Observable<any> {  
    const formData = new FormData();
    if(username != null){ 
      formData.append('user_name', username); 
    }
    if(userId != null){ 
      formData.append('user_id', userId); 
    }
    formData.append('action', 'remove');
    formData.append('image_url', imageUrl);
    return this.http.post(URL_user_image, formData);
  }
}
