import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { GroupInfoService } from '../group-info-panel.service';

export interface UserAttendee {
  user_id: string;
  user_name: string;
  first_name: string;
  last_name: string;
  profile_url: string;
  selected: boolean;
}

export interface ReqAttendee {
  nic: string;
  name_in_full: string;
  selected: boolean;
}

@Component({
  selector: 'app-edit-members-dlg',
  templateUrl: './edit-members-dlg.component.html',
  styleUrls: ['./edit-members-dlg.component.css']
})
export class EditMembersDlgComponent implements OnInit {

  selectedUserMemberList: UserAttendee[] = [];
  selectedReqMemberList: ReqAttendee[] = [];
  removedUserMemberList: UserAttendee[] = [];
  removedReqMemberList: ReqAttendee[] = [];
  groupId: string;
  origin: string = 'group-info'; //To pass participant-adding component
  groupCategory: string;
  public selectedUserIds: String[] = []; //Use when submit
  public selectedReqNics: String[] = []; //Use when submit
  public removedUserIds: String[] = []; //Use when submit
  public removedReqNics: String[] = []; //Use when submit

  constructor(
    public dialogRef: MatDialogRef<EditMembersDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private groupInfoService  : GroupInfoService,
    private toastr            : ToastrService
  ) { 
    this.groupId = data.group_id;
    this.selectedUserMemberList = data.user_members;
    this.selectedReqMemberList = data.req_members;
    this.groupCategory = data.group_category;
  }

  ngOnInit(): void {
  }

  onCloseClick(){
    this.dialogRef.close();
  }

  removeDuplicates(){

  }

  updateMembers(){
    this.selectedUserMemberList.forEach(element => {
      this.selectedUserIds.push(element.user_id);
    });

    this.selectedReqMemberList.forEach(element => {
      this.selectedReqNics.push(element.nic);
    });

    this.removedReqMemberList.forEach(element => {
      this.removedReqNics.push(element.nic);
    });

    //Create removedUserId list eliminating duplicates, 
    //elements in selected list
    if(this.removedUserMemberList.length > 0){
      this.removedUserMemberList.forEach(obj => {
        if(!this.selectedUserMemberList.includes(obj)){
           if(!this.removedUserIds.some(el => el == obj.user_id)){
            this.removedUserIds.push(obj.user_id);
           }
          }
      });
    }

    //Create removedReqNics list eliminating duplicates, 
    //elements in selected list
    if(this.removedReqMemberList.length > 0){
      this.removedReqMemberList.forEach(obj => {
        if(!this.selectedReqMemberList.includes(obj)){
           if(!this.removedReqNics.some(el => el == obj.nic)){
            this.removedReqNics.push(obj.nic);
           }
          }
      });
    }
    
    this.groupInfoService.addOrUpdateGroupMembers(this.groupId, this.groupCategory, this.selectedUserIds, this.selectedReqNics, this.removedUserIds, this.removedReqNics).subscribe(
      res => {
        if(res['status'] == 'success'){
          this.toastr.success('Group members updated successfully', 'Success');     
        }
        else{
          this.toastr.error('Something went wrong!', 'Failed');
        }        
      },
      err => {
        this.toastr.error('Something went wrong!', 'Failed');
      }
    );
    this.selectedUserIds = [];
    this.selectedReqNics = [];
    this.removedUserIds = [];
    this.removedReqNics = [];
  }
}