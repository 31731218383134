import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

const URL_get_titles_list = environment.url_sfeatures + '/basic-data/get_title_list.php';
const URL_add_new_title = environment.url_blogics + '/basic-data/title/create_title.php';
const URL_update_title = environment.url_blogics + '/basic-data/title/update_title.php';
const URL_delete_title = environment.url_blogics + '/basic-data/title/remove_title.php';
const URL_delete_titles = environment.url_blogics + '/basic-data/title/remove_titles.php';

@Injectable({
  providedIn: 'root'
})
export class TitlesService {

  constructor(private _http: HttpClient) { }

  getTitlesList(){
    return this._http.get(URL_get_titles_list);
  }

  saveNewTitle(formData : FormData){
    return this._http.post(URL_add_new_title, JSON.stringify(formData));
  }

  updateTitle(rowData: any){
    return this._http.post(URL_update_title, JSON.stringify(rowData));
  }

  deleteTitle(rowData: any){
    return this._http.post(URL_delete_title, JSON.stringify(rowData));
  }

  deleteTitles(data: any){
    return this._http.post(URL_delete_titles, JSON.stringify(data));
  }

}
