import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { SlideInOutAnimation } from '../animations';
import { TitlesService } from './titles.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';

export interface iTitleData {
  title_id: string;
  title_name: string;
}

@Component({
  selector: 'app-titles',
  templateUrl: './titles.component.html',
  styleUrls: ['./titles.component.css', '../basic-data.component.css'],
  animations: [SlideInOutAnimation]
})
export class TitlesComponent implements OnInit {

  titleColumns: string[] = ['select', 'title_name', 'action'];
  titleDetails: MatTableDataSource<iTitleData>;
  selection = new SelectionModel<string>(true, []);
  editRowId = null;
  beforeEditRow : any;
  inEditMode : boolean = false;
  showAddNew : boolean = false;
  animationState = 'out';

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('table', { static: true }) table;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(
    private titlesService : TitlesService,
    private _formBuilder  : FormBuilder,
    private toastr        : ToastrService,
    private confDialog    : MatDialog,)
  {}

  title_details = this._formBuilder.group({
    title_id: [''],
    title_name: ['', [Validators.required, , Validators.maxLength(50)]]
  });

  ngOnInit(): void {
    this.getTitleDetails();
  }

  getTitleDetails(){
    this.titlesService.getTitlesList().subscribe(res => {
      this.titleDetails = new MatTableDataSource(res as iTitleData[]);
      this.titleDetails.sort = this.sort;
      this.titleDetails.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.titleDetails.filter = filterValue.trim().toLowerCase();
    if (this.titleDetails.paginator) {
      this.titleDetails.paginator.firstPage();
    }
  }

  addNewRecord(){
    this.showAddNew = true;
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }

  saveNewRecord(){  
    this.titlesService.saveNewTitle(this.title_details.value)
    .subscribe((data: any) => {
      if(data['status'] == "success"){
        this.title_details.patchValue({title_id : data['record_id']});
        this.titleDetails.data.push(this.title_details.value);
        this.titleDetails.data = this.titleDetails.data.slice();
        this.toastr.success('Record saved succesfully', 'Success!');
        this.clearForm();
      }else{
        this.toastr.error("Failed to create record", 'Error !');
      }
    }, err => {
        this.toastr.error("Sever error", 'Error!');         
    });
  }

  cancelAddNew(){
    this.animationState = 'out';
  }

  editRow(row: any){
    this.beforeEditRow = JSON.parse(JSON.stringify(row));
    this.editRowId = row.title_id;
    this.inEditMode = true;
  }

  updateEdit(rowData: iTitleData){
    this.titlesService.updateTitle(rowData)
      .subscribe((data: any) => {
        if(data['status'] == "success"){
          this.editRowId = null;
          this.beforeEditRow = null;
          this.inEditMode = false;
          this.toastr.success(data.Message, 'Success!');
        }else{
          //this.cancelEdit(rowData);
          this.toastr.error("Update failed", 'Error!');
        }
      }, err => {
          this.toastr.error("Sever error", 'Error!');         
      });
  }

  cancelEdit(index: any){
    if(this.beforeEditRow != null){
      this.titleDetails.data[index] = this.beforeEditRow;
      this.titleDetails.data = this.titleDetails.data.slice(0);
    }
    this.editRowId = null;
    this.inEditMode = false;
  }

  deleteRow(rowData: any, index: any){
    const title = 'Delete Confirmation';
    const message = 'Are you sure you want to delete this record?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        this.titlesService.deleteTitle(rowData)
      .subscribe((data: any) => {
        if(data['status'] == "success"){
          this.titleDetails.data.splice(index, 1); // Remove from datasource
          this.titleDetails.data = this.titleDetails.data.slice();
          this.toastr.success('Record is deleted successfully', 'Success!');
        }
        else if(data['status'] == "dependency"){
          this.toastr.warning('Record is dependent with other records', 'Failed!');
        }
        else{
          this.toastr.error("Delete failed", 'Error!');
        }
      }, err => {
          this.toastr.error("Sever error", 'Error!');         
      });
      }
    });   
  }

  deleteRows(){
    const title = 'Delete Confirmation';
    const message = 'Are you sure you want to delete these records?';
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.maxWidth = "400px";
    dialogconfig.data = {
      title : title,
      message : message
    }
    var dialog_ref = this.confDialog.open(ConfirmDialogComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      if(res){
        this.titlesService.deleteTitles(this.selection.selected)
        .subscribe((response: any) => {
          response.forEach(data => {
            if(data['status'] == "success"){
              let index: number = this.titleDetails.data.findIndex(d => d.title_id == data['record_id']);
              this.titleDetails.data.splice(index,1) // Remove from records datasource
              this.titleDetails.data = this.titleDetails.data.slice();
              this.toastr.success('Records are deleted successfully', 'Success!');
            }
            else if(data['status'] == "dependency"){
              let titleName = this.titleDetails.data.find(d => d.title_id == data['record_id']).title_name;
              this.toastr.warning("Title "+titleName+" has dependency with other records", 'Failed!');
            }
            else{           
              this.toastr.error("Delete failed", 'Error!');
            }
          });
            this.selection = new SelectionModel<string>(true, []);
      }, err => {
          this.toastr.error("Sever error", 'Error!');         
      });
      }
    });   
  }

  clearForm(){
    this.formGroupDirective.resetForm();
    this.title_details.reset();  
  }

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.titleDetails.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.titleDetails.data.forEach(row => this.selection.select(row.title_id));
  }

}
