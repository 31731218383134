<div class="window-title">
    <h2>Group List</h2>
  </div>
  <div class="search-div">
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
    <div class="row search-row">
      <div class="col-xl-2 col-sm-5">
        <mat-form-field> 
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Enter any key word">
        </mat-form-field>
      </div>
      <div class="col-xl-3 col-sm-4">
        <button mat-flat-button (click)="addNewGroup()" color="primary" type="button" style="margin-left:10px">
          <mat-icon aria-hidden="false">group_add</mat-icon>&emsp;New Group</button>
      </div>
    </div>
  </div> 
  <div class="table-wrapper">
    <table mat-table [dataSource]="groupDetails" matSort>
      <ng-container matColumnDef="group_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Id </th>
        <td mat-cell *matCellDef="let row"> {{row.group_id}} </td>
      </ng-container>
      <ng-container matColumnDef="group_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Name </th>
        <td mat-cell *matCellDef="let row"> {{row.group_name}} </td>
      </ng-container>
      <ng-container matColumnDef="desctiption">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let row">{{row.description}}  </td>
      </ng-container>
      <ng-container matColumnDef="group_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Type </th>
        <td mat-cell *matCellDef="let row"> {{row.category}} </td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>     
        <td mat-cell *matCellDef="let row">
          <span class="badge" [ngClass]="{'badge-danger': row.is_active == '0', 'badge-success': row.is_active == '1'}"> 
            {{row.is_active == '1' ? ' Active ' : 'Inactive'}} 
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row; let i = index" style="justify-content: center; text-align: right;"> 
          <button mat-flat-button (click)="viewGroupInfo(row.group_id, row.category)">
            <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
          </button> 
          <button mat-flat-button (click)="deleteGroup(row.group_id, i)">
            <i class="fa fa-trash-o fa-2x" aria-hidden="true"></i>
          </button> 
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>