<div class="main-mat">
    <div class="row head-row">
        <mat-card-title *ngIf="source == 'view&edit'">{{nic || requester_details.controls.nic.value}}</mat-card-title>
        <mat-card-title *ngIf="source == 'addNew'"><i class="fa fa-user-plus fa-lg" aria-hidden="true"></i>&ensp;New Requester</mat-card-title>
        <button mat-stroked-button class="btn-close" (click)="onCloseClick()">Close</button>
    </div>
    <mat-card-subtitle>Requester Details</mat-card-subtitle>
    <mat-card class="in-mat">
        <form [formGroup]="requester_details" enctype="multipart/form-data">
            <div class="container row">                
                <div class="col-sm-12">
                    <div class="row" *ngIf="source == 'addNew'">
                        <div class="col-2">NIC</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <input matInput formControlName="nic">
                        </mat-form-field>               
                    </div>
                    <div class="row">
                        <div class="col-2">Title</div>
                        <div class="col-4" *ngIf="!isEditable">{{titleName}}</div>
                        <mat-form-field *ngIf="isEditable" class="inputLabel">
                            <mat-select formControlName="title" >
                                <mat-option *ngFor="let title of titleList" [value]="title.title_id">
                                    {{title.title_name}}
                                </mat-option> 
                            </mat-select>                                     
                        </mat-form-field>                   
                    </div>
                    <div class="row">
                        <div class="col-2">Name In Use</div>
                        <div class="col-10" *ngIf="!isEditable">{{requester_details.controls.name_in_use.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <input matInput formControlName="name_in_use">
                        </mat-form-field>               
                    </div>
                    <div class="row" >
                        <div class="col-2">Full Name</div>
                        <div class="col-10" *ngIf="!isEditable">{{requester_details.controls.name_in_full.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <input matInput formControlName="name_in_full">
                        </mat-form-field> 
                    </div>
                    <div class="row">
                        <div class="col-2">Requester Type</div>
                        <div class="col-10" *ngIf="!isEditable">{{requesterTypeName}}</div>
                        <mat-form-field *ngIf="isEditable" class="inputLabel">
                            <mat-select formControlName="requester_type" >
                                <mat-option *ngFor="let reqType of requesterTypeList" [value]="reqType.type_id">
                                    {{reqType.type_name}}
                                </mat-option> 
                            </mat-select>                                     
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-2">Division</div>
                        <div class="col-10" *ngIf="!isEditable">{{divisionName}}</div>
                        <mat-form-field *ngIf="isEditable" class="inputLabel">
                            <mat-select formControlName="division" >
                                <mat-option *ngFor="let division of divisionList" [value]="division.division_id">
                                    {{division.division_name}}
                                </mat-option> 
                            </mat-select>                                     
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-2">Mobile No</div>
                        <div class="col-10" *ngIf="!isEditable">{{requester_details.controls.mobile_no.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable" style="max-width: 284px;">
                            <input matInput formControlName="mobile_no" type="number">
                        </mat-form-field>
                    </div> 
                    <div class="row">
                        <div class="col-2">Date of Birth</div>
                        <div class="col-10" *ngIf="!isEditable">{{requester_details.controls.dob.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable" style="max-width: 284px;">
                            <input matInput [matDatepicker]="picker" formControlName="dob">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>                                      
                    <div class="row">
                        <div class="col-2">Email</div>
                        <div class="col-10" *ngIf="!isEditable">{{requester_details.controls.email.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <input matInput formControlName="email">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-2">Adderess</div>
                        <div class="col-10" *ngIf="!isEditable">{{requester_details.controls.address.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <input matInput formControlName="address">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-2">State</div>
                        <div class="col-10">
                            <mat-slide-toggle [checked]="requester_details.controls.active.value == '1' ? true : false"
                             [disabled]="!isEditable" (change)="setState($event)">
                                <div>{{requester_details.controls.active.value == '1' ? 'Active' : 'Inactive'}}</div>
                            </mat-slide-toggle>
                        </div>
                    </div>                  
                    <div class="row">
                        <div class="col-2">Personal Background</div>
                        <div class="col-10" *ngIf="!isEditable">{{requester_details.controls.background.value}}</div>
                        <mat-form-field class="col-10 inputLabel" *ngIf="isEditable">
                            <textarea matInput formControlName="background" style="max-height: 100px;"></textarea>
                        </mat-form-field>
                    </div>                                             
                </div>
            </div>
            <br>
            <br>
            <div class="button-line">
                <button mat-raised-button *ngIf="isEditable" (click)="saveDetails()" [disabled]="!requester_details.valid" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px">Save Details</button>
                <button mat-raised-button *ngIf="source == 'view&edit' && permission.PERM_EDIT_REQUESTER" (click)="onEditCancel()" color="primary" type="button" class="mat-button-class" style="float: right;">{{editBtnName}}</button>
                <button mat-raised-button *ngIf="source == 'addNew'" (click)="clear()" color="primary" type="button" class="mat-button-class" style="float: right;">Clear</button>
            </div>
        </form>
    </mat-card>
</div>