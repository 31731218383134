import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { UsersService } from './users.service';
import { PermissionService } from '../services/permissions.service';

export interface iUserData {
  user_name: string;
  first_name: string;
  last_name: string;
  mobile_no: string;
  nic: string;
  designation: string;
  active: string;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  designation: string;
  displayedColumns: string[] = ['user_image', 'user_name', 'first_name', 'last_name', 'nic', 'user_role', 'active', 'button'];
  usersDetails: MatTableDataSource<iUserData>;
  public defaultImage: string = '../../assets/images/user-default.png';

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private dialog : MatDialog, private usersService :  UsersService, public permission: PermissionService) {
    this.loadAllUsersData();
  }

  ngOnInit() {}

  loadAllUsersData() {
    this.usersService.getAllUsersData().subscribe(res => {
      this.usersDetails = new MatTableDataSource(res as iUserData[]);
      this.usersDetails.sort = this.sort;
      this.usersDetails.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.usersDetails.filter = filterValue.trim().toLowerCase();
    if (this.usersDetails.paginator) {
      this.usersDetails.paginator.firstPage();
    }
  }

  viewUserPanel(userId: string, username: string){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = false;
    dialogconfig.autoFocus= true;
    dialogconfig.width = "70%";
    dialogconfig.minWidth = "380px"; /*Set minimum width - Important for mobile widows*/
    dialogconfig.data = {
      userId : userId,
      username : username,
      source : "view&edit"
    }
    var dialog_ref = this.dialog.open(UserPanelComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      this.loadAllUsersData();
    });
  }
  
  addNewUser(){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.autoFocus= true;
    dialogconfig.width = "95%";
    dialogconfig.data = {
      source : "addNew"
    }
    var dialog_ref = this.dialog.open(UserPanelComponent, dialogconfig);
    dialog_ref.afterClosed().subscribe(res=>{
      this.loadAllUsersData();
    });
  }
}
