<h1 mat-dialog-title>
    Add Item
  </h1>
  
  <div mat-dialog-content>
    <mat-form-field>
        <input (keydown.space)="$event.preventDefault()" matInput [formControl]="itemValue" required>
    </mat-form-field>
  </div>
  
  <div mat-dialog-actions>
    <button mat-stroked-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onSave()">Save</button>
  </div>