<div class="row" style="margin-top: 15px; margin-right: 0px;">
    <div class="col-md-3 col-xs-6">
    <mat-form-field class="inline-form-field">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
    </div>
    <div class="col-md-9 col-xs-6">
        <button mat-raised-button (click)="addNewRecord()" type="button" style="margin-left:10px; margin-top:10px">
        <mat-icon aria-hidden="false">add</mat-icon>&emsp;
        </button>
        <button mat-raised-button (click)="deleteRows()" [disabled]="!selection.hasValue()" type="button" style="margin-left:10px; margin-top:10px">
        <mat-icon aria-hidden="false">delete</mat-icon>
        &emsp;
        </button>
    </div>
</div>
<mat-card [@slideInOut]="animationState" [hidden]="!showAddNew" class="add-new-card">
    <form [formGroup]="title_details">
        <div class="row">
            <div class="col-6">
                <mat-form-field class="inline-form-field">
                    <mat-label>Title Name</mat-label>
                    <input matInput formControlName="title_name" required>
                </mat-form-field>
            </div>
            <div class="col-6">
                <button mat-flat-button color="primary" (click)="cancelAddNew()" style="float: right; margin-top:10px; margin-left:10px">Cancel</button>
                <button mat-flat-button color="primary" [disabled]="!title_details.valid" (click)="saveNewRecord()" style="float: right; width: 75px; margin-top:10px">Save</button>
            </div>
        </div>
    </form>
</mat-card>
<table mat-table [dataSource]="titleDetails" matSort>
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row.title_id) : null"
                        [checked]="selection.isSelected(row.title_id)">
          </mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="title_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Title Name </th>
        <td mat-cell *matCellDef="let row">        
            <ng-container *ngIf="row.title_id!==editRowId">
                {{row.title_name}}
            </ng-container>
            <ng-container *ngIf="row.title_id===editRowId">
                <mat-form-field>
                    <input matInput name="title name" [(ngModel)]="row.title_name"  maxlength="50" required>
                </mat-form-field>
            </ng-container>     
        </td>
    </ng-container>
    <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row ; let i = index" style="justify-content: center; text-align: right;"> 
            <button mat-flat-button *ngIf="row.title_id!==editRowId" (click)="editRow(row)" [disabled]="inEditMode">
                <i class="fa fa-pencil fa-2x" aria-hidden="true"></i>
            </button> 
            <button mat-flat-button *ngIf="row.title_id!==editRowId" (click)="deleteRow(row, i)" [disabled]="inEditMode">
                <i class="fa fa-trash-o fa-2x" aria-hidden="true"></i>
            </button> 
            <ng-container *ngIf="row.title_id===editRowId">
                <button mat-icon-button color="primary" matTooltip="Update" (click)="updateEdit(row)">
                    <i class="fa fa-check fa-2x" aria-hidden="true"></i>
                </button>
                <button mat-icon-button color="warn" matTooltip="Cancel" (click)="cancelEdit(i)" style="margin-left: 25px; margin-right: 10px;">
                    <i class="fa fa-close fa-2x" aria-hidden="true"></i>
                </button>
            </ng-container>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="titleColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: titleColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="25"></mat-paginator>