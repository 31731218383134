import { Injectable } from '@angular/core';
import { tr } from 'date-fns/locale';
import { environment } from '../../environments/environment';

/**
 * This service used to maintain permissions for UI components within a module.
 * There is several permission parameters to view/hide some elements in components 
 * according to the privilages of use roles
 */
const URL = environment.url_blogics;

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private ADD_USER = false;
  private EDIT_USER = false;
  private ADD_REQUESTER = false;
  private EDIT_REQUESTER = false;
  private ADD_EVENT = false;
  private EDIT_EVENT = false;
  private DELETE_EVENT = false;

  private LOAD_ALL_REQUESTS = false;
  private LOAD_ALL_AGREEMENTS = false;

  constructor() 
  { }

  setPermissions(userRole: string){
    //For Super Admin
    if(userRole == "1"){
        this.ADD_USER = true;
        this.EDIT_USER = true;
        this.ADD_REQUESTER = true;
        this.EDIT_REQUESTER = true;
        this.ADD_EVENT = true;
        this.EDIT_EVENT = true;
        this.DELETE_EVENT = true;
        this.LOAD_ALL_REQUESTS = true;
        this.LOAD_ALL_AGREEMENTS = true;
    }
    //For Admin
    else if(userRole == "2"){
        this.ADD_USER = false;
        this.EDIT_USER = false;
        this.ADD_REQUESTER = true;
        this.EDIT_REQUESTER = true;
        this.ADD_EVENT = true;
        this.EDIT_EVENT = true;
        this.DELETE_EVENT = true;
        this.LOAD_ALL_REQUESTS = true;
        this.LOAD_ALL_AGREEMENTS = true;
    }
    //For Full Filler
    else if(userRole == "3"){
        this.ADD_USER = false;
        this.EDIT_USER = false;
        this.ADD_REQUESTER = false;
        this.EDIT_REQUESTER = false;
        this.ADD_EVENT = true;
        this.EDIT_EVENT = true;
        this.DELETE_EVENT = true;
        this.LOAD_ALL_REQUESTS = false;
        this.LOAD_ALL_AGREEMENTS = false;
    }
    //For App User
    else if(userRole == "4"){
        this.ADD_USER = false;
        this.EDIT_USER = false;
        this.ADD_REQUESTER = false;
        this.EDIT_REQUESTER = false;
        this.ADD_EVENT = false;
        this.EDIT_EVENT = false;
        this.DELETE_EVENT = false;
        this.LOAD_ALL_REQUESTS = false;
        this.LOAD_ALL_AGREEMENTS = false;
    }
  }

  get PERM_ADD_USER() {
    return this.ADD_USER;
  }
  get PERM_EDIT_USER() {
    return this.EDIT_USER;
  }
  get PERM_ADD_REQUESTER() {
    return this.ADD_REQUESTER;
  }
  get PERM_EDIT_REQUESTER() {
    return this.EDIT_REQUESTER;
  }
  get PERM_ADD_EVENT() {
    return this.ADD_EVENT;
  }
  get PERM_EDIT_EVENT() {
    return this.EDIT_EVENT;
  }
  get PERM_DELETE_EVENT() {
    return this.DELETE_EVENT;
  }

  get PERM_LOAD_ALL_REQUESTS() {
    return this.LOAD_ALL_REQUESTS;
  }
  get PERM_LOAD_ALL_AGREEMENTS() {
    return this.LOAD_ALL_AGREEMENTS;
  }
}