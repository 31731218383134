<div class="row" style="margin-top: 15px; margin-right: 0px;">
    <div class="col-md-3 col-xs-6">
    <mat-form-field class="inline-form-field">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
    </div>
    <div class="col-md-9 col-xs-6">
        <button mat-raised-button (click)="addNewRecord()" type="button" style="margin-left:10px; margin-top:10px">
        <mat-icon aria-hidden="false">add</mat-icon>&emsp;
        </button>
        <button mat-raised-button (click)="deleteRows()" [disabled]="!selection.hasValue()" type="button" style="margin-left:10px; margin-top:10px">
        <mat-icon aria-hidden="false">delete</mat-icon>
        &emsp;
        </button>
    </div>
</div>
<mat-card [@slideInOut]="animationState" [hidden]="!showAddNew" class="add-new-card">
    <form [formGroup]="section_details">
        <div class="row">
            <div class="col-md-2 col-xs-12">
            <mat-form-field class="inline-form-field">
                <mat-label>Department ID</mat-label>
                <input matInput formControlName="section_id" required>
            </mat-form-field>
            </div>
            <div class="col-md-3 col-xs-12">
            <mat-form-field class="inline-form-field">
                <mat-label>Department Name</mat-label>
                <input matInput formControlName="section_name" required>
            </mat-form-field>
            </div>
            <div class="col-md-3 col-xs-12">
            <mat-form-field class="inline-form-field">
                <mat-label>Description</mat-label>
                <input matInput formControlName="section_description">
            </mat-form-field>
            </div>
            <div class="col-md-2 col-xs-12">
                <mat-slide-toggle formControlName="active" style="padding-top: 16px;">
                    <div>{{section_details.controls.active.value == '1' ? 'Active' : 'Inactive'}}</div>
               </mat-slide-toggle>
            </div>
            <div class="col-md-2 col-xs-12">
            <button mat-flat-button color="primary" (click)="cancelAddNew()" style="float: right; margin-top:10px; margin-left:10px">Cancel</button>
            <button mat-flat-button color="primary" [disabled]="!section_details.valid" (click)="saveNewRecord()" style="float: right; width: 75px; margin-top:10px">Save</button>

            </div>
        </div>
    </form>
</mat-card>
<table mat-table [dataSource]="sectionDetails" matSort>
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="10%">
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row.section_id) : null"
                        [checked]="selection.isSelected(row.section_id)">
          </mat-checkbox>
        </td>
      </ng-container>
    <ng-container matColumnDef="section_id" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> ID </th>
        <td mat-cell *matCellDef="let row"> 
            <ng-container>
                {{row.section_id}}
            </ng-container>
            <!-- <ng-container *ngIf="row.section_id===editRowId">
                <mat-form-field class="inline-edit" style="max-width:70px">
                    <input matInput name="section id" [(ngModel)]="row.section_id" maxlength="5" required>
                </mat-form-field>
            </ng-container> -->
        </td>
    </ng-container>
    <ng-container matColumnDef="section_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Department Name </th>
        <td mat-cell *matCellDef="let row">        
            <ng-container *ngIf="row.section_id!==editRowId">
                {{row.section_name}}
            </ng-container>
            <ng-container *ngIf="row.section_id===editRowId">
                <mat-form-field class="inline-edit">
                    <input matInput name="section name" [(ngModel)]="row.section_name"  maxlength="50" required>
                </mat-form-field>
            </ng-container>     
        </td>
    </ng-container>
    <ng-container matColumnDef="section_description"  width="40%">
        <th mat-header-cell *matHeaderCellDef mat-sort-heade> Description </th>
        <td mat-cell *matCellDef="let row"> 
            <ng-container *ngIf="row.section_id!==editRowId">
                {{row.section_description}}
            </ng-container>
            <ng-container *ngIf="row.section_id===editRowId">
                <mat-form-field style="width: 100%; padding-right: 30px;">
                    <input matInput name="section description" [(ngModel)]="row.section_description"  maxlength="100">
                </mat-form-field>
            </ng-container>
        </td>
    </ng-container>
    <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
        <td mat-cell *matCellDef="let row"> 
            <ng-container *ngIf="row.section_id!==editRowId">
                <span class="badge" [ngClass]="{'badge-danger': row.active != '1', 'badge-success': row.active == '1'}"> 
                    {{row.active == '1' ? ' Active ' : 'Inactive'}} 
                </span>
            </ng-container>
            <ng-container *ngIf="row.section_id===editRowId">
                <mat-slide-toggle [checked]="row.active == '1' ? true : false"
                (change)="$event.checked ? row.active = '1' : row.active = '0'">
               </mat-slide-toggle>
            </ng-container>
        </td>
    </ng-container>
    <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row ; let i = index" style="justify-content: center; text-align: right;"> 
            <button mat-flat-button *ngIf="row.section_id!==editRowId" (click)="editRow(row)" [disabled]="inEditMode">
                <i class="fa fa-pencil fa-2x" aria-hidden="true"></i>
            </button> 
            <button mat-flat-button *ngIf="row.section_id!==editRowId" (click)="deleteRow(row, i)" [disabled]="inEditMode">
                <i class="fa fa-trash-o fa-2x" aria-hidden="true"></i>
            </button> 
            <ng-container *ngIf="row.section_id===editRowId">
                <button mat-icon-button color="primary" matTooltip="Update" (click)="updateEdit(row)">
                    <i class="fa fa-check fa-2x" aria-hidden="true"></i>
                </button>
                <button mat-icon-button color="warn" matTooltip="Cancel" (click)="cancelEdit(i)" style="margin-left: 25px; margin-right: 10px;">
                    <i class="fa fa-close fa-2x" aria-hidden="true"></i>
                </button>
            </ng-container>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="sectionColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: sectionColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="25"></mat-paginator>