<div class="sidebar-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar *ngIf="isShowHeader$ | async" color="primary">
    <!-- <img src="../../assets/images/mini-logo.png" alt="avatar" class="img-fluid logo-in" style="height: 30px;"> -->
    <!-- <span class="menu-spacer" style="margin-left: 0px;"></span> -->
    <button type="button" mat-icon-button (click)="sidenav.toggle()">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <span class="menu-spacer"></span>
    <!-- <div fxShow="true" fxHide.lt-md="true" class="top-nav-list"> -->
    <a mat-button routerLink="/dashboard" style="padding-left:0px">
      <mat-icon aria-hidden="false">home</mat-icon>
    </a>
    <!-- <a mat-button  routerLink="/new_request">New Request</a>
      <a mat-button *ngIf="userRole == '1' || userRole === '2' || userRole === '3'" routerLink="/progress">Progress</a>
      <a mat-button *ngIf="userRole == '1' || userRole === '2'" routerLink="/users">Users</a>
      <a mat-button *ngIf="userRole == '1' || userRole === '2'" routerLink="/communication">Communication</a>
      <a mat-button *ngIf="userRole == '1' || userRole === '2'" routerLink="/basicData">Basic Data</a>   -->
    <!-- </div> -->
    <div fxShow="true" fxHide.lt-md="true" style="margin-left: auto;">
      <button type="button" mat-icon-button (click)="notifyPanel.toggle(); setNotifySeen()">
        <span *ngIf="notificationCount != 0" class="badge badge-danger" style="position: absolute; right: 0; top: 0;">{{notificationCount}}</span>
        <mat-icon aria-label="Side nav toggle icon" style="margin-right: 10px;">notifications_active</mat-icon>
      </button>

      <img [src]="userImageUrl" (error)="onImageError($event)" class="profile-image img-fluid rounded-circle z-depth-0">
      <button mat-button [matMenuTriggerFor]="menu">{{username}} <mat-icon>expand_more</mat-icon></button>

      <mat-menu #menu="matMenu">
        <a mat-menu-item routerLink="/settings">
          <mat-icon aria-hidden="false">settings</mat-icon>Settings
        </a>
        <a mat-menu-item (click)="sidenav.close(); logout()">
          <mat-icon aria-hidden="false">logout</mat-icon>Logout
        </a>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
    <mat-sidenav #sidenav class="sidenav" opened="true" [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="true" fixedTopGap="45">
      <!-- <mat-toolbar><img src="../../assets/images/mini-logo.png" alt="avatar" class="img-fluid" style="height: 30px;"></mat-toolbar> -->
      <mat-nav-list fxLayout="column">

        <span *ngFor="let item of menu">
          <span *ngIf="item.children && item.children.length > 0">
            <mat-accordion>
              <mat-expansion-panel class="nav-expansion">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div fxLayout="row" fxLayoutAlign="space-between center" >
                      <mat-icon>{{item.iconName}}</mat-icon>
                      &nbsp;&nbsp;{{item.displayName}}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <span *ngFor="let child of item.children">
                  <mat-divider></mat-divider>
                  <mat-list-item routerLink="{{child.route}}" routerLinkActive="active">
                    <div fxLayout="row" fxLayoutAlign="space-between center" >
                      <mat-icon>{{child.iconName}}</mat-icon>
                      &nbsp;&nbsp;{{child.displayName}}
                    </div>
                  </mat-list-item>
                </span>
              </mat-expansion-panel>
              <mat-divider></mat-divider>
            </mat-accordion>
          </span>
          <span *ngIf="!item.children || item.children.length === 0">
            <mat-list-item routerLink="{{item.route}}" routerLinkActive="active">
              <!-- Entradas principales -->
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-icon>{{item.iconName}}</mat-icon>
                &nbsp;&nbsp;{{item.displayName}}
              </div>
            </mat-list-item>
            <mat-divider></mat-divider>
          </span>
        </span>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-loader></app-loader>
      <router-outlet></router-outlet>
    </mat-sidenav-content>

    <mat-sidenav #notifyPanel class="notifyPanel" position="end" opened="false" [mode]="mobileQuery.matches ? 'over' : 'over'"
    [fixedInViewport]="true" fixedTopGap="45">
    <mat-nav-list fxLayout="column">
        <div *ngFor="let notification of notifications" class="alert alert-info notification-bar">
          <button type="button" class="close" (click)="closeNotification(notification.notification_id)" aria-label="close">
            <i class="material-icons">close</i>
          </button>
          <span>
            <b class="clickOn" (click)="showRecord(notification.header, notification.module)">{{notification.header}}</b><br>
            {{notification.message}}
          </span>
        </div>
    </mat-nav-list>
  </mat-sidenav>


  </mat-sidenav-container>
</div>
