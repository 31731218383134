import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';

const URL_get_requester_details = environment.url_blogics + '/requester/get_requester_by_id.php';
const URL_get_requester_details_with_more_info = environment.url_blogics + '/requester/get_requester_by_id_with_more_info.php'; //This is only for special instance
const URL_get_title_list = environment.url_sfeatures+'/basic-data/get_title_list.php';
const URL_get_req_type_list = environment.url_blogics + '/requester/get_requester_type_list.php';
const URL_get_division_list = environment.url_sfeatures+'/basic-data/get_division_list.php';
const URL_update_requester_details = environment.url_blogics + '/requester/update_requester_details.php';
const URL_add_new_requester = environment.url_blogics + '/requester/create_new_requester.php';

@Injectable({
  providedIn: 'root'
})
export class RequesterPanelService {

  constructor(private _http: HttpClient, private _auth: AuthService,) { }
  
  //Correct method for genaral info...
  // getRequesterDetails(nic: string){
  //   return this._http.post(URL_get_requester_details,JSON.stringify({'nic': nic}));
  // }

  //This is only for special instance only
  getRequesterDetails(nic: string){
    return this._http.post(URL_get_requester_details_with_more_info,JSON.stringify({'nic': nic}));
  }

  updateRequesterDetails(formData: FormData){
    return this._http.post(URL_update_requester_details, JSON.stringify(formData));
  }

  addNewRequesterDetails(formData: FormData){
    return this._http.post(URL_add_new_requester, JSON.stringify(formData));
  }

  getTitleList(){
    return this._http.get(URL_get_title_list);
  }

  getRequesterTypeList(){
    return this._http.get(URL_get_req_type_list);
  }

  getDivisionList(){
    return this._http.get(URL_get_division_list);
  }
}
