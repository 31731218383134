import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';


const URL = environment.url_blogics+'/agreement/get_agr_list_by_state.php';

@Injectable({
  providedIn: 'root'
})
export class AgreementProgressService {

  constructor(private httpclient: HttpClient) { }

  getProgressList(state : string) {
    let params = new HttpParams();
    params = params.append('state',state);
    return this.httpclient.get(URL,{params: params});
    //return (this.httpclient.post(URL, JSON.stringify({ "state" : state}))) 
  }
  getProgressSelectedList(state : string, user : string) {
    let params = new HttpParams();
    params = params.append('state',state);
    params = params.append('user_id', user);
    return (this.httpclient.get(URL, {params: params})) 
  }
}
