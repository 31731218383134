import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';


const URL = environment.url_blogics+'/event/get_event_list_by_state.php';

@Injectable({
  providedIn: 'root'
})
export class EventListService {

  constructor(private httpclient: HttpClient) { }

  getEventList(state : string) {
    let params = new HttpParams();
    params = params.append('state',state);
    return this.httpclient.get(URL,{params: params});
  }
  getSelectedEventList(state : string, user : string) {
    let params = new HttpParams();
    params = params.append('state',state);
    params = params.append('user_id', user);
    return this.httpclient.get(URL,{params: params});  
  }
}
