<div class="main-mat">
    <mat-card-title>
        <div class="row head-row">
            <mat-card-title *ngIf="source == 'view&edit'">{{groupId || group_details.controls.group_id.value}}</mat-card-title>
            <mat-card-title *ngIf="source == 'addNew'"><i class="fa fa-users fa-lg" aria-hidden="true"></i>&ensp;New Group</mat-card-title>
            <button mat-icon-button class="btn-close" (click)="onCloseClick()"><mat-icon>close</mat-icon></button>
        </div>
    </mat-card-title>

    <!-- Group details -->
    <mat-card-subtitle>Group details</mat-card-subtitle>
    <mat-card class="in-mat">
        <div class="container">
            <form [formGroup]="group_details" >
                <div class="row">
                    <div class="col-md-9 col-xs-12">
                        <div class="row">               
                            <div class="col-2">Group Id</div>
                            <div *ngIf="!isEditable" class="col-10">
                                {{group_details.controls.group_id.value}}
                            </div>
                            <div *ngIf="isEditable">
                                <mat-form-field class="col-10 inputLabel">
                                    <input type="text" formControlName="group_id" (keydown.space)="$event.preventDefault()" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">               
                            <div class="col-2 col-md-6">Group Name</div>
                            <div *ngIf="!isEditable" class="col-10">
                                {{group_details.controls.group_name.value}}
                            </div>
                            <div *ngIf="isEditable">
                                <mat-form-field class="col-10 inputLabel">
                                    <input type="text" formControlName="group_name" matInput>
                                </mat-form-field>
                            </div>

                        </div>        
                        <div class="row">               
                            <div class="col-2">Group Category</div>
                            <div *ngIf="!isEditable" class="col-10">
                                {{group_details.controls.group_category.value}}
                            </div>
                            <div *ngIf="isEditable">
                                <mat-form-field *ngIf="isEditable" class="col-10 inputLabel">
                                    <mat-select formControlName="group_category" >
                                        <mat-option value="user">USER</mat-option> 
                                        <mat-option value="requester">REQUESTER</mat-option> 
                                    </mat-select>                                     
                                </mat-form-field>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-2">State</div>
                            <div class="col-10">
                                <mat-slide-toggle [checked]="group_details.controls.active.value == '1' ? true : false"
                                 [disabled]="!isEditable" (change)="setState($event)">
                                    <div>{{group_details.controls.active.value == '1' ? 'Active' : 'Inactive'}}</div>
                                </mat-slide-toggle>
                            </div>
                        </div>               
                        <div class="row">               
                            <div class="col-2">Description</div>
                            <div *ngIf="!isEditable" class="col-10">
                                {{group_details.controls.group_desc.value}}
                            </div>
                            <div *ngIf="isEditable">
                                <mat-form-field class="col-10 inputLabel">
                                    <textarea formControlName="group_desc" type="text" matInput required style="height: 20px"></textarea>
                                </mat-form-field>
                            </div>                           
                        </div>                                                                  
                    </div>
                </div>

                <div class="row">
                    <div class="button-line">                      
                        <button mat-raised-button *ngIf="isEditable" (click)="saveDetails()" [disabled]="!group_details.valid" color="primary" type="button" class="mat-button-class" style="float: right; margin-left:10px">Save Details</button>
                        <button mat-raised-button *ngIf="source == 'view&edit'" (click)="onEditCancel()" color="primary" type="button" class="mat-button-class" style="float: right;">{{editBtnName}}</button>
                        <button mat-raised-button *ngIf="source == 'addNew'" (click)="clear()" color="primary" type="button" class="mat-button-class" style="float: right;">Clear</button>
                    </div>
                </div>

            </form>
        </div>
    </mat-card>  
    <!-- end group details -->
   
    <!-- Members details -->
    <mat-card-subtitle>Members</mat-card-subtitle>
    <mat-card class="in-mat">
        <mat-card *ngIf="userList?.length !=0" class="col card-outline box-card">
            <mat-card-header style="display: inline;">   
                <mat-card-title>
                    <div class="row">
                        <div class="col-9" style="padding:0px">
                            <mat-form-field class="box-card-search">  
                                <i matPrefix class="fa fa-search" style="width: 25px;" aria-hidden="true"></i>
                                <input type="text" matInput [formControl]="userSearch">
                            </mat-form-field>
                        </div>
                        <i id="count">{{(filteredUserList | async)?.length}}</i>
                    </div>                              
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="box-card-content">   
                <div class="not-found-option" *ngIf="(filteredUserList | async)?.length == 0">No Members</div>                     
                <mat-nav-list>
                    <mat-list-item id="box-list-option" *ngFor="let participant of filteredUserList | async">
                        <img class="list-option-img" [src]="participant.profile_url || '../../assets/images/user-default.png'" alt="avatar" height="30">
                        <div class="mat-list-text">{{participant.first_name}}</div>
                    </mat-list-item>
                </mat-nav-list>                                      
            </mat-card-content>        
        </mat-card>
        <mat-card *ngIf="requesterList?.length !=0" class="col card-outline box-card">
            <mat-card-header style="display: inline;">   
                <mat-card-title>
                    <div class="row">
                        <div class="col-9" style="padding:0px">
                            <mat-form-field class="box-card-search">  
                                <i matPrefix class="fa fa-search" style="width: 25px;" aria-hidden="true"></i>
                                <input type="text" matInput [formControl]="reqSearch">
                            </mat-form-field>
                        </div>
                        <i id="count">{{(filteredReqList| async)?.length}}</i>
                    </div>                              
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="box-card-content">   
                <div class="not-found-option" *ngIf="(filteredReqList | async)?.length == 0">No Members</div>                     
                <mat-nav-list>
                    <mat-list-item id="box-list-option" *ngFor="let participant of filteredReqList | async">
                        <img class="list-option-img" [src]="participant.profile_url || '../../assets/images/user-default.png'" alt="avatar" height="30">
                        <div class="mat-list-text">{{participant.name_in_full}}</div>
                    </mat-list-item>
                </mat-nav-list>                                      
            </mat-card-content>        
        </mat-card>
        <mat-card-subtitle *ngIf="source == 'addNew'">*Adding members will be enabled after save group information</mat-card-subtitle>
        <div class="row">      
            <button mat-flat-button (click)="addOrEditMembers()" [disabled]="source == 'view&edit' ? false : true" color="primary" type="button" style="margin:auto">Add/Edit Members</button>
        </div>
    </mat-card>

<!-- Members details -->

</div>