<mat-card class ="main-mat-card" style="float: none;">
    <mat-card-content>
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <mat-card class="detail-box" style="border-left: 4px solid rgb(49, 112, 143);">
                    <i class="fa fa-cloud-download fa-3x icon-view-box" style="color: rgb(49, 112, 143)" aria-hidden="true"></i>
                    <div style="padding: 16px; position: relative;">
                        <span class="detail-box-value">{{requestsNumData.new}}</span>
                        <span class="detail-box-heading">New</span>
                    </div>
                </mat-card> 
            </div>
            
            <div class="col-md-3 col-sm-6">
                <mat-card class="detail-box" style="border-left: 4px solid rgb(255, 152, 0);">
                    <i class="fa fa-refresh fa-3x icon-view-box" style="color: rgb(255, 152, 0)" aria-hidden="true"></i>
                    <div style="padding: 16px; position: relative;">
                        <span class="detail-box-value">{{requestsNumData.in_progress}}</span>
                        <span class="detail-box-heading">In Progress</span>
                    </div>
                </mat-card> 
            </div>

            <div class="col-md-3 col-sm-6">
                <mat-card class="detail-box" style="border-left: 4px solid rgb(76, 175, 80);">
                    <i class="fa fa-handshake-o fa-3x icon-view-box" style="color: rgb(76, 175, 80)" aria-hidden="true"></i>
                    <div style="padding: 16px; position: relative;">
                        <span class="detail-box-value">{{requestsNumData.completed}}</span>
                        <span class="detail-box-heading">Closed</span>
                    </div>
                </mat-card> 
            </div>

            <div class="col-md-3 col-sm-6">
                <mat-card class="detail-box" style="border-left: 4px solid rgb(192, 0, 255);">
                    <i class="fa fa-arrows-alt fa-3x icon-view-box" style="color: rgb(192, 0, 255)" aria-hidden="true"></i>
                    <div style="padding: 16px; position: relative;">
                        <span class="detail-box-value">{{requestsNumData.all}}</span>
                        <span class="detail-box-heading">All Requests</span>
                    </div>
                </mat-card> 
            </div>
        </div> 
        
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <mat-card class="detail-box" style="border-left: 4px solid #17a2b8">
                    <i class="fa fa fa-clock-o fa-3x icon-view-box" style="color: #17a2b8" aria-hidden="true"></i>
                    <div style="padding: 16px; position: relative;">
                        <span class="detail-box-value">{{requestsNumData.pending_approval}}</span>
                        <span class="detail-box-heading">Pending Approval</span>
                    </div>
                </mat-card> 
            </div>
            
            <div class="col-md-3 col-sm-6">
                <mat-card class="detail-box" style="border-left: 4px solid #343a40">
                    <i class="fa fa-check-circle fa-3x icon-view-box" style="color: #343a40" aria-hidden="true"></i>
                    <div style="padding: 16px; position: relative;">
                        <span class="detail-box-value">{{requestsNumData.approved}}</span>
                        <span class="detail-box-heading">Approved</span>
                    </div>
                </mat-card> 
            </div>

            <div class="col-md-3 col-sm-6">
                <mat-card class="detail-box" style="border-left: 4px solid #007bff">
                    <i class="fa fa-thumbs-up fa-3x icon-view-box" style="color: #007bff" aria-hidden="true"></i>
                    <div style="padding: 16px; position: relative;">
                        <span class="detail-box-value">{{requestsNumData.resolved}}</span>
                        <span class="detail-box-heading">Resolved</span>
                    </div>
                </mat-card> 
            </div>

            <div class="col-md-3 col-sm-6">
                <mat-card class="detail-box" style="border-left: 4px solid rgb(146, 146, 146);">
                    <i class="fa fa-hourglass-half fa-3x icon-view-box" style="color: rgb(146, 146, 146)" aria-hidden="true"></i>
                    <div style="padding: 16px; position: relative;">
                        <span class="detail-box-value">{{requestsNumData.pending}}</span>
                        <span class="detail-box-heading">Pending</span>
                    </div>
                </mat-card> 
            </div>
        </div> 
        
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <mat-card class="detail-box">
                    <mat-card-title>System Counts</mat-card-title>
                    <canvas id="doughnutChart" width="250" height="300"></canvas>
                    <div id="chartjs-tooltip">
                        <div><p></p></div>
                    </div>                  
                </mat-card>
            </div>

            <div class="col-lg-6 mid-col">
                <img src="../../assets/images/background-app-logo.png" alt="avatar" class="img-fluid background-image">
            </div>

            <div class="col-lg-3 col-md-6">
                <mat-card class="detail-box">
                    <mat-card-title>Recent Progress</mat-card-title>
                     <canvas id="barChart" width="250" height="300"></canvas>
                </mat-card>
            </div>

        </div>

    </mat-card-content>
</mat-card>

