import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

const URL_get_category_list = environment.url_blogics + '/basic-data/request-category/get_ticket_category_list.php';
const URL_get_sections_list = environment.url_blogics + '/basic-data/section/get_section_list.php';
const URL_update_category = environment.url_blogics + '/basic-data/request-category/update_request_category.php';
const URL_delete_category = environment.url_blogics + '/basic-data/request-category/remove_request_category.php';
const URL_delete_categories = environment.url_blogics + '/basic-data/request-category/remove_request_categories.php';
const URL_add_new_category = environment.url_blogics + '/basic-data/request-category/create_new_request_category.php';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private _http: HttpClient) { }

  getCategoryList(){
    return this._http.get(URL_get_category_list);
  }

  getSectionsList(){
    return this._http.get(URL_get_sections_list);
  }

  saveNewCategory(formData : FormData){
    return this._http.post(URL_add_new_category, JSON.stringify(formData));
  }

  updateCategory(rowData: any){
    return this._http.post(URL_update_category, JSON.stringify(rowData));
  }

  deleteCategory(rowData: any){
    return this._http.post(URL_delete_category, JSON.stringify(rowData));
  }

  deleteCategories(data: any){
    return this._http.post(URL_delete_categories, JSON.stringify(data));
  }
}
