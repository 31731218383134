<div class="window-title">
  <h2>Requesters</h2>
</div>
<div class="search-div">
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
  <div class="row search-row">
    <div class="col-xl-2 col-sm-5">
      <mat-form-field> 
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Enter any key word">
      </mat-form-field>
    </div>
    <div *ngIf="permission.PERM_ADD_REQUESTER" class="col-xl-3 col-sm-4">
      <button mat-flat-button (click)="addNewRequester()" color="primary" type="button" style="margin-left:10px">
        <mat-icon aria-hidden="false">person_add</mat-icon>&emsp;New Requester</button>
    </div>
  </div>

</div> 
<div class="table-wrapper">
  <table mat-table [dataSource]="requestersDetails" matSort>
    <ng-container matColumnDef="nic">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> RID </th>
      <td mat-cell *matCellDef="let row"> {{row.nic}} </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
      <td mat-cell *matCellDef="let row"> {{row.title}} </td>
    </ng-container>
    <ng-container matColumnDef="name_in_use">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name in Use </th>
      <td mat-cell *matCellDef="let row">{{row.name_in_use}}  </td>
    </ng-container>
    <ng-container matColumnDef="division">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Division </th>
      <td mat-cell *matCellDef="let row"> {{row.division}} </td>
    </ng-container>
    <ng-container matColumnDef="requester_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let row"> {{row.requester_type}} </td>
    </ng-container>
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>     
      <td mat-cell *matCellDef="let row">
        <span class="badge" [ngClass]="{'badge-danger': row.active == '0', 'badge-success': row.active == '1'}"> 
          {{row.active == '1' ? ' Active ' : 'Inactive'}} 
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row" style="justify-content: center; text-align: right;"> 
        <button mat-flat-button (click)="viewRequesterPanel(row.nic)">
          <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
        </button> 
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
</div>